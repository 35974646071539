import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "../styles/Notification.css";

import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import InmunityApi from "../../api/InmunityApi";
import {
	setCurrentTrial,
	getTrialDescription,
	setCurrentNavigationSubLink,
	getSearchForPopulation,
	setCurrentNavigationLink,
} from "../../actions/trialActions";

class NotificationElement extends React.Component {
	getDate = date => {
		let dif = moment.duration(moment().diff(date));
		if (parseInt(dif.asDays()) >= 1) {
			return parseInt(dif.asDays()) + "d";
		} else if (parseInt(dif.asHours()) !== 0) {
			return parseInt(dif.asHours()) + "h";
		} else {
			return parseInt(dif.asMinutes()) + "m";
		}
	};
	onClickNotification = () => {
		this.props.readNotification(this.props.index);
		if (this.props.item.type === "trial") {
			this.props.setCurrentNavBarLink(this.props.item.payload.trialId);
			this.props.getTrialDescription(this.props.item.payload.trialId);
			this.props.setCurrentTrial(this.props.item.payload.trialId);
			window.location = `/trials/${this.props.item.payload.trialId}/matches`;
		} else if (this.props.item.type === "search") {
			this.getPartionSearchInfo(this.props.item.payload.searchId);
		} else if (this.props.item.type === "workflow") {
			this.goToTrials(this.props.item);
		} else if (this.props.item.type === "reminder") {
			this.props.history.push({
				pathname: "/dashboard",
				params: {
					reminderId: this.props.item.payload.reminderId,
				},
			});
		}
	};

	goToTrials = item => {
		InmunityApi.getPatientStatus(
			item.payload.patientId,
			item.payload.trialId
		).then(res => {
			this.props.setCurrentNavBarLink(item.payload.trialId);
			this.props.getTrialDescription(item.payload.trialId);
			this.props.setCurrentTrial(item.payload.trialId);
			switch (res.status) {
				case "match for trial":
					this.props.relocate(`/trial/${item.payload.trialId}/matches`);
					setCurrentNavigationLink(3);
					this.props.relocate({
						pathname: `/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`,
						detailTitle: "Match Details",
					});
					break;
				case "candidate for trial":
					this.props.relocate(`/trial/${item.payload.trialId}/candidates`);
					setCurrentNavigationLink(3);
					this.props.relocate({
						pathname: `/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`,
						detailTitle: "Candidate Details",
					});
					break;
				case "excluded from trial":
					this.props.relocate(`/trial/${item.payload.trialId}/exclusion`);
					setCurrentNavigationLink(3);
					this.props.relocate({
						pathname: `/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`,
						detailTitle: "Patient Details",
					});
					break;
				case "enrolled for trials":
					this.props.relocate(`/trial/${item.payload.trialId}/enrolls`);
					setCurrentNavigationLink(3);
					this.props.relocate({
						pathname: `/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`,
						detailTitle: "Patient Details",
					});
					break;
				case "excluded from all trials":
					this.props.relocate(`trials-exclusions`);
					setCurrentNavigationLink(3);
					this.props.relocate({
						pathname: `/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`,
						detailTitle: "Patient Details",
					});
					break;
				default:
					return;
			}
		});
	};
	getPartionSearchInfo = id => {
		InmunityApi.getPatientSearchInfo(id)
			.then(resp => {
				if (resp.status !== 200) {
					this.setState({
						open: true,
						loadingMessage: "An error occured while receiving result",
						closePopupOnError: true,
					});
				}
				return resp.json();
			})
			.then(info => {
				if (info.count === 0) {
					this.setState({
						open: true,
						loadingMessage: "Results not found",
						closePopupOnError: true,
					});
				} else {
					this.setState({ flag: true });
					this.props.history.push({
						pathname: `/patients-search/${id ? id + "/" : ""}`,
					});
				}
			})
			.catch(e => {
				this.props.history.push("/patients");
				this.props.getSearchForPopulation(id);
			});
	};

	render() {
		return (
			<div
				className={this.props.nonRead ? "notElement nonRead" : "notElement"}
				onClick={() => this.onClickNotification()}
			>
				<div className="notText">
					<span>{this.props.item.message}</span>
				</div>
				<div className="notHrs">
					<span>{this.getDate(this.props.item.date)}</span>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getTrialDescription: id => dispatch(getTrialDescription(id)),
		setCurrentTrial: id => dispatch(setCurrentTrial(id)),
		setCurrentNavBarLink: id => dispatch(setCurrentNavigationSubLink(id)),
		relocate: path => dispatch(push(path)),
		getSearchForPopulation: id => dispatch(getSearchForPopulation(id)),
		setCurrentNavigationLink: id => dispatch(setCurrentNavigationLink(id)),
	};
}

export default withRouter(
	connect(null, mapDispatchToProps)(NotificationElement)
);
