import { getAuthDataNotification } from "./socketInstance";
import ReconnectingWebSocket from "reconnecting-websocket";

export const getTicket = () => {
	return getAuthDataNotification("api/auth").then(res => {
		return res.ticket;
	});
};
var connected = false;

const urlProvider = async () => {
	if (!publicURL.includes(window.location.pathname)) {
		const ticket = await getTicket();
		return `${process.env.REACT_APP_SOCKET_URL}?ticket=${ticket}`;
	} else {
		return null;
	}
};
var publicURL = ["/", "/patient-details/", "/patient-authorization/"];

var rws;
//
export const socketConnect = mes => {
	rws = new ReconnectingWebSocket(urlProvider, [], {
		reconnectInterval: 3000,
		reconnectDecay: 5000,
	});
	rws.addEventListener("open", () => {
		connected = true;
		console.log("Socket Connected");
	});
	rws.addEventListener("message", message => {
		mes(JSON.parse(message.data));
	});
};

export const sendMessage = message => {
	if (connected) {
		rws.send(JSON.stringify(message));
	}
};
