import React, {Component} from "react";
import "./index.css";
import moment from "moment";
import InmunityApi from "../../../../api/InmunityApi";
import {
  setPatientsResult,
  setPatientsFilterResult,
  getSearchForPopulation
} from "../../../../actions/trialActions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Popup from "reactjs-popup";
import crossicon from "./../../../../images/icon-close-w.svg";

class SearchBlock extends Component {
  state = {
    id: this.props.id,
    open: false,
    message: "",
    closePopupOnError: false,
    flag: false
  };

  closeModal = () => {
    this.setState({open: false, message: "", closePopupOnError: false});
  };

  getPrepopulationOfSearch = ({target}) => {
    this.props.dispatch(getSearchForPopulation(this.state.id));
    let searchBlock = target.parentNode;
    let parentOfsearchBlock = searchBlock.parentNode.parentNode;
    if (parentOfsearchBlock.querySelector(".searchBlock-active")) {
      parentOfsearchBlock
        .querySelector(".searchBlock-active")
        .classList.remove("searchBlock-active");
    }
    searchBlock.classList.add("searchBlock-active");
  };

  componentWillUnmount() {
    if (this.state.flag) {
      this.props.dispatch(getSearchForPopulation(this.state.id));
    }
  }

  getResults = (id) => {
    const {dispatch, history} = this.props;
    return (
      InmunityApi.getPatientSearchInfo(id)
        .then(resp => {
          if (resp.status === 400) {
          }
          this.setState({flag: true});
          return resp.json();
        })
        .then(info => {
          if (info.count === 0) {
            this.setState({
              open: true,
              loadingMessage: "Results not found",
              closePopupOnError: true
            });
          } else {
            let nullObj = {};
            dispatch(setPatientsResult(nullObj));
            dispatch(setPatientsResult(info));
            history.push({
              pathname: `/patients-search/${id}`
            });
          }
        })
        .catch(error => {
          this.setState({
            loadingMessage: "Error occured while loading a search.",
            closePopupOnError: true
          });
        })
    )
  }
  giveActive = () => {
    const {hasResults, dispatch, history, id} = this.props;
    if (!hasResults) {
      return;
    }
    this.setState({
      open: true,
      loadingMessage:
        "Your search is in progress and results will load shortly."
    });

    if (hasResults === false) {
      this.setState({
        open: true,
        loadingMessage: "Results not found",
        closePopupOnError: true
      });
      setTimeout(() => {
        this.setState({
          open: false
        })
      }, 3000)
    } else if (hasResults === true) {
      setTimeout(() => this.props.history.push({
        pathname: `/patients-search/${id + '/'}`,
      }), 1956)
    } else if (hasResults === undefined || hasResults === null) {
      this.setState({
        open: true,
        loadingMessage: "Error occured while creating a search",
        closePopupOnError: true
      });
      setTimeout(() => this.setState({open: false}), 2000)
    }
  };

  render() {
    let {loadingMessage, closePopupOnError, open} = this.state;
    const {created, name, hasResults, index} = this.props;

    return (
      <div className="searchBlock forImportPopup" key={index}>
        <Popup
          open={open}
          closeOnDocumentClick={closePopupOnError}
          onClose={this.closeModal}
          className="saveUserPopup"
        >
          <div>
            {closePopupOnError && (
              <div className="closePendingPopupCross" onClick={this.closeModal}>
                <img src={crossicon} alt="Cross"/>
              </div>
            )}
            <div className="saveUserPopupText">{loadingMessage}</div>
            {!closePopupOnError && (
              <div style={{display: "flex", justifyContent: "center"}}>
                <img
                  className="small-spinner"
                  src={require("./../../../../images/spinner.gif")}
                  alt=""
                />
              </div>
            )}
          </div>
        </Popup>
        <div
          className="searchBlockActive"
          onClick={this.getPrepopulationOfSearch}
        />
        <span className="searchBlock__date">
          {moment(created).format("MMM DD - hh:mm A")}
        </span>
        <div className="searchBlock__bottom">
          <span className="searchBlock__countCT">{name}</span>
          <div
            className={
              hasResults
                ? "searchBlock__search"
                : "searchBlock__search searchBlock__search-disabled"
            }
            onClick={this.giveActive}
          >
            {hasResults ? "Search again" : "No results"}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userToken: state.userToken,
  };
}

export default withRouter(connect(mapStateToProps)(SearchBlock));
