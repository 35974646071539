import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function newMedicationsReducer(
  state = initialState.newMedications,
  action
) {
  switch (action.type) {
    case types.NEW_MEDICATIONS_SUCCESS:
      return action.newMedications;
    default:
      return state;
  }
}
