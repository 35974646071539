import React, {useState} from 'react'
import Select from 'react-select'
import { Search } from './search'
import inmunityApi from "../api/InmunityApi";
import './sorting.css'
export function Sort ({
  options,
  selectedOption,
  isRevert,
  result,
  revert,
  select,
  exportCSV,
  change,
  press,
  paths,
  name,
  currentTrial,
  searching = true
}) {
  const [value, changeValue] = useState ("")
  const hocChange = (e) => {
    changeValue(e.target.value) 
  change(e)
  }

  const getCSVexport = () => {
    let ordering = isRevert ? "-" + selectedOption.value : selectedOption.value
    const download = (url) => {
      const element = document.createElement('a');
      element.setAttribute('href', url.slice(1,-1));
      element.setAttribute('download', "none");

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
    const decoder = new TextDecoder('utf-8')
    inmunityApi.getCSVexport(currentTrial, paths, value, ordering).then((response) => {
        return response.body.getReader().read().then(({done, value}) => decoder.decode(value))
      }).then(res => {
        download(res)
      })
  }
  return (
    <div className='inputSearch'>
      <div className='isimginputWrapp'>
        <Search change={hocChange} press={press} />
        <div className='searchLengthResult'>
          {searching === true && result}{' '}
          {typeof result === 'number' && searching
            ? result === 0
              ? 'results'
              : result === 1
                ? 'result'
                : 'results'
            : ''}
        </div>
      </div>
      <div className='isssWrapp trialsPage'>
        {!["/trials", "/trials-matches", "/all-trials", "/trials-exclusions", "/trials-enrolled"].includes(window.location.pathname) && (
          <div className='ntw__headerWrapp-btn' onClick={() => getCSVexport()}>
            Export
          </div>
        )}
        <div className='ntw__headerWrapp-btn' onClick={revert}>
          {isRevert
            ? selectedOption.value === 'encounter' ||
              selectedOption.value === 'rate' ||
              selectedOption.value === 'immunity' ||
              selectedOption.value === 'age' ||
              selectedOption.value === 'visit'
              ? '9 - 0' : 
              selectedOption.value === 'last_seen' || 
              selectedOption.value === 'next_visit' ||
              selectedOption.value === 'comsented' || 
              selectedOption.value === 'date' || 
              selectedOption.value === 'enrolled'
              ? "DESC"
              : 'Z - A'
            : selectedOption.value === 'encounter' ||
              selectedOption.value === 'rate' ||
              selectedOption.value === 'immunity' ||
              selectedOption.value === 'age' ||
              selectedOption.value === 'visit'
              ? '0 - 9' :
              selectedOption.value === 'last_seen' || 
              selectedOption.value === 'next_visit' ||
              selectedOption.value === 'comsented' || 
              selectedOption.value === 'date' || 
              selectedOption.value === 'enrolled'
              ? "ASC"
              : 'A - Z'}{' '}
        </div>
        <span>Sort by:</span>
        <Select
          value={selectedOption}
          onChange={select}
          options={options}
          className={'sort_select'}
        />
      </div>
    </div>
  )
}
