import React from "react";
import drug_type_image from "./../../../../../images/drug_type.svg";

export default function DrugTypeItem({ drug_type }) {
  return (
    <div className="infoItem">
      <img src={drug_type_image} alt="Drug type" />
      <div className="tfc-tc">
        <div className="tfc-title">Drug Type</div>
        <div className="tfc-content">
          <span data-test="drugType">{drug_type ? drug_type : "no data"}</span>
        </div>
      </div>
    </div>
  );
}
