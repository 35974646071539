import React, { useState, useEffect, useContext, useRef } from "react";
import checkInFalse from "./../../../../../images/exclusion_inclusion/plus_empty.svg";
import checkExFalse from "./../../../../../images/exclusion_inclusion/minus_empty.svg";
import checkInTrue from "./../../../../../images/exclusion_inclusion/plus_full.svg";
import checkExTrue from "./../../../../../images/exclusion_inclusion/minus_full.svg";
import PickerSendContext from "./../../PickerContext";
import capitalize from "../../../../../utils/capitalize";

const SelectItem = props => {
	let [checkInclude, setCheckInclude] = useState(false);
	let [checkExclude, setCheckExclude] = useState(false);
	const activeElementRef = useRef(null);
	const {
		name,
		id,
		setCodes,
		parentId,
		search,
		filter,
		activeTab,
		list,
		include,
	} = props;
	const forSending = useContext(PickerSendContext);

	const handleCheckboxChange = e => {
		const attrName = e.target.parentNode.getAttribute("name");
		let checkedInclude = checkInclude;
		let checkedExclude = checkExclude;
		if (attrName === "include") {
			checkedInclude = !checkInclude;
			setCheckInclude(checkedInclude);
			if (checkedInclude) {
				if (checkExclude) {
					setCheckExclude(false);
					checkExclude = false;
					checkedExclude = false;
				}
			}
		} else if (attrName === "exclude") {
			checkedExclude = !checkExclude;
			setCheckExclude(checkedExclude);
			if (checkedExclude) {
				if (checkInclude) {
					setCheckInclude(false);
					checkInclude = false;
					checkedInclude = false;
				}
			}
		} else {
		}
		sendIncludeExclude(checkedInclude, checkedExclude);
	};

	const sendIncludeExclude = (checkedInclude, checkedExclude) => {
		const criteria = checkFilter();
		const itemsLength = list.length;
		if (checkedInclude || checkedExclude) {
			setCodes({
				id,
				include: checkedInclude,
				criteria,
				hasLeaf: false,
				hasChildren: false,
				activeTab,
				name,
				parentId,
				itemsLength,
			});
		} else {
			setCodes({
				id,
				include: "delete",
				criteria,
				hasLeaf: false,
				hasChildren: false,
				activeTab,
				name,
				parentId,
				itemsLength,
			});
		}
	};

	const checkFilter = () => {
		let res;
		switch (filter) {
			case "Medication Filters":
				res = "Medication";
				break;
			case "Disease Filters":
				res = "Disease";
				break;
			case "Procedure History":
				res = "Procedure";
				break;
			case "Allergy Filters":
				res = "Allergy";
				break;
			default:
				return null;
		}
		return res;
	};

	useEffect(() => {
		forSending.forEach(el => {
			if (el.id === id) {
				if (el.include === true) {
					setCheckInclude(true);
					setCheckExclude(false);
				} else if (el.include === false) {
					setCheckExclude(true);
					setCheckInclude(false);
				} else if (el.include === "delete") {
					setCheckExclude(false);
					setCheckInclude(false);
				}
			}
		});
	}, [forSending, id, parentId]);

	useEffect(() => {
		if (include) {
			const { id: catId, include: inc, exclude: exc } = include;
			if (catId === parentId) {
				(async () => {
					await setCheckInclude(inc);
					await setCheckExclude(exc);
					await sendIncludeExclude(inc, exc);
				})();
			}
		}
	}, [include, sendIncludeExclude, parentId]);

	const scrollView = () => {
		if (
			!props.myRef ||
			!props.myRef.current ||
			!activeElementRef ||
			!activeElementRef.current
		) {
			return;
		}
		let element = props.myRef.current;

		let interval = window.setInterval(function () {
			if (activeElementRef.current === null) {
				window.clearInterval(interval);
				return;
			}
			element.scrollTop += 10;
			if (
				activeElementRef.current.offsetTop +
					activeElementRef.current.clientHeight -
					element.clientHeight <=
				element.scrollTop
			) {
				window.clearInterval(interval);
			}
		}, 5);

	};

	useEffect(() => {
		if (!search) return;
		scrollView();
	}, [search, scrollView]);

	return (
		<div className="itemValue itemDis">
			<div style={{ display: "flex", alignItems: "center" }}>
				{search ? (
					<>
						<span ref={activeElementRef} className="search-value">
							{checkFilter() === "Medication" ? capitalize(name) : name}
						</span>
						<div className="search-ball" />
					</>
				) : (
					<span className="search-value">
						{checkFilter() === "Medication" ? capitalize(name) : name}
					</span>
				)}
			</div>
			<div className="checkboxes-container">
				<div
					name="exclude"
					onClick={handleCheckboxChange}
					className="plus_minus_wrapp"
				>
					<img
						src={checkExclude ? checkExTrue : checkExFalse}
						alt="exclusion"
					/>
				</div>
				<div
					name="include"
					onClick={handleCheckboxChange}
					className="plus_minus_wrapp"
				>
					<img
						src={checkInclude ? checkInTrue : checkInFalse}
						alt="inclusion"
					/>
				</div>
			</div>
		</div>
	);
};
export default SelectItem;
