import React from "react";
import PatientsBySite from "./PatientsBySite";
import SiteDetails from "./SiteDetails";

export default function SearchSites({
  sites,
  site,
  hospitals,
  patientSpinner,
}) {
  return (
    <div className="search_sites_wrapp">
      <PatientsBySite
        sites={sites}
        spinner={patientSpinner}
        site={site.items}
        siteSpinner={site.loading}
      />
      <div className="pograyLine sites_pogray_line" />
      <SiteDetails
        hospitals={hospitals.items}
        spinner={hospitals.loading}
        sites={sites}
      />
    </div>
  );
}
