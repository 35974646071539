import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {
  FETCH_PATIENT_SEARCH_RESULT_ERROR,
  FETCH_PATIENT_SEARCH_RESULT_PAGINATED_ERROR,
  FETCH_PATIENT_SEARCH_RESULT_PAGINATED_START, FETCH_PATIENT_SEARCH_RESULT_PAGINATED_SUCCESS,
  FETCH_PATIENT_SEARCH_RESULT_START,
  FETCH_PATIENT_SEARCH_RESULT_SUCCESS,
  SORT_PATIENT_SEARCH_RESULT_ERROR,
  SORT_PATIENT_SEARCH_RESULT_START,
  SORT_PATIENT_SEARCH_RESULT_SUCCESS
} from "../actions/searchResultActions";

export default function setPatientsResult(state = initialState.patientsResult, action) {
  switch(action.type) {
    case types.PATIENTS_RESULT:
      return action.patientsResult
    case FETCH_PATIENT_SEARCH_RESULT_START:
      return {
        ...action.patientsResult,
        loading: true,
        error: null
      }
    case FETCH_PATIENT_SEARCH_RESULT_ERROR:
      return {
        loading: false,
        error: action.error
      }
    case FETCH_PATIENT_SEARCH_RESULT_SUCCESS:
      return {
        ...action.patientsResult,
        loading: false,
        error: null,
      }
    case FETCH_PATIENT_SEARCH_RESULT_PAGINATED_START:
      return {
        ...action.patientsResult,
        loading: true,
        error: null
      }
    case FETCH_PATIENT_SEARCH_RESULT_PAGINATED_ERROR:
      return {
        loading: false,
        error: action.error
      }
    case FETCH_PATIENT_SEARCH_RESULT_PAGINATED_SUCCESS:
      return {
        ...action.patientsResult,
        loading: false,
        error: null,
      }
    case SORT_PATIENT_SEARCH_RESULT_START:
      return {
        ...action.patientsResult,
        loading: true,
        error: null
      }
    case SORT_PATIENT_SEARCH_RESULT_ERROR:
      return {
        loading: false,
        error: action.error
      }
    case SORT_PATIENT_SEARCH_RESULT_SUCCESS:
      return {
        ...action.patientsResult,
        loading: false,
        error: null,
      }
    default:
      return state;
  }
}
