import React from 'react'
import { Circle } from 'rc-progress'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './index.css'

const Behavior = ({
  data,
  title,
  second_stat_name,
  third_stat_name,
  first_response_name,
  second_response_name,
  response_cart_name
}) => {
  const { avg_days, min_response, max_response, auth_contacted, chart } = data
  const optionsSmall = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        colors: ['#5BBEBF', '#386A6B'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Patients',
        colorByPoint: true,
        data: [
          {
            name: `${first_response_name} - ${data.responses.interested}`,
            y: data.responses.interested
          },
          {
            name: `${second_response_name} - ${data.responses.not_interested}`,
            y: data.responses.not_interested
          }
        ]
      }
    ]
  }
  const getCharData = () => {
    let data = []
    chart.map(el => {
      data.push({
        name: el.title,
        y: el.value
      })
      return null
    })
    return data
  }
  const optionsBig = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              'black'
          }
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Patients',
        colorByPoint: true,
        data: getCharData()
      }
    ]
  }
  let behavior_responses = [
    {
      title: 'Patient Interested',
      value: data.responses.interested,
      color: '#5BBEBF'
    },
    {
      title: 'Patient not interested',
      value: data.responses.not_interested,
      color: '#386A6B'
    }
  ]
  return (
    <div className='containerForAnalytics physicianBehavior'>
      <div className='cfa__phb'>
        <span className='forcfa__label'>{title}</span>
        <div className='chartBlock phb__chbl'>
          <div className='rg__chbl-ls'>
            <span className='insideLabelAnalytics'>
              Total Responses vs Contacted
            </span>
            <Circle
              percent={auth_contacted && auth_contacted.percent}
              strokeWidth='2'
              trailWidth='0'
              strokeColor='#5BBEBF'
              className='lastenCircle'
            />
            <span className='circleNumber'>
              {auth_contacted && auth_contacted.percent}%
            </span>
            <span className='circleSecNumber'>
              {auth_contacted && auth_contacted.authorized}/
              {auth_contacted && auth_contacted.contacted}
            </span>
          </div>
          <div className='rg__chbl-cls'>
            <div className='rg__chbl-cs-section'>
              <span className='insideLabelAnalytics'>Avg. Response time</span>
              <div className='numberDays'>
                <span className='number'>{avg_days}</span>
                <span className='daystext'>Days</span>
              </div>
            </div>
            <div className='rg__chbl-cs-section'>
              <span className='insideLabelAnalytics'>{second_stat_name}</span>
              <div className='numberDays'>
                <span className='number'>{min_response}</span>
                <span className='daystext'>Days</span>
              </div>
            </div>
            <div className='rg__chbl-cs-section'>
              <span className='insideLabelAnalytics'>{third_stat_name}</span>
              <div className='numberDays'>
                <span className='number'>{max_response}</span>
                <span className='daystext'>Days</span>
              </div>
            </div>
          </div>
          <div className='rg__chbl-crs'>
            <span className='insideLabelAnalytics'>Responses</span>
            <div className='pieChart-wrapp analyticsPhBResponses'>
              {behavior_responses && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsSmall}
                />
              )}
            </div>
          </div>
          {chart && (
            <div className='right-pie-chart'>
              <span className='insideLabelAnalytics'>{response_cart_name}</span>
              <div className='pieChart-wrapp analyticsPhBResponses'>
                <HighchartsReact highcharts={Highcharts} options={optionsBig} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Behavior
