import React from "react";

export default function LeafSpinner() {
  return (
    <div className="leaf-spinner">
      <div className="spinner-container" style={{ height: "100%" }}>
        <img
          className="medium-spinner"
          src={require("./../../../../images/spinner.gif")}
          alt="Spinner"
        />
      </div>
    </div>
  );
}
