import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import sexes from "./../../../../../images/sexes.svg";
import age_range from "./../../../../../images/age_range.svg";

class Sexes extends Component {
  state = {
    minValue: this.props.minAge,
    maxValue: this.props.maxAge,

    checked_smoking: this.props.smoking,
    checked_substance: this.props.substance
  };

  render() {
    let gen = "";
    const { gender } = this.props;
    const { minValue, maxValue } = this.state;
    if (gender === "M") {
      gen = "Male";
    } else if (gender === "F") {
      gen = "Female";
    } else if (gender === "") {
      gen = "No data in field";
    } else {
      gen = "Male, Female";
    }
    return (
      <div className="filters-sexes">
        <div className="trialFactsInfoItems">
          <div className="infoItem">
            <img src={sexes} alt="Sex" />
            <div className="tfc-tc">
              <div className="tfc-title">Sexes eligible for study</div>
              <div className="tfc-content gender_text">{gen}</div>
            </div>
          </div>
          <div className="infoItem">
            <img src={age_range} alt="Age range" />
            <div className="tfc-tc">
              <div className="tfc-title">Age range</div>
              <div className="tfc-content">
                {minValue} - {maxValue}
              </div>
            </div>
          </div>
          <div className="infoItem" />
        </div>
        {/* <div className="">
          <span className="filters-social__title">Social History</span>
          <div style={{ marginTop: "20px" }} className="trialFactsInfoItems">
            <div className="infoItem">
              <img src={smoke} alt="Smoke" />
              <div className="tfc-tc">
                <div className="tfc-title">Smoking permited</div>
                <div className="tfc-content smoke_text">
                  {smoking ? "Yes" : "No"}
                </div>
              </div>
            </div>
            <div className="infoItem">
              <img src={subst} alt="Substance" />
              <div className="tfc-tc">
                <div className="tfc-title">Substance use permited</div>
                <div className="tfc-content substance_text">
                  {substance ? "Yes" : "No"}
                </div>
              </div>
            </div>
            <div className="infoItem" />
          </div>
        </div> */}
      </div>
    );
  }
}

Sexes.propTypes = {
  gender: PropTypes.string,
  smoking: PropTypes.bool,
  substance: PropTypes.bool,
  minAge: PropTypes.number,
  maxAge: PropTypes.number
};

export default Sexes;
