import React from "react";
import Sexes from "./Sexes";
import "./index.css";

const Filters = ({
  setInfo,
  genderError,
  defaultGender,
  defaultAgeMin,
  defaultAgeMax,
  defaultSmokingStatus,
  defaultSubstanceStatus,
  defaultMedicationAllergy
}) => {
  return (
    <div className="filters-for-info">
      <h1>Eligibility Criteria</h1>
      <span className="ntw__filters__demographic">Demographic data</span>
      <Sexes
        setInfo={setInfo}
        genderError={genderError}
        defaultGender={defaultGender}
        defaultAgeMin={defaultAgeMin}
        defaultAgeMax={defaultAgeMax}
        defaultSmokingStatus={defaultSmokingStatus}
        defaultSubstanceStatus={defaultSubstanceStatus}
        defaultMedicationAllergy={defaultMedicationAllergy}
      />
    </div>
  );
};
export default Filters;
