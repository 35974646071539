import React from "react";
import DataStructured from "./../DataStructured";
import _ from "lodash";

const FreetextBlock = ({
	defaultEligibilityCriteria,
	freeText,
	setInfo,
	showPicker,
	selectedCriteria,
	data,
	newFdata,
}) => {
	const removeBlock = () => {
		let newAllData = [...defaultEligibilityCriteria];
		newAllData = newAllData.filter(el => !_.isEqual(el, data));
		setInfo("eligibility_criteria", newAllData);
	};
	return (
		<div className="list">
			<div>{freeText}</div>
			<div className="search_container ntw__ti-inputs newTrialWrapp newWrappElBlockSearch">
				<div className="searchInputs EC-main__listfields-extrawrapp">
					{
						<DataStructured
							data={[data]}
							allData={defaultEligibilityCriteria}
							showPicker={showPicker}
							flag={selectedCriteria}
							setInfo={setInfo}
							freeTextBlock={true}
							newFdata={newFdata}
							freeTextVersion={false}
						/>
					}
				</div>
			</div>
			<div className="red-cross close-block" onClick={removeBlock} />
		</div>
	);
};

export default FreetextBlock;
