import React, { useState, useEffect } from "react";
import NewSearch from "./NewSearch";
import RecentSearch from "./RecentSearch";
import i18n from "../../i18n";
import { GlobalSpinner } from "./../../ui/spinner";
import TreeSelect from "./TreeSelect";
import {
	showPicker,
	setCurrentNavigationLink,
	setGlobalSpinner,
} from "../../actions/trialActions";
import { connect } from "react-redux";
import "./index.css";

const Patients = ({
	setCurrentNavigationLink,
	showPicker,
	showPickerData,
	setGlobalSpinner,
	globalSpinner,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			showPicker({ status: false, filter: "", showLab: false, version: null });
		};
	}, []);
	const [medicationObject, setMedicationObject] = useState([]);
	const [allData, setAllData] = useState([]);
	const [update, forceUpdate] = useState(false);
	const toggleUpdate = () => {
		forceUpdate(!update);
	};
	setCurrentNavigationLink(2);
	const sendForMedication = medicationObject => {
		let data = [...allData, ...medicationObject];
		setAllData(data);
		setMedicationObject(medicationObject);
	};

	const removeData = data => {
		setAllData(data);
	};


	if (globalSpinner) {
		return <GlobalSpinner />;
	}
	return (
		<div className="container-n">
			<h1 className="title-container">{i18n.t("menu.patients")}</h1>
			<div className="matchDetailsWrapper patientsSearch">
				<div className="patientsSearch-ls">
					<NewSearch
						cleanMedicationArr={() => setMedicationObject([])}
						medicationArray={medicationObject}
						setAllData={removeData}
						toggleUpdate={toggleUpdate}
					/>
				</div>
				<div className="patientsSearch-rs">
					<RecentSearch update={update} />
				</div>
				<div className="forImportPopup disPopup">
					{showPickerData.status && (
						<TreeSelect
							sendForMedication={sendForMedication}
							allData={allData}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
function mapStateToProps(state) {
	return {
		showPickerData: state.showPicker,
		globalSpinner: state.globalSpinner,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setCurrentNavigationLink: navID =>
			dispatch(setCurrentNavigationLink(navID)),
		showPicker: () => showPicker({ status: false, filter: "" }),
		setGlobalSpinner: e => dispatch(setGlobalSpinner(e)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
