import React, {Component} from "react";
import "./index.css";
import moment from 'moment'
import Popup from 'reactjs-popup'
import LoadMoreBtn from "../../ui/loadmore";
import InmunityApi from "../../api/InmunityApi";
import {MicroSpinner, SpinnerSmall} from "../../ui";
import crossicon from "../../images/icon-close-w.svg";
import i18n from "../../i18n";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Toggle from "react-toggle";

class ImportPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      open: false,
      loading: true,
      result: [],
      nextResult: null,
      roleName: "",
      editRole: this.editRole,
      loadMore: false,
      isMore: false,
      importInProgress: false,
      showError: false,
      errors: []
    };
  }

  componentDidMount() {
    this.getTableData(null)
  }

  getTableData = () => {
    let haveResults = !this.state.result
    this.setState({loading: haveResults, isMore: true})
    InmunityApi.getPatientImportHistory(this.state.nextResult)
      .then(res => {
        this.setState({
          result: this.state.result.concat(res.results),
          nextResult: res.next,
          loading: false,
          loadMore: !!res.next,
          isMore: false
        })
      })
  }

  loadMoreData = () => {
    this.getTableData()
  }

  importFile = e => {
    let input = e.target
    let result = [...this.state.result]
    this.setState({importInProgress: true})
    InmunityApi.uploadPatient(input.files[0])
      .then(res => {
        if (res.status === 201) {
          result.unshift(res.data)
          this.setState({result: result, importInProgress: false})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({importInProgress: false})
  }

  errorModal = (showState, errors) => {
    console.log("error!")
    this.setState({
      showError: showState,
      errors: errors
    })
  }

  render() {
    return (
      <div className="import">
        <h1 className="import__title">Import</h1>

        <div className='attachmentsWrapp right'>
          {this.state.importInProgress ? (
            <div className='addNew'>
              <MicroSpinner />
            </div>
          ) : (
            <div className='addNew'>
              <img
                src={require('../../images/clip.svg')}
                alt='Close Circle'
                height='16px'
                style={{ marginRight: '6px' }}
              />
              Import New
              <input
                id='attachment'
                className='forSending'
                type='file'
                accept=".json,.ndjson,.zip"
                onChange={this.importFile}
              />
            </div>
          )}
        </div>

        <div className="tableHistory">
          <div className="row head">
            <div className="cell">File name</div>
            <div className="cell">Start</div>
            <div className="cell">End</div>
            <div className="cell">Status</div>
            <div className="cell">Errors</div>
          </div>
          {!this.state.loading && this.state.result && (
            this.state.result.map(row => {
              return (
                <div className="row" key={row.id}>
                  <div className="cell left">{row.filename}</div>
                  <div className="cell dates">{row.start_date ? moment(row.start_date).format("MMM DD YYYY HH:mm:ss") : "-"}</div>
                  <div className="cell dates">{row.finish_date ? moment(row.finish_date).format("MMM DD YYYY HH:mm:ss") : "-"}</div>
                  <div className="cell">{row.status}</div>
                  <div className="cell">{row.error !== null && <div className="show_error" onClick={() => this.errorModal(true, row.error)}>Show errors</div>}</div>
                </div>
              )
            })
          )}
        </div>
        {this.state.loading && (
          <SpinnerSmall />
        )}

        {this.state.loadMore && !this.state.isMore ? (
          <LoadMoreBtn loadMoreData={e => this.loadMoreData(e)}/>
        ) : (
          <SpinnerSmall />
        )}
        <Popup
          open={this.state.showError === true}
          closeOnDocumentClick
          onClose={() => this.errorModal(false, [])}
          className='add-note-contact-popup'
        >
          <div className='add-note-contact-container'>
            <div className="tableHistory">
              <div className="row head">
                <div className="cell">Row</div>
                <div className="cell">Field</div>
                <div className="cell">Issue</div>
              </div>
              {
                this.state.errors.map(row => {
                  return (
                    <div className="row" key={row.row_id}>
                      <div className="cell">{row.row_id}</div>
                      <div className="cell">{row.field}</div>
                      <div className="cell">{row.issue}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default ImportPatient;
