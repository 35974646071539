import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setDiseasesICD9CMListReducer(
  state = initialState.diseasesICD9CMList,
  action
) {
  switch (action.type) {
    case types.SET_DISEASES_ICD9CM_LIST:
      return action.diseasesICD9CMList;
    default:
      return state;
  }
}
