import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function labUomsReducer(state = initialState.labUoms, action) {
  switch(action.type) {
    case types.LABUOMS_SUCCESS:
      return action.labUoms
    default:
      return state;
  }
}