import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import Trials from "./Trials";
import NavLink from "./NavLink";
import logo from "../../../images/logo-w.png";
import home from "../../../images/home-icon.svg";
import user from "../../../images/users-icon.svg";
import trial from "../../../images/trial-icon.svg";
import {
  loadTrials,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../../actions/trialActions";
import "../../styles/Nav.css";

const TrialBar = ({
  currentNavigation,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  loadTrials,
  relocate,
  trials,
  globalSpinner
}) => {
  useEffect(() => {
    loadTrials();
  }, [loadTrials]);

  return (
    <nav className="mdc-permanent-drawer mdc-typography middle-nav">
      <nav className="mdc-list">
        <div className="trialbar-content">
          <div className="logo-nav">
            <img src={logo} className="img-logo-nav" alt="" />
          </div>
          {
            !globalSpinner &&
            (
              <>
                <NavLink
                  to="/dashboard"
                  click={() => {
                    setCurrentNavigationLink(1);
                    relocate("/dashboard");
                  }}
                  active={currentNavigation.navBar === 1}
                  icon={home}
                  text={"Dashboard"}
                />
                <NavLink
                  to=""
                  click={() => {
                    setCurrentNavigationLink(2);
                    relocate("/patients");
                  }}
                  active={currentNavigation.navBar === 2}
                  icon={user}
                  text={"Patients"}
                />
                <NavLink
                  to="/trials"
                  click={() => {
                    setCurrentNavigationLink(3);
                    relocate("/trials");
                  }}
                  active={currentNavigation.navBar === 3}
                  icon={trial}
                  text={`Trials (${trials.length})`}
                />
                {currentNavigation.navBar === 3 && trials.length > 0 && (
                  <div id="trials" className="trial-list-container">
                    <div className="nav-trial-list">
                      <Link
                        to="/all-trials"
                        className={`mdc-list-item middle-nav-item ${
                          currentNavigation.navBarSublink === "all"
                            ? "active-trial"
                            : ""
                          }`}
                        onClick={e => {
                          e.preventDefault();
                          setCurrentNavigationSubLink("all");
                          setCurrentNavigationLink(3);
                          relocate("/all-trials");
                        }}
                      >
                        {"All Trials"}
                      </Link>
                    </div>
                    <Trials />
                  </div>
                )}
              </>
            )
          }
        </div>
      </nav>
    </nav>
  );
};

function mapStateToProps(state) {
  return {
    trials: state.trials,
    currentNavigation: state.currentNavigation,
    globalSpinner: state.globalSpinner
  };
}

function mapDispatchToProps(dispatch) {
  return {
    relocate: path => dispatch(push(path)),
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavigationSubLink: id =>
      dispatch(setCurrentNavigationSubLink(id)),
    loadTrials: () => dispatch(loadTrials())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrialBar);
