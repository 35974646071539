import React from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../../images/icon-close-w.svg";

export default function CriteriaPopup({
                                        criteriaPopup,
                                        closeCriteriaPopup,
                                        selectCriteria,
                                        permissions
                                      }) {
  return (
    <Popup
      open={criteriaPopup}
      closeOnDocumentClick
      onClose={closeCriteriaPopup}
      className="popup-criterias"
    >
      <div>
        <div className="popup-criterias__content">
          <h1>Please select a criteria type.</h1>
          <div className="criteriasBox" onClick={e => selectCriteria(e)}>
            <div className="criteriasBox__row">
              <div className="criteriasBox-item">Medication Filters</div>
              <div className="criteriasBox-item">Disease Filters</div>
              <div className="criteriasBox-item">Lab Filters</div>
            </div>

            <div className="criteriasBox__row">
              <div className="criteriasBox-item">Procedure History</div>
              <div className="criteriasBox-item">Allergy Filters</div>
              <div className="criteriasBox-item">Demographic</div>
            </div>

            {/* <div className="criteriasBox__row">
              <div className="criteriasBox-item">Demographic</div>
              <div className="criteriasBox-item">Smoking</div>
            </div> */}

            <div className="criteriasBox__row">
              <div className="criteriasBox-item">Geography</div>
              <div className="criteriasBox-item">Physician</div>
              <div className="criteriasBox-item">Substance Use</div>
            </div>
            <div className="criteriasBox__row">
              {
                (permissions.view_data || permissions.view_data_own)
                  ? <>
                    <div className="criteriasBox-item">ID Number</div>
                    <div className="criteriasBox-item">Name</div>
                  </>
                  : <div className="criteriasBox-item">ID Number</div>
              }
            </div>
          </div>
        </div>

        <div className="closePendingPopupCross" onClick={closeCriteriaPopup}>
          <img src={crossicon} alt="Cross"/>
        </div>
      </div>
    </Popup>
  );
}
