import React, { useState } from "react";
import PhysiciansTooltip from "../../../../trials/NewTrial/PhysiciansTooltip";

export default function Physician({
  searchPage,
  physicianTooltip,
  findPhysicianName,
  physicianPicked,
  physicianOptions
}) {
  const [show, setShow] = useState(false);
  const hideList = () => {
    setShow(false);
  };
  return (
    <div
      className={
        searchPage
          ? "ntw__ti-input forGettingValueFields width100 postionRelative"
          : "ntw__ti-input forGettingValueFields searchPatientInput"
      }
      style={{ display: "flex" }}
    >
      <span className="ntw__ti-inputlabel">Name</span>
      <input
        type="text"
        name="Physician"
        className="get-freetext-name"
        defaultValue={physicianTooltip.name}
        onChange={e => {
          findPhysicianName(e);
          if (e.target.value && physicianOptions.length > 0) {
            setShow(true);
          }
        }}
      />
      {show && (
        <PhysiciansTooltip
          physiciansNames={physicianTooltip}
          Mpicked={physicianPicked}
          physicianOptions={physicianOptions}
          setShow={hideList}
        />
      )}
    </div>
  );
}
