import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import warningIcon from '../images/warning.svg'
import check from '../images/icon-check.svg'
import close from '../images/icon-close.svg'
import i18n from '../i18n'
import './input.css'
export function SimpleInput ({
  placeholder,
  input,
  label,
  type,
  focus,
  meta: { touched, error }
}) {
  return (
    <div>
      <label className='text-small '>{label}</label>
      <div className='input-field'>
        {focus ? (
          <input
            autoFocus
            {...input}
            placeholder={placeholder}
            type={type}
            className={`text-field simple ${touched && error && 'border-red'}`}
          />
        ) : (
          <input
            {...input}
            placeholder={placeholder}
            type={type}
            className={`text-field simple ${touched && error && 'border-red'}`}
          />
        )}
        {touched && error && (
          <img
            src={warningIcon}
            alt='Error'
            className={'img-warning img-warning-show'}
            id='warningPassword'
          />
        )}
      </div>
    </div>
  )
}

export function SettingsInput ({
  placeholder,
  input,
  label,
  type,
  meta: { touched, error },
  class_name
}) {
  return (
    <div className={class_name}>
      <label className='text-small'>{label}</label>
      <div className='input-field'>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className={`text-field-account simple ${touched &&
            error &&
            'border-red'}`}
        />
        {touched && error && (
          <img src={warningIcon} alt='Error' className={'img-warning'} />
        )}
      </div>
    </div>
  )
}

export function PasswordInput ({
  placeholder,
  input,
  label,
  type,
  meta: { touched, error },
  class_name
}) {
  const [showPopup, setPopup] = useState(false)
  return (
    <div className={`password__input-wrapper ${class_name}`}>
      <label className='text-small'>{label}</label>
      <div className='input-field'>
        <input
          {...input}
          onFocus={() => setPopup(true)}
          onBlur={() => setPopup(false)}
          placeholder={placeholder}
          type={type}
          className={`text-field-account simple ${touched &&
            error &&
            'border-red'}`}
        />
        {showPopup && (
          <div className='password-popup'>
            <div className='password-popup__row'>
              <span className='text-small'>
                {i18n.t('resetPasswordSuccess.validations.characters')}
              </span>
              <img
                className='icon-validation'
                src={error && error.characters ? close : check}
                alt={error && error.characters ? 'Check' : 'Close'}
              />
            </div>
            <div className='div-two-items'>
              <span className='text-small'>
                {i18n.t('resetPasswordSuccess.validations.uppercase')}
              </span>
              <img
                className='icon-validation'
                src={error && error.uppercase ? close : check}
                alt={error && error.uppercase ? 'Check' : 'Close'}
              />
            </div>
            <div className='div-two-items'>
              <span className='text-small'>
                {i18n.t('resetPasswordSuccess.validations.lowercase')}
              </span>
              <img
                className='icon-validation'
                src={error && error.lowercase ? close : check}
                alt={error && error.lowercase ? 'Check' : 'Close'}
              />
            </div>
            <div className='div-two-items'>
              <span className='text-small'>
                {i18n.t('resetPasswordSuccess.validations.number')}
              </span>
              <img
                className='icon-validation'
                src={error && error.number ? close : check}
                alt={error && error.number ? 'Check' : 'Close'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const InputWithMask = ({
  placeholder,
  input,
  label,
  type,
  meta: { touched, error },
  class_name,
  mask
}) => {
  return (
    <div className={class_name}>
      <label className='text-small'>{label}</label>
      <div className='input-field'>
        <MaskedInput
          {...input}
          mask={mask}
          placeholder={placeholder}
          className={`text-field-account simple ${touched &&
            error &&
            'border-red'}`}
          type={type}
        />
        {touched && error && (
          <img src={warningIcon} alt='Error' className={'img-warning'} />
        )}
      </div>
    </div>
  )
}

export function Checkbox ({ input }) {
  return (
    <div>
      <input type='checkbox' id='remember' {...input} />
      <label
        htmlFor='remember'
        className='gray-small-text checkbox-label remeber__block'
      >
        <span />
        <div className='remeber__text'>Remember my login on this computer</div>
      </label>
    </div>
  )
}
