import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setMedicationsATCReducer(
  state = initialState.medicationsATC,
  action
) {
  switch (action.type) {
    case types.SET_MEDICATIONS_ATC:
      return action.medicationsATC;
    default:
      return state;
  }
}
