import * as types from "../actions/actionTypes";

const initialState = localStorage.getItem("geo")
  ? JSON.parse(localStorage.getItem("geo"))
  : "";

export default function setGeoData(state = initialState, action) {
  switch (action.type) {
    case types.GEO_DATA:
      return action.geoData;
    default:
      return state;
  }
}
