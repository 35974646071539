import React, { PureComponent } from "react";
import StudyArm from "./../../StudyArm";
import { connect } from "react-redux";
import VirtualizedSelect from "react-virtualized-select";
import createFilterOptions from "react-select-fast-filter-options";
import "./selectStyles.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import _ from "lodash";

import {
  loadDiseases,
} from "../../../../../actions/trialActions";

const customStyles = {
  control: base => ({
    ...base,
    height: 43
  })
};


class DisOrCondition extends PureComponent {
  state = {
    disOrCon: []
  };


  loadDis = async () => {
    const { defaultProcedures } = this.props;
    await this.props.loadDiseases();


    let diseases = Array.isArray(this.props.diseases) ? this.props.diseases : [];
    diseases = diseases.map((el, i) => ({
      label: el.name,
      value: i
    }));


    this.setState(() => ({
      disOrCon: defaultProcedures,
      disOrConOptions: diseases,
      filterOptions: createFilterOptions({
        options: diseases
      })
    }));


  }

  componentDidMount() {
    this.loadDis();
  }

  componentDidUpdate(props) {
    if (!_.isEqual(props.diseases, this.props.diseases)) {
      this.setState(
        () => ({
          // disOrConOptions: this.props.diseases.map((el, i) => ({
          //   label: el.name,
          //   value: i
          // }))
        }),
        () =>
          this.setState(prevState => ({
            filterOptions: createFilterOptions({
              options: prevState.disOrConOptions
            })
          }))
      );
    }
  }

  handleChangeDisease = disOrCon => {
    this.setState(
      () => ({
        disOrCon
      }),
      () => {
        this.props.setInfo("procedures", disOrCon);
      }
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.props.setInfo(name, value);
  };

  addProcedures = () => {
    let newProcedures = [...this.props.defaultProcedures];
    newProcedures.push({ text: "", id: this.props.defaultProcedures.length });
    this.handleChange({ target: { name: "procedures", value: newProcedures } });
  };

  removeProcedures = index => {
    let newProcedures = [...this.props.defaultProcedures];
    newProcedures.splice(index, 1);
    this.handleChange({ target: { name: "procedures", value: newProcedures } });
  };

  handleChangeProcedures = (index, text) => {
    let newProcedures = [...this.props.defaultProcedures];
    newProcedures[index] = {
      ...newProcedures[index],
      text
    };
    this.handleChange({ target: { name: "procedures", value: newProcedures } });
  };

  addStudyArms = () => {
    let newStudyArms = [...this.props.defalutSudyArms];
    newStudyArms.push({
      name: "",
      percentage: "",
      id: this.props.defalutSudyArms.length
    });
    this.handleChange({ target: { name: "study_arms", value: newStudyArms } });
  };

  removeStudyArms = index => {
    let newStudyArms = [...this.props.defalutSudyArms];
    newStudyArms.splice(index, 1);
    this.handleChange({ target: { name: "study_arms", value: newStudyArms } });
  };

  handleChangeStudyArms = (index, text, name) => {
    let newStudyArms = [...this.props.defalutSudyArms];
    newStudyArms[index][name] = text;
    this.handleChange({ target: { name: "study_arms", value: newStudyArms } });
  };


  render() {
    if (this.props.defalutSudyArms.length === 0) this.addStudyArms();
    if (this.props.defaultProcedures.length === 0) this.addProcedures();
    return (
      <div className="ntw__tf-inputWrapp">
        <div className="ntw__ti-input ntw__tfWrapp">
          <span className="ntw__ti-inputlabel">Drug Type</span>
          <input
            type="text"
            id="drug-type"
            name="drug_type"
            onChange={this.handleChange}
            value={this.props.defaultDrugType}
          />
        </div>
        <div className="ntw__ti-input ntw__tfWrapp">
          <span className="ntw__ti-inputlabel">Drug Administration</span>
          <input
            type="text"
            id="drug-admin"
            name="drug_admin"
            onChange={this.handleChange}
            value={this.props.defaultDrugAdmin}
          />
        </div>
        <div className="ntw__ti-input ntw__proceduresSelect">
          <span className="ntw__ti-inputlabel">Disease or Condition</span>
          <div className="dfdoc">
            <div className="wrappForDisOrCon">
              <VirtualizedSelect
                value={this.state.disOrCon}
                filterOptions={this.state.filterOptions}
                options={this.state.disOrConOptions}
                onChange={this.handleChangeDisease}
                multi={true}
                closeOnSelect={false}
                styles={customStyles}
                className="procedures_select"
              />
            </div>
          </div>
        </div>
        <div className="ntw__ti-input ntw__proceduresSelect">
          <div className="studyArmsSpan">
            <div className="studyArmsSpan__spans">
              <span className="ntw__ti-inputlabel">Study Arms Name</span>
              <span className="ntw__ti-inputlabel">Study Arms %</span>
            </div>
            <div className="ntw__ti-empty" />
          </div>
          {this.props.defalutSudyArms.map((studyarm, index) => {
            return (
              <div
                className={`width100 arm_${index} get_study_arms`}
                key={studyarm.id}
              >
                <StudyArm
                  remove={this.removeStudyArms}
                  add={this.addStudyArms}
                  handleChangeStudyArms={this.handleChangeStudyArms}
                  defaultStudyArms={studyarm}
                  id={index}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    diseases: state.diseases
  };
}

const mapDispatchToProps = {
  loadDiseases
}

export default connect(mapStateToProps, mapDispatchToProps)(DisOrCondition);
