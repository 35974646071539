import React, {Component} from 'react'
import {connect} from 'react-redux'
import MaskedInput from 'react-text-mask'
import InmunityApi from '../../../api/InmunityApi'
import './index.css'
import i18n from '../../../i18n'
import crossicon from './../../../images/icon-close-w.svg'
import Popup from 'reactjs-popup'

class Inputs extends Component {
  state = {
    currentUser: {},
    password: '',
    passwordC: '',
    validPassword: false,
    validPasswordC: false,
    regexPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
    validCharacters: false,
    validUpperCase: false,
    validLowerCase: false,
    validNumber: false,
    regexCharacters: /^([A-Za-z0-9]){8,15}/,
    regexUpperCase: /^(?=.*[A-Z])/,
    regexLowerCase: /^(?=.*[a-z])/,
    regexNumber: /^(?=.*[0-9])/,
    roleOption: '',
    roleOptions: [],
    roleName: '',
    openPopup: false,
    message: ''
  }

  componentDidMount () {
    if (this.props.currentUser !== this.state.currentUser) {
      let currentUser = { ...this.props.currentUser }
      let obj = {}
      if (currentUser.role) {
        obj.value = currentUser.role.id
        obj.label = currentUser.role.name
        this.setState({
          roleOption: obj,
          roleName: currentUser.role.name
        })
        currentUser.role = currentUser.role ? currentUser.role.id : ''
      }
      this.setState({ currentUser }, () => {
        localStorage.setItem(
          'myProfile',
          JSON.stringify(this.state.currentUser)
        )
      })
    }
  }

  handleChangeRoleOption = roleOption => {
    this.setState({ roleOption })
  }

  showPassword = ({ target }) => {
    target.parentNode.nextSibling.classList.toggle('hidden')
  }

  changePassword = () => {
    if (
      this.state.validPassword &&
      this.state.validCharacters &&
      this.state.validUpperCase &&
      this.state.validLowerCase &&
      this.state.validNumber &&
      this.state.validPassword === this.state.validPasswordC
    ) {
      let oldPassword = document.getElementById('oldPassword').value
      let newPassword = document.getElementById('newPassword').value
      let object = {}
      object.newPassword = newPassword
      object.oldPassword = oldPassword
      if (
        this.state.validPassword &&
        this.state.validCharacters &&
        this.state.validUpperCase &&
        this.state.validLowerCase &&
        this.state.validNumber &&
        this.state.validPassword === this.state.validPasswordC
      ) {
        InmunityApi.changePassword(object)
          .then(response => {
            if (response.status !== 200) {
              response.text().then(text => {
                if (
                  (text = "{'new_password': ['This password is too common.']}")
                ) {
                  this.setState({
                    openPopup: true,
                    message:
                      'The password is too weak. Please choose another one.'
                  })
                } else {
                  this.setState({
                    openPopup: true,
                    message: 'Error occured while changing password.'
                  })
                }
              })
            } else {
              this.setState({
                openPopup: true,
                message: 'Password was changed successfully.'
              })
            }
          })
          .catch(error =>
            console.log('Error occured while changing password: ', error)
          )
      }
    } else {
    }
  }

  editUser = ({ target }) => {
    let currentUser = this.state.currentUser
    if (target.id !== 'firstPhone' && target.id !== 'secondPhone') {
      currentUser[target.id] = target.value
    } else if (target.id === 'firstPhone') {
      currentUser[target.id] = target.value
        .split(' ')
        .map(el => {
          if (el[0] === '(') {
            return el.slice(1, -1)
          }
          return el
        })
        .join('')
    } else if (target.id === 'secondPhone') {
      currentUser[target.id] = target.value
        .split(' ')
        .map(el => {
          if (el[0] === '(') {
            return el.slice(1, -1)
          }
          return el
        })
        .join('')
    }
    localStorage.setItem('myProfile', JSON.stringify(currentUser))
  }

  handleInputChange = event => {
    let value = event.target.value
    let name = event.target.name
    let state = this.state

    if (name === 'password') {
      state.validPassword = state.regexPassword.test(value);
      state.validCharacters = value.length >= 8;
      state.validUpperCase = state.regexUpperCase.test(value);
      state.validLowerCase = state.regexLowerCase.test(value);
      state.validNumber = state.regexNumber.test(value);
    }

    if (name === 'passwordC') {
      state.validPasswordC = state.regexPassword.test(value);
    }

    state[name] = value
    this.setState(state, () => {
    })
  }

  closeModal = () => {
    this.setState({ openPopup: false, message: '' })
  }

  render () {
    return (
      <div className='inputs'>
        {this.state.currentUser.firstName && (
          <div className='inputs width100 forImportPopup'>
            <Popup
              open={this.state.openPopup}
              closeOnDocumentClick
              onClose={this.closeModal}
              className='saveUserPopup'
            >
              <div>
                <div
                  className='closePendingPopupCross'
                  onClick={this.closeModal}
                >
                  <img src={crossicon} alt='Cross' />
                </div>
                <div className='saveUserPopupText'>{this.state.message}</div>
              </div>
            </Popup>
            <div className='inputs__name'>
              <div>
                <span>First name</span>
                <input
                  className='myProfileFirstName forSending'
                  id='firstName'
                  onChange={this.editUser}
                  defaultValue={this.state.currentUser.firstName}
                  type='text'
                />
              </div>
              <div>
                <span>Last name</span>
                <input
                  className='myProfileLastName forSending'
                  id='lastName'
                  onChange={this.editUser}
                  defaultValue={this.state.currentUser.lastName}
                  type='text'
                />
              </div>
            </div>
            <div className='inputs__name '>
              <div className='inputs__email-address width100'>
                <span>E-mail address</span>
                <input
                  className='myProfileEmail forSending'
                  id='email'
                  onChange={this.editUser}
                  defaultValue={this.state.currentUser.email}
                  type='email'
                  readOnly={true}
                />
              </div>
            </div>
            <div className='inputs__name '>
              <div className='inputs__phone-number width100'>
                <span>Phone Number</span>
                <MaskedInput
                  mask={[
                    '+',
                    '1',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder='(303) 456 7890'
                  defaultValue={this.state.currentUser.firstPhone}
                  className='forSending phoneNumber'
                  id='firstPhone'
                  onChange={this.editUser}
                />
              </div>
            </div>
            <div className='inputs__name '>
              <div className='inputs__office-number width100'>
                <span>Office or alternate phone</span>
                <MaskedInput
                  mask={[
                    '+',
                    '1',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder='(303) 456 7890'
                  defaultValue={this.state.currentUser.secondPhone}
                  className='forSending officePhone'
                  id='secondPhone'
                  onChange={this.editUser}
                />
              </div>
            </div>
            <div className='inputs_div'>
              <div className='newTrialWrapp'>
                <span>Role</span>
                <input
                  className='myProfileEmail forSending'
                  id='roleName'
                  onChange={this.editUser}
                  value={this.state.roleName}
                  type='text'
                />
              </div>
            </div>
            <div className='inputs__name'>
              <div className='inputs__change-pass' onClick={this.showPassword}>
                Change Password
              </div>
            </div>
            <div className='inputs__name pass hidden'>
              <div>
                <span>Old Password</span>
                <input type='password' id='oldPassword' />
              </div>
              <div className='forPasswordValidation'>
                <span>New Password</span>
                <input
                  type='password'
                  name='password'
                  onChange={this.handleInputChange}
                  id='newPassword'
                />
                <div className='validationStatus'>
                  <div className='div-two-items'>
                    <span className='text-small'>
                      {i18n.t('resetPasswordSuccess.validations.characters')}
                    </span>
                    <img
                      className='icon-validation'
                      src={
                        this.state.validCharacters
                          ? require('../../../images/icon-check.svg')
                          : require('../../../images/icon-close.svg')
                      }
                      alt={this.state.validCharacters ? 'Check' : 'Close'}
                    />
                  </div>
                  <div className='div-two-items'>
                    <span className='text-small'>
                      {i18n.t('resetPasswordSuccess.validations.uppercase')}
                    </span>
                    <img
                      className='icon-validation'
                      src={
                        this.state.validUpperCase
                          ? require('../../../images/icon-check.svg')
                          : require('../../../images/icon-close.svg')
                      }
                      alt={this.state.validUpperCase ? 'Check' : 'Close'}
                    />
                  </div>
                  <div className='div-two-items'>
                    <span className='text-small'>
                      {i18n.t('resetPasswordSuccess.validations.lowercase')}
                    </span>
                    <img
                      className='icon-validation'
                      src={
                        this.state.validLowerCase
                          ? require('../../../images/icon-check.svg')
                          : require('../../../images/icon-close.svg')
                      }
                      alt={this.state.validLowerCase ? 'Check' : 'Close'}
                    />
                  </div>
                  <div className='div-two-items'>
                    <span className='text-small'>
                      {i18n.t('resetPasswordSuccess.validations.number')}
                    </span>
                    <img
                      className='icon-validation'
                      src={
                        this.state.validNumber
                          ? require('../../../images/icon-check.svg')
                          : require('../../../images/icon-close.svg')
                      }
                      alt={this.state.validNumber ? 'Check' : 'Close'}
                    />
                  </div>
                </div>
              </div>
              <div>
                <span>Confirm New Password</span>
                <input
                  type='password'
                  name='passwordC'
                  onChange={this.handleInputChange}
                  id='confirmNewPassword'
                />
              </div>
              <div className='wrappForChangePasswordBtn'>
                <div
                  className='mdForHeaderAndArrow__btn'
                  onClick={this.changePassword}
                >
                  Change password
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    trials: state.trials,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(Inputs)
