import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function sitesOptions (state = initialState.sitesOptions, action) {
  switch (action.type) {
    case types.LOAD_SITES_OPTIONS_SUCCESS:
      return action.sitesOptions;
    default:
      return state
  }
}
