import React from "react";
import Sexes from "./Sexes";
import "./index.css";

const Filters = props => {
  return (
    <div className="ntw__trialInformation ntw__filters filters-for-info">
      <h1>Filters</h1>
      <span className="ntw__filters__demographic">Demographic data</span>
      <Sexes
        minAge={props.minAge}
        maxAge={props.maxAge}
        substance={props.substance}
        smoking={undefined}
        gender={props.gender}
      />
    </div>
  );
};
export default Filters;
