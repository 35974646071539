import React, { Component } from "react";
import { Spinner, ArrowUp } from "../index";

export class CustomInfinityScroll extends Component {
	state = {
		anchor: false,
	};

	moveToTop = e => {
		document.getElementById("scroolTop__list").scrollTop = 0;
	};

	infinityScroll = e => {
		if (
			!this.props.loadingNext &&
			this.props.isMore &&
			e.currentTarget.scrollHeight - e.currentTarget.offsetHeight <=
				e.currentTarget.scrollTop
		) {
			this.props.loadMoreData();
		}
		e.currentTarget.scrollTop > 200
			? this.setState({ anchor: true })
			: this.setState({ anchor: false });
	};
	render() {
		let {
			data,
			searching,
			loading,
			textNoResult,
			textNoResultSearch,
			children,
			loadingNext,
		} = this.props;
		let { anchor } = this.state;

		return (
			<>
				<div
					id="scroolTop__list"
					className="candidatesWrapper"
					style={this.props.removePadding && { padding: 0, height: "inherit" }}
					ref={ref => (this.scrollParentRef = ref)}
					onScroll={e => this.infinityScroll(e)}
				>
					{children}
					{anchor && <ArrowUp click={this.moveToTop} />}
					{data.length === 0 && searching && !loading && (
						<div className="noResFound">{textNoResultSearch}</div>
					)}
					{(loading || loadingNext) && <Spinner />}
					{data.length === 0 && !searching && !loading && (
						<div id="loading-candidates" className="loadingCandidates">
							<div className="noResFound">{textNoResult}</div>
						</div>
					)}

					{/* <InfiniteScroll
						pageStart={0}
						loadMore={!loadingNext ? loadMoreData : () => {}}
						hasMore={isMore}
						getScrollParent={() => this.scrollParentRef}
						threshold={500}
						useWindow={false}
						loader={!loading ? <SpinnerSmall key={0} /> : <div key={0} />}
					>
						{children}
						{anchor && <ArrowUp click={this.moveToTop} />}
						{data.length === 0 && searching && !loading && (
							<div className="noResFound">{textNoResultSearch}</div>
						)}
						{loading && <Spinner />}
						{data.length === 0 && !searching && !loading && (
							<div id="loading-candidates" className="loadingCandidates">
								<div className="noResFound">{textNoResult}</div>
							</div>
						)}
					</InfiniteScroll> */}
				</div>
			</>
		);
	}
}
