import React, {useState, useEffect} from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { SpinnerSmall, UserInitial } from '../../../ui'
import LoadMoreBtn from '../../../ui/loadmore'

const Trial = ({
  trials,
  isMoreActiveTrials,
  isMoreRetiredTrials,
  loadMoreData,
  loading,
  clickTrial,
  name
}) => {
  const [isBtnShowm, setIsBtnShown] = useState(false);
  useEffect(() => {
    setIsBtnShown(isMoreActiveTrials || isMoreRetiredTrials);
  }, [isMoreActiveTrials, isMoreRetiredTrials])

  return (
    <div className='trial__body'>
      {trials.length > 0 ? (
        <>
          {trials.map((trial, i) => {
            return (
              <div
                className='trial-block'
                key={i}
                onClick={() => clickTrial(trial.id, trial.name)}
              >
                <div className='trial-name'>
                  <span>{trial.nct}</span>
                </div>
                <div className='trial-name'>
                  <span className='trial-name-content'>{trial.name}</span>
                </div>
                <div className='trial-name'>
                  <span className='trial-name-content'>{trial.sponsor}</span>
                </div>
                <div className='trial-physician'>
                  <UserInitial user_initials={trial.pi} />
                  <div className='trial-physician-text'>
                    <div className='contact-name'>{trial.pi}</div>
                  </div>
                </div>
              </div>
            )
          })}
          {!isBtnShowm && (isMoreActiveTrials || isMoreRetiredTrials) && <SpinnerSmall/>}
          {isBtnShowm && <LoadMoreBtn loadMoreData={e => {setIsBtnShown(!isBtnShowm); loadMoreData(e)}}/>}
        </>
      ) : (
        <div className='noResFound'>No {name} trials available</div>
      )}
    </div>
  )
}
export default Trial
