import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setDiseasesICD10CMListReducer(
  state = initialState.diseasesICD10CMList,
  action
) {
  switch (action.type) {
    case types.SET_DISEASES_ICD10CM_LIST:
      return action.diseasesICD10CMList;
    default:
      return state;
  }
}
