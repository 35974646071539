import React, {Component} from "react";
import {connect} from "react-redux";

import InmunityApi from "./../../api/InmunityApi";
import crossicon from "./../../images/icon-close-w.svg";
import MaskedInput from "react-text-mask";
import i18n from "../../i18n";

class PatientContactPopup extends Component {
    state = {
        data: [],
        active: false,
        deactivate: false,
        displayEmail: false,
        displayPhone: false,
        waitData: true,
        currentHadmId: "",
        email: "",
        phone1: "",
        requestProceed: false,
        validData: false
    };
    myRefEmail = React.createRef();
    myRefPhone = React.createRef();

    closePopup = () => {
        this.props.closePopup();
    }

    componentDidMount() {
        this.setState({waitData: true})
        InmunityApi.getEmptyContactFields(this.props.currentHadmId)
            .then(fields => {
                fields.forEach(element => {
                    if (element.field === "email") {
                        this.setState({displayEmail: true})
                    }
                    if (element.field === "phone1") {
                        this.setState({displayPhone: true})
                    }
                    this.setState({waitData: false})
                });
            })
            .catch(error => console.log("error sending workflow ", error));
    }

    sendData = () => {
        if (this.state.validData) {
            this.setState({ requestProceed: true })
            const object = {};
            object.email = this.state.email;
            object.phone = this.state.phone1;
            InmunityApi.patchEmptyContactFields(this.props.currentHadmId, object)
              .then(response => {
                  if (response.status === 200) {
                      this.props.postWorkFlow(this.props.param);
                      this.props.updatePersonalInfo(this.state.email, this.state.phone1);
                      this.props.closePopup();
                  }
              })
              .catch(error => console.log("error updating patient personal contacts", error));
        } else {
            this.myRefEmail.current.classList.add("redBorder");
        }
    }

    validateEmail = (e) => {
        const val = e.target.value;
        let mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(val.match(mailformat)) {
            this.setState({ email: val, validData: true });
            e.target.classList.remove("redBorder");
            this.myRefEmail.current.classList.remove("redBorder");
        } else {
            e.target.classList.add("redBorder");
            this.setState({ email: val, validData: false });
        }
      }

    handleInputChange = ({ target }) => {
    switch (target.id) {
        case "phone":
            this.myRefPhone.current.inputElement.classList.remove("redBorder");
            this.setState({ phone1: target.value });
        break;
        default:
        return null;
    }
    };
    
    render() {
        const {
            email,
            phone1,
            waitData,
            displayEmail,
            displayPhone
          } = this.state;

        return (
            <div style={{ maxHeight: "95vh" }}>
                <div
                    className="closePendingPopupCross"
                    onClick={this.closePopup}
                >
                    <img src={crossicon} alt="Popup Cross" />
                </div>
                <div className="personal-form">
                    <h3 className="title-form">Patient Personal Contact</h3>
                    {!waitData ? 
                    (
                        <div className="in_filds_wrapp">
                        {displayEmail &&
                        (
                            <div className="input-container">
                                <label className="label-modal">Email</label>
                                <input 
                                    type="text" 
                                    id="email"
                                    value={email}
                                    placeholder="email@example.com"
                                    className="field-modal"
                                    onChange={this.validateEmail}
                                    ref={this.myRefEmail}
                                />
                            </div>
                        )}
                        {displayPhone && 
                        (
                            <div className="input-container">
                                <label className="label-modal">Phone</label>
                                <MaskedInput
                                    mask={[
                                        "+",
                                        "1",
                                        " ",
                                        "(",
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ")",
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                    ]}
                                    id="phone"
                                    value={phone1}
                                    placeholder={i18n.t("accountRegistration.placeHphone")}
                                    className="field-modal"
                                    onChange={this.handleInputChange}
                                    ref={this.myRefPhone}
                                />
                            </div>
                        )}
                        </div>
                    ) : (
                        <div style={{margin: "auto"}}>
                                <img
                                    src={require("../../images/spinner.gif")}
                                    alt="Spinner"
                                    height="50px"
                                />
                        </div>
                    )
                    }
                    <div className="button-container">
                        { !this.state.requestProceed ? (
                            <div className="submit-button" onClick={this.sendData}>
                                Save
                            </div>
                        ) : (
                            <div className="submit-button">
                                <img
                                    src={require("../../images/spinner.gif")}
                                    alt="Spinner"
                                    height="30px"
                                  />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userToken: state.userToken,
        currentUser: state.currentUser,
        changeEmailPopup: state.changeEmailPopup
    };
}

export default connect(mapStateToProps)(PatientContactPopup);
