import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { getExclusionReasons } from "../actions/trialActions";

import "./styles/PatientDetails.css";
import "./styles/ReasonDecline.css";

class ReasonDecline extends React.Component {
  state = {
    selectedOption: [],
    declineReasons: [],
    other: false,
    result: {},
    criteriasTrial: [],
    criteriaMatchedValue: [],
    criteriaUnmatchedValue: [],
    matchedReason: false,
    unmatchedReason: false,
    otherReason: false,
    otherReasonValue: ""
  };

  componentDidMount() {
    const { declineReasons } = this.props;
    declineReasons.length > 0
      ? this.makeOptions(declineReasons)
      : (async () => {
          await this.props.dispatch(getExclusionReasons());
          this.makeOptions(this.props.declineReasons);
        })();
  }

  makeOptions = array => {
    let declineReasons = [];
    array.forEach(el => {
      declineReasons.push({ value: el.id, label: el.name });
    });
    this.setState({ declineReasons });
  };

  reasonDecline = () => {
    this.props.dispatch(push("/forgot-password"));
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption }, () => {
      let reasons = [];
      this.state.selectedOption.forEach(el => {
        reasons.push(el.value);
      });
      this.setState({ reasons });
      reasons.includes(1)
        ? this.setState({ matchedReason: true })
        : this.setState({ matchedReason: false });
      reasons.includes(2)
        ? this.setState({ unmatchedReason: true })
        : this.setState({ unmatchedReason: false });
      reasons.includes(6)
        ? this.setState({ otherReason: true })
        : this.setState({ otherReason: false });
    });
  };

  handleMatchedCriteriaChange = criteriaMatchedValue => {
    this.setState({
      criteriaMatchedValue
    });
  };

  handleUnmatchedCriteriaChange = criteriaUnmatchedValue => {
    this.setState({
      criteriaUnmatchedValue
    });
  };

  handleOtherReasonChange = ({ target }) => {
    this.setState({ otherReasonValue: target.value });
  };

  sendData = () => {
    let result = {};
    let arr = [...this.state.selectedOption];
    let other = arr.filter(el => {
      return el.value === "Other";
    });
    if (other.length) {
      result.otherOption = this.state.result.otherOption;
    } else {
      result.otherOption = "";
    }
    result.options = [];
    arr.forEach(el => {
      result.options.push(el.value);
    });
    this.setState({
      result
    });
  };

  render() {
    const {
      criteriaMatchedValue,
      criteriaUnmatchedValue,
      matchedReason,
      unmatchedReason,
      otherReason,
      otherReasonValue,
      criteriasTrial
    } = this.state;
    return (
      <div className="base-container forPD forBlueBC">
        <div className="base-container">
          <div className="login-container patientDetails reasonDecline">
            <div className="left-login">
              <img src={require("../images/logo-w.png")} alt="Logo" />
              <div className="patientDetails__leftText">
                <span>
                  If (patient name) is interested in learning more about
                  clinical trials they may be eligible for, please enter their
                  contact information.
                </span>
              </div>
            </div>
            <div className="right-login">
              <div className="inputFields">
                <img
                  src={require("../images/left-icon.svg")}
                  className="mdarrowback"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  alt="Logo"
                />
                <h1 className="heading">Reason for declining.</h1>
                <p className="plsSelect">Please select an option below.</p>
                <div className="newTrialWrapp select-reasonDecline">
                  <Select
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={this.state.declineReasons}
                    closeMenuOnSelect={false}
                    className="sort_select withInput"
                    components={makeAnimated()}
                    isMulti
                  />
                </div>
                {matchedReason && (
                  <div className="decline-reason-select">
                    <hr className="select-separator" />
                    <span>Matched inclusion criteria</span>
                    <Select
                      value={criteriaMatchedValue}
                      classNamePrefix={"select-search"}
                      closeMenuOnSelect={false}
                      onChange={this.handleMatchedCriteriaChange}
                      options={criteriasTrial}
                      className="sort_select withInput width100"
                      isMulti={true}
                      components={makeAnimated()}
                    />
                  </div>
                )}
                {unmatchedReason && (
                  <div className="decline-reason-select">
                    <hr className="select-separator" />
                    <span>Did not match inclusion criteria</span>
                    <Select
                      value={criteriaUnmatchedValue}
                      classNamePrefix={"select-search"}
                      closeMenuOnSelect={false}
                      onChange={this.handleUnmatchedCriteriaChange}
                      options={criteriasTrial}
                      className="sort_select withInput width100"
                      isMulti={true}
                      components={makeAnimated()}
                    />
                  </div>
                )}
                {otherReason && (
                  <div className="decline-reason-select">
                    <hr className="select-separator" />
                    <span>Other reason</span>
                    <input
                      value={otherReasonValue}
                      onChange={this.handleOtherReasonChange}
                      className="input-other-reason"
                      placeholder="Type other reason"
                    />
                  </div>
                )}
                <div className="btnsWrapp">
                  <div className="submit width100" onClick={this.sendData}>
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    trials: state.trials,
    declineReasons: state.exclusionReasons
  };
}

export default connect(mapStateToProps)(ReasonDecline);
