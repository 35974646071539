import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

export default connect(store => ({
  permissions: store.permissions
}))(function ImmunityId({
                          searchPage,
                          nameSearch,
                          GeoIdPhysHandleChange,
                          permissions,
                          elem
                        }) {
  console.log('label - ImmunityId', elem);

  return (
    <div
      className={
        searchPage
          ? "ntw__ti-input forGettingValueFields new-search-id-name width100"
          : "ntw__ti-input forGettingValueFields new-search-id-name searchPatientInput"
      }
    >
      {
        elem === 'ID Number'
          ? <div
            // className={`new-search__input${!permissions.view_data && !permissions.view_data_own ? ' width100':''}`}
            className={'new-search__input width100'}
          >
            <span className="ntw__ti-inputlabel">ID Number</span>
            <input
              type="number"
              name="Immunity ID"
              className="get-freetext-name"
              defaultValue={nameSearch}
              onChange={GeoIdPhysHandleChange}
            />
          </div>
          : <div className='new-search__input width100'>
            <span className="ntw__ti-inputlabel">Name</span>
            <input
              type="text"
              name="Immunity Name"
              className="get-freetext-name"
              defaultValue={nameSearch}
              onChange={GeoIdPhysHandleChange}
            />
          </div>
      }
    </div>
  );
})
