// import React from "react";

import './index.css'

const Tabs = () => {
  // function goToNurse({ target }) {
  //   target.classList.remove("active");
  //   target.nextSibling.classList.add("active");
  // }

  // function goToProgress({ target }) {
  //   target.classList.remove("active");
  //   target.previousSibling.classList.add("active");
  // }
  return null
  // <div className='tabs__container'>
  // <div className='tabs__items'>
  //     <div className='tabs__item ' onClick={goToNurse}>Nurse Note</div>
  //     <div className='tabs__item active' onClick={goToProgress}>Progress Note</div>
  // </div>
  // <div className ='tabs__render'>
  //   <div className="tabs__render-forScroll">
  //       <div className="tabs-textBlock">
  //         <div className="tabs-textBlock__heading">
  //           <span>Nurse Mary Hoffman</span>
  //           <span>08/10/18</span>
  //         </div>
  //         <div className="tabs-textBlock__text">
  //           dsg asgno;asg sdg sdh sdh sdh sdjh sdjh sdhj sdjh sdjh sdhj sdjh sdj sdj sdj sdj dsj sdj dsj sdj sd jsdj sd jsd jsd sdj sdj s
  //         </div>
  //       </div>
  //       <div className="tabs-textBlock">
  //         <div className="tabs-textBlock__heading">
  //           <span>Nurse Mary Hoffman</span>
  //           <span>08/10/18</span>
  //         </div>
  //         <div className="tabs-textBlock__text">
  //           dsg asgno;asg sdg sdh sdh sdh sdjh sdjh sdhj sdjh sdjh sdhj sdjh sdj sdj sdj sdj dsj sdj dsj sdj sd jsdj sd jsd jsd sdj sdj s
  //         </div>
  //       </div>
  //       <div className="tabs-textBlock">
  //         <div className="tabs-textBlock__heading">
  //           <span>Nurse Mary Hoffman</span>
  //           <span>08/10/18</span>
  //         </div>
  //         <div className="tabs-textBlock__text">
  //           dsg asgno;asg sdg sdh sdh sdh sdjh sdjh sdhj sdjh sdjh sdhj sdjh sdj sdj sdj sdj dsj sdj dsj sdj sd jsdj sd jsd jsd sdj sdj s
  //         </div>
  //       </div>
  //       <div className="tabs-textBlock">
  //         <div className="tabs-textBlock__heading">
  //           <span>Nurse Mary Hoffman</span>
  //           <span>08/10/18</span>
  //         </div>
  //         <div className="tabs-textBlock__text">
  //           dsg asgno;asg sdg sdh sdh sdh sdjh sdjh sdhj sdjh sdjh sdhj sdjh sdj sdj sdj sdj dsj sdj dsj sdj sd jsdj sd jsd jsd sdj sdj s
  //         </div>
  //       </div>

  //   </div>
  // </div>
  // </div>
}
export default Tabs
