import React from "react";

export default function FilterItem({
  elName,
  type,
  required,
  deleteFilter,
  include,
  exclude
}) {
  let name = "";
  switch (type) {
    case "medication":
      name = `Medication ${include ? "Include" : ""}${
        exclude ? "Exclude" : ""
      }`;
      break;
    case "disease":
      name = `Diseases ${include ? "Include" : ""}${exclude ? "Exclude" : ""}`;
      break;
    case "procedure":
      name = `Procedures ${include ? "Include" : ""}${
        exclude ? "Exclude" : ""
      }`;
      break;
    case "allergy":
      name = `Allergies ${include ? "Include" : ""}${
        exclude ? "Exclude" : ""
      }`;
      break;
    case "lab":
      name = `Lab ${include ? "Include" : ""}${exclude ? "Exclude" : ""}`;
      break;
    case "substance":
      name = `Substances ${include ? "Include" : ""}${
        exclude ? "Exclude" : ""
      }`;
      break;
    case "smoking":
      name = `Smoking ${include ? "Include" : ""}${exclude ? "Exclude" : ""}`;
      break;
    case "immunity":
      name = "ID Number";
      break;
    case "name":
      name = "Name";
      break;
    case "practitioner":
      name = "Physician";
      break;
    case "geography":
      name = "Geography";
      break;
    case "demography":
      name = "Demography";
      break;
    default:
      name = "";
      break;
  }
  return (
    <div className="searchItem">
      <span className="searchCrType">
        {name}
        {required && " Required"}:
      </span>
      <span className="searchCrType__value">{elName}</span>
      <span
        className="crossIconOnSI"
        onClick={() => deleteFilter(type, elName)}
      >
        +
      </span>
    </div>
  );
}
