import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function physiciansOptionsReducer (state = initialState.physiciansOptions, action) {  
  switch (action.type) {
    case types.LOAD_PHYSICIANS_OPTIONS_SUCCESS:
      return action.physiciansOptions;
    default:
      return state
  }
}
