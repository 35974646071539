import * as types from "./actionTypes";
import {push} from "react-router-redux";

import inmunityApi from "../api/InmunityApi";
import InmunityApi from "../api/InmunityApi";
import {fetchAnalytics, fetchHospitals, fetchSite} from "./sitesDataActions";
import {sortPatientSearchResult} from "./searchResultActions";
import setPatientsFilterResult from "../reducers/patientsFilterReducer";
import {setAnalytics, setHospitals, setSite} from "../reducers/sitesDataReducer";
import setPatientOverview from "../reducers/patientOverviewReducer";
import store from "../store";
import {loadPhysicians} from "./physiciansActions";

export function showPicker(showPicker) {
  return {type: types.SHOW_PICKER, showPicker};
}

export function globalState(globalState) {
  return {type: types.GLOBAL_STATE, globalState};
}

export function loadWorkFlowActionsSuccess(actions) {
  return {type: types.LOAD_ACTIONS_SUCCESS, actions};
}

export function setWorkFlowId(workFlowStudyId) {
  return {type: types.WORKFLOW_STUDY_ID, workFlowStudyId};
}

export function toggleModalGeneric(modalGeneric) {
  return {type: types.TOGGLE_MODAL_GENERIC, modalGeneric};
}

export function loadTrialsSuccess(trials) {
  return {type: types.LOAD_TRIALS_SUCCESS, trials};
}

export function loadTrialsOptionsSuccess(trialsOptions) {
  return {type: types.LOAD_TRIALS_OPTIONS_SUCCESS, trialsOptions};
}

export function loadTrialDescriptionSuccess(trial) {
  return {type: types.TRIAL_DESCRIPTION_SUCCESS, trial};
}

export function loadTrialMatches(matches) {
  return {type: types.LOAD_TRIALS_MATCHES, matches};
}

export function loadTrialCandidates(candidates) {
  return {type: types.LOAD_TRIALS_CANDIDATES, candidates};
}

export function loadDiseasesSuccess(diseases) {
  return {type: types.DISEASES_SUCCESS, diseases};
}

export function loadNewMedicationsSuccess(newMedications) {
  return {type: types.NEW_MEDICATIONS_SUCCESS, newMedications};
}

export function loadLabNamesSuccess(labNames) {
  return {type: types.LABNAMES_SUCCESS, labNames};
}

export function loadLabUomsSuccess(labUoms) {
  return {type: types.LABUOMS_SUCCESS, labUoms};
}

export function loadLabWithUomSuccess(labWithUom) {
  return {type: types.LABWITHUOM_SUCCESS, labWithUom};
}

export function loadLabListSuccess(labList) {
  return {type: types.LAB_LIST_SUCCESS, labList};
}

export function loadRolesSuccess(roles) {
  return {type: types.LOAD_ROLES_SUCCESS, roles};
}

export function loadSearchSuccess(search) {
  return {type: types.LOAD_SEARCH_SUCCESS, search};
}

export function setSearchObject(search) {
  return {type: types.SET_SEARCH_SUCCESS, search};
}

export function getSavedSearchesSuccess(searches) {
  return {type: types.GET_SAVED_SEARCHES_SUCCESS, searches};
}

export function getRecentSearchesSuccess(searchesRecent, next) {
  return {type: types.GET_RECENT_SEARCHES_SUCCESS, searchesRecent, next};
}

export function setRecentSearchesReload(reload) {
  return {type: types.SET_RECENT_SEARCHES_RELOAD, reload};
}

export function getExclusionReasonsSuccess(exclusionReasons) {
  return {type: types.GET_EXCLUSION_REASONS_SUCCESS, exclusionReasons};
}

export function getLastStagesSuccess(lastStages) {
  return {type: types.GET_LAST_STAGES_SUCCESS, lastStages};
}

export function getMedicationsRootSuccess(rootMedications) {
  return {type: types.GET_MEDICATIONS_ROOT_SUCCESS, rootMedications};
}

// new picker

export function getMedicationsPickerRootSuccess(medicationsPicker) {
  return {type: types.GET_ROOT_MEDICATIONS_PICKER, medicationsPicker};
}

export function getDiseasesPickerRootSuccess(diseasesPickerRoot) {
  return {type: types.GET_ROOT_DISEASES_PICKER, diseasesPickerRoot};
}

export function getProceduresPickerRootSuccess(proceduresPickerRoot) {
  return {type: types.GET_ROOT_PROCEDURES_PICKER, proceduresPickerRoot};
}

// ------------------- //

export function getDiseasesRootSuccess(rootDiseases) {
  return {type: types.GET_DISEASES_ROOT_SUCCESS, rootDiseases};
}

export function getProceduresRootSuccess(rootProcedures) {
  return {type: types.GET_PROCEDURES_ROOT_SUCCESS, rootProcedures};
}

export function setRootMedications(rootMedicationsTree) {
  return {type: types.SET_MEDICATIONS_ROOT_SUCCESS, rootMedicationsTree};
}

export function getSubstancesSuccess(substances) {
  return {type: types.GET_ALL_SUBSTANCES, substances};
}

export function getSubstancesSearchSuccess(substancesSearch) {
  return {type: types.GET_ALL_SUBSTANCES_SEARCH, substancesSearch};
}

export function getSmokingSuccess(smoking) {
  return {type: types.GET_SMOKING, smoking};
}

export function getPhysiciansSuccess(physicians) {
  return {type: types.GET_PHYSICIANS, physicians};
}

export function getPhysiciansSearchSuccess(physiciansSearch) {
  return {type: types.GET_PHYSICIANS_SEARCH, physiciansSearch};
}

export function updateTrial(trial) {
  return {
    type: types.UPDATE_TRIAL,
    trial,
  };
}

/**
 |--------------------------------------------------
 | SECTION FOR PICKER
 |--------------------------------------------------
 */

export function getRootMedications() {
  return function (dispatch) {
    return inmunityApi
      .getMedicationsRoot()
      .then(rootMedications => {
        if (rootMedications.error) {
          throw new Error(rootMedications.error);
        }
        dispatch(getMedicationsRootSuccess(rootMedications));
      })
      .catch(e => console.log(e));
  };
}

// export function getRootDiseases() {
//   return function(dispatch) {
//     return inmunityApi
//       .getDiseasesRoot()
//       .then(rootDiseases => {
//         if (rootDiseases.error) {
//           throw new Error(rootDiseases.error);
//         }
//         dispatch(getDiseasesRootSuccess(rootDiseases));
//       })
//       .catch(e => console.log(e));
//   };
// }

export function getRootProcedures() {
  return function (dispatch) {
    return inmunityApi
      .getProceduresRoot()
      .then(rootProcedures => {
        if (rootProcedures.error) {
          throw new Error(rootProcedures.error);
        }
        dispatch(getProceduresRootSuccess(rootProcedures));
      })
      .catch(e => console.log(e));
  };
}

export function setMedicationsATC(medicationsATC) {
  return {
    type: types.SET_MEDICATIONS_ATC,
    medicationsATC,
  };
}

export function setMedicationsEPC(medicationsEPC) {
  return {
    type: types.SET_MEDICATIONS_EPC,
    medicationsEPC,
  };
}

export function setMedicationsMOA(medicationsMOA) {
  return {
    type: types.SET_MEDICATIONS_MOA,
    medicationsMOA,
  };
}

export function setProcedures(proceduresPicker) {
  return {
    type: types.SET_PROCEDURES,
    proceduresPicker,
  };
}

export function setProceduresList(proceduresList) {
  return {
    type: types.SET_PROCEDURES_LIST,
    proceduresList,
  };
}

export function setMedicationsATCList(medicationsATCList) {
  return {
    type: types.SET_MEDICATIONS_ATC_LIST,
    medicationsATCList,
  };
}

export function setMedicationsEPCList(medicationsEPCList) {
  return {
    type: types.SET_MEDICATIONS_EPC_LIST,
    medicationsEPCList,
  };
}

export function setMedicationsMOAList(medicationsMOAList) {
  return {
    type: types.SET_MEDICATIONS_MOA_LIST,
    medicationsMOAList,
  };
}

export function setGlobalSpinner(bool) {
  return {
    type: types.SET_GLOBAL_SPINNER,
    payload: bool,
  };
}

export function setPopup(popup) {
  return {
    type: types.SET_POPUP,
    popup,
  };
}

/**
 |--------------------------------------------------
 | SECTION FOR NEW PICKER
 |--------------------------------------------------
 */

export function getMedicationsPicker() {
  return async function (dispatch) {
    try {
      const medicationsPicker = await InmunityApi.getMedicationsRootNew();
      if (medicationsPicker.error) {
        throw new Error(medicationsPicker.error);
      }
      dispatch(getMedicationsPickerRootSuccess(medicationsPicker));
    } catch (e) {
      return console.log(e);
    }
  };
}

export function getDiseasesPicker() {
  return async function (dispatch) {
    try {
      const diseasesPickerRoot = await InmunityApi.getDiseasesRootNew();
      if (diseasesPickerRoot.error) {
        throw new Error(diseasesPickerRoot.error);
      }
      dispatch(getDiseasesPickerRootSuccess(diseasesPickerRoot));
    } catch (e) {
      return console.log(e);
    }
  };
}

export function getProceduresPicker() {
  return async function (dispatch) {
    try {
      const proceduresPickerRoot = await InmunityApi.getProceduresRoot();
      if (proceduresPickerRoot.error) {
        throw new Error(proceduresPickerRoot.error);
      }
      dispatch(getProceduresPickerRootSuccess(proceduresPickerRoot));
    } catch (e) {
      return console.log(e);
    }
  };
}

export function setDiseasesDO(diseasesDO) {
  return {
    type: types.SET_DISEASES_DO,
    diseasesDO,
  };
}

export function setProceduresICD9(proceduresICD9) {
  return {
    type: types.SET_PROCEDURES_ICD9,
    proceduresICD9,
  };
}

export function setProceduresICD10(proceduresICD10) {
  return {
    type: types.SET_PROCEDURES_ICD10,
    proceduresICD10,
  };
}

export function setProceduresLabel(label) {
  return {
    type: types.SET_PROCEDURES_LABEL,
    label,
  };
}

export function setPickerLabel(label) {
  return {
    type: types.SET_DISEASES_LABEL,
    label,
  };
}

export function setMedicationsLabel(label) {
  return {
    type: types.SET_MEDICATIONS_LABEL,
    label,
  };
}

export function setDiseasesICD9CM(diseasesICD9CM) {
  return {
    type: types.SET_DISEASES_ICD9CM,
    diseasesICD9CM,
  };
}

export function setDiseasesICD10CM(diseasesICD10CM) {
  return {
    type: types.SET_DISEASES_ICD10CM,
    diseasesICD10CM,
  };
}

export function setDiseasesDOList(diseasesDOList) {
  return {
    type: types.SET_DISEASES_DO_LIST,
    diseasesDOList,
  };
}

export function setProceduresICD9List(proceduresICD9List) {
  return {
    type: types.SET_PROCEDURES_ICD9_LIST,
    proceduresICD9List,
  };
}

export function setProceduresICD10List(proceduresICD10List) {
  return {
    type: types.SET_PROCEDURES_ICD10_LIST,
    proceduresICD10List,
  };
}

export function setDiseasesICD9CMList(diseasesICD9CMList) {
  return {
    type: types.SET_DISEASES_ICD9CM_LIST,
    diseasesICD9CMList,
  };
}

export function setDiseasesICD10CMList(diseasesICD10CMList) {
  return {
    type: types.SET_DISEASES_ICD10CM_LIST,
    diseasesICD10CMList,
  };
}

export function setVeryFarLabelFromBlock(label) {
  return {
    type: types.SET_FAR_LABEL,
    label,
  };
}

export const setAllergiesList = list => dispatch => {
  return dispatch({
    type: types.SET_ALLERGIES_LIST,
    list,
  });
};
export const setAllergiesLeafs = leafs => dispatch => {
  return dispatch({
    type: types.SET_ALLERGIES_LEAFS,
    leafs,
  });
};

export const setAllergiesPicker = picker => {
  return {
    type: types.SET_ALLERGIES,
    picker,
  };
};

/**
 |--------------------------------------------------
 | END SECTION FOR NEW PICKER
 |--------------------------------------------------
 */

export function getWFActions() {
  return function (dispatch) {
    return inmunityApi
      .getWorkFlowActions()
      .then(actions => {
        dispatch(loadWorkFlowActionsSuccess(actions));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getExclusionReasons() {
  return function (dispatch) {
    return inmunityApi
      .getExclusionReasons()
      .then(exclusionReasons => {
        dispatch(getExclusionReasonsSuccess(exclusionReasons));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getLastStages() {
  return function (dispatch) {
    return inmunityApi
      .getLastStages()
      .then(lastStages => {
        dispatch(getLastStagesSuccess(lastStages));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getSavedSearches() {
  return function (dispatch) {
    return inmunityApi
      .getSavedSearches()
      .then(searches => {
        dispatch(getSavedSearchesSuccess(searches));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getRecentSearches(next, reload) {
  // debugger ;
  return function (dispatch) {
    if (next) {
      return inmunityApi
        .getPatientSearchPagination(next)
        .then(searchesRecent => {
          dispatch(getRecentSearchesSuccess(searchesRecent, next));
          dispatch(setRecentSearchesReload(false));
        })
        .catch(error => {
          throw error;
        });
    }
    if (reload) {
      dispatch(setRecentSearchesReload(reload));
    }
    return inmunityApi
      .getRecentSearches()
      .then(searchesRecent => {
        dispatch(getRecentSearchesSuccess(searchesRecent));
        dispatch(setRecentSearchesReload(false));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getSearchForPopulation(id) {
  return function (dispatch) {
    return inmunityApi
      .getPrepopulationOfSearch(id)
      .then(search => {
        dispatch(loadSearchSuccess(search));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadDiseases() {
  return function (dispatch) {
    return inmunityApi
      .getAllDiseases()
      .then(diseases => {
        // localStorage.setItem("diseases", JSON.stringify(diseases));
        dispatch(loadDiseasesSuccess(diseases));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadNewMedications() {
  return function (dispatch) {
    return inmunityApi
      .getAllNewMedications()
      .then(newMedications => {
        dispatch(loadNewMedicationsSuccess(newMedications));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadLabNames() {
  return function (dispatch) {
    return inmunityApi
      .getLabNames()
      .then(labNames => {
        dispatch(loadLabNamesSuccess(labNames));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadLabList() {
  return function (dispatch) {
    return inmunityApi
      .getLabList()
      .then(labList => {
        dispatch(loadLabListSuccess(labList));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadLabWithUom() {
  return function (dispatch) {
    return inmunityApi
      .getLabWithUom()
      .then(labWithUom => {
        dispatch(loadLabWithUomSuccess(labWithUom));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadLabUoms() {
  return function (dispatch) {
    return inmunityApi
      .getUoms()
      .then(labUoms => {
        dispatch(loadLabUomsSuccess(labUoms));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadTrials() {
  return function (dispatch) {
    // dispatch(setGlobalSpinner(true));
    return inmunityApi
      .getAllStudies()
      .then(trials => {
        setTimeout(() => dispatch(setGlobalSpinner(false)), 1500);
        if (!trials.error) {
          dispatch(loadTrialsSuccess(trials));

          const trialsOptions = trials.map((t, i) => ({
            label: t.studyName,
            studyId: t.studyID,
            value: i,
          }));

          dispatch(loadTrialsOptionsSuccess(trialsOptions));
        }
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getTrialDescription(id) {
  return function (dispatch) {
    return inmunityApi
      .getTrialDescription(id)
      .then(trial => {
        dispatch(loadTrialDescriptionSuccess(trial));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getRoles() {
  return function (dispatch) {
    return inmunityApi
      .getAllRoles()
      .then(roles => {
        dispatch(loadRolesSuccess(roles));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function setCurrentTrial(currentTrial) {
  return {
    type: types.CURRENT_TRIAL,
    currentTrial,
  };
}

export function setCurrentNavigationLink(link) {
  return {
    type: types.CURRENT_NAVIGATION_LINK,
    link,
  };
}

export function setCurrentNavigationSubLink(sublink) {
  return {
    type: types.CURRENT_NAVIGATION_SUB_LINK,
    sublink,
  };
}

export function setCurrentHadmId(currentHadmId) {
  return {
    type: types.CURRENT_HADMID,
    currentHadmId,
  };
}

export function newSessionSuccess(token) {
  return {type: types.LOG_IN_OUT, token};
}

export function logInUser(user, password, company) {
  return function (dispatch) {
    dispatch({type: types.IS_FETCHING_LOGIN, isFetching: true});
    return inmunityApi
      .newSession(user, password, company)
      .then(token => {
        dispatch({type: types.IS_FETCHING_LOGIN, isFetching: false});
        if (token.non_field_errors) {
          if (!token) return;
          return {error: token.non_field_errors[0]};
        } else {
          localStorage.setItem("token", token.token);
          localStorage.setItem("company", token.company);
          localStorage.setItem("role", token.role);
          store.dispatch(loadPhysicians());
          store.dispatch(loadLabNames());
          store.dispatch(loadLabUoms());
          store.dispatch(loadLabWithUom());
          return {token: token};
        }
      })
      .catch(error => {
        throw Error;
      });
  };
}

export function sendSmsType(sendSms) {
  return {type: types.SEND_SMS, sendSms};
}

export function sendSms(email) {
  return function (dispatch) {
    return email === "astern@hbs.edu"
      ? dispatch(
        sendSmsType({validEmail: true, response: "Successfully sent sms"})
      )
      : dispatch(
        sendSmsType({validEmail: false, response: "Email does not exist"})
      );
  };
}

export function verifyAccountType(verifyCode) {
  return {type: types.VERIFY_ACCOUNT, verifyCode};
}

export function verifyAccount(verifyCode) {
  return function (dispatch) {
    return verifyCode === "111111"
      ? dispatch(push("/reset-password"))
      : dispatch(
        verifyAccountType({
          validEmail: false,
          validCode: false,
          response: "Code is incorrect",
        })
      );
  };
}

export function reSendSmsType(reSendSms) {
  return {type: types.RE_SEND_SMS, reSendSms};
}

export function reSendSms() {
  return function (dispatch) {
    dispatch(reSendSmsType({validEmail: true}));
  };
}

export function logOutUser() {
  return {
    type: types.LOG_IN_OUT,
    token: "",
  };
}

export function getUserSuccess(user) {
  return {type: types.GET_USER_SUCCESS, user};
}

export function getUserByToken() {
  return function (dispatch) {
    return inmunityApi
      .getUser()
      .then(user => {
        dispatch(getUserSuccess(user));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getPendingUsersSuccess(res) {
  return {type: types.GET_PENDING_USERS_SUCCESS, res};
}

export function getPendingUsers() {
  return function (dispatch) {
    return inmunityApi
      .getPendingUsers()
      .then(res => {
        dispatch(getPendingUsersSuccess(res));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getAllSubstances() {
  return async function (dispatch) {
    try {
      const res = await inmunityApi.getAllSubstances();
      dispatch(getSubstancesSuccess(res));
    } catch (error) {
      throw error;
    }
  };
}

export function getAllSubstancesSearch() {
  return async function (dispatch) {
    try {
      const res = await inmunityApi.getAllSubstancesSearch();
      dispatch(getSubstancesSearchSuccess(res));
    } catch (error) {
      throw error;
    }
  };
}

export function getSmoking() {
  return async function (dispatch) {
    try {
      const res = await inmunityApi.getSmoking();
      dispatch(getSmokingSuccess(res));
    } catch (error) {
      throw error;
    }
  };
}

export function getPhysiciansSearch() {
  return async function (dispatch) {
    try {
      const res = await inmunityApi.getPhysiciansSearch();
      dispatch(getPhysiciansSearchSuccess(res));
    } catch (error) {
      throw error;
    }
  };
}

export function setProfilePhoto(profilePhoto) {
  return {
    type: types.PROFILE_PHOTO,
    profilePhoto,
  };
}

export function setNewUserPhoto(newUserPhoto) {
  return {
    type: types.NEW_USER_PHOTO,
    newUserPhoto,
  };
}

export function setUserPhoto(userPhoto) {
  return {
    type: types.USER_PHOTO,
    userPhoto,
  };
}

export function setPatientRes(currentID) {
  return async function (dispatch) {
    return await inmunityApi
      .getPatientSearchInfo(currentID)
      .then(res => res.json())
      .then(res => {
        dispatch(setPatientsResult(res));
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function setPatientsResult(patientsResult) {
  return {
    type: types.PATIENTS_RESULT,
    patientsResult,
  };
}

export const formatMatchingCriteria = (arr, missing) => {
  const diseases = [];
  const procedures = [];
  const labs = [];
  const medication = [];
  const allergies = [];

  const m_diseases = [];
  const m_procedures = [];
  const m_labs = [];
  const m_medication = [];
  const m_allergies = [];

  arr.forEach(r => {
    if (r.criteria === "disease") {
      diseases.push(r);
    } else if (r.criteria === "procedure") {
      procedures.push(r);
    } else if (r.criteria === "lab") {
      labs.push(r);
    } else if (r.criteria === "medication") {
      medication.push(r);
    } else if (r.criteria === "allergy") {
      allergies.push(r);
    }
    // else if (r.criteria === 'demography') {
    //   demography.push(r);
    // }
  });

  missing.forEach(r => {
    if (r.criteria === "disease") {
      m_diseases.push(r);
    } else if (r.criteria === "procedure") {
      m_procedures.push(r);
    } else if (r.criteria === "lab") {
      m_labs.push(r);
    } else if (r.criteria === "medication") {
      m_medication.push(r);
    } else if (r.criteria === "allergy") {
      m_allergies.push(r);
    }
  });

  const res = {
    diseases: {
      find: diseases,
      missing: m_diseases //ghost#temp
    },
    procedures: {
      find: procedures,
      missing: m_procedures //ghost#temp
    },
    labs: {
      find: labs,
      missing: m_labs //ghost#temp
    },
    medication: {
      find: medication,
      missing: m_medication //ghost#temp
    },
    allergies: {
      find: allergies,
      missing: m_allergies //ghost#temp
    }
    // demography
  };

  return res;
};

export function cancelSearchParam(searchID, value, formatter) {
  return async dispatch => await InmunityApi
    .cancelSearchParam(searchID, {criteriaName: value})
    .then(r => {
      if (r.status === 404) {
        return {status: 'Nothing found', message: 'Nothing found'}
      } else {
        return r.json();
      }
    })
    .then(response => {
      if (response.status === 'Success') {
        dispatch(sortPatientSearchResult(searchID));
        dispatch(setPatientsFilterRes(searchID, formatter));
        dispatch(fetchSite(searchID));
        dispatch(fetchHospitals(searchID));
        dispatch(fetchAnalytics(searchID));
      } else if (response.status === 'Nothing found') {
        return response
      } else {
        console.log(response);
      }
    })
    .catch(e => console.error(e));
}


export function setPatientsFilterResultStart(oldFilters) {
  return {
    type: types.PATIENTS_FILTER_RESULT_START,
    patientsFilterResult: {...oldFilters}
  };
}

export function setPatientsFilterResultSuccess(patientsFilterResult) {
  return {
    type: types.PATIENTS_FILTER_RESULT_SUCCESS,
    patientsFilterResult: {...patientsFilterResult},
  };
}

export function setPatientsFilterResultError(error) {
  return {
    type: types.PATIENTS_FILTER_RESULT_ERROR,
    error
  };
}

export function setPatientsFilterRes(currentID, formatter) {
  return async function (dispatch, getState) {
    if (!getState().patientsFilterResult.loading) {
      dispatch(setPatientsFilterResultStart(getState().patientsFilterResult))
      return await InmunityApi.getPatientSearchFilters(currentID)
        .then(resp => {
          dispatch(setPatientsFilterResultSuccess(formatter ? formatter(resp) : resp));
        })
        .catch(error => {
            dispatch(setPatientsFilterResultError(error));
            console.error("Error occurred while creating a search: ", error);
          }
        );
    }
  };
}

export function setPatientOverviewMiddleware(patient) {
  return async (dispatch, getState) => {
    if (!getState().patientOverview.loading && getState().patientOverview.hadm_id !== patient.hadm_id) {
      dispatch(fetchPatientOverviewStart());
      return await InmunityApi.getPatientOverview(patient.hadm_id).then(obj => {
        obj.chart = patient.chart;
        obj.loading = false;
        dispatch(fetchPatientOverviewSuccess(obj));
      })
        .catch(e => dispatch(fetchPatientOverviewError(e)))
    }
  }
}

export function fetchPatientOverviewSuccess(patientOverview) {
  return {
    type: types.PATIENT_OVERVIEW_SUCCESS,
    loading: false,
    error: null,
    patientOverview,
  };
}

export function fetchPatientOverviewStart() {
  return {
    type: types.PATIENT_OVERVIEW_START,
    loading: true,
    error: null,
  };
}

export function fetchPatientOverviewError(error) {
  return {
    type: types.PATIENT_OVERVIEW_ERROR,
    loading: false,
    error
  };
}

export function sendEmailNotification(emailNotification) {
  return {
    type: types.SEND_EMAIL_NOTIFICATION,
    emailNotification,
  };
}

export function setChangeEmailPopup(bool, name = "") {
  return {
    type: types.SET_CHANGE_EMAIL_POPUP,
    payload: bool,
    name,
  };
}

// here
export function setGeoDataSuccess(geoData) {
  return {
    type: types.GEO_DATA,
    geoData,
  };
}

export function setGeoData() {
  return function (dispatch) {
    return inmunityApi
      .getGeographyData()
      .then(res => {
        localStorage.setItem("geo", JSON.stringify(res));
        dispatch(setGeoDataSuccess(res));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function setResponsibleNames(responsibles) {
  return {
    type: types.SET_RESPONSIBLE_NAMES,
    responsibles,
  };
}

export function setReminders(reminders) {
  return {
    type: types.SET_REMINDERS,
    reminders,
  };
}

export function setFullChartName(str) {
  return {
    type: types.SET_FULL_CHART_NAME,
    payload: str,
  };
}

export function setOtherReminders(otherReminders) {
  return {
    type: types.SET_OTHER_REMINDERS,
    otherReminders,
  };
}


export const setPermissions = (payload) => {
  return {
    type: types.GET_PERMISSIONS,
    payload
  }
}
