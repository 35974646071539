import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import InmunityApi from "../../../api/InmunityApi";
import {setPatientOverviewMiddleware} from "../../../actions/trialActions";
import InfoBlock from '../InfoBlock'
import handleNullDate from '../../../utils/handleNullDate'
import "./index.css";



class PatientBlock extends React.Component {
  state = {};
  activeOverview = () => {
    this.props.setPatientOverview(this.props.patient);
  };

  giveActive = ({ target }) => {
    let patientBlock = target.parentNode;
    let parentOfPatientBlock = patientBlock.parentNode;
    if (parentOfPatientBlock.querySelector(".patientBlock-active")) {
      parentOfPatientBlock
        .querySelector(".patientBlock-active")
        .classList.remove("patientBlock-active");
    }
    patientBlock.classList.add("patientBlock-active");
    this.activeOverview();
  };

  render() {
    let {
      rate,
      age,
      gender,
      last_seen,
      next_visit,
      chart,
      immunity_id,
    } = this.props.patient;
    return (
      <div className="patientBlock">
        <div
          className="patientActive"
          onClick={this.giveActive}
        />
        <div className="patientBlock-top">
          <div className="patientID">
            {immunity_id}
            <div className="patientV">
              <span className="patientTitle">Score</span>
              <span className="patientValue">{rate}</span>
            </div>
          </div>
          <div className="patientAG">
            <div className="patientV">
              <span className="patientTitle">Age</span>
              <span className="patientValue">{age}</span>
            </div>
            <div className="patientV">
              <span className="patientTitle">Gender</span>
              <span className="patientValue">
                {gender === "M" ? "Male" : gender === "F" ? "Female" : "Both"}
              </span>
            </div>
          </div>
          <div className="patientAG">
            <div className="patientV">
              <span className="patientTitle">Last Seen</span>
              <span className="patientValue">
                {handleNullDate(moment(last_seen).format("MMM DD YYYY"))}
              </span>
            </div>
            <div className="patientV">
              <span className="patientTitle">Next visit</span>
              <span className="patientValue">
                {handleNullDate(moment(next_visit).format("MMM DD YYYY"))}
              </span>
            </div>
          </div>
        </div>
        <div className="patientBlock-bottom resultView">
          {chart.procedures.find.length > 0 && (
            <div className="patientDP">
              <span className="patientTitle">Past surgical history</span>
              <div className="forPMitems">
                {chart.procedures.find.map((el, i) => (
                  <InfoBlock
                    activeOverview={this.activeOverview}
                    key={i}
                    el={el}
                    immunity_id={immunity_id}
                  />
                ))}
              </div>
            </div>
          )}
          {chart.diseases.find.length > 0 && (
            <div className="patientDP">
              <span className="patientTitle">Past medical history</span>
              <div className="forPMitems">
                {chart.diseases.find.map((el, i) => (
                  <InfoBlock
                    activeOverview={this.activeOverview}
                    key={i}
                    el={el}
                    immunity_id={immunity_id}
                  />
                ))}
              </div>
            </div>
          )}
          {chart.medication.find.length > 0 && (
            <div className="patientDP">
              <span className="patientTitle">Medications</span>
              <div className="forPMitems">
                {chart.medication.find.map((el, i) => (
                  <InfoBlock
                    activeOverview={this.activeOverview}
                    key={i}
                    el={el}
                    immunity_id={immunity_id}
                  />
                ))}
              </div>
            </div>
          )}
          {chart.labs.find.length > 0 && (
            <div className="patientDP">
              <span className="patientTitle">Labs</span>
              <div className="forPMitems">
                {chart.labs.find.map((el, i) => (
                  <InfoBlock
                    activeOverview={this.activeOverview}
                    key={i}
                    el={el}
                    immunity_id={immunity_id}
                  />
                ))}
              </div>
            </div>
          )}
          {chart.allergies.find.length > 0 && (
            <div className="patientDP">
              <span className="patientTitle">Allergies</span>
              <div className="forPMitems">
                {chart.allergies.find.map((el, i) => (
                  <InfoBlock
                    activeOverview={this.activeOverview}
                    key={i}
                    el={el}
                    immunity_id={immunity_id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPatientOverview: obj => dispatch(setPatientOverviewMiddleware(obj))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(PatientBlock);
