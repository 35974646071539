import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import {connect} from 'react-redux'
import "./index.css";
import { StyledLabTable, StyledTableRow, StyledLabTitle, StyledTab, StyledLabDetailedHeader, StyledLabHeader, StyledLabName, StyledLabRowElement, LabScrollContainer, StyledBlockWrapper } from "./styled";

export function NotFound({ searchText }) {
    return (
        <div className="recentSearches">
            <div>
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight="No information available."
                />
            </div>
        </div>
    )
}

function Content({ nameTitle, content, searchText, detailed, onChangeNotesTab, contentType }) {
    console.log(content)
    return (
        <>
            <LabLine
                searchText={searchText}
                nameTitle={nameTitle}
                detailed={detailed}
                contentType={contentType}
            />
            <LabTable
                detailed={detailed}
                content={content}
                searchText={searchText}
                onChangeNotesTab={onChangeNotesTab}
                contentType={contentType}
            />
        </>
    )
}
export function ContentGroup({ content, extended = false, contentType, searchText, onChangeNotesTab }) {
    if (!content) return <NotFound searchText={searchText} />;
    if (content.inclusion && content.exclusion) {
        if (
            (!Array.isArray(content.exclusion) || content.exclusion.length === 0) &&
            (!Array.isArray(content.inclusion) || content.inclusion.length === 0)) {
            return <NotFound searchText={searchText} />;
        }
        return (
            <LabScrollContainer>
                {
                    (Array.isArray(content.inclusion) && content.inclusion.length > 0) &&
                    <Content
                        nameTitle="Inclusion Criteria"
                        searchText={searchText}
                        detailed={false}
                        contentType={contentType}
                        content={content.inclusion}
                        onChangeNotesTab={onChangeNotesTab}
                />
                }
                {
                    (Array.isArray(content.exclusion) && content.exclusion.length > 0) &&
                    <Content
                        nameTitle="(not detected)"
                        searchText={searchText}
                        detailed={false}
                        contentType={contentType}
                        content={content.exclusion}
                        onChangeNotesTab={onChangeNotesTab}
                    />
                }

            </LabScrollContainer>
        )
    }

    if (!Array.isArray(content) || content.length === 0) return <NotFound searchText={searchText} />;
    return (
        <LabScrollContainer>
            <Content
                searchText={searchText}
                contentType={contentType}
                detailed={contentType === 'medications'}
                onChangeNotesTab={onChangeNotesTab}
                content={content}
            />
        </LabScrollContainer>
    )
}



export function NotesDataBlock({ content, searchText }) {
    if (!content || content.length === 0) {
        return (
            <StyledBlockWrapper><NotFound searchText={searchText} /></StyledBlockWrapper>
        )
    }

    return (
        <StyledBlockWrapper>
            <LabScrollContainer>
                <div className="recentSearches">
                    {
                        content.split("\n").map((item, i) => (
                            <div key={i}>
                                <Highlighter
                                    highlightClassName="found"
                                    searchWords={searchText}
                                    autoEscape
                                    textToHighlight={item}
                                />
                                <br />
                            </div>
                        ))
                    }
                </div>
            </LabScrollContainer>
        </StyledBlockWrapper>
    )
}



export function Allergies({ allergiesString, searchText }) {
    return (
        <div className="pcMinWrapp">
            <span className="pcw_title"> Allergies </span>
            <div className="patientChartTA">
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={allergiesString || ""}
                />
            </div>
        </div>
    )
}

export function Title({ title, searchText }) {
    return (
        <span className="pcw_title">
            <Highlighter
                highlightClassName="found"
                searchWords={searchText}
                autoEscape
                textToHighlight={title}
            />
        </span>
    )
}

function TabNotConnected({ index, active, maxLen = 2, target, content, searchText, hideCount = false, fullChartName, length }) {
    const [hover, setHover] = useState(false);

    let count;
    if (hideCount) {
        count = ""
    } else if (!content) {
        count = ""
    } else if (content.inclusion && content.exclusion) {
        count = ` (${content.inclusion.length + content.exclusion.length})`;
    }
    else {
        count = ` (${content.length})`;
    }

    const toggleHover = () => {
        const hiddenName = index === 1 && target.includes('...');
        if (!hiddenName) return;
        setHover(!hover);
    }

    return (
        <div style={{ overflow: 'visible', width: '100%', height: '100%', position: 'relative' }}>
            <StyledTab
                leftCorner={active && index !== 0}
                rightCorner={active && index !== maxLen - 1}
                active={active}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
            >
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={`${target}${count}`}
                />
            </StyledTab>
            {hover &&
                <div className="my-chart-tooltip">
                    <p className="tooltip-text">{fullChartName}</p>
                </div>
            }
        </div>
    )
}

export const Tab = connect((state) => ({ fullChartName: state.fullChartName }))(TabNotConnected)

export function TitleSelectorGroup({ currentTab, tabs, data, searchText, onClick, hideCount }) {
    return (
        <div className="searchBlockWrapper__selector" style={{width: "100%"}}>
            {
                tabs.map(
                    (e, index) => (
                        !tabs[index] ? null :
                            <div
                                style={{width: "20%"}}
                                key={index}
                                onClick={() => { onClick(index) }}>
                                <Tab
                                    maxLen={tabs.length}
                                    active={currentTab === index}
                                    index={index}
                                    hideCount={hideCount}
                                    key={index}
                                    target={e}
                                    content={data[index]}
                                    searchText={searchText}
                                />
                            </div>
                    )
                )
            }
        </div>
    )
}


export function LabLine({ nameTitle = 'Condition Name', searchText, detailed = false, contentType }) {
    // if (!detailed) return null //ghost#hidden
    // else nameTitle = ""
    if(!detailed) nameTitle = ""
    return (
        <StyledLabHeader>
            <StyledLabName>
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    className=""
                    autoEscape
                    textToHighlight={nameTitle}
                />
            </StyledLabName>
            {
              (contentType==="diseases" || contentType==="procedures") && (
                <StyledLabDetailedHeader type={true}>
                    <Highlighter
                        highlightClassName="found"
                        searchWords={[]}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="Code"
                    />
                </StyledLabDetailedHeader>
              )
            }
            {
              (contentType==="diseases" || contentType==="procedures") && (
                <StyledLabDetailedHeader type={true}>
                    <Highlighter
                        highlightClassName="found"
                        searchWords={[]}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="Date"
                    />
                </StyledLabDetailedHeader>
              )
            }
            {
                detailed &&
                <StyledLabDetailedHeader>

                    <Highlighter
                        highlightClassName="found"
                        searchWords={searchText}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="Dosage"
                    />


                    <Highlighter
                        highlightClassName="found"
                        searchWords={searchText}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="UoM"
                    />
                    <Highlighter
                        highlightClassName="found"
                        searchWords={searchText}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="ProdStrength"
                    />


                    <Highlighter
                        highlightClassName="found"
                        searchWords={searchText}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="Route"
                    />


                    <Highlighter
                        highlightClassName="found"
                        searchWords={searchText}
                        className="chart-highlighter"
                        autoEscape
                        textToHighlight="Date"
                    />

                </StyledLabDetailedHeader>
            }
        </StyledLabHeader>
    )
}


export function LabElementDetailed({ el, searchText }) {
    const date = moment(el.startDate).format(
        "MMM DD YYYY"
    );
    return (
        <StyledLabRowElement>
            <span className="ntw__table-value">
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={el.doseValRx}
                />
            </span>
            <span className="ntw__table-value">
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={el.doseUnitRx}
                />
            </span>
            <span className="ntw__table-value">
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={el.prodStrength}
                />
            </span>
            <span className="ntw__table-value">
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={el.route}
                />
            </span>
            <span className="ntw__table-value"
                style={{ minWidth: 120 }}>
                <Highlighter
                    highlightClassName="found"
                    searchWords={searchText}
                    autoEscape
                    textToHighlight={date}
                />
            </span>
        </StyledLabRowElement>
    )
}
export function LabTableDataRow({el , searchText, isDate=false}){
  let codeSystem = "" 
  if (el.code) {
    let codeValue = el.code.value ? el.code.value : ""
    let codeOntology = el.code.ontology ? el.code.ontology : ""
    codeSystem = codeValue + " " + codeOntology
  }
  
  let value = isDate ? (el.date ? moment(el.date).format("MMM DD YYYY") : "-") : codeSystem
  return (
    <StyledLabRowElement type={true}>
        <span className="ntw__table-value" style={{width: "100%", minWidth: "100%", maxWidth: "100%"}}>
            <Highlighter
                highlightClassName="found"
                searchWords={searchText}
                autoEscape
                textToHighlight={value}
            />
        </span>
    </StyledLabRowElement>
  )
}
export function LabTable({ content, searchText, detailed = false, onChangeNotesTab, contentType }) {
    const click = (el) => {
        if(el.chart_id){
            let element = document.getElementsByClassName("notes-list")[0]
            element.scrollIntoView({behavior: 'smooth'})
            onChangeNotesTab({label: `${el.chartdate} ${el.source_category}`, value: el.chart_id})
        }
    }
    return (
        <StyledLabTable>
            {
                content.map(
                    (el, i) => {
                        const nlp_attribution = el.nlp
                            ? "(NLP " + el.source_category + " - " + el.source_description + ", " + el.chartdate + ")"
                            : '';

                        return (
                            <StyledTableRow isFirst={i === 0} key={i}>
                                <StyledLabTitle
                                onClick={() => click(el)}
                                >
                                    <Highlighter
                                        highlightClassName="found"
                                        className="chart-highlighter-col"
                                        searchWords={searchText}
                                        autoEscape
                                        textToHighlight={el.name + " " + nlp_attribution || ""}
                                    />
                                    {/* <Highlighter
                                        highlightClassName="found"
                                        className="chart-highlighter-col"
                                        searchWords={searchText}
                                        autoEscape
                                        textToHighlight={"Hello))))"}
                                    /> */}
                                </StyledLabTitle>
                                {
                                    (contentType==="diseases" || contentType==="procedures" || contentType==="allergies") && (
                                      <LabTableDataRow el={el} searchText={searchText}/>
                                    )
                                }
                                {
                                    (contentType==="diseases" || contentType==="procedures" || contentType==="allergies") && (
                                      <LabTableDataRow el={el} searchText={searchText} isDate={true}/>
                                    )
                                }
                                {
                                    detailed &&
                                    <LabElementDetailed searchText={searchText} el={el} />
                                }
                            </StyledTableRow>
                        )
                    }
                )
            }
        </StyledLabTable>
    )
}
