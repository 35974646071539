import React from 'react'
import { withRouter } from 'react-router'
import defautlUserPhoto from '../../../images/user-default-photo.jpg'
import InmunityApi from '../../../api/InmunityApi'

class User extends React.Component {
  editUser = () => {
    let { history } = this.props
    InmunityApi.getUserById(this.props.id)
      .then(res => {
        localStorage.setItem('infoAboutUser', JSON.stringify(res))
        history.push({
          pathname: '/settings/users/edituser'
        })
      })
      .catch(error => {
        throw new Error(error)
      })
  }
  render () {
    return (
      <div
        className='user-wrapp width100'
        id={this.props.id}
        onClick={this.editUser}
      >
        {this.props.online && (
          <div className="user_online"></div>
        )}
        <div className='userinfo'>
          <img
            src={this.props.photo ? this.props.photo : defautlUserPhoto}
            className='userphoto'
            alt=''
          />

          <div className='username'>
            {this.props.firstName} {this.props.lastName}
          </div>
          <div className='usermail'>{this.props.email}</div>
          <div className='usermembership'>
            Member since {this.props.register.slice(0, 10)}
          </div>
        </div>
        <div className='userrole'>
          <span>{this.props.role}</span>
        </div>
      </div>
    )
  }
}

export default withRouter(User)
