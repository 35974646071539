import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import AllTrialCandidate from "./AllCandidates";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import inmunityApi from "../../api/InmunityApi";
import TrialsNav from "../TrialsNav";
import {URLGenerator} from "../../utils/trailsNavigation";
import "./index.css";
import "../styles/AllTrial.css";
import "react-toggle/style.css";
import {Sort, CustomInfinityScroll} from "../../ui";
import LoadMoreBtn from '../../ui/loadmore'
import {SpinnerSmall} from "../../ui/spinner";

const options = [
  {value: "consented", label: "Contact Consent"},
  {value: "immunity", label: "Immunity ID"},
  {value: "screening", label: "Proceed To Screening"},
  {value: "name", label: "Name"},
  {value: "next_visit", label: "Upcoming Visit" },
  {value: "last_seen", label: "Last Seen" }
];

class AllTrials extends Component {
  state = {
    candidates: [],
    candidatesLength: 0,
    selectedOption: options[0],
    loading: true,
    searching: false,
    isMore: null,
    link: null,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    loadingNext: false,
    paths: []
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.mounted = true;
    let {setCurrentNavigationLink, setCurrentNavBarSubLink} = this.props;
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink("all");
    this.setState({
      loading: true,
    });

    inmunityApi
      .getAllTrialCandidates()
      .then(candidates => {
        if (this.mounted) {
          candidates.results.sort((a, b) => b.age - a.age);
          this.setState({
            candidates: candidates.results,
            loading: false,
            isMore: !!candidates.next,
            link: candidates.next,
          });
        }
      })
      .catch(error =>
        console.log("Error occured while receiving search: ", error)
      );
  }

  handleChange = selectedOption => {
    this.setState({selectedOption}, () => {
      this.sortCandidatesApi();
    });
  };

  reverseSort = () => {
    this.setState({isReverseSort: !this.state.isReverseSort}, () => {
      this.sortCandidatesApi();
    });
  };

  sortCandidatesApi = () => {
    this.setState({loading: true});
    let flag = this.state.selectedOption.value;
    let sign = this.state.isReverseSort ? "-" : "";
    let query = this.state.input !== "" ? this.state.input : null;
    inmunityApi.getAllCandsSorted(sign, query, flag).then(response => {
      this.setState({
        candidates: response.results,
        loading: false,
        isMore: !!response.next,
        link: response.next,
        searchLengthResult: response.count
      });
    });
  };

  loadMoreData = () => {
    this.setState({loadingNext: true}, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldCandidates = [...this.state.candidates];
            let newCandidates = oldCandidates.concat(response.results);
            this.setState({
              candidates: newCandidates,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };
  // Sort Upcoming Visit

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          input: e.target.value,
          candidates: [],
          searchLengthResult: null,
          searching: false
        },
        () => {
          this.sortCandidatesApi();
        }
      );
    } else {
      this.setState({
        input: e.target.value
      });
    }
  };

  searchCands = () => {
    this.setState(
      {
        candidates: [],
        loading: true,
        isMore: false,
        link: null,
        searching: true
      },
      () => {
        this.sortCandidatesApi();
      }
    );
  };

  render() {
    let {
      selectedOption,
      candidates,
      loading,
      searching,
      isReverseSort,
      searchLengthResult,
      isMore,
      loadingNext,
    } = this.state;
    let {history, currentUser} = this.props
    return (
      <div className="content-body-matches" id="list">
        <TrialsNav current='candidates' permissions={this.props.permissions}/>
        <div className="search-container">
          <Sort
            options={options}
            selectedOption={selectedOption}
            isRevert={isReverseSort}
            result={searchLengthResult}
            revert={this.reverseSort}
            select={this.handleChange}
            change={this.handleChangeInput}
            press={this.searchCands}
            searching={searching}
          />
        </div>
        <div className="candidatesWrapper">
          {candidates.map(k => {
            return (
              <AllTrialCandidate
                key={"trial-candidate-slice-" + k["hadmId"]}
                id={"trial-candidate-slice-" + k["hadmId"]}
                candidate={k}
                history={history}
                currentUser={currentUser}
              />
            );
          })}
          {(loading || loadingNext) && <SpinnerSmall/>}
          {!loadingNext && isMore && <LoadMoreBtn loadMoreData={e => this.loadMoreData(e)}/>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    permissions: state.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AllTrials)
);
