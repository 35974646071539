import React, { useState } from "react";
import right from "./../../../images/right.png";
import ExclusionReasonFilter from "../../Exclusions/Filter/ExclusionReasonFilter";
import LastStageFilter from "../../Exclusions/Filter/LastStageFilter";
import SliderFilter from "../../Exclusions/Filter/SliderFilter";
import GenderFilter from "../../Exclusions/Filter/GenderFilter";
import StatusFilter from "../../Exclusions/Filter/StatusFilter";
import DateFilter from "../../Exclusions/Filter/DateFilter";

export const FilterWrapper = ({
  show,
  cancelFilter,
  allExclusions,
  applyFilter
}) => {
  const [options, changeOptions] = useState("");

  // Selected Date From filters
  const [dateFrom, changeDateFrom] = useState("");
  const [dateTo, changeDateTo] = useState("");

  // CallBack to get Field values from filter
  const getValue = value => {
    changeOptions(value);
  };
  // Current Filter
  const [filter, choose] = useState("scoreFilter");

  //Apply Filters Action
  const filterApply = () => {
    if (
      filter !== "Date of exclusion" &&
      filter !== "Next visit" &&
      filter !== "Last seen"
    ) {
      applyFilter({ filter: filter, options: options });
    } else {
      applyFilter({ filter: filter, options: { dateFrom, dateTo } });
    }
  };

  return (
    show && (
      <div className="filterWrapper">
        <div onClick={cancelFilter} className="wrappForFilterClose" />
        <div className="filtersContent perm-sel-wrapper">
          <div className="filterName-wrapper">
            <div
              className={`filterName ${filter === "Score" ? "active" : ""}`}
              onClick={() => choose("Score")}
            >
              Score
              <img height="10px" src={right} alt="icon-right" />
            </div>
            <div
              className={`filterName ${filter === "Age" ? "active" : ""}`}
              onClick={() => choose("Age")}
            >
              Age
              <img height="10px" src={right} alt="icon-right" />
            </div>
            <div
              className={`filterName ${filter === "Status" ? "active" : ""}`}
              onClick={() => choose("Status")}
            >
              Status
              <img height="10px" src={right} alt="icon-right" />
            </div>
            <div
              className={`filterName ${filter === "Gender" ? "active" : ""}`}
              onClick={() => choose("Gender")}
            >
              Gender
              <img height="10px" src={right} alt="icon-right" />
            </div>
            <div
              className={`filterName ${filter === "Last seen" ? "active" : ""}`}
              onClick={() => choose("Last seen")}
            >
              Last seen
              <img height="10px" src={right} alt="icon-right" />
            </div>
            <div
              className={`filterName ${
                filter === "Next visit" ? "active" : ""
              }`}
              onClick={() => choose("Next visit")}
            >
              Next visit
              <img height="10px" src={right} alt="icon-right" />
            </div>
          </div>

          {filter === "Score" && (
            <SliderFilter getValue={getValue} type="score" />
          )}
          {filter === "Age" && <SliderFilter getValue={getValue} type="age" />}
          {filter === "Gender" && <GenderFilter getValue={getValue} />}
          {filter === "Status" && <StatusFilter getValue={getValue} />}
          {filter === "Date of exclusion" && (
            <DateFilter dateTo={changeDateTo} dateFrom={changeDateFrom} />
          )}
          {filter === "Next visit" && (
            <DateFilter dateTo={changeDateTo} dateFrom={changeDateFrom} />
          )}
          {filter === "Last seen" && (
            <DateFilter dateTo={changeDateTo} dateFrom={changeDateFrom} />
          )}
          {filter === "Reason for exclusion" && (
            <ExclusionReasonFilter getValue={getValue} />
          )}
          {filter === "stageFilter" && <LastStageFilter getValue={getValue} />}
        </div>
        <div className="btns-wrapper">
          <div className="filter-btn btn-cancel" onClick={cancelFilter}>
            Cancel
          </div>
          <div className="filter-btn btn-apply" onClick={filterApply}>
            Apply
          </div>
        </div>
      </div>
    )
  );
};
