import * as types from "../actions/actionTypes";

export default function allergiesList(state = [], action) {
	switch (action.type) {
		case types.SET_ALLERGIES_LIST:
			return action.list;
		default:
			return state;
	}
}
