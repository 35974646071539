import * as types from "../actions/actionTypes";

export default function setProceduresLabel(
  state = "some",
  action
) {
  switch (action.type) {
    case types.SET_PROCEDURES_LABEL:
      return action.label;
    default:
      return state;
  }
}
