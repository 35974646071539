import React, { Component } from "react";
import "./index.css";
import moment from "moment";
import InmunityApi from "../../../../api/InmunityApi";
import {
	getSearchForPopulation,
} from "../../../../actions/trialActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Popup from "reactjs-popup";
import crossicon from "./../../../../images/icon-close-w.svg";

class SearchBlock extends Component {
	state = {
		id: this.props.id,
		open: false,
		message: "",
		closePopupOnError: false,
	};

	closeModal = () => {
		this.setState({ open: false, message: "", closePopupOnError: false });
	};

	getPrepopulationOfSearch = ({ target }) => {
		this.props.dispatch(getSearchForPopulation(this.state.id));
		let searchBlock = target.parentNode;
		let parentOfsearchBlock = searchBlock.parentNode.parentNode;
		if (parentOfsearchBlock.querySelector(".searchBlock-active")) {
			parentOfsearchBlock
				.querySelector(".searchBlock-active")
				.classList.remove("searchBlock-active");
		}
		searchBlock.classList.add("searchBlock-active");
	};

	componentWillUnmount() {
		if (this.state.flag) {
			this.props.dispatch(getSearchForPopulation(this.state.id));
		}
	}

	formatFilters = filters => {
		const pushFilterElement = (obj, r) => {
			if (r.include === true) {
				obj.include.push(r.name);
			} else if (r.include === false) {
				obj.exclude.push(r.name);
			} else {
				obj.push(r);
			}
		};
		const ret = {
			demographics: [],
			diseases: {
				include: [],
				exclude: [],
			},
			labs: {
				include: [],
				exclude: [],
			},
			medication: {
				include: [],
				exclude: [],
			},
			procedures: {
				include: [],
				exclude: [],
			},
			substances: {
				include: [],
				exclude: [],
			},
			geo: [],
			physicians: [],
		};
		filters.forEach(r => {
			pushFilterElement(ret[r.criteria], r);
		});
		return ret;
	};

	giveActive = ({ target }) => {
		const { hasResults } = this.props;
		if (!hasResults) {
			return;
		}
		this.setState({
			open: true,
			loadingMessage:
				"Your search is in progress and results will load shortly.",
		});

		InmunityApi.getPatientSearchInfoId(this.state.id)
			.then(res => {
				if (res.status === 202) {
					// this.setState({
					//   finalObj
					// });
					this.setState({
						loadingMessage:
							"Search in process, we will send you notification when search will be finished",
						closePopupOnError: true,
					});
					setTimeout(() => {
						this.setState({
							open: false,
						});
					}, 3000);
					// return res.json();
				} else if (res.status === 200) {
					res.json().then(resp => {
						InmunityApi.getPatientSearchInfo(resp.searchId)
							.then(response => {
								return response.json();
							})
							.then(info => {
								if (info.count === 0) {
									this.setState({
										open: true,
										loadingMessage: "Results not found",
										closePopupOnError: true,
									});
								} else {
									this.props.history.push({
										pathname: `/patients-search/${
											resp.searchId ? resp.searchId + "/" : ""
										}`,
									});
								}
							});
					});
				} else {
					this.setState({
						loadingMessage: "Error occured while creating a search",
						closePopupOnError: true,
					});
				}
			})
			.catch(error =>
				console.log("Error occured while creating a search: ", error)
			);
	};

	render() {
		let { loadingMessage, closePopupOnError, open } = this.state;
		const { created, name, hasResults, index } = this.props;
		return (
			<div className="searchBlock forImportPopup" key={index}>
				<Popup
					open={open}
					closeOnDocumentClick={closePopupOnError}
					onClose={this.closeModal}
					className="saveUserPopup"
				>
					<div>
						{closePopupOnError && (
							<div className="closePendingPopupCross" onClick={this.closeModal}>
								<img src={crossicon} alt="Cross" />
							</div>
						)}
						<div className="saveUserPopupText">{loadingMessage}</div>
						{!closePopupOnError && (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<img
									className="small-spinner"
									src={require("./../../../../images/spinner.gif")}
									alt=""
								/>
							</div>
						)}
					</div>
				</Popup>
				<div
					className="searchBlockActive"
					onClick={this.getPrepopulationOfSearch}
				/>
				<span className="searchBlock__date">
					{moment(created).format("DD MMM - hh:mm A")}
				</span>
				<div className="searchBlock__bottom">
					<span className="searchBlock__countCT">{name}</span>
					<div
						className={
							hasResults
								? "searchBlock__search"
								: "searchBlock__search searchBlock__search-disabled"
						}
						onClick={this.giveActive}
					>
						{hasResults ? "Search again" : "No results"}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		userToken: state.userToken,
	};
}

export default withRouter(connect(mapStateToProps)(SearchBlock));
