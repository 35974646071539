import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import './index.css'

class WorkflowMultiple extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      protocolSelected: 0,
      trialSelected: 0,
      workflowTrials: null,
      fromPOWorkflow: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.workflowTrials !== state.workflowTrials) {
      return {
        workflowTrials: props.workflowTrials
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    if (
      this.props.fromPOWorkflow &&
      this.props.fromPOWorkflow[this.state.trialSelected] &&
      this.props.fromPOWorkflow[this.state.trialSelected].trial
    ) {
      this.setState({
        workflowTrials: this.props.fromPOWorkflow
      })
    }
    if (this.props.workflowTrials) {
      this.setState({
        workflowTrials: this.props.workflowTrials
      })
    }
    if (
      this.props.fromPOWorkflow &&
      this.props.fromPOWorkflow[this.state.trialSelected] &&
      !this.props.fromPOWorkflow[this.state.trialSelected].trial
    ) {
      this.setState({
        fromPOWorkflow: this.props.fromPOWorkflow
      })
    }
  }

  selectWorkflow = protocolSelected => {
    this.setState({
      protocolSelected,
      trialSelected: protocolSelected
    })
  }

  render() {
    return (
      <div className='workflow-multiple-container'>
        {!this.props.fromPOWorkflow && !this.props.workflowTrials && (
          <div style={{ marginTop: '12px' }}>
            No current workflow available.
          </div>
        )}
        {!this.props.workflowTrials ||
          (this.props.workflowTrials.length === 0 && (
            <div style={{ marginTop: '12px' }} className='patInfo_value'>
              No current workflow available.
            </div>
          ))}
        <div className='trials-workflow'>
          {
            Array.isArray(this.props.workflowTrials) &&
            this.props.workflowTrials.map((el, i) => (
              <div
                className={
                  this.state.protocolSelected === i
                    ? 'trial-button selected'
                    : 'trial-button'
                }
                style={{flex: 1}}
                key={el.trial + i}
                onClick={() => this.selectWorkflow(i)}
              >
                {el.trial}
              </div>
            ))}
        </div>
        <div className='time-line'>
          {!this.props.fromPOWorkflow &&
            this.props.workflowTrials &&
            this.props.workflowTrials.length > 0 &&
            this.props.workflowTrials[this.state.protocolSelected].workflow.map(
              (item, index) => {
                let time = moment(item.time)
                let date = time.format('D MMM')
                let hour = time.format('h:mm a')
                return (
                  <div className='item' key={index}>
                    <div className='state-workFlow'>
                      <div className='date-container'>
                        <div className='date'>{date}</div>
                        <div className='date'>{hour}</div>
                      </div>

                      <div className='line-container'>
                        {index !== 0 ? (
                          <div className='line' />
                        ) : (
                            <div className='line b-white' />
                          )}
                        <div className='circle' />
                        {index + 1 !==
                          this.props.workflowTrials[this.state.protocolSelected]
                            .workflow.length ? (
                            <div className='line' />
                          ) : (
                            <div className='line b-white' />
                          )}
                      </div>

                      <div className='status-container'>
                        <h3 className='title text-inactive'>{item.status}</h3>
                        <p className='description text-inactive'>
                          {item.statusDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          {this.props.fromPOWorkflow &&
            this.props.fromPOWorkflow[this.state.trialSelected].workflow.map((item, index) => {
              let time = moment(item.time)
              let date = time.format('D MMM')
              let hour = time.format('h:mm a')
              return (
                <div className='item' key={index}>
                  <div className='state-workFlow'>
                    <div className='date-container'>
                      <div className='date'>{date}</div>
                      <div className='date'>{hour}</div>
                    </div>

                    <div className='line-container'>
                      {index !== 0 ? (
                        <div className='line' />
                      ) : (
                          <div className='line b-white' />
                        )}
                      <div className='circle' />
                      {index + 1 !== this.props.fromPOWorkflow[this.state.trialSelected].workflow.length ? (
                        <div className='line' />
                      ) : (
                          <div className='line b-white' />
                        )}
                    </div>

                    <div className='status-container'>
                      <h3 className='title text-inactive'>{item.status}</h3>
                      <p className='description text-inactive'>
                        {item.statusDescription}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(WorkflowMultiple)
