import React from "react";
import phase_of_study from "./../../../../../images/phase_of_study.svg";

export default function PhaseItem({ phase }) {
  return (
    <div className="infoItem">
      <img src={phase_of_study} alt="Phase of study" />
      <div className="tfc-tc">
        <div className="tfc-title">Phase of study</div>
        <div className="tfc-content" data-test="phaseDisplay">
          Phase{" "}
          {phase
            ? phase.length !== 0
              ? `${phase[0]}${phase[1] ? ", " + phase[1] : ""}`
              : ""
            : ""}
        </div>
      </div>
    </div>
  );
}
