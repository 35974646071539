import React from 'react'
export const UserInitial = ({ user_initials }) => {
  return (
    <div className='contact-image'>
      {user_initials
        ? user_initials
          .split(' ')
          .map(name => name[0].toUpperCase())
          .join('')
        : 'NN'}
    </div>
  )
}
