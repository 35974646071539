import * as types from "../actions/actionTypes";

export default function setFullChartName(
  state = "",
  action
) {
  switch (action.type) {
    case types.SET_FULL_CHART_NAME:
      return action.payload;
    default:
      return state;
  }
}
