import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getSavedSearchesReducer(
  state = initialState.searches,
  action
) {
  switch (action.type) {
    case types.GET_SAVED_SEARCHES_SUCCESS:
      return action.searches;
    default:
      return state;
  }
}
