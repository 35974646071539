import React, { Component, Fragment } from "react";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import InmunityApi from "../../../api/InmunityApi";
import { withRouter } from "react-router";
import i18n from "../../../i18n";
import "./index.css";

class ExclusionReasonsSelect extends Component {
  state = {
    trialMatch: [],
    criteriaMatchedValue: [],
    criteriaUnmatchedValue: [],
    reasonExclusion: [],
    matchedReason: false,
    unmatchedReason: false,
    otherReason: false,
    otherReasonValue: "",
    reasons: [],
    criteriasTrial: []
  };

  componentDidMount() {
    InmunityApi.getCriteriaStudy(this.props.id).then(criterias => {
      const criteriasTrial = criterias.map((el, i) => ({
        ...el,
        value: i
      }));
      this.setState({
        criteriasTrial
      });
    });
  }

  handleTrialChange = selectedOption => {
    this.setState({ trialMatch: selectedOption });
  };

  handleReasonChange = selectedOption => {
    
    this.setState(
      {
        reasonExclusion: selectedOption
      },
      () => {
        let reasons = [];
        this.state.reasonExclusion.forEach(el => {
          reasons.push(el.value);
        });
        this.setState({ reasons });
        reasons.includes(1)
          ? this.setState({ matchedReason: true })
          : this.setState({ matchedReason: false });
        reasons.includes(2)
          ? this.setState({ unmatchedReason: true })
          : this.setState({ unmatchedReason: false });
        reasons.includes(6)
          ? this.setState({ otherReason: true })
          : this.setState({ otherReason: false });
      }
    );
  };

  handleMatchedCriteriaChange = criteriaMatchedValue => {

    this.setState({
      criteriaMatchedValue
    });
  };

  handleUnmatchedCriteriaChange = criteriaUnmatchedValue => {
    this.setState({
      criteriaUnmatchedValue
    });
  };

  handleOtherReasonChange = ({ target }) => {
    this.setState({ otherReasonValue: target.value });
  };

  sendPost = () => {
    if (
      this.props.idAction === 1 &&
      this.state.trialMatch &&
      this.state.trialMatch.value
    ) {
      this.props.setAction(0);

      InmunityApi.postMakematch(this.props.hadmID, this.state.trialMatch.value)
        .then(res => {
          if (res.status === 201) {
            this.props.closeActions();
            InmunityApi.getWorkFlowAll(this.props.hadmID).then(workFlow => {
              this.props.updateWorkflow();
              this.props.hideSpinner && this.props.hideSpinner();
            });
          }
        })
        .catch(e => console.log("error matching to other trial: ", e));
    } else if (this.state.reasonExclusion) {
      let obj = {
        exclude_all: !!(
          this.props.idAction === 3 ||
          this.props.idAction === 4 ||
          this.props.idAction === 7
        )
      };
      let reasons = [];
      this.state.reasonExclusion.forEach(el => {
        let object = {};
        object.reasonId = el.value;
        object.criteria = [];
        switch (el.value) {
          case 1:
            this.state.criteriaMatchedValue.length > 0 &&
              this.state.criteriaMatchedValue.forEach(el => {

                el.value !== undefined && object.criteria.push(el);
              });
            break;
          case 2:
            this.state.criteriaUnmatchedValue.length > 0 &&
              this.state.criteriaUnmatchedValue.forEach(el => {
                el.value !== undefined && object.criteria.push(el);
              });
            break;
          case 6:
            this.state.otherReasonValue &&
              object.criteria.push({
                label: this.state.otherReasonValue,
                value: "Other"
              });
            break;
          default:
            break;
        }
        reasons.push(object);
      });
      obj.reasons = reasons;
      this.props.setAction && this.props.setAction(0);
      InmunityApi.postExcludeTrial(this.props.hadmID, this.props.id, obj)
        .then(response => {
          if (response.status === 201) {
            if (this.props.idAction !== undefined) {
              let object = {
                actionId:
                  this.props.idAction === 2
                    ? 16
                    : this.props.idAction === 5 ||
                      this.props.idAction === 4 ||
                      this.props.idAction === 7 ||
                      this.props.idAction === 8
                      ? this.props.idAction
                      : 17
              };
              InmunityApi.postWorkFlow(this.props.hadmID, this.props.id, object)
                .then(res => {
                  if (res.status === 200) {
                    this.props.workflow.length < 2
                      ? InmunityApi.getWorkFlow(
                        this.props.hadmID,
                        this.props.id
                      ).then(workFlow => {
                        console.log("Here in esclusion componenet ", workFlow)
                        this.props.updateWorkflow();
                        this.props.hideSpinner && this.props.hideSpinner();
                      })
                      : InmunityApi.getWorkFlowAll(this.props.hadmID).then(
                        workFlow => {
                          this.props.updateWorkflow(workFlow);
                          this.props.hideSpinner && this.props.hideSpinner();
                        }
                      );
                  }
                })
                .catch(error => console.log("Error saving workflow: ", error));
            } else {
              this.props.history.push("/thank-you");
            }
          } else {
            console.log("ERROR excluding from trial");
          }
        })
        .catch(error =>
          console.log("Error occured while saving reasons: ", error)
        );
    }
  };
  render() {
    const { idAction, trials, declineReasons } = this.props;
    const {
      reasonExclusion,
      trialMatch,
      matchedReason,
      unmatchedReason,
      criteriaMatchedValue,
      criteriaUnmatchedValue,
      otherReasonValue,
      otherReason,
      criteriasTrial
    } = this.state;
    console.log("declineReasons",declineReasons);
    
    return (
      <Fragment>
        {idAction === 1 ? (
          <Select
            classNamePrefix={"select-search"}
            name="selectTrial"
            value={trialMatch}
            options={trials || []}
            onChange={this.handleTrialChange}
            placeholder="Please select one"
          />
        ) : (
            <Select
              classNamePrefix={"select-search"}
              name="selectReason"
              options={declineReasons}
              value={reasonExclusion}
              onChange={this.handleReasonChange}
              placeholder="Please select one"
              isMulti={true}
              components={makeAnimated()}
            />
          )}
        {matchedReason && (
          <div className="decline-reason-select">
            <hr className="select-separator" />
            <span>{i18n.t("actionsOverview.matchedInclusionCriteria")}</span>
            <Select
              value={criteriaMatchedValue}
              classNamePrefix={"select-search"}
              closeMenuOnSelect={false}
              onChange={this.handleMatchedCriteriaChange}
              options={criteriasTrial}
              className="sort_select withInput width100"
              isMulti={true}
              components={makeAnimated()}
            />
          </div>
        )}
        {unmatchedReason && (
          <div className="decline-reason-select">
            <hr className="select-separator" />
            <span>
              {i18n.t("actionsOverview.didNotMatchInclusionCriteria")}
            </span>
            <Select
              value={criteriaUnmatchedValue}
              classNamePrefix={"select-search"}
              closeMenuOnSelect={false}
              onChange={this.handleUnmatchedCriteriaChange}
              options={criteriasTrial}
              className="sort_select withInput width100"
              isMulti={true}
              components={makeAnimated()}
            />
          </div>
        )}
        {otherReason && (
          <div className="decline-reason-select">
            <hr className="select-separator" />
            <span>{i18n.t("actionsOverview.otherReason")}</span>
            <input
              value={otherReasonValue}
              onChange={this.handleOtherReasonChange}
              className="input-other-reason"
              placeholder="Type other reason"
            />
          </div>
        )}
        {idAction !== undefined && (
          <div className="button-action-container">
            <div className="submit-button" onClick={this.sendPost}>
              {i18n.t("actionsOverview.continue")}
            </div>
          </div>
        )}
        {idAction === undefined && (
          <div className="btnsWrapp">
            <div className="submit width100" onClick={this.sendPost}>
              Submit
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(ExclusionReasonsSelect);
