import React, { Component, Fragment } from "react";
import moment from "moment";
import DateFilter from "./DateFilter";
import ExclusionReasonFilter from "./ExclusionReasonFilter";
import LastStageFilter from "./LastStageFilter";
import SliderFilter from "./SliderFilter";
import GenderFilter from "./GenderFilter";
import StatusFilter from "./StatusFilter";
import FilterResult from "./FilterResult";
import TrialFilter from "./TrialFilter";
// import PateintIdFilter from "./PatientIdFilter";

import "./index.css";

import right from "./../../../images/right.png";

export default class Filter extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      dateFilter: props.type === "exclusionsFilter",
      nextVisitFilter: false,
      lastSeenFilter: false,
      reasonFilter: false,
      stageFilter: false,
      patientIdFilter: false,
      scoreFilter: props.type === "searchFilter",
      ageFilter: false,
      genderFilter: false,
      statusFilter: false,
      trialFilter: false,
      dateTo: "",
      dateFrom: "",
      value: "",
      filterName:
        props.type === "exclusionsFilter" ? "Date of exclusion" : "Score",
      filterValue: "",
      filtersArray: [],
      show: false,
      resultsArray: []
    };
  }

  openFilter = () => {
    this.setState({ show: true });
  };

  cancelFilter = () => {
    this.setState({ show: false, dateTo: "", dateFrom: "" });
  };

  chooseFilter = e => {
    let text = null;
    if (e.target.tagName === "IMG") {
      text = e.target.parentNode.textContent;
    } else {
      text = e.target.textContent;
    }
    switch (text) {
      case "Date of exclusion":
        this.setState({
          lastSeenFilter: false,
          nextVisitFilter: false,
          dateFilter: true,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Reason for exclusion":
        this.setState({
          lastSeenFilter: false,
          nextVisitFilter: false,
          dateFilter: false,
          reasonFilter: true,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text,
          dateFrom: "",
          dateTo: ""
        });
        break;
      case "Last stage":
        this.setState({
          lastSeenFilter: false,
          nextVisitFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: true,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text,
          dateFrom: "",
          dateTo: ""
        });
        break;
      case "Patient ID":
        this.setState({
          lastSeenFilter: false,
          nextVisitFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: true,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text,
          dateFrom: "",
          dateTo: ""
        });
        break;
      case "Last seen":
        this.setState({
          lastSeenFilter: true,
          nextVisitFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Next visit":
        this.setState({
          nextVisitFilter: true,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Score":
        this.setState({
          nextVisitFilter: false,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: true,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Age":
        this.setState({
          nextVisitFilter: false,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: true,
          genderFilter: false,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Gender":
        this.setState({
          nextVisitFilter: false,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: true,
          statusFilter: false,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Status":
        this.setState({
          nextVisitFilter: false,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: true,
          trialFilter: false,
          filterName: text
        });
        break;
      case "Trial":
        this.setState({
          nextVisitFilter: false,
          lastSeenFilter: false,
          dateFilter: false,
          reasonFilter: false,
          stageFilter: false,
          patientIdFilter: false,
          scoreFilter: false,
          ageFilter: false,
          genderFilter: false,
          statusFilter: false,
          trialFilter: true,
          filterName: text
        });
        break;
      default:
        return null;
    }
  };

  dateTo = dateTo => {
    this.setState({ dateTo });
  };

  dateFrom = dateFrom => {
    this.setState({ dateFrom });
  };

  getValue = value => {
    this.setState({ value });
  };
  getDerivedStateFromProps = (props, state) => {
    this.setState({
      filtersArray: [],
      resultsArray: [],
      ...this.props.filtersArray
    });
    this.applyFilter();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  applyFilter = () => {
    let filtersArray = [...this.state.filtersArray];
    let resultsArray = [...this.state.resultsArray];
    let {
      filterName,
      dateFrom,
      dateTo,
      value,
      dateFilter,
      genderFilter,
      lastSeenFilter,
      nextVisitFilter
    } = this.state;
    if (dateFilter || lastSeenFilter || nextVisitFilter) {
      let inputs = document.querySelectorAll(".cal-filter");
      let length = inputs.length;
      for (let i = 0; i < length; i++) {
        if (inputs[i].textContent === "select date") {
          inputs[i].classList.add("redBorder");
          return null;
        } else {
          inputs[i].classList.remove("redBorder");
        }
      }
    } else if (genderFilter) {
      let input = document.querySelector(".ex-filter-select");
      if (value === "Male" || value === "Female" || value === "Both") {
        if (input) {
          input.classList.remove("redBorder");
        }
      } else {
        if (input) {
          input.classList.add("redBorder");
        }
        return null;
      }
    } else {
      let input = document.querySelector(".ex-filter-select");
      if (value === "") {
        if (input) {
          input.classList.add("redBorder");
        }
        return null;
      } else {
        if (input) {
          input.classList.remove("redBorder");
        }
      }
    }
    let existID = -1;
    resultsArray.map((el, index) => {
      if (el.filterName === filterName) {
        existID = index;
      }
      return null;
    });
    if (existID === -1) {
      filtersArray.push(
        <FilterResult
          name={filterName}
          value={dateFrom && dateTo ? `${dateFrom} - ${dateTo}` : value}
          removeFilter={this.deleteResult}
          index={filtersArray.length}
        />
      );
    } else {
      filtersArray[existID] = (
        <FilterResult
          name={filterName}
          value={dateFrom && dateTo ? `${dateFrom} - ${dateTo}` : value}
          removeFilter={this.deleteResult}
        />
      );
    }
    let numValue = value.split(" - ");
    let filter =
      this.state.dateFrom && this.state.dateTo
        ? {
            dateFrom: moment(this.state.dateFrom).format("MMM DD YYYY"),
            dateTo: moment(this.state.dateTo).format("MMM DD YYYY"),
            filterName: this.state.filterName
          }
        : {
            value: { min: numValue[0], max: numValue[1] },
            filterName: this.state.filterName
          };
    if (existID === -1) {
      resultsArray.push(filter);
    } else {
      resultsArray[existID] = filter;
    }
    this.props.applyFilter(resultsArray);
    this.setState({
      filtersArray,
      dateTo: "",
      dateFrom: "",
      value: "",
      filterName:
        this.props.type === "exclusionsFilter" ? "Date of exclusion" : "Score",
      dateFilter: this.props.type === "exclusionsFilter" ? true : false,
      nextVisitFilter: false,
      lastSeenFilter: false,
      reasonFilter: false,
      stageFilter: false,
      patientIdFilter: false,
      scoreFilter: this.props.type === "searchFilter" ? true : false,
      ageFilter: false,
      genderFilter: false,
      statusFilter: false,
      trialFilter: false,
      show: false,
      resultsArray
    });
    this.props.updateFilters({
      filtersArray,
      dateTo: "",
      dateFrom: "",
      value: "",
      filterName:
        this.props.type === "exclusionsFilter" ? "Date of exclusion" : "Score",
      dateFilter: this.props.type === "exclusionsFilter" ? true : false,
      nextVisitFilter: false,
      lastSeenFilter: false,
      reasonFilter: false,
      stageFilter: false,
      patientIdFilter: false,
      scoreFilter: this.props.type === "searchFilter" ? true : false,
      ageFilter: false,
      genderFilter: false,
      statusFilter: false,
      trialFilter: false,
      show: false,
      resultsArray
    });
  };

  deleteResult = (index, value, name) => {
    let filtersArray = [...this.state.filtersArray];
    let resultsArray = [...this.state.resultsArray];
    filtersArray.splice(index, 1);
    resultsArray.splice(index, 1);
    if (resultsArray.length === 0) {
      this.props.applyFilter(resultsArray);
    } else {
      const func = async () => {
        let i = 0;
        for (const el of resultsArray) {
          try {
            if (i === 0) {
              await this.props.applyFilter(resultsArray);
            } else {
              await this.props.applyFilter(resultsArray);
            }
            i++;
          } catch (e) {
            console.log("error occured while waiting results: ", e, el);
          }
        }
      };
      func();
    }
    this.setState({ filtersArray, resultsArray });
    this.props.updateFilters({ ...this.state, filtersArray, resultsArray });
  };

  render() {
    let {
      type,
      dateFilter,
      reasonFilter,
      stageFilter,
      // patientIdFilter,
      filtersArray,
      show,
      nextVisitFilter,
      lastSeenFilter,
      scoreFilter,
      ageFilter,
      genderFilter,
      statusFilter,
      trialFilter
    } = this.state;
    const { allExclusions } = this.props;
    return (
      <Fragment>
        <div
          className={
            type === "exclusionsFilter"
              ? "ex_save_search_wrapper"
              : "ex_save_search_wrapper ex_wrapper"
          }
        >
          <div className="ex_addWrapp" onClick={this.openFilter}>
            <div className="ex_save_search_btn">+</div>
            {type === "exclusionsFilter" && (
              <span className="ex_save_search_text">Add new filter</span>
            )}
            {type === "searchFilter" && (
              <span className="ex_save_search_text">
                Filter current search results
              </span>
            )}
          </div>
          {show && (
            <div className="filterWrapper">
              <div
                onClick={this.cancelFilter}
                className="wrappForFilterClose"
              />
              <div className="filtersContent perm-sel-wrapper">
                <div className="filterName-wrapper">
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${scoreFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Score
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${ageFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Age
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${statusFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Status
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${genderFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Gender
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${lastSeenFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Last seen
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "searchFilter" && (
                    <div
                      className={`filterName ${
                        nextVisitFilter ? "active" : ""
                      }`}
                      onClick={this.chooseFilter}
                    >
                      Next visit
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "exclusionsFilter" && (
                    <div
                      className={`filterName ${dateFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Date of exclusion
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "exclusionsFilter" && (
                    <div
                      className={`filterName ${reasonFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Reason for exclusion
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "exclusionsFilter" && (
                    <div
                      className={`filterName ${stageFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Last stage
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                  {type === "exclusionsFilter" && allExclusions && (
                    <div
                      className={`filterName ${trialFilter ? "active" : ""}`}
                      onClick={this.chooseFilter}
                    >
                      Trial
                      <img height="10px" src={right} alt="icon-right" />
                    </div>
                  )}
                </div>
                {scoreFilter && (
                  <SliderFilter getValue={this.getValue} type="score" />
                )}
                {ageFilter && (
                  <SliderFilter getValue={this.getValue} type="age" />
                )}
                {genderFilter && <GenderFilter getValue={this.getValue} />}
                {statusFilter && <StatusFilter getValue={this.getValue} />}
                {dateFilter && (
                  <DateFilter dateTo={this.dateTo} dateFrom={this.dateFrom} />
                )}
                {nextVisitFilter && (
                  <DateFilter dateTo={this.dateTo} dateFrom={this.dateFrom} />
                )}
                {lastSeenFilter && (
                  <DateFilter dateTo={this.dateTo} dateFrom={this.dateFrom} />
                )}
                {reasonFilter && (
                  <ExclusionReasonFilter getValue={this.getValue} />
                )}
                {stageFilter && <LastStageFilter getValue={this.getValue} />}
                {trialFilter && allExclusions && (
                  <TrialFilter getValue={this.getValue} />
                )}
              </div>
              <div className="btns-wrapper">
                <div
                  className="filter-btn btn-cancel"
                  onClick={this.cancelFilter}
                >
                  Cancel
                </div>
                <div
                  className="filter-btn btn-apply"
                  onClick={this.applyFilter}
                >
                  Apply
                </div>
              </div>
            </div>
          )}
        </div>
        {filtersArray.length > 0 && (
          <div
            className={`wrappForTopSearchItems exclusionsResultsWrapper${
              type === "searchFilter"
                ? " searchFilterRes"
                : " exclusions_page_fr"
            }`}
          >
            {filtersArray.map((filter, i) => {
              return <Fragment key={i}>{filter}</Fragment>;
            })}
          </div>
        )}
      </Fragment>
    );
  }
}
