import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setProceduresICD10ListReducer(
  state = initialState.proceduresICD10List,
  action
) {
  switch (action.type) {
    case types.SET_PROCEDURES_ICD10_LIST:
      return action.proceduresICD10List;
    default:
      return state;
  }
}
