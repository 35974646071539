import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getRecentSearchesReducer(
  state = initialState.searchesRecent,
  action
) {
  switch (action.type) {
    case types.GET_RECENT_SEARCHES_SUCCESS:
      if (action.next && Array.isArray(action.searchesRecent.results)) {
        return {
          ...state,
          next: action.searchesRecent.next,
          count: action.searchesRecent.count,
          previous: action.searchesRecent.previous,
          results: [...state.results, ...action.searchesRecent.results].reduce(
            (acc, cur) => {
              if (acc.idsArray.includes(cur.id)) {
                return acc;
              } else {
                acc.idsArray.push(cur.id);
                acc.result.push(cur);
                return acc;
              }
            },
            {
              idsArray: [],
              result: []
            }
          ).result
        };
      }
      if (action.next) {
        return {
          ...state,
          next: action.searchesRecent.next,
          count: action.searchesRecent.count,
          previous: action.searchesRecent.previous
        };
      }
      return {
        ...state,
        next: action.searchesRecent.next,
        count: action.searchesRecent.count,
        previous: action.searchesRecent.previous,
        results: [...action.searchesRecent.results]
      };

    case types.SET_RECENT_SEARCHES_RELOAD:
      return {
        ...state,
        reload: action.reload
      };

    default:
      return state;
  }
}
