import React, {Fragment} from "react";
import moment from "moment";

export default function WorkFlowItem(props) {
    let {item, index, workflow, openEmailPopup, openPrimitivePopup} = props;
    let time = moment(item.time);
    let date = time.format("MMM DD YY");
    let hour = time.format("h:mm a");
    return (
        <div className="state-workFlow wf-width95">
            {workflow && (
                <div className="date-container">
                    <div className="date">{date}</div>
                    <div className="date">{hour}</div>
                </div>
            )}

            {workflow && (
                <div className="line-container">
                    {index !== 0 ? (
                        <div className="line" />
                    ) : (
                        <div className="line b-white" />
                    )}
                    <div className="circle" />
                    {index + 1 !== workflow.length ? (
                        <div className="line" />
                    ) : (
                        <div className="line b-white" />
                    )}
                </div>
            )}

            <div className="status-container">
                {!workflow && (
                    <Fragment>
                        <div className="inline-items">
                            <div className="circle mg-r-15" />
                            <div>
                                <div className="inline-items">
                                    <h3 className="title text-inactive">
                                        {item.status}
                                    </h3>
                                    <div className="date date-pd">{date}</div>
                                    <div className="date date-pd">{hour}</div>
                                </div>
                                {(item.status === "Physician Email Resent")  && (
                                    <h3
                                        onClick={() =>
                                            openEmailPopup(item.email_id)
                                        }
                                        className="view-email text-inactive"
                                    >
                                        View Email
                                    </h3>
                                )}
                                {item.coordinator && (
                                    <p className="description text-inactive">
                                        {item.coordinator.name}
                                    </p>
                                )}
                                <p className="description text-inactive">
                                    {item.statusDescription}
                                </p>
                            </div>
                        </div>
                    </Fragment>
                )}
                {workflow && (
                    <Fragment>
                        <h3 className="title text-inactive">{item.status}</h3>
                        {(item.status === "Physician Email Sent" ||
                            item.status === "Patient Sent Email") && (
                            <h3
                                onClick={() => {
                                    if ( item.status === "Patient Sent Email") {                                        
                                        openPrimitivePopup(item.email_id)
                                    }
                                    else {
                                        openEmailPopup(item.email_id)
                                    }  
                                }
                                }
                                className="view-email text-inactive"
                            >
                                View Email
                            </h3>
                        )}
                        {item.coordinator && (
                            <p className="description text-inactive">
                                {item.coordinator.name}
                            </p>
                        )}
                        <p className="description text-inactive">
                            {item.statusDescription}
                        </p>
                    </Fragment>
                )}
            </div>
        </div>
    );
}
