import React, {useEffect, useState, useRef} from 'react';
import {
  cancelSearchParam,
  getSearchForPopulation,
  setCurrentNavigationLink,
  setGlobalSpinner,
  setPatientOverviewMiddleware,
  setPatientRes,
  setPatientsFilterRes
} from "../../actions/trialActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from "moment";
import editSearch from "../../images/edit_search.svg";
import Filters from "./Filter";
import {Spinner, SpinnerSmall} from "../../ui";
import "./index.css";
import PatientInfo from "./PatientInfo";
import {
  fetchPatientSearchPaginationFullUrl,
  fetchPatientSearchResult,
  sortPatientSearchResult
} from "../../actions/searchResultActions";
import NoResultsPopup from "./Popup/NoResultsPopup";
import SearchSort from "./SearchSort";
import PatientBlock from "./PatientBlock";
import LoadMoreBtn from "../../ui/loadmore";
import arrow from "../../images/left.png";
import SearchSites from "./SearchSites";
import {fetchAnalytics, fetchHospitals, fetchSite} from "../../actions/sitesDataActions";
import InmunityApi from "../../api/InmunityApi";
import FullScreenLoader from "./FullScreenLoader";

const formatFilters = data => {
  const filters = data.criteria;

  if (!Array.isArray(filters)) return [];
  const pushFilterElement = (obj, r) => {
    if (r.criteria === "demography") {
      obj.push({
        name: r.name,
        required: r.required
      });
    } else if (r.criteria === "geography") {
      obj.push({
        name: r.name,
        required: r.required
      });
    } else if (r.criteria === "practitioner") {
      obj.push({
        name: r.name,
        required: r.required
      });
    } else if (r.criteria === "immunity") {
      obj.push({
        name: r.name,
        required: r.required
      });
    } else if (r.criteria === "name") {
      obj.push({
        name: r.name,
        required: r.required
      });
    } else if (r.include === true) {
      obj.include.push({
        name: r.name,
        required: r.required
      });
    } else if (r.include === false) {
      obj.exclude.push({
        name: r.name,
        required: r.required
      });
    } else {
      obj.push({
        name: r.name,
        required: r.required
      });
    }
  };
  const ret = {
    demography: [],
    practitioner: [],
    disease: {
      include: [],
      exclude: []
    },
    lab: {
      include: [],
      exclude: []
    },
    medication: {
      include: [],
      exclude: []
    },
    procedure: {
      include: [],
      exclude: []
    },
    allergy: {
      include: [],
      exclude: []
    },
    immunity: [],
    name: [],
    substance: {
      include: [],
      exclude: []
    },
    geography: [],
    physicians: [],
    timestamp: data.searchTimestamp
  };
  filters.forEach(r => {
    pushFilterElement(ret[r.criteria], r);
  });

  return ret;
};

const formatCount = c => String(c).split("")
  .reverse()
  .reduceRight(
    (c, e, index) =>
      c +
      (index && index % 3 === 0
        ? e + ","
        : e), ""
  )

const PatientsSearch =
  ({
     sortPatientSearchResult,
     patientsFilterResult: filters,
     currentNavigationLink,
     setCurrentNavigationLink,
     cancelSearchParam,
     globalSpinner,
     setGlobalSpinner,
     patientsResult,
     setPatientsResult,
     setPatientsFilterResult,
     getSearchForPopulation,
     setPatientOverview,
     loadMorePatientResults,
     permissions,
     location,
     history,
     hospitals,
     site,
     analiticData,
     setSite,
     setHospitals,
     setAnalytics
   }) => {
    const [state, setState] = useState({
      activeTab: 1,
      currentID: location.pathname.split("/")[2],
      isTimeToGoUp: false,
      isNoResults: false,
      isMorePatients: false,
      isFetching: false
    });
    const [filtersArray, setFiltersArray] = useState([]);
    let patientListContainer = useRef(null)

    useEffect(() => {
      window.scrollTo(0, 0);
      if (currentNavigationLink !== 2) {
        setCurrentNavigationLink(2);
      }
      setState({...state, isFetching: true})

      setPatientsFilterResult(state.currentID);
      setSite(state.currentID);
      setHospitals(state.currentID);
      setAnalytics(state.currentID);

      setState({...state, isFetching: false})
    }, []);

    const goBack = () => {
      getSearchForPopulation(state.currentID);
      history.push({pathname: `/patients`});
    };

    const changeTabs = newActiveTab => {
      // newActiveTab === 1 &&
      // this.getPatientOverview(this.props.patientsResult.result[0]);
      setState({...state, activeTab: newActiveTab});
    }

    const removeFilter = (name, value) => {
      setState({...state, isFetching: true});
      cancelSearchParam(state.currentID, value)
        .then(r => {
          if (r && r.message) {
            setState({...state, isNoResults: true})
          }
          setState({...state, isFetching: false});
        })
    }

    const patientsBlocks = patientsResult.result
    && patientsResult.result.length > 0
      ? patientsResult.result.map((r, i) => <PatientBlock key={i} itemIndex={i} patient={r}/>)
      : [];

    const loadMoreData = e => {
      if (patientsResult.next && !patientsResult.loading) {
        loadMorePatientResults(patientsResult.next, patientsResult.result);
      }
    }

    const handlePatientListScroll = (e) => {
      document.getElementById("scroolTop__list").scrollTop > 500
        ? setState({...state, isTimeToGoUp: true})
        : setState({...state, isTimeToGoUp: false})

    }

    const moveToTop = e => {
      e.preventDefault();
      document.getElementById("scroolTop__list").scrollTop = 0;
    }

    const onClosePopup = () => {
      setState({...state, isNoResults: false});
      history.push({
        pathname: "/patients"
      });
    };

    return (
      <div className="container-n cont-searchP">
        {
          globalSpinner ?
            <Spinner/>
            :
            <div className="patientsTest-header">
              <div className="patientsTest-headerHelper">
                <h1 className="title-container">
                  {filters.timestamp
                    ? moment(filters.timestamp).format(
                      "MMM DD YYYY - hh:mm A "
                    )
                    : ""}
                  ({patientsResult.count ? formatCount(patientsResult.count) : 0} result)
                </h1>
                <div className="wrappForBtn-patients">
                  <span className="patInfo_title">Edit Search</span>
                  <div className="editSearch" onClick={goBack}>
                    <img src={editSearch} alt="Arrow"/>
                  </div>
                </div>
              </div>
              <div className="patients_tabs_wrapp">
                <div
                  className={`tab_item${state.activeTab === 1 ? " active_tab" : ""}`}
                  onClick={() => changeTabs(1)}
                >
                  Patients
                </div>
                {permissions.analytics_trials &&
                <div
                  className={`tab_item${state.activeTab === 2 ? " active_tab" : ""}`}
                  onClick={() => changeTabs(2)}
                >
                  Sites
                </div>
                }
              </div>
              <div className="wrappForTopSearchItems">
                {
                  filters &&
                  Object.values(filters).filter((type, i) => Object.keys(filters)[i] !== 'error' || Object.keys(filters)[i] !== 'loading').map((filter, i) =>
                    <Filters
                      deleteFilter={removeFilter}
                      filters={filter}
                      required={filter.required}
                      type={Object.keys(filters)[i]}
                      key={i}
                    />)
                }
              </div>

              {state.activeTab === 1 && (
                <SearchSort
                  id={state.currentID}
                  patients={patientsBlocks}
                  initialPatients={patientsResult.result || []}
                  sortPatients={async (url) => {
                    setState({...state, isFetching: true})
                    sortPatientSearchResult(state.currentID, url)
                      .then(() => setState({...state, isFetching: false}))
                  }}
                  updateFilters={(newFilters) => setFiltersArray([...newFilters])}
                  filtersArray={filtersArray}
                />
              )}
              {state.activeTab === 1 &&
              <div className="matchDetailsWrapper patientsView">
                <div
                  className="patientsView-ls"
                  id="scroolTop__list"
                  onScroll={handlePatientListScroll}
                  ref={ref => patientListContainer = ref}
                >
                  <div>
                    {patientsBlocks}
                    {
                      patientsBlocks.length === 0
                      && !patientsResult.loading
                      && patientsResult.count === 0
                      && <div className="noResFound">No result.</div>
                    }
                    {patientsResult.loading && <SpinnerSmall/>}
                    {
                      !patientsResult.loading
                      && patientsResult.next
                      && <LoadMoreBtn loadMoreData={e => loadMoreData(e)}/>
                    }
                    <div
                      id="anchor"
                      className={`btn-up btn-up-patSerRes ${
                        state.isTimeToGoUp ? "" : "hide"
                      }`}
                      onClick={moveToTop}
                    >
                      <img src={arrow} alt="Arrow"/>
                    </div>
                  </div>
                </div>
                <div className="patientsView-rs">
                  <PatientInfo/>
                </div>
              </div>
              }
              {state.activeTab === 2 && (
                <SearchSites
                  sites={analiticData}
                  site={site}
                  hospitals={hospitals}
                  patientSpinner={patientsResult.loading}
                />
              )}
              <NoResultsPopup status={state.isNoResults} onClose={onClosePopup} />
              {state.isFetching && <FullScreenLoader/>}
            </div>
        }
      </div>
    );
  };

function mapStateToProps(state) {
  return {
    globalSpinner: state.globalSpinner,
    patientsResult: state.patientsResult,
    patientOverview: state.patientOverview,
    patientsFilterResult: state.patientsFilterResult,
    search: state.search,
    permissions: state.permissions,
    currentNavigationLink: state.currentNavigation.navBar,
    hospitals: state.hospitals,
    site: state.site,
    analiticData: state.analiticData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGlobalSpinner: spinner => dispatch(setGlobalSpinner(spinner)),
    setPatientsResult: currentID => dispatch(fetchPatientSearchResult(currentID)),
    setPatientsFilterResult: currentID => dispatch(setPatientsFilterRes(currentID, formatFilters)),
    getSearchForPopulation: id => dispatch(getSearchForPopulation(id)),
    setCurrentNavigationLink: navID => dispatch(setCurrentNavigationLink(navID)),
    setPatientOverview: obj => dispatch(setPatientOverviewMiddleware(obj)),
    cancelSearchParam: (searchID, value) => dispatch(cancelSearchParam(searchID, value, formatFilters)),
    loadMorePatientResults: (link, prevRes) => dispatch(fetchPatientSearchPaginationFullUrl(link, prevRes)),
    sortPatientSearchResult: (id, url) => dispatch(sortPatientSearchResult(id, url || 'ordering=-score')),
    setSite: (searchID) => dispatch(fetchSite(searchID)),
    setHospitals: (searchID) => dispatch(fetchHospitals(searchID)),
    setAnalytics: (searchID) => dispatch(fetchAnalytics(searchID)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientsSearch)
);