import React, { Component } from "react";
import ReactSlider from "react-slider";

export default class SliderFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      minValue: 0,
      maxValue: 100
    };
    this.refStart = React.createRef();
    this.refEnd = React.createRef();
    this.refFirstValue = React.createRef();
    this.refSecondValue = React.createRef();
  }

  componentDidMount() {
    this.setState({
      type: this.props.type
    });
    this.changeSliderValue([0, 100]);
    this.refFirstValue.current.style.marginLeft = "0";
    this.refSecondValue.current.style.marginLeft = "0";
  }

  changeSliderValue = value => {
    let minValue = value[0];
    let maxValue = value[1];
    let res = minValue + " - " + maxValue;
    let start = this.refStart.current;
    let end = this.refEnd.current;
    let distance = this.getDistanceBetweenElements(start, end);
    if (distance < 21 && distance > 0) {
      this.refFirstValue.current.style.marginLeft = "-5px";
      this.refSecondValue.current.style.marginLeft = "10px";
    } else if (distance === 0) {
      this.refFirstValue.current.style.marginLeft = "2px";
      this.refSecondValue.current.style.marginLeft = "2px";
    } else {
      this.refFirstValue.current.style.marginLeft = "0";
      this.refSecondValue.current.style.marginLeft = "0";
    }
    this.setState({ minValue, maxValue });
    this.props.getValue(res);
  };

  getPositionAtCenter = element => {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2
    };
  };

  getDistanceBetweenElements = (a, b) => {
    const aPosition = this.getPositionAtCenter(a);
    const bPosition = this.getPositionAtCenter(b);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
  };

  render() {
    let { type, minValue, maxValue } = this.state;
    return (
      <div className="filterContent sliderContent">
        {type === "score" && <span className="datePicker">Score</span>}
        {type === "age" && <span className="datePicker">Age</span>}
        <div className="filters-sexes__sliderWrap">
          <ReactSlider
            min={0}
            max={100}
            withBars
            onChange={this.changeSliderValue}
            defaultValue={[minValue, maxValue]}
          >
            <div>
              <div className="sexSlider filterSlider" ref={this.refStart} />
              <span ref={this.refFirstValue}>{minValue}</span>
            </div>
            <div>
              <div className="sexSlider filterSlider" ref={this.refEnd} />
              <span ref={this.refSecondValue}>{maxValue}</span>
            </div>
          </ReactSlider>
        </div>
      </div>
    );
  }
}
