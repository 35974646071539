import * as types from '../actions/actionTypes';

export default function permissions(state = {}, action) {
    switch(action.type) {
        case types.GET_PERMISSIONS:
            return action.payload
        default:
            return state;
    }
}
