import React from "react";
import spinner from "../images/spinner.gif";
import "./spinner.css";
export function Spinner() {
  return (
    <div
      id="loading-candidates"
      className="loadingCandidates load-match-details"
    >
      <img className="big-spinner" src={spinner} alt="Spinner" />
    </div>
  );
}

export function SpinnerSmall() {
  return (
    <div className="loader">
      <img className="small-spinner" src={spinner} alt="Spinner" />
    </div>
  );
}

export function MicroSpinner() {
  return (
    <div className="loader micro-spinner-wrapp">
      <img className="micro-spinner" src={spinner} alt="Spinner" />
    </div>
  );
}


export const GlobalSpinner = () => (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '85%', float: 'right'}}> <Spinner /> </div>)