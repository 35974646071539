import React, {PureComponent} from "react";
import "create-react-class";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {connect} from "react-redux";
import "react-day-picker/lib/style.css";
import "./index.css";
import StandartMedication from "./StandartMedication.js";
import Lab from "./Lab";
import PrimaryCriteriaField from "./PrimaryCriteriaField";
import Demographic from "./Demographic";
import SubstanceUse from "./SubstanceUse";
import Smoking from "./Smoking";
import Physician from "./Physician";
import Geography from "./Geography";
import BlockHeader from "./BlockHeader";
import ImmunityId from "./ImmunityId";
import Procedure from "./Procedure";
import MyEdit from "./MyEdit";
import {
  getMedicationsPicker,
  showPicker,
  getPhysiciansSearch,
  getDiseasesPicker,
  getProceduresPicker,
  setGeoData,
  setVeryFarLabelFromBlock,
} from "../../../../actions/trialActions";
import _ from "lodash";
import {
  classOptions,
  statusOptions,
  frequencyOptions,
  amountOptions,
  inExOptions,
} from "./Smoking/data.js";

const medicOptions = [
  {value: "Include", label: "Include"},
  {value: "Exclude", label: "Exclude"},
];

const rangeOptions = [
  {value: "GreaterThan", label: "Greater than"},
  {value: "SmallerThan", label: "Smaller than"},
  {value: "Between", label: "Between"},
];

const dateRangeOptions = [
  {value: "Include", label: "Include"},
  {value: "Exclude", label: "Exclude"},
];

const requiredOptions = [
  {value: "True", label: "True"},
  {value: "False", label: "False"},
];

const priorityOptions = [
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
];

const searchOptions = [
  {value: "medication", label: "Medication Filters"},
  {value: "disease", label: "Disease Filters"},
  {value: "lab", label: "Lab Filters"},
  {value: "procedure", label: "Procedure History"},
  {value: "immunity_id", label: "ID Number"},
  {value: "immunity_name", label: "Name"},
  {value: "geography", label: "Geography"},
  {value: "physician", label: "Physician"},
  {value: "demographic", label: "Demographic"},
];

class ElBlockSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchOptions: [...searchOptions],
      includeOption:
        this.props && this.props.include
          ? {value: this.props.include, label: this.props.include}
          : {value: "Include", label: "Include"},
      testOption:
        this.props && this.props.criteriaFromSearch
          ? {
            value: this.props.criteriaFromSearch,
            label: this.props.criteriaFromSearch,
          }
          : {value: "medication", label: "Medication Filters"},
      rangeOption:
        this.props && this.props.range !== undefined
          ? {value: this.props.range, label: this.props.range}
          : {value: "GreaterThan", label: "Greater than"},
      dateRangeOption:
        this.props && this.props.dateRangeIE
          ? {value: this.props.dateRangeIE, label: this.props.dateRangeIE}
          : {value: "Include", label: "Include"},
      priorityOption:
        this.props && this.props.priority
          ? {value: this.props.priority, label: this.props.priority}
          : {value: "1", label: "1"},
      requiredOption:
        this.props && this.props.requiredOption
          ? {value: this.props.requiredOption, label: this.props.requiredOption}
          : {value: "False", label: "False"},
      from: undefined,
      to: undefined,
      fromForPat: undefined,
      toForPat: undefined,
      labNamesToolTip: [],
      labName: "",
      UomNamesToolTip: [],
      diseasesTooltip: [],
      medicationTooltip: [],
      proceduresNames: [],
      proceduresTooltip: [],
      physicianNames: [],
      physicianTooltip: [],
      physicianOptions: [],
      minValue: 18,
      maxValue: 35,
      sexOption: {value: "M", label: "Male"},
      statusMenOption: {value: "both", label: "All"},
      includeSearch: "",
      id: "",
      nameSearch: "",
      minLabValue: "",
      maxLabValue: "",
      immunity: null,
      labUoMOption: "",
      // geo state //
      geographyData: null,
      stateList: [],
      stateOption: "",
      cityList: [],
      cityOption: "",
      hospitalList: [],
      hospitalOption: "",
      radius: 0,
      picker: this.props.picker,
      sendForMedication: this.props.sendForMedication
        ? [...this.props.sendForMedication]
        : [],
      isOpen: true,
      rootMedications: [],
      diseasesPickerRoot: [],
      activeTab: 0,
      pickerResult: [],
      substances: [],
      include: inExOptions[0],
      classification: classOptions[0],
      status: statusOptions[0],
      amount: amountOptions[0],
      frequency: frequencyOptions[0],
      pack_years: "",
      date_quit: "",
      specific_arg: {
        toggle: false,
        from: new Date(),
        to: new Date(),
      },
      snomed: "449868002",
    };

    this.to = React.createRef();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchMedicationFiltres();
    this.fetchRootMedications();
    this.fetchRootDiseases();
    this.fetchRootProcedures();
    this.geoInState();
    if (this.props.criteriaFromSearch) {
      switch (
        this.props.criteriaSearch
          ? this.props.criteriaSearch
          : this.props.criteriaFromSearch
        ) {
        case "Medication Filters":
          this.setState({
            testOption: {value: "medication", label: "Medication Filters"},
          });
          break;
        case "Disease Filters":
          this.setState({
            testOption: {value: "disease", label: "Disease Filters"},
          });
          break;
        case "Lab Filters":
          this.setState({
            testOption: {value: "lab", label: "Lab Filters"},
          });
          break;
        case "Lab":
          this.setState({
            testOption: {value: "lab", label: "Lab Filters"},
          });
          break;
        case "Procedure History":
          this.setState({
            testOption: {value: "procedure", label: "Procedure History"},
          });
          break;
        case "ID Number":
          this.setState({
            testOption: {value: "immunity_id", label: "ID Number"},
          });
          break;
        case "Name":
          this.setState({
            testOption: {value: "immunity_name", label: "Name"},
          });
          break;
        case "Geography":
          this.setState({
            testOption: {value: "geography", label: "Geography"},
          });
          break;
        case "Physician":
          this.setState({
            testOption: {value: "physician", label: "Physician"},
          });
          break;
        case "Demographic":
          this.setState({
            testOption: {value: "demographic", label: "Demographic"},
          });
          break;
        case "Substance":
          this.setState({
            testOption: {value: "substance", label: "Substance"},
          });
          break;
        case "Smoking":
          this.setState({
            testOption: {value: "smoking", label: "Smoking"},
          });
          break;
        case "Allergy Filters":
          this.setState({
            testOption: {value: "allergies", label: "Allergy Filters"},
          });
          break;
        default:
          this.setState({
            testOption: {value: "medication", label: "Medication Filters"},
          });
          break;
      }
    }

    (async () => {
      const {physiciansSearch, fromSearch, dispatch} = this.props;
      if (fromSearch) {
        if (physiciansSearch.length === 0) {
          await dispatch(getPhysiciansSearch());
        }
      }
      await this.setPhysiciansOptions();
    })();

    setTimeout(() => {
      this.setState({includeSearch: false});
    }, 300);
    //for picker tabs
    if (this.props.sendForMedication) {
      this.forPickerPrePop();
      if (!this.props.fromSearch) {
        this.fillInDataForLab();
      }
    }
  }

  fetchMedicationFiltres = () => {
    const {sendForMedication} = this.state;
    if (sendForMedication[0] && sendForMedication[0].required) {
      this.setState({required: sendForMedication[0].required});
    } else {
      let newState = [...sendForMedication];
      newState = newState.map(el => ({...el, required: false}));
      this.setState({sendForMedication: newState});
      this.setState({required: false});
    }
    if (sendForMedication[0] && sendForMedication[0].specific_arg) {
      this.setState({specific_arg: sendForMedication[0].specific_arg});
    } else {
      this.setState({
        specific_arg: {
          toggle: false,
          from: new Date(),
          to: new Date(),
        },
      });
    }
  };
  setPhysiciansOptions = () => {
    const {physiciansSearch, fromSearch} = this.props;
    const physicians = JSON.parse(localStorage.getItem("physiciansOptions"));
    if (
      physicians &&
      Array.isArray(physicians) &&
      physiciansSearch &&
      Array.isArray(physiciansSearch)
    ) {
      const physicianNames = fromSearch
        ? physiciansSearch.map(el => ({...el}))
        : physicians.map(el => ({...el}));
      if (this.mounted) {
        this.setState({
          physicianNames,
        });
      }
    } else {
      return null;
    }
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.sendForMedication, this.props.sendForMedication)) {
      this.fillInDataForLab();
      // this.updateMedications();
    }
  }

  fillInDataForLab() {
    const {sendForMedication} = this.props;
    sendForMedication.forEach(el => {
      if (el.criteria === "Lab") {
        this.setLabValuesState(el);
      } else if (el.criteria === "Substance") {
        this.setSubstancesValuesState(el);
      } else if (el.criteria === "Smoking") {
        this.setSmokingValuesState(el);
      }
    });
  }

  setLabValuesState = el => {
    const {
      name,
      uomValues,
      labValue,
      minValue,
      maxValue,
      uom,
      range,
      include,
      priority,
      required,
      dateRangeFrom,
      dateRangeTo,
    } = el;
    console.log("el => ", el)
    // debugger
    if (name) {
      this.setState({
        nameSearch: name,
        UomNamesToolTip: uomValues,
        labUoMOption: uomValues ? uomValues[0] : [],
      });
    }
    if (uom) {
      this.setState({labUoMOption: {value: uom, label: uom}});
    }
    if (labValue) {
      this.setState({labValue: labValue});
    }
    if (minValue !== null && minValue !== undefined) {
      this.setState({minLabValue: minValue});
    }
    if (maxValue !== null && maxValue !== undefined) {
      this.setState({maxLabValue: maxValue});
    }
    if (range) {
      this.setState({rangeOption: {value: range, label: range}});
    }
    if (include !== undefined) {
      let value = include ? "Include" : "Exclude";
      this.setState({dateRangeOption: {value, label: value}});
    }
    let requiredState
    if (required) {
      this.setState({requiredOption: {value: "True", label: "True"}});
    } else {
      this.setState({requiredOption: {value: "False", label: "False"}});
    }

    if (priority) {
      this.setState({
        priorityOption: {value: priority, label: priority},
      });
    }
    if (dateRangeFrom) {
      this.setState({
        from: dateRangeFrom,
      });
    }
    if (dateRangeTo) {
      this.setState({
        to: dateRangeTo,
      });
    }
  };

  setSubstancesValuesState = el => {
    const {include, name} = el;

    if (name && !Array.isArray(name)) {
      const newSubstance = {value: name, label: name};
      this.setState(p => ({
        substances: [...p.substances, newSubstance],
      }));
    }

    if (include !== undefined) {
      const value = include ? "Include" : "Exclude";
      this.setState({include: {value, label: value}});
    }
  };

  setSmokingValuesState = ({
                             amount,
                             classification,
                             date_quit,
                             frequency,
                             include,
                             pack_years,
                             snomed,
                             status,
                           }) => {
    if (amount !== undefined) {
      amount
        ? this.setState({amount: {value: amount, label: amount}})
        : this.setState({amount});
    }
    if (classification !== undefined) {
      classification
        ? this.setState({
          classification: {value: classification, label: classification},
        })
        : this.setState({classification});
    }
    if (date_quit) {
      this.setState({date_quit});
    }
    if (frequency !== undefined) {
      frequency
        ? this.setState({frequency: {value: frequency, label: frequency}})
        : this.setState({frequency});
    }
    if (include !== undefined) {
      let value = include ? "Include" : "Exclude";
      this.setState({dateRangeOption: {value, label: value}});
    }
    if (pack_years) {
      this.setState({pack_years});
    }
    if (snomed) {
      this.setState({snomed});
    }
    if (status) {
      this.setState({status: {value: status, label: status}});
    }
  };

  forPickerPrePop() {
    const {sendForMedication} = this.props;
    let atc = [];
    let epc = [];
    let moa = [];
    let pickerResult = [];
    let activeTab = 0;
    sendForMedication.forEach(el => {
      if (!el.required) {
        el.required = false;
      }
      if (el.activeTab === 0) {
        atc.push(el);
      } else if (el.activeTab === 1) {
        epc.push(el);
      } else if (el.activeTab === 2) {
        moa.push(el);
      }
      if (el.specific_arg) {
        this.setState({specific_arg: el.specific_arg});
      } else {
        this.setState({
          specific_arg: {
            toggle: false,
            from: new Date(),
            to: new Date(),
          },
        });
      }
    });

    if (atc.length > 0) {
      pickerResult = atc;
      activeTab = 0;
    } else if (epc.length > 0) {
      pickerResult = epc;
      activeTab = 1;
    } else if (moa.length > 0) {
      pickerResult = moa;
      activeTab = 2;
    }
    this.setState({atc, epc, moa, pickerResult, activeTab});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id === state.id && !props.sameSearch) {
      return null;
    }
    if (props.criteriaSearch) {
      let testOption;
      if (props.criteriaSearch === "Disease Filters") {
        testOption = {value: "disease", label: "Disease Filters"};
      } else if (props.criteriaSearch === "Medication Filters") {
        testOption = {value: "medication", label: "Medication Filters"};
      } else if (props.criteriaSearch === "Lab") {
        testOption = {value: "lab", label: "Lab Filters"};
      } else if (props.criteriaSearch === "Procedure History") {
        testOption = {value: "procedure", label: "Procedure History"};
      } else if (props.criteriaSearch === "ID Number") {
        testOption = {value: "immunity_id", label: "ID Number"};
      } else if (props.criteriaSearch === "Name") {
        testOption = {value: "immunity_name", label: "Name"};
      } else if (props.criteriaSearch === "Geography") {
        testOption = {value: "geography", label: "Geography"};
      } else if (props.criteriaSearch === "Physician") {
        testOption = {value: "physician", label: "Physician"};
      } else if (props.criteriaSearch === "Demographic") {
        testOption = {value: "demographic", label: "Demographic"};
      } else if (props.criteriaSearch === "Substance") {
        testOption = {value: "substance", label: "Substance"};
      } else if (props.criteriaSearch === "Smoking") {
        testOption = {value: "smoking", label: "Smoking"};
      }
      let nameSearch = props.nameSearch;
      let substances = props.substances
        ? props.substances.map(el => ({label: el, value: el}))
        : [];
      let include,
        classification,
        status,
        frequency,
        amount,
        pack_years = props.pack_years ? props.pack_years : "",
        date_quit = props.date_quit ? moment(props.date_quit).format("LL") : "";
      if (props.classification) {
        classification = {
          label: props.classification,
          value: props.classification,
        };
      }
      if (props.status) {
        status = {
          label: props.status,
          value: props.status,
        };
      }
      if (props.frequency) {
        frequency = {
          label: props.frequency,
          value: props.frequency,
        };
      }
      if (props.amount) {
        amount = {
          label: props.amount,
          value: props.amount,
        };
      }
      if (props.include === true) {
        include = {label: "Include", value: "Include"};
      } else if (props.include === false) {
        include = {label: "Exclude", value: "Exclude"};
      }
      let priorityOption;
      if (props.prioritySearch) {
        priorityOption = {
          value: props.prioritySearch,
          label: props.prioritySearch,
        };
      }
      let requiredOption;
      if (props.requiredSearch) {
        requiredOption = {
          value: "True",
          label: "True",
        };
      } else {
        requiredOption = {
          value: "False",
          label: "False",
        };
      }
      if (props.includeSearch !== undefined) {
        let includeOption;
        let dateRangeOption;
        let rangeOption;

        if (props.includeSearch === true) {
          includeOption = {value: "Include", label: "Include"};
          dateRangeOption = {value: "Include", label: "Include"};
        } else if (props.includeSearch === false) {
          includeOption = {value: "Exclude", label: "Exclude"};
          dateRangeOption = {value: "Exclude", label: "Exclude"};
        } else {
          dateRangeOption = {value: "Include", label: "Include"};
        }
        if (props.minValue !== undefined || props.maxValue !== undefined) {
          if (props.minValue !== 0 && props.minValue !== "" && props.maxValue !== 0 && props.maxValue !== "") {
            rangeOption = {value: "Between", label: "Between"};
          } else if (props.maxValue === 0 || props.maxValue === "") {
            rangeOption = {value: "GreaterThan", label: "Greater than"};
          } else if (props.minValue === 0 || props.minValue === "") {
            rangeOption = {value: "SmallerThan", label: "Smaller than"};
          }
        } else {
          rangeOption = {value: "GreaterThan", label: "Greater than"};
        }

        let labUoMOption = {
          value: props.labUoM,
          label: props.labUoM,
        };

        return {
          testOption,
          includeOption,
          dateRangeOption,
          priorityOption,
          requiredOption,
          rangeOption,
          labUoMOption,
          id: props.id,
          nameSearch,
          minLabValue: props.minValue,
          maxLabValue: props.maxValue,
          from: props.dateRangeFrom
            ? moment(props.dateRangeFrom).format("MMM DD, YYYY")
            : "",
          to: props.dateRangeTo
            ? moment(props.dateRangeTo).format("MMM DD, YYYY")
            : "",
          labNamesToolTip: {code: props.code, shortName: nameSearch},
          substances,
          include,
          classification,
          status,
          frequency,
          amount,
          pack_years,
          date_quit,
        };
      } else {
        let minValue = props.min;
        let maxValue = props.max;
        let sexOption;
        let statusMenOption;
        let priorityOption;
        let requiredOption;

        if (props.prioritySearch) {
          priorityOption = {
            value: props.prioritySearch,
            label: props.prioritySearch,
          };
        }

        if (props.requiredSearch) {
          requiredOption = {
            value: props.requiredSearch,
            label: props.requiredSearch,
          };
        }

        let physicianTooltip = {
          name: props.nameSearch,
          id: props.physicianId,
        };

        //geo
        let cityOption;
        let stateOption;
        let radius;
        let hospitalOption;
        let cityList = [];
        let hospitalList = [];

        if (Array.isArray(props.geoData)) {
          radius = props.radius;
          hospitalOption = {value: props.hospital, label: props.hospital};
          if (props.stateOption) {
            let stateIndex = props.geoData
              .map(e => e.state)
              .indexOf(props.stateOption);
            let indexCity = props.geoData[stateIndex].cities
              .map(el => el.city)
              .indexOf(props.city);
            cityOption = {value: indexCity, label: props.city};
            stateOption = {
              value: stateIndex,
              label: props.stateOption,
            };
            if (props.geoData[stateIndex]) {
              props.geoData[stateIndex].cities.map((el, i) => {
                let obj = {
                  label: el.city,
                  value: i,
                };
                cityList.push(obj);
                return null;
              });
              if (props.geoData[stateIndex].cities[indexCity]) {
                props.geoData[stateIndex].cities[indexCity].hospitals.map(
                  (el, i) => {
                    let obj = {
                      label: el,
                      value: i,
                    };
                    hospitalList.push(obj);
                    return null;
                  }
                );
              }
            }
          }
        }

        if (props.sex === "M") {
          sexOption = {value: "M", label: "Male"};
        } else if (props.sex === "F") {
          sexOption = {value: "F", label: "Female"};
        } else if (props.sex === "B") {
          sexOption = {value: "B", label: "Both"};
        }
        if (props.statusMenOption === "both") {
          statusMenOption = {value: "both", label: "All"};
        } else if (props.statusMenOption === "living") {
          statusMenOption = {value: "living", label: "Living"};
        } else if (props.statusMenOption === "death") {
          statusMenOption = {value: "death", label: "Death"};
        } else {
        }
        return {
          nameSearch,
          id: props.id,
          testOption,
          priorityOption,
          requiredOption,
          physicianTooltip,
          minValue,
          maxValue,
          sexOption,
          statusMenOption,
          cityOption,
          stateOption,
          radius,
          hospitalOption,
          cityList,
          hospitalList,
          substances,
          include,
        };
      }
    } else {
      return null;
    }
  }

  fetchRootMedications = () => {
    const {rootMedications, token, dispatch} = this.props;
    if (rootMedications.length > 0) {
      this.setState({rootMedications});
    } else {
      (async () => {
        await dispatch(getMedicationsPicker(token));
        await this.setState({rootMedications: this.props.rootMedications});
      })();
    }
  };

  fetchRootDiseases = () => {
    const {diseasesPickerRoot, token, dispatch} = this.props;
    if (diseasesPickerRoot.length > 0) {
      this.setState({diseasesPickerRoot});
    } else {
      (async () => {
        await dispatch(getDiseasesPicker(token));
        await this.setState({
          diseasesPickerRoot: this.props.diseasesPickerRoot,
        });
      })();
    }
  };

  fetchRootProcedures = () => {
    const {proceduresPickerRoot, token, dispatch} = this.props;
    if (proceduresPickerRoot.length > 0) {
      this.setState({proceduresPickerRoot});
    } else {
      (async () => {
        await dispatch(getProceduresPicker(token));
        await this.setState({
          proceduresPickerRoot: this.props.proceduresPickerRoot,
        });
      })();
    }
  };

  getGeographyData = () => {
    const {geoData, dispatch} = this.props;
    if (geoData.length === 0) {
      dispatch(setGeoData());
    }
  };

  geoInState = async () => {
    await this.getGeographyData();
    await this.setGeoState();
  };

  setGeoState = () => {
    let stateList = [];
    let geoData =
      this.props.geoData && Array.isArray(this.props.geoData)
        ? [...this.props.geoData]
        : null;

    geoData &&
    geoData.map((el, i) => {
      let obj = {
        label: el.state,
        value: i,
      };
      stateList.push(obj);
      return null;
    });
    this.setState({
      stateList,
      geographyData: geoData,
    });
  };

  handleChangeSex = sexOption => {
    this.setState({sexOption});
  };
  handleChangeStatus = statusMenOption => {
    this.setState({statusMenOption});
  };
  handleChangeMinValue = minValue => {
    this.setState({minValue});
  };

  handleChangeMaxValue = maxValue => {
    this.setState({maxValue});
  };

  handleChangeProcedures = proceduresOption => {
    this.setState({includeOption: proceduresOption});
  };

  handleChangeTest = testOption => {
    this.setState({testOption, nameSearch: ""});
  };

  handleChangeMedic = medicationOption => {
    this.setState({includeOption: medicationOption});
  };

  handleChangeDisease = diseaseOption => {
    this.setState({includeOption: diseaseOption});
  };

  handleChangeRange = rangeOption => {
    switch (rangeOption.value) {
      case "SmallerThan":
        this.setState(
          {
            minLabValue: "",
            rangeOption,
          },
          () => this.sendLabs("minValue", 0, rangeOption)
        );
        break;
      case "GreaterThan":
        this.setState(
          {
            maxLabValue: "",
            rangeOption,
          },
          () => this.sendLabs("maxValue", 0, rangeOption)
        );
        break;
      case "Between":
        this.setState(
          {
            minLabValue: "",
            maxLabValue: "",
            rangeOption,
          },
          () => {
            this.sendLabs("minValue", 0, rangeOption);
            this.sendLabs("maxValue", 0, rangeOption);
          }
        );
        break;
      default:
        return "mistake";
    }
    // this.setState({ rangeOption }, () => {
    //   !this.props.fromSearch && this.sendLabs("range", rangeOption.label);
    // });
  };

  handleChangeDateRange = dateRangeOption => {
    this.setState({dateRangeOption}, () => {
      !this.props.fromSearch &&
      this.sendLabs(
        "include",
        dateRangeOption.value === "Include"
      );
    });
  };

  handleChangePriority = priorityOption => {
    this.setState({priorityOption}, () =>
      this.props.fromSearch
        ? null
        : this.sendLabs("priority", priorityOption.value)
    );
  };

  handleChangeLabRequired = requiredOption => {
    this.setState({requiredOption}, () =>
      this.props.fromSearch
        ? null
        : this.sendLabs("required", requiredOption.value === "True")
    );
  };

  showFromMonth() {
    if (!this.state.from || !this.to || !this.to.current) {
      return;
    }
    if (moment(this.state.to).diff(moment(this.state.from), "months") < 2) {
      this.to.current.getDayPicker().showMonth(this.state.from);
    }
  }

  handleFromChange = from => {
    // Change the from date and focus the "to" input field
    this.setState({from}, () => {
      !this.props.fromSearch && this.sendLabs("dateRangeFrom", from);
    });
  };

  handleToChange = to => {
    this.setState({to}, () => {
      !this.props.fromSearch && this.sendLabs("dateRangeTo", to);
      this.showFromMonth();
    });
  };

  // ------ start datepicker for patients page ----- //

  showFromMonthForPat() {
    if (!this.state.fromForPat || !this.to || !this.to.current) {
      return;
    }
    if (
      moment(this.state.toForPat).diff(
        moment(this.state.fromForPat),
        "months"
      ) < 2
    ) {
      this.to.current.getDayPicker().showMonth(this.state.fromForPat);
    }
  }

  handleFromChangeForPat = fromForPat => {
    // Change the from date and focus the "to" input field
    this.setState({fromForPat});
  };

  handleToChangeForPat = toForPat => {
    this.setState({toForPat}, this.showFromMonthForPat);
  };

  // ------ end datepicker for patients page ----- //

  GeoIdPhysHandleChange = ({target}) => {
    let res = {}
    if (target.length === 0) {
      target.classList.add("redBorder");
    } else {
      target.classList.remove("redBorder");
    }

    switch (target.name) {
      case "Immunity ID":
        res = {
          ...this.state.immunity,
          immunity: target.value
        }
        break;
      case "Immunity Name":
        res = {
          ...this.state.name,
          name: target.value
        }
        break;
      default:

    }

    this.setState(res);
  };

  //------ Physicians Tooltip -------- //
  findPhysicianName = ({target}) => {
    let physicianNames = this.state.physicianNames;
    if (
      target.value.length >= 0 &&
      physicianNames &&
      physicianNames.length > 0
    ) {
      let query = target.value.toLowerCase();
      let result = physicianNames
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) {
        };
        this.setState({physicianOptions: result});
      } else {
        result.length = 0;
        this.setState({physicianOptions: result});
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({physicianOptions: []});
    }
  };

  physicianPicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({physicianTooltip: obj});
  };

  //------ UoM Tooltip -------- //
  findUom = labUoMOption => {
    this.setState({labUoMOption}, () => {
      !this.props.fromSearch && this.sendLabs("uom", labUoMOption.value);
    });
  };

  //------ Disease Tooltip -------- //
  findDisease = ({target}) => {
    let diseases = this.props.diseases;
    if (target.value.length >= 0 && diseases && diseases.length > 0) {
      let query = target.value.toLowerCase();
      let result = diseases
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) {
        };
        this.setState({diseasesTooltip: result});
      } else {
        result.length = 0;
        this.setState({diseasesTooltip: result});
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({diseasesTooltip: []});
    }
  };

  diseasePicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({diseasesTooltip: obj});
  };

  //------ Medication Tooltip -------- //
  findMedication = e => {
    e.preventDefault();
    let target = e.target;
    let medications = this.props.medications;
    if (target.value.length >= 0 && medications && medications.length > 0) {
      let query = target.value.toLowerCase();
      let result = medications
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) {
        };
        this.setState({medicationTooltip: result});
      } else {
        this.setState({medicationTooltip: []});
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({medicationTooltip: []});
    }
  };

  medPicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({medicationTooltip: obj});
  };

  handleChangeDateRangeMedication = (date, field, id) => {
    let newState = {...this.state.specific_arg};
    const {fullData, data, setInfo, freeTextVersion} = this.props;

    let newData = freeTextVersion ? [...data] : fullData && [...fullData];

    if (newData) {
      newData.forEach(el => {
        el.criteria.forEach(element => {
          if (
            (element.criteria === "Medication" ||
              element.criteria === "Procedure" ||
              element.criteria === "Disease") &&
            this.state.sendForMedication[0].id === element.id
          ) {
            element.specific_arg[field] = date;
            newState[field] = date;
            this.setState({specific_arg: newState});
          }
        });
      });
      freeTextVersion
        ? setInfo("eligibilityNewFreeText", newData)
        : setInfo("eligibility_criteria", newData);
    } else {
      newState[field] = date;
      this.setState({specific_arg: newState});
    }
  };

  toggleSpecificDate = id => {
    const {fullData, data, setInfo, freeTextVersion} = this.props;
    let newData = freeTextVersion ? [...data] : fullData && [...fullData];
    if (newData) {
      newData.forEach(el => {
        el.criteria.forEach(element => {
          if (
            (element.criteria === "Medication" ||
              element.criteria === "Procedure" ||
              element.criteria === "Disease") &&
            this.state.sendForMedication[0].id === element.id
          ) {
            let newState = {...this.state.specific_arg};
            newState.toggle = !this.state.specific_arg.toggle;
            element.specific_arg = newState;
            this.setState({specific_arg: newState});
          }
        });
      });
      freeTextVersion
        ? setInfo("eligibilityNewFreeText", newData)
        : setInfo("eligibility_criteria", newData);
    } else {
      let newState = {...this.state.specific_arg};
      newState.toggle = !this.state.specific_arg.toggle;
      this.setState({specific_arg: newState});
    }
  };
  onChangeRequired = id => {
    const {fullData, data, setInfo, freeTextVersion} = this.props;
    const {sendForMedication} = this.state;
    let newState = [...sendForMedication];
    let index = newState.findIndex(el => el.id === id);
    newState[index].required = !newState[index].required;
    this.setState({sendForMedication: newState, pickerResult: newState});
    let newData = freeTextVersion ? [...data] : fullData && [...fullData];

    if (newData) {
      newData.forEach(el => {
        el.criteria.forEach(element => {
          if (
            (element.criteria === "Medication" ||
              element.criteria === "Procedure" ||
              element.criteria === "Disease") &&
            this.state.sendForMedication[index].id === element.id
          ) {
            element.required = !element.required;
          }
        });
      });
      freeTextVersion
        ? setInfo("eligibilityNewFreeText", newData)
        : setInfo("eligibility_criteria", newData);
    }
  };
  //------ Procedure Tooltip -------- //

  findProcedure = ({target}) => {
    let procedures = this.props.procedures;
    if (target.value.length >= 0 && procedures && procedures.length > 0) {
      let query = target.value.toLowerCase();
      let result = procedures
        .filter(el => {
          return el.long_title.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) {
        };
        this.setState({proceduresTooltip: result});
      } else {
        this.setState({proceduresTooltip: []});
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({proceduresTooltip: []});
    }
  };

  prodPicked = (obj, whereToFill) => {
    whereToFill.value = obj.long_title;
    this.setState({proceduresTooltip: obj});
  };

  remove = e => {
    const {
      id,
      elIndex,
      delete: removeEl,
      criteriaFromSearch,
      sendForMedication,
    } = this.props;
    if (e.target.classList.contains("red-cross")) {
      if (elIndex !== undefined) {
        removeEl(elIndex, criteriaFromSearch, sendForMedication);
      } else {
        removeEl(id, criteriaFromSearch, sendForMedication);
      }
    }
  };

  handleLabValueChange = (e, prop) => {
    let {target} = e;
    if (target.value.length > 0) {
      target.classList.remove("redBorder");
    } else {
      target.classList.add("redBorder");
    }
    if (prop === "minValue") {
      this.setState({
        minLabValue: target.value,
      });
    } else if (prop === "maxValue") {
      this.setState({
        maxLabValue: target.value,
      });
    } else if (prop === "labValue") {
      this.setState({
        labValue: target.value,
      });
    }
  };

  handleSetInfo = name => {
    if (!this.props.fromSearch) {
      switch (name) {
        case "labValue":
          this.sendLabs("labValue", this.state.labValue);
          break;
        case "minValue":
          this.sendLabs(
            "minValue",
            +this.state.minLabValue,
            this.state.rangeOption
          );
          this.state.rangeOption.value !== "Between" &&
          this.sendLabs("maxValue", 0, this.state.rangeOption);
          break;
        case "maxValue":
          this.sendLabs(
            "maxValue",
            +this.state.maxLabValue,
            this.state.rangeOption
          );
          this.state.rangeOption.value !== "Between" &&
          this.sendLabs("minValue", 0, this.state.rangeOption);
          break;
        case "pack_years":
          this.sendLabs("pack_years", this.state.pack_years);
          break;
        default:
          return "no option";
      }
    } else {
      return null;
    }
  };

  // ---- Geo select changes ---- //

  handleChangeState = stateOption => {
    let cityList = [];
    this.state.geographyData[stateOption.value].cities.map((el, i) => {
      let obj = {
        label: el.city,
        value: i,
      };
      cityList.push(obj);
      return null;
    });
    cityList.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    this.setState({
      stateOption,
      cityList,
      cityOption: "",
      hospitalOption: "",
    });
  };

  handleChangeCity = cityOption => {
    let hospitalList = [];
    this.state.geographyData[this.state.stateOption.value].cities[
      cityOption.value
      ].hospitals.map((el, i) => {
      let obj = {
        label: el,
        value: i,
      };
      hospitalList.push(obj);
      return null;
    });
    this.setState({
      cityOption,
      hospitalList,
      hospitalOption: "",
    });
  };

  handleChangeHospital = hospitalOption => {
    this.setState({
      hospitalOption,
    });
  };

  handleChangeRadius = ({target}) => {
    this.setState({
      radius: target.value,
    });
    if (target.length === 0) {
      target.classList.add("redBorder");
    } else {
      target.classList.remove("redBorder");
    }
  };

  toggleFilterView = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleTabs = i => {
    let pickerResult = [];
    const {atc, epc, moa} = this.state;
    if (i === 0) {
      pickerResult = [...atc];
    } else if (i === 1) {
      pickerResult = [...epc];
    } else if (i === 2) {
      pickerResult = [...moa];
    }
    this.setState({activeTab: i, pickerResult});
  };

  openLabPicker = () => {
    const {sendForMedication, freeTextVersion, fromSearch, id} = this.props;
    if (!fromSearch) {
      const id =
        sendForMedication && sendForMedication.length > 0
          ? sendForMedication[0].fakeId
          : null;
      localStorage.setItem("labId", id);
      localStorage.setItem("freeTextVersion", freeTextVersion);
    } else {
      localStorage.setItem("labIdS", id);
    }
    this.props.dispatch(
      showPicker({status: false, filter: "", showLab: true})
    );
  };

  setLabValue = obj => {
    const {
      setInfo,
      data,
      newFdata,
      fullData,
      freeTextBlock,
      fromSearch,
    } = this.props;
    let UomNamesToolTip = [];
    obj.uom && Array.isArray(obj.uom)
      ? obj.uom.forEach(el => {
        UomNamesToolTip.push({label: el, value: el});
      })
      : UomNamesToolTip.push({label: obj.uom, value: obj.uom});
    if (fromSearch) {
      if (JSON.parse(localStorage.getItem("labIdS")) === this.props.id) {
        this.setState({
          nameSearch: obj.shortName ? obj.shortName : obj.longName,
          UomNamesToolTip,
          labUoMOption: UomNamesToolTip[0],
          labNamesToolTip: obj,
        });
        localStorage.removeItem("labIdS");
      }
    } else {
      const labId = localStorage.getItem("labId");
      const freeTextVersion = JSON.parse(
        localStorage.getItem("freeTextVersion")
      );
      if (freeTextVersion === true) {
        let newData = [...newFdata];
        newData.forEach(el => {
          el.criteria.forEach(element => {
            if (element.fakeId === labId) {
              element.name = obj.shortName ? obj.shortName : obj.longName;
              element.code = obj.code;
              element.uomValues = UomNamesToolTip;
              element.uom =
                UomNamesToolTip.length > 0 ? UomNamesToolTip[0].value : "";
            }
          });
        });
        setInfo("eligibilityNewFreeText", newData);
      } else {
        let newData = freeTextBlock ? [...fullData] : [...data];
        newData.forEach(el => {
          el.criteria.forEach(element => {
            if (element.fakeId === labId) {
              element.name = obj.shortName ? obj.shortName : obj.longName;
              element.code = obj.code;
              element.uomValues = UomNamesToolTip;
              element.uom =
                UomNamesToolTip.length > 0 ? UomNamesToolTip[0].value : "";
            }
          });
        });
        setInfo("eligibility_criteria", newData);
      }
      localStorage.removeItem("labId");
      localStorage.removeItem("freeTextVersion");
    }
  };

  formatCriteria = (criteria, value, include) => {
    const includeValue = include.value === "Include" ? true : false;
    if (!criteria) return;
    const newCriteria = value.map(nm => ({
      ...criteria,
      name: nm,
      include: includeValue,
      allNames: value,
    }));
    return newCriteria;
  };

  addIncludeToSubstances = () => {
    const {
      fullData,
      freeTextVersion,
      data,
    } = this.props;

    const {include} = this.state;

    if (!data && !fullData) return undefined;
    let newData = freeTextVersion ? [...data] : [...fullData];
    if (newData && Array.isArray(newData)) {
      if (newData) {
        newData.forEach(dataElem => {
          if (dataElem.criteria && Array.isArray(dataElem.criteria)) {
            dataElem.criteria = dataElem.criteria.map(critElem => ({
              ...critElem,
              include: include.value === "Include" ? false : true,
            }));
          }
        });
      }
    }
  };

  formatSubstancesForDisplay = substances => {
    return substances;
  };

  sendSubstances = (name, value) => {
    const {
      fullData,
      freeTextVersion,
      sendForMedication,
      data,
    } = this.props;

    const {include} = this.state;

    if (!data && !fullData) return undefined;
    let newData = freeTextVersion ? [...data] : [...fullData];
    if (newData && Array.isArray(newData)) {
      if (newData) {
        newData.forEach(dataElem => {
          dataElem.criteria = this.formatCriteria(
            dataElem.criteria[0],
            value,
            include
          );
        });
      }
    }
  };

  sendLabs = (name, value, rangeType) => {
    const {
      setInfo,
      fullData,
      freeTextVersion,
      sendForMedication,
      data,
    } = this.props;

    if (!data && !fullData) return undefined;

    const id = sendForMedication[0] && sendForMedication[0].fakeId;
    let newData = freeTextVersion ? [...data] : [...fullData];
    if (newData) {
      newData.forEach(el => {
        el.criteria.forEach(element => {
          if (element.fakeId === id) {
            if (Array.isArray(name)) {
              for (let n of name) {
                element[n.name] = n.value;
              }
            } else {
              if (name === "name") {
                if (Array.isArray(value)) {
                  value.forEach(e => {
                    el.criteria.push({
                      ...element,
                      name: e,
                    });
                  });
                }
              } else if (name) {
                element[name] = value;
              }
              if (rangeType) {
                element.range = rangeType.label;
              }
            }
          }
        });
        if (name === "name") {
          el.criteria = el.criteria.filter(f => !Array.isArray(f.name));
        }
      });
      freeTextVersion
        ? setInfo("eligibilityNewFreeText", newData)
        : setInfo("eligibility_criteria", newData);
    }
  };

  setInclude = (value, options) => {
    this.setState(
      {
        include: value,
      },
      this.addIncludeToSubstances()
    );
  };

  setStateOptionFromSelects = (value, options) => {
    this.setState({[options.name]: value}, () => {
      !this.props.fromSearch &&
      options.name !== "substances" &&
      this.sendLabs(options.name, value.value === "Include" ? true : false);
    });
  };

  setStateOptionFromSelectsSmoking = (value, options) => {
    let include;
    if (options.name === "include") {
      include = value.value === "Include" ? true : false;
    }
    this.setState({[options.name]: value}, () => {
      this.smokingLogic(options.name);
      !this.props.fromSearch &&
      this.sendLabs(
        options.name,
        include !== undefined ? include : value.value
      );
    });
  };

  smokingLogic = field => {
    switch (field) {
      case "classification":
        this.classOptionsChosen();
        break;
      case "status":
        this.statusOptionsChosen();
        break;
      case "frequency":
        this.frequencyOptionsChosen();
        break;
      default:
        return;
    }
  };

  classOptionsChosen = () => {
    const {classification} = this.state;
    if (
      classification.value === "NonSmoker" ||
      classification.value === "Unknown"
    ) {
      const status =
        classification.value === "NonSmoker"
          ? {label: "Never smoked", value: "Never smoked"}
          : {
            label: "Smoking Status Unknown",
            value: "Smoking Status Unknown",
          };
      const snomed =
        classification.value === "NonSmoker" ? "266919005" : "266927001";
      this.setState({
        status,
        frequency: "",
        pack_years: "",
        amount: "",
        date_quit: "",
        snomed,
      });
      if (!this.props.fromSearch) {
        this.sendLabs(
          [
            {name: "status", value: status.value},
            {name: "frequency", value: ""},
            {name: "pack_years", value: ""},
            {name: "amount", value: ""},
            {name: "date_quit", value: ""},
            {name: "snomed", value: snomed},
          ],
          false
        );
      }
    } else {
      this.setState({
        frequency: frequencyOptions[0].value,
        status: statusOptions[0].value,
        amount: amountOptions[0].value,
        snomed: "449868002",
      });
      if (!this.props.fromSearch) {
        this.sendLabs(
          [
            {name: "frequency", value: frequencyOptions[0].value},
            {name: "status", value: statusOptions[0].value},
            {name: "amount", value: amountOptions[0].value},
            {name: "snomed", value: "449868002"},
          ],
          false
        );
      }
    }
  };

  statusOptionsChosen = () => {
    const {status} = this.state;
    if (
      status.value === "Never smoked" ||
      status.value === "Smoking Status Unknown" ||
      status.value === "Unknown"
    ) {
      const snomed =
        status.value === "Never smoked"
          ? "266919005"
          : status.value === "Smoking Status Unknown"
          ? "266927001"
          : "77176002";
      this.setState({
        frequency: "",
        amount: "",
        pack_years: "",
        snomed,
      });
      if (!this.props.fromSearch) {
        this.sendLabs(
          [
            {name: "frequency", value: ""},
            {name: "amount", value: ""},
            {name: "pack_years", value: ""},
            {name: "snomed", value: snomed},
          ],
          false
        );
      }
    } else if (status.value === "Past") {
      this.setState({
        amount: amountOptions[0],
        frequency: "",
        pack_years: "",
        snomed: "8517006",
      });
      if (!this.props.fromSearch) {
        this.sendLabs(
          [
            {name: "amount", value: amountOptions[0].value},
            {name: "frequency", value: ""},
            {name: "pack_years", value: ""},
            {name: "snomed", value: "8517006"},
          ],
          false
        );
      }
    } else {
      this.setState({
        frequency: frequencyOptions[0],
        amount: amountOptions[0],
        pack_years: "",
        snomed: "449868002",
      });
      if (!this.props.fromSearch) {
        this.sendLabs(
          [
            {name: "frequency", value: frequencyOptions[0].value},
            {name: "amount", value: amountOptions[0].value},
            {name: "pack_years", value: ""},
            {name: "snomed", value: "449868002"},
          ],
          false
        );
      }
    }
  };

  frequencyOptionsChosen = () => {
    const {frequency} = this.state;
    let snomed = "449868002";
    switch (frequency.value) {
      case "every day":
        snomed = "449868002";
        this.setState({snomed});
        break;
      case "some day":
        snomed = "428041000124106";
        this.setState({snomed});
        break;
      case "heavy":
        snomed = "428071000124103";
        this.setState({snomed});
        break;
      case "light":
        snomed = "428061000124105";
        this.setState({snomed});
        break;
      default:
        console.log("no such value");
    }
    !this.props.fromSearch && this.sendLabs("snomed", snomed);
  };

  setStateForTrialSmoke = () => {
  };

  setStateOptionFromInput = (name, value) => {
    this.setState({[name]: value}, () => {
      !this.props.fromSearch &&
      name !== "pack_years" &&
      this.sendLabs(name, value);
    });
  };
  diseasesPickerRoot;

  render() {
    const {
      nameSearch,
      rootMedications,
      diseasesPickerRoot,
      proceduresPickerRoot,
      activeTab,
      pickerResult,
      epc,
      atc,
      moa,
      testOption,
      isOpen,
      searchOptions,
      includeOption,
      priorityOption,
      medicationTooltip,
      rangeOption,
      minLabValue,
      requiredOption,
      maxLabValue,
      labUoMOption,
      UomNamesToolTip,
      dateRangeOption,
      stateOption,
      stateList,
      cityOption,
      cityList,
      hospitalOption,
      hospitalList,
      radius,
      physicianTooltip,
      physicianOptions,
      minValue,
      maxValue,
      sexOption,
      statusMenOption,
      substances,
      include,
      classification,
      status,
      frequency,
      amount,
      date_quit,

      pack_years,
    } = this.state;
    let {from, to} = this.state;
    from = from ? new Date(from) : undefined;
    to = to ? new Date(to) : undefined;
    const {
      searchPage,
      criteria,
      edit,
      fromSearch,
      criteriaFromSearch,
      dispatch,
    } = this.props;
    return (
      <div className="EC-main__list" onClick={this.remove}>
        <BlockHeader
          searchPage={searchPage}
          isOpen={isOpen}
          toggleFilterView={this.toggleFilterView}
          testOption={testOption}
        />
        {isOpen && (
          <div className="EC-main__listfields freetext-collect forNewSearchItems">
            <StandartMedication
              priorityData={{
                option: priorityOption,
                options: priorityOptions,
                handler: this.handleChangePriority,
              }}
              medicData={{
                option: includeOption,
                options: medicOptions,
                handler: this.handleChangeMedic,
              }}
              testData={{
                option: testOption,
                options: searchOptions,
                handler: this.handleChangeTest,
              }}
              criteria={criteria}
              searchPage={searchPage}
              nameData={{
                search: nameSearch,
                medicationTooltip,
                medPicked: this.medPicked,
                handler: this.findMedication,
              }}
            />
            <PrimaryCriteriaField
              title=" Allergy Filters selected"
              type="allergies"
              toggleSpecificDate={this.toggleSpecificDate}
              handleChangeDateRangeMedication={
                this.handleChangeDateRangeMedication
              }
              specific_arg={this.state.specific_arg}
              // activeTab={activeTab}
              // toggleTabs={this.toggleTabs}
              pickerResult={pickerResult}
              // pickerArray={rootMedications}
              show={searchPage && testOption.value === "allergies"}
              onChangeRequired={this.onChangeRequired}
              setVeryFarLabelFromBlock={e =>
                dispatch(setVeryFarLabelFromBlock(e))
              }
              tabs={{
                atc,
                epc,
                moa,
              }}
            />
            <PrimaryCriteriaField
              title=" Medication selected"
              type="medication"
              toggleSpecificDate={this.toggleSpecificDate}
              handleChangeDateRangeMedication={
                this.handleChangeDateRangeMedication
              }
              specific_arg={this.state.specific_arg}
              activeTab={activeTab}
              toggleTabs={this.toggleTabs}
              pickerResult={pickerResult}
              pickerArray={rootMedications}
              show={searchPage && testOption.value === "medication"}
              onChangeRequired={this.onChangeRequired}
              setVeryFarLabelFromBlock={e =>
                dispatch(setVeryFarLabelFromBlock(e))
              }
              tabs={{
                atc,
                epc,
                moa,
              }}
            />

            <PrimaryCriteriaField
              title=" Disease selected"
              type="disease"
              toggleSpecificDate={this.toggleSpecificDate}
              handleChangeDateRangeMedication={
                this.handleChangeDateRangeMedication
              }
              specific_arg={this.state.specific_arg}
              activeTab={activeTab}
              onChangeRequired={this.onChangeRequired}
              toggleTabs={this.toggleTabs}
              pickerResult={pickerResult}
              pickerArray={diseasesPickerRoot}
              setVeryFarLabelFromBlock={e =>
                dispatch(setVeryFarLabelFromBlock(e))
              }
              show={searchPage && testOption.value === "disease"}
              tabs={{
                atc,
                epc,
                moa,
              }}
            />

            <PrimaryCriteriaField
              title="Procedure selected"
              type="procedure"
              toggleSpecificDate={this.toggleSpecificDate}
              handleChangeDateRangeMedication={
                this.handleChangeDateRangeMedication
              }
              specific_arg={this.state.specific_arg}
              activeTab={activeTab}
              onChangeRequired={this.onChangeRequired}
              toggleTabs={this.toggleTabs}
              pickerResult={pickerResult}
              pickerArray={proceduresPickerRoot}
              setVeryFarLabelFromBlock={e =>
                dispatch(setVeryFarLabelFromBlock(e))
              }
              show={searchPage && testOption.value === "procedure"}
              tabs={{
                atc,
                epc,
                moa,
              }}
            />

            <Lab
              ref={this.to}
              priorityData={{
                option: priorityOption,
                options: priorityOptions,
                handler: this.handleChangePriority,
              }}
              uomData={{
                option: labUoMOption,
                options: UomNamesToolTip,
                handler: this.findUom,
              }}
              dateRangeData={{
                option: dateRangeOption,
                options: dateRangeOptions,
                handler: this.handleChangeDateRange,
              }}
              rangeData={{
                option: rangeOption,
                options: rangeOptions,
                handler: this.handleChangeRange,
              }}
              rangeValues={[minLabValue, maxLabValue]}
              calendarData={{
                handleFromChange: this.handleFromChange,
                handleToChange: this.handleToChange,
                to: to,
                from: from,
              }}
              requiredData={{
                option: requiredOption,
                options: requiredOptions,
                handler: this.handleChangeLabRequired,
              }}
              nameSearch={nameSearch}
              criteria={criteriaFromSearch}
              testOption={testOption}
              setLabValue={this.setLabValue}
              handleLabValueChange={this.handleLabValueChange}
              handleSetInfo={this.handleSetInfo}
              openLabPicker={this.openLabPicker}
              show={testOption.value === "lab"}
            />
            {(testOption.value === "immunity_id" || testOption.value === "immunity_name") && (
              <ImmunityId
                searchPage={searchPage}
                nameSearch={nameSearch}
                GeoIdPhysHandleChange={this.GeoIdPhysHandleChange}
                elem={testOption.label}
              />
            )}
            {testOption.value === "geography" && (
              <Geography
                stateOption={stateOption}
                handleChangeState={this.handleChangeState}
                stateList={stateList}
                cityOption={cityOption}
                handleChangeCity={this.handleChangeCity}
                cityList={cityList}
                hospitalOption={hospitalOption}
                handleChangeHospital={this.handleChangeHospital}
                hospitalList={hospitalList}
                radius={radius}
                handleChangeRadius={this.handleChangeRadius}
                priorityOption={priorityOption}
                handleChangePriority={this.handleChangePriority}
                priorityOptions={priorityOptions}
              />
            )}
            {testOption.value === "substance" && (
              <SubstanceUse
                setValue={this.setStateOptionFromSelects}
                setInclude={this.setInclude}
                fromSearch={fromSearch}
                include={include}
                option={substances}
                sendLabs={this.sendSubstances}
              />
            )}
            {testOption.value === "smoking" && (
              <Smoking
                setValue={this.setStateOptionFromSelectsSmoking}
                setInputValue={this.setStateOptionFromInput}
                classification={classification}
                status={status}
                frequency={frequency}
                amount={amount}
                date_quit={date_quit}
                pack_years={pack_years}
                include={include}
                classOptions={classOptions}
                statusOptions={statusOptions}
                frequencyOptions={frequencyOptions}
                amountOptions={amountOptions}
                inExOptions={inExOptions}
                handleSetInfo={this.handleSetInfo}
              />
            )}
            {testOption.value === "physician" && (
              <Physician
                fromSearch={fromSearch}
                searchPage={searchPage}
                physicianTooltip={physicianTooltip}
                physicianOptions={physicianOptions}
                findPhysicianName={this.findPhysicianName}
                physicianPicked={this.physicianPicked}
              />
            )}
            {testOption.value === "demographic" && (
              <Demographic
                minValue={minValue}
                maxValue={maxValue}
                sexOption={sexOption}
                statusMenOption={statusMenOption}
                handleChangeStatus={this.handleChangeStatus}
                changeSliderValue={this.changeSliderValue}
                handleChangeSex={this.handleChangeSex}
                handleChangeMinValue={this.handleChangeMinValue}
                handleChangeMaxValue={this.handleChangeMaxValue}
              />
            )}
          </div>
        )}
        <MyEdit edit={edit} onClick={this.editBlock}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    medications: state.medications,
    rootMedications: state.medicationsPicker,
    diseasesPickerRoot: state.diseasesPickerRoot,
    proceduresPickerRoot: state.proceduresPickerRoot,
    labNames: state.labNames,
    diseases: state.diseases,
    procedures: state.procedures,
    labWithUom: state.labWithUom,
    labList: state.labList,
    geoData: state.geoData,
    token: state.userToken.token,
    physicians: state.physicians,
    physiciansSearch: state.physiciansSearch,
  };
}

export default connect(mapStateToProps, null, null, {withRef: true})(
  ElBlockSearch
);
