import React from "react";
import {Circle} from "rc-progress";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ReactTooltip from "react-tooltip";
import "./index.css";
import moment from "moment";

import inmunityApi from "../../api/InmunityApi";
import PatientOverview from "./../PatientOverview";
import {CustomTooltip, InfoIcon} from "../../ui";
import checkInc from "../../images/exclusion_inclusion/plus_full.svg";
import checkEx from "../../images/exclusion_inclusion/minus_full.svg";

class AllMatches extends React.Component {
  state = {
    showOverview: false,
    details: {},
    matchedTags: [],
    enrolledTags: [],
    candidatesTags: [],
  };

  closePatientOverview = () => {
    this.setState({showOverview: false});
  };

  showPatientOverview = () => {
    this.setState({showOverview: true});
    inmunityApi
      .getPatientWorkflow(this.props.mat.hadmId, this.props.mat.studyId)
      .then(details => {
        this.setState({
          details
        });
      });
  };

  goToDetails = ({target}) => {
    if (
      !target.classList.contains("button-center") &&
      !target.closest(".pat-over-wrapp") &&
      !target.classList.contains("button__icon") &&
      !target.classList.contains("popup-overlay") &&
      !target.classList.contains("multiIndicator") &&
      !target.classList.contains("td-content")
    ) {
      this.redirect();
    }
  };

  redirect = () => {
    let {history} = this.props;
    history.push({
      pathname: `/trial/${this.props.mat.studyId}/matches/details/${
        this.props.mat.hadmId
      }`,
      id: this.props.mat.hadmId,
      detailTitle: "Match Details"
    });
  };

  makeTrials = obj => {
    let result = "";
    if (obj) {
      obj.sort((a, b) => b.matchrate - a.matchrate);
      obj.forEach(trial => {
        let precntage = trial.matchrate;
        result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`;
      });
    }
    return result;
  };

  makeMatchTags = (obj = []) => {
    let result =
      obj.length > 0
        ? `Matched for ${obj.length} other ${
          obj.length > 1 ? "trials" : "trial"
        }:<br>`
        : "";
    obj.sort((a, b) => b.matchrate - a.matchrate);
    obj.forEach(trial => {
      let precntage = trial.matchrate;
      result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeEnrollTags = (obj = []) => {
    let result =
      obj.length > 0
        ? `Enrolled in ${obj.length} other ${
          obj.length > 1 ? "trials" : "trial"
        }:<br>`
        : "";
    obj.sort((a, b) => b.name - a.name);
    obj.forEach(trial => {
      result += ` ${trial.name} - ${trial.status}<br>`;
    });
    return result;
  };

  showMCInfo = e => {
    if (!this.props.mat.matchCriteria || this.props.mat.matchCriteria.length <= 0)
      return;
    e.target.previousSibling.previousSibling.style.display = "block";
  };

  closeMCInfo = e => {
    e.target.previousSibling.previousSibling.style.display = "none";
  };



  makeMatchedTags = () => {
    if (this.state.matchedTags.length === 0) {
      inmunityApi
        .getPatientMatchedStatus(this.props.mat.immunity, this.props.mat.studyId)
        .then(details => {
          details.sort((a, b) => b.rate - a.rate);
          this.setState({ matchedTags: details, loadedMatched: true })
        });
    }
  }

  makeEnrolledTags = () => {
    if (this.state.enrolledTags.length === 0) {
      inmunityApi
        .getPatientEnrolledStatus(this.props.mat.immunity, null)
        .then(details => {
          details.sort((a, b) => b.name - a.name);
          this.setState({ enrolledTags: details, loadedEnrolled: true })
        });
    }
  }

  makeCandidateTags = () => {
    if (this.state.candidatesTags.length === 0) {
      inmunityApi
        .getPatientCandidateStatus(this.props.mat.immunity, null)
        .then(details => {
          details.sort((a, b) => b.name - a.name);
          this.setState({ candidatesTags: details, loadedCandidates: true })
        });
    }
  }

  showSpinner = () => {
    return (
      <img
        style={{ width: 30, height: 30, margin: "auto", display: "block" }}
        className="small-spinner"
        src={require("../../images/spinner.gif")}
        alt=""
      />
    )
  }

  render() {
    const {
      gender,
      age,
      consented,
      lastEncounter,
      nextEncounter,
      matchrate,
      name,
      immunity,
      studyTags,
      match_count,
      candidate_count,
      enrolled_count,
      matchTags: mTags,
      enrollTags: eTags
    } = this.props.mat;

    var trials = this.makeTrials(studyTags);
    var matchTags = this.makeMatchTags(mTags);
    const enrollTags = this.makeEnrollTags(eTags);

    return (
      <div
        className="candidatesWr matchesWr"
        key={this.props.key}
        onClick={this.goToDetails}
      >
        <div className="candidates hadmId forMatchesTooltip">
          <span className="imm-id">Immunity ID</span>
          <span className="td-content" onClick={this.goToDetails}>{immunity}</span>
          {candidate_count !== 0 ? (
            <CustomTooltip
              className={"forCandidateTrials"}
              tooltipClass={"custom-tooltip-relative"}
              tooltipContent={
                <>
                  { !this.state.loadedCandidates ? (
                    <div className="forCandidateTrials-elem">
                      {this.showSpinner()}
                    </div>
                  ) : (
                    this.state.candidatesTags.map((item, i) => (
                      <div key={i} className="forCandidateTrials-elem">
                        <span className="forCandidateTrials-title">
                          {item.name}
                        </span>
                        <span className="forCandidateTrials-rate">
                          {item.rate}%
                        </span>
                      </div>
                    ))
                  )}
                </>
              }
            >
              <span
                className="td-content candidatesAmTrial"
                onClick={() => this.makeCandidateTags()}
              >
                {candidate_count > 1
                  ? "Candidate for " +
                  candidate_count +
                  " other Trials"
                  : ""}
                {candidate_count === 1 &&
                "Candidate for " +
                candidate_count +
                " other Trial"}
              </span>
            </CustomTooltip>
          ) : (
            ""
          )}
        </div>
        <div className="candidates forname">
          <div className="forgam-g">
            <span className="">Name</span>
            <span className="td-content">{name || "–"}</span>
          </div>
        </div>
        <div className="candidates forgam">
          <div className="forgam-g">
            <span className="">Gender</span>
            <span className="td-content">
              {gender === "M" ? "Male" : "Female"}
            </span>
          </div>
          <div className="forgam-a">
            <span>Age</span>
            <span className="td-content">{age}</span>
          </div>
        </div>
        <div
          className={
            "candidates forgam-a " +
            (consented ? "contact-consent-can" : "non-contact-consent-can")
          }
        >
          <span>Contact Consent</span>
        </div>
        <div className="candidates lastenc">
          <div>
          <div className="lastenc-forIcon">
            <span>Last Encounter</span>
            <span className="td-content">
              {lastEncounter ? moment(lastEncounter).format("MMM DD YYYY") : "-"}
            </span>
          </div>
          <div className="lastenc-forIcon">
            <span>Next Visit</span>
            <span className="td-content">
              {nextEncounter ? moment(nextEncounter).format("MMM DD YYYY") : "-"}
            </span>
          </div>
          </div>
          <div className="vertical-center">
            <InfoIcon click={this.showPatientOverview}/>
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <div className="lastencForCircle all-matches-tags">

              {
                this.props.mat.matchCriteria &&
                <div className="forMatchedCriteria">
                  {this.props.mat.matchCriteria.map((el, i) => {
                    return (
                      <div key={i} className="forMatchedCriteria-elem">
                      <span className="forMatchedCriteria-title">
                        {el.name}
                      </span>
                        <img
                          src={el.inclusion ? checkInc : checkEx}
                          className="forMatchedCriteria-icon"
                          alt="Criteria"
                        />
                      </div>
                    );
                  })}
                </div>
              }
              <Circle
                percent={matchrate}
                strokeWidth="5"
                trailWidth="0"
                strokeColor="#5BBEBF"
                className="lastenCircle"
              />
              <span
                className="circleNumber"
                // onMouseOver={e => this.showMCInfo(e)}
                // onMouseLeave={e => this.closeMCInfo(e)}
              >
                {Math.round(matchrate)}%
              </span>
              {match_count > 0 ? (
                  <CustomTooltip
                    className={"forMatchedTrials"}
                    tooltipClass={"custom-tooltip"}
                    tooltipContent={
                      <>
                        { !this.state.loadedMatched ? (
                          <div className="forMatchedTrials-elem">
                            { this.showSpinner() }
                          </div>
                        ) : (
                          <>
                            <div className="forMatchedTrials-elem">
                              Matched for {this.state.matchedTags.length} other {
                              this.state.matchedTags.length > 1 ? "trials" : "trial"}:
                            </div>
                            {this.state.matchedTags.map((item, i) => (
                              <div key={i} className="forMatchedTrials-elem">
                            <span className="forMatchedTrials-title">
                              {item.name}
                            </span>
                                <span className="forMatchedTrials-rate">
                              {item.rate}%
                            </span>
                              </div>
                            ))}
                          </>
                        )
                        }
                      </>
                    }
                  >
                <span
                  className="multiIndicator"
                  onClick={() => this.makeMatchedTags()}
                >
                  +{match_count}
                </span>
                  </CustomTooltip>) :
                <span
                  className="multiIndicator"
                >
                  +{match_count}
                </span>
              }

              {enrolled_count > 0 ? (
                  <CustomTooltip
                    className={"forMatchedTrials"}
                    tooltipClass={"custom-tooltip"}
                    tooltipContent={
                      <>
                        {!this.state.loadedEnrolled ? (
                          <div className="forMatchedTrials-elem">
                            { this.showSpinner() }
                          </div>
                        ) : (
                          <>
                            <div className="forMatchedTrials-elem">
                              Enrolled in {this.state.enrolledTags.length} other {
                              this.state.enrolledTags.length > 1 ? "trials" : "trial"}:
                            </div>
                            {
                              this.state.enrolledTags.map((item, i) => (
                                <div key={i} className="forMatchedTrials-elem">
                          <span className="forMatchedTrials-title">
                            {item.name}
                          </span>
                                  <span className="forMatchedTrials-rate">
                            {item.rate}%
                          </span>
                                </div>
                              ))
                            }
                          </>
                        )}
                      </>
                    }
                  >
                <span
                  className="multiIndicator multiEnroll"
                  onClick={() => this.makeEnrolledTags()}
                >
                  +{enrolled_count}
                </span>
                  </CustomTooltip>) :
                <span
                  className="multiIndicator multiEnroll"
                >
                  +{enrolled_count}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    globalState: state,
    userToken: state.userToken
  };
}

export default withRouter(connect(mapStateToProps)(AllMatches));
