import React from 'react'
import i18n from '../i18n'
import search_icon from '../images/search.svg'
export function Search ({ change, press }) {
  const searchMatchesOnEnter = event => {
    if (event.key === 'Enter') {
      press()
    }
  }
  return (
    <div className='trial-search-container'>
      <img className='icon-left-search' src={search_icon} alt='Search' />
      <input
        type='text'
        className='trial-search-field'
        name='trialName'
        id='trialName'
        placeholder={`${i18n.t('general.search')}`}
        onChange={change}
        onKeyPress={searchMatchesOnEnter}
      />
    </div>
  )
}
