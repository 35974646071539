import React, { PureComponent } from "react";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import InmunityApi from "./../../../api/InmunityApi";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class InputsDefault extends PureComponent {
  constructor() {
    super();
    this.state = {
      roleOption: "",
      newUser: [],
      roleOptions: [],
      user: {},
      showModal: false,
    };
  }

  handleChangeRoleOption = roleOption => {
    let user = JSON.parse(localStorage.getItem("infoAboutUser"));
    this.setState({ roleOption }, () => {
      user.role = {
        id: roleOption.value,
        name: roleOption.label
      };

      localStorage.setItem("infoAboutUser", JSON.stringify(user));
      // InmunityApi.postUs
    });
  };

  componentDidMount() {
    InmunityApi.getAllRolesShort()
      .then(res => {
        let roleOptions = [];
        res.forEach(role => {
          let obj = {};
          obj.value = role.id;
          obj.label = role.name;
          roleOptions.push(obj);
          if (role.id === 3) {
            if (!this.props.location.pathname.endsWith("edituser")) {
              this.setState({ roleOption: obj });
            }
          }
        });
        this.setState({ roleOptions });
      })
      .catch(error =>
        console.log("Error occured while getting roles: ", error)
      );
    if (this.props.location.pathname.endsWith("edituser")) {
      let infoAboutUser = JSON.parse(localStorage.getItem("infoAboutUser"));
      this.setState({
        user: infoAboutUser,
        roleOption: {
          value: infoAboutUser.role.id,
          label: infoAboutUser.role.name
        }
      });
    }
  }

  editUser = ({ target }) => {
    let user = this.state.user;
    if (target.id !== "firstPhone" && target.id !== "secondPhone") {
      user[target.id] = target.value;
    } else if (target.id === "firstPhone") {
      user[target.id] = target.value
        .split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
    } else if (target.id === "secondPhone") {
      user[target.id] = target.value
        .split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
    }
    localStorage.setItem("infoAboutUser", JSON.stringify(user));
  };

  render() {
    return (
      <div className="inputs">
        <div className="inputs__name">
          <div>
            <span>First name</span>
            <div className="another-profile-info">
              {this.state.user.firstName}
            </div>
          </div>
          <div>
            <span>Last name</span>
            <div className="another-profile-info">
              {this.state.user.lastName}
            </div>
          </div>
        </div>
        <div className="inputs_div">
          <div>
            <span>Email Address</span>
            <div className="another-profile-info">
              {this.state.user.email}
            </div>
          </div>
        </div>
        <div className="inputs_div">
          <div className="inputs__phone-number ">
            <span>Phone Number</span>
            <div className="another-profile-info">
              {this.state.user.firstPhone}
            </div>
          </div>
        </div>
        <div className="inputs_div">
          <div className="inputs__office-number ">
            <span>Office or alternate phone</span>
            <div className="another-profile-info">
              {this.state.user.secondPhone}
            </div>
          </div>
        </div>
        {/* <div className="inputs_div">
          <div>
            <span>Password</span>
            <input
              className="newUserPassword forSending"
              id="password"
              type="password"
              onChange={this.editUser}
            />
          </div>
        </div> */}
        <div className="inputs_div">
          <div className="newTrialWrapp">
            <span>Role</span>
            {/* this.state.roleOption.label, */}

            {
              this.props.permission && this.props.permission.add_user ? <Select
                value={this.state.roleOption}
                onChange={this.handleChangeRoleOption}
                options={this.state.roleOptions}
                className="sort_role forSending"
              /> :
                <div className="another-profile-info">
                  {this.state.roleOption.label}
                </div>
            }

            {/* <ChooseRole
            onClick={this.handleChangeRoleOption}
            options={['Magician', 'Warrior', 'Dragon']} /> */}
          </div>
        </div>
      </div>
    );
  }
}

class Inputs extends PureComponent {
  constructor() {
    super();
    this.state = {
      roleOption: "",
      newUser: [],
      roleOptions: [],
      user: {}
    };
  }

  handleChangeRoleOption = roleOption => {
    let user = JSON.parse(localStorage.getItem("infoAboutUser"));
    this.setState({ roleOption }, () => {
      user.role = {
        id: roleOption.value,
        name: roleOption.label
      };
      localStorage.setItem("infoAboutUser", JSON.stringify(user));
    });
  };

  componentDidMount() {
    InmunityApi.getAllRolesShort()
      .then(res => {
        let roleOptions = [];
        res.forEach(role => {
          let obj = {};
          obj.value = role.id;
          obj.label = role.name;
          roleOptions.push(obj);
          if (role.id === 3) {
            if (!this.props.location.pathname.endsWith("edituser")) {
              this.setState({ roleOption: obj });
            }
          }
        });
        this.setState({ roleOptions });
      })
      .catch(error =>
        console.log("Error occured while getting roles: ", error)
      );
    if (this.props.location.pathname.endsWith("edituser")) {
      let infoAboutUser = JSON.parse(localStorage.getItem("infoAboutUser"));
      this.setState({
        user: infoAboutUser,
        roleOption: {
          value: infoAboutUser.role.id,
          label: infoAboutUser.role.name
        }
      });
    }
  }

  editUser = ({ target }) => {
    let user = this.state.user;
    if (target.id !== "firstPhone" && target.id !== "secondPhone") {
      user[target.id] = target.value;
    } else if (target.id === "firstPhone") {
      user[target.id] = target.value
        .split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
    } else if (target.id === "secondPhone") {
      user[target.id] = target.value
        .split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
    }
    localStorage.setItem("infoAboutUser", JSON.stringify(user));
  };

  render() {
    return (
      <div
        onClick={() => this.setState({ showModal: false })}
        className="inputs">
        <div className="inputs__name">
          <div>
            <span>First name</span>
            <input
              className="newUserFirstName forSending"
              id="firstName"
              type="text"
              onChange={this.editUser}
              defaultValue={this.state.user.firstName}
            />
          </div>
          <div>
            <span>Last name</span>
            <input
              className="newUserLastName forSending"
              id="lastName"
              type="text"
              onChange={this.editUser}
              defaultValue={this.state.user.lastName}
            />
          </div>
        </div>
        <div className="inputs_div">
          <div className="inputs__email-address ">
            <span>E-mail address</span>
            <input
              className="newUserEmail forSending"
              id="email"
              type="email"
              onChange={this.editUser}
              defaultValue={this.state.user.email}
            />
          </div>
        </div>
        <div className="inputs_div">
          <div className="inputs__phone-number ">
            <span>Phone Number</span>
            <MaskedInput
              mask={[
                "+",
                "1",
                " ",
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              placeholder="(303) 456 7890"
              className="forSending phoneNumber"
              id="firstPhone"
              defaultValue={this.state.user.firstPhone}
              onChange={this.editUser}
            />
          </div>
        </div>
        <div className="inputs_div">
          <div className="inputs__office-number ">
            <span>Office or alternate phone</span>
            <MaskedInput
              mask={[
                "+",
                "1",
                " ",
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              placeholder="(303) 456 7890"
              className="forSending officePhone"
              id="secondPhone"
              defaultValue={this.state.user.secondPhone}
              onChange={this.editUser}
            />
          </div>
        </div>
        <div className="inputs_div">
          <div>
            <span>Password</span>
            <input
              className="newUserPassword forSending"
              id="password"
              type="password"
              onChange={this.editUser}
            />
          </div>
        </div>
        <div className="inputs_div">
          <div className="newTrialWrapp">
            <span>Role</span>
            <Select
              value={this.state.roleOption}
              onChange={this.handleChangeRoleOption}
              options={this.state.roleOptions}
              className="sort_role forSending"
            />
            <input
              type="hidden"
              value={this.state.roleOption.value}
              id="role"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken,
    permission: state.permissions,
  };
}

function InputsSwitch(props) {
  if (props.newUser) {
    return <Inputs {...props} />
  }
  return <InputsDefault {...props} />
}

export default withRouter(connect(mapStateToProps)(InputsSwitch));
