import React from "react";

export default function DrugVersusPlacebo({ drugVersusPlacebo }) {
  return (
    <div className="ntw__tf-inputWrapp wrap-trial-in">
      {drugVersusPlacebo && drugVersusPlacebo.length !== 0 && (
        <div
          className="ntw__ti-input ntw__proceduresSelect"
          data-test="drugVplacebo"
        >
          <span className="ntw__ti-inputlabel">Study Arms</span>
          <div className="ntw__ti ntw__tablePlaceboDrugs">
            {drugVersusPlacebo.map((el, i) => {
              return (
                <div className="ntw__tableRow" key={i}>
                  <span className="ntw__table-title">{el.name}</span>
                  <span className="ntw__table-value">{el.percentage}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
