import React, {useState} from "react";
import {StyledTab,StyledLabTable} from '../styled'
import {connect} from 'react-redux'
import LabTable from "./LabTable";
import Highlighter from "react-highlight-words";

const Medications = ({medications, fullChartName, tabs}) => {
    const [activeTab, changeActiveTab] = useState(0)
    const [hover, setHover] = useState(false);
    const toggleHover = (index) => {
      setHover(index);
  }

  
    return (
        <div className="patientChartSubContent">
            <div className="ppWrapper pcdownWrapp__block width100">
                <div className="popup-notes">
                    <div>
                        <div>
                            <div className="pcdownWrapp__block-container newTrialWrapp">
                                <span>Medications History</span>
                            </div>
                            
                            <div className="searchBlockWrapper__selector"
                                style={
                                    {
                                        overflow: 'visible',
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative'
                                    }
                            }>

                                {
                                tabs.map((e, index) => e && (
                                    <div style={
                                            {width: "20%"}
                                        }
                                        key={index}
                                        onClick={
                                            () => changeActiveTab(index)
                                    }>
                                        <StyledTab 
                                            active={activeTab === index}
                                            index={index}
                                            key={index}
                                            leftCorner={activeTab === index && index !== 0}
                                            rightCorner={activeTab === index && index !== 1}
                                            target={e}
                                            onMouseEnter={() => toggleHover(index)}
                                            onMouseLeave={() => toggleHover(false)}
                                            >
                                            <Highlighter
                                                highlightClassName="found"
                                                searchWords={[]}
                                                autoEscape
                                                textToHighlight={`${e}(${Object.values(medications)[index] && Object.values(medications)[index].length})`}
                                            />
                                        </StyledTab>
                                    </div>
                                ))} 
                                  {hover === 1 && fullChartName && 
                                    <div className="my-chart-tooltip" style={{width: "20%", marginLeft: "20%"}}>
                                        <p className="tooltip-text">{fullChartName}</p>
                                    </div>
                                }
                                </div>
                                <StyledLabTable>
                            <LabTable labValues={
                                Object.values(medications)[activeTab]
                            }/>
                                </StyledLabTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default connect((state) => ({fullChartName: state.fullChartName}))(Medications);






