import React from 'react'
import ReactSlider from 'react-slider'

export default function recruitmentGoal ({ setInfo, defaultGoal }) {
  const changeSliderValue = recruitmentGoal => {
    setInfo('goal', recruitmentGoal)
  }

  return (
    <div className='ntw__tf-sliderWrapp'>
      <span className='ntw__ti-inputlabel'>Recruitment Goal</span>
      <ReactSlider
        min={0}
        max={75}
        value={defaultGoal}
        onChange={changeSliderValue}
        withBars
      >
        <div className='numberBlock'>{defaultGoal}</div>
      </ReactSlider>
    </div>
  )
}
