import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import "./index.css";
import inmunityApi from "../../api/InmunityApi";
import styled from 'styled-components';
import {URLGenerator} from "../../utils/trailsNavigation";

const TrialsNav = ({permissions = null, trialID, current}) => {
  const [trialPaths, setTrialPaths] = useState(URLGenerator(trialID));

  useEffect(() => {
    console.log(trialID);
    const paths = URLGenerator(trialID);
    // Object.keys(r).forEach(tabName => {
    //   paths.find(tP => tP.name.toLowerCase() === tabName).count = `()`;
    // });

    inmunityApi.getAllTrialsNavCounts(trialID)
      .then(r => {
        const paths = URLGenerator(trialID);

        Object.keys(r).forEach(tabName => {
          paths.find(tP => tP.name.toLowerCase() === tabName).count = `(${r[tabName]})`;
        });

        setTrialPaths(paths);
      })
  }, [trialID]);

  if (!permissions.analytics_trials) {
    let index = trialPaths.findIndex(x => x.name === "Analytics")
    if (index >= 0) {
      trialPaths.splice(index, 1)
    }
  }

  return (
    <nav className="navigation-all-trials">
      <ul className="all-trials-nav">
        {
          trialPaths.map((el, i) => (
            <li className="all-trials-item" key={i}>
              <NavLink to={el.path} exact>
                <span>
                  {el.name}
                </span>
                {(el.name !== 'Information' && el.name !== 'Analytics') && (el.count || <>(<TinySpinner/>)</>)}
              </NavLink>
            </li>
            )
          )}
      </ul>
    </nav>
  );
};

export default TrialsNav;

const TinySpinner = styled.span`
width: 10px;
height: 10px;
border-radius: 50%;
border: 1px solid #00abd9;
border-left-color: transparent;
border-right-color: transparent;
animation: rotate 1s linear infinite;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
