import React from "react";
import { withRouter } from "react-router";
import logo from "../images/logo-w.png";
import InmunityApi from '../api/InmunityApi'
import "./styles/PatientDetails.css";

class ThankYou extends React.Component {
  state = {
    isChecking: true,
    error: false,
    errorMwssage: ""
  }

  goToLogin = () => {
    let { history } = this.props;
    history.push({
      pathname: "/"
    });
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    const company = this.props.match.params.company;
    if (token && company)
      InmunityApi.createCompany(company, token).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.setState({ isChecking: false, error: false });
        }
        else {
          this.setState({ isChecking: false, error: true, errorMwssage: 'The Token is Invalid!' });
        }
      }
      )
    else {
      this.setState({ isChecking: false })
      const { location } = this.props
      if(typeof location.state!=='undefined' && typeof location.state.user!=='undefined' && location.state.user.status==="error"){
        this.setState({ isChecking: false, error: true, errorMwssage: 'Invalid personal information!' })
      }
    }

  }

  render() {
    const {
      location: { pathname, state }
    } = this.props;

    if (this.state.isChecking) {
      return (
        <div style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <img
            className="small-spinner"
            style={{ height: "50px" }}
            src={require("../images/spinner.gif")}
            alt="Spinner"
          />
        </div>
      )
    };

    return (
      <div className="base-container forPD forBlueBC thankYouPage">
        <div className="base-container">
          <div className="login-container patientDetails reasonDecline">
            <img src={logo} alt="Immunity" />
            <p className="helloName">
              {
                this.state.error ?
                  this.state.errorMwssage :
                  'Thank You!'
              }
            </p>
            {pathname === "/physician-consented" && (
              <span>The patient will be contacted</span>
            )}
            {(pathname === "/patient-consented") && (
              <span>
                {state.user.message}
              </span>
            )}
            <div className="closeWindow" onClick={this.goToLogin}>
              Close window
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ThankYou);
