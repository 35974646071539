import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setPatientsFilterResult(state = initialState.patientsFilterResult, action) {
  switch(action.type) {
    case types.PATIENTS_FILTER_RESULT_START:
      return {
        ...action.patientsFilterResult,
        loading: true,
      }
    case types.PATIENTS_FILTER_RESULT_SUCCESS:
      return {
        ...action.patientsFilterResult,
        loading: false,
      }
    case types.PATIENTS_FILTER_RESULT_ERROR:
      return {
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}
