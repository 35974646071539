import React, { Component } from 'react'
import { Circle } from 'rc-progress'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PatientOverview from './../PatientOverview'
import inmunityApi from '../../api/InmunityApi'
import { InfoIcon,  CustomTooltip } from "../../ui"
import './index.css'

class AllTrialsMatches extends Component {
  state = {
    showOverview: false,
    details: {},
    matchCriteriaInfo: [],
    matchedTags: [],
    enrolledTags: [],
    candidatesTags: [],
    loadedCandidates: false,
    loadedMatched: false,
    loadedEnrolled: false
  }

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains('button-center') &&
      !target.closest('.pat-over-wrapp') &&
      (!target.classList.contains('button__icon') &&
        !target.classList.contains('popup-overlay'))
    ) {
      this.redirect()
    }
  }

  redirect = () => {
    let {
      history,
      mat: { hadmId }
    } = this.props
    history.push({
      pathname: `/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: 'Match Details'
    })
  }

  makeMatchedTags = () => {
    if (this.state.matchedTags.length === 0) {
      inmunityApi
      .getPatientMatchedStatus(this.props.mat.immunity, this.props.mat.studyId)
      .then(details => {
          details.sort((a, b) => b.rate - a.rate);
          this.setState({ matchedTags: details, loadedMatched: true })
        });
    }
  }

  makeEnrolledTags = () => {
    if (this.state.enrolledTags.length === 0) {
      inmunityApi
      .getPatientEnrolledStatus(this.props.mat.immunity, null)
      .then(details => {
          details.sort((a, b) => b.name - a.name);
          this.setState({ enrolledTags: details, loadedEnrolled: true })
        });
    }
  }

  makeCandidateTags = () => {
    if (this.state.candidatesTags.length === 0) {
      inmunityApi
      .getPatientCandidateStatus(this.props.mat.immunity, null)
      .then(details => {
          details.sort((a, b) => b.name - a.name);
          this.setState({ candidatesTags: details, loadedCandidates: true })
        });
    }
  }

  showSpinner = () => {
    return (
      <img
        style={{ width: 30, height: 30, margin: "auto", display: "block" }}
        className="small-spinner"
        src={require("../../images/spinner.gif")}
        alt=""
      />
    )
  }

  render() {
    console.log(this.props.mat)
    const {
      gender,
      age,
      consented,
      lastEncounter,
      nextEncounter,
      matchrate,
      name,
      immunity,
      candidate_count,
      match_count,
      enrolled_count
    } = this.props.mat;
    
    return (
      <div
        className="candidatesWr matchesWr"
        key={this.props.key}
      >
        <div className="candidates hadmId forMatchesTooltip">
          <span className="imm-id" onClick={this.goToDetails}>Immunity ID</span>
          <span className="td-content" onClick={this.goToDetails}>{immunity}</span>
          {candidate_count !== 0 ? (
            <CustomTooltip
              className={"forCandidateTrials"}
              tooltipClass={"custom-tooltip-relative"}
              tooltipContent={
                <>
                  { !this.state.loadedCandidates ? (
                    <div className="forCandidateTrials-elem">
                      {this.showSpinner()}
                    </div>
                    ) : (
                    this.state.candidatesTags.map((item, i) => (
                      <div key={i} className="forCandidateTrials-elem">
                        <span className="forCandidateTrials-title">
                          {item.name}
                        </span>
                        <span className="forCandidateTrials-rate">
                          {item.rate}%
                        </span>
                      </div>
                    ))
                  )}
                </>
              }
            >
              <span
                className="td-content candidatesAmTrial"
                onClick={() => this.makeCandidateTags()}
              >
                {candidate_count > 1
                  ? "Candidate for " +
                  candidate_count +
                  " other Trials"
                  : ""}
                {candidate_count === 1 &&
                  "Candidate for " +
                  candidate_count +
                  " other Trial"}
              </span>
            </CustomTooltip>
          ) : (
              ""
            )}
        </div>
        <div className="candidates forname" onClick={this.goToDetails}>
          <div className="forgam-g">
            <span className="">Name</span>
            <span className="td-content">{name || "–"}</span>
          </div>
        </div>
        <div className="candidates forgam" onClick={this.goToDetails}>
          <div className="forgam-g">
            <span className="">Gender</span>
            <span className="td-content">
              {gender === "M" ? "Male" : "Female"}
            </span>
          </div>
          <div className="forgam-a">
            <span>Age</span>
            <span className="td-content">{age}</span>
          </div>
        </div>
        <div
          className={
            "candidates forgam-a " +
            (consented ? "contact-consent-can" : "non-contact-consent-can")
          }
        >
          <span>Contact Consent</span>
        </div>
        <div className="candidates lastenc">
          <div>
            <div className="lastenc-forIcon">
              <span>Last Encounter</span>
              <span className="td-content">
                {lastEncounter ? lastEncounter
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/") : "-"}
              </span>
            </div>
            <div className="lastenc-forIcon">
              <span>Next Visit</span>
              <span className="td-content">
                {nextEncounter ? nextEncounter
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/") : "-"}
              </span>
            </div>
          </div>
          <div className="vertical-center">
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <div className="lastencForCircle all-matches-tags">
              <Circle
                percent={matchrate}
                strokeWidth="5"
                trailWidth="0"
                strokeColor="#5BBEBF"
                className="lastenCircle"
              />
              
              <span
                  className="circleNumber"
              >
              {Math.round(matchrate)}%
            </span>
              
              {match_count > 0 ? (
              <CustomTooltip
                className={"forMatchedTrials"}
                tooltipClass={"custom-tooltip"}
                tooltipContent={
                  <>
                    { !this.state.loadedMatched ? (
                      <div className="forMatchedTrials-elem">
                        { this.showSpinner() }
                      </div>
                    ) : (
                      <>
                      <div className="forMatchedTrials-elem">
                          Matched for {this.state.matchedTags.length} other {
                          this.state.matchedTags.length > 1 ? "trials" : "trial"}:
                        </div>
                        {this.state.matchedTags.map((item, i) => (
                          <div key={i} className="forMatchedTrials-elem">
                            <span className="forMatchedTrials-title">
                              {item.name}
                            </span>
                            <span className="forMatchedTrials-rate">
                              {item.rate}%
                            </span>
                          </div>
                        ))}
                        </>
                        )
                    }
                  </>
                }
              >
                <span
                  className="multiIndicator"
                  onClick={() => this.makeMatchedTags()}
                >
                  +{match_count}
                </span>
              </CustomTooltip>) : 
                <span
                  className="multiIndicator"
                >
                  +{match_count}
                </span>
              }
              
              {enrolled_count > 0 ? (
              <CustomTooltip
                className={"forMatchedTrials"}
                tooltipClass={"custom-tooltip"}
                tooltipContent={
                  <>
                  {!this.state.loadedEnrolled ? (
                    <div className="forMatchedTrials-elem">
                      { this.showSpinner() }
                    </div>
                  ) : (
                    <>
                    <div className="forMatchedTrials-elem">
                        Enrolled in {this.state.enrolledTags.length} other {
                        this.state.enrolledTags.length > 1 ? "trials" : "trial"}:
                    </div>
                    {
                      this.state.enrolledTags.map((item, i) => (
                        <div key={i} className="forMatchedTrials-elem">
                          <span className="forMatchedTrials-title">
                            {item.name}
                          </span>
                          <span className="forMatchedTrials-rate">
                            {item.rate}%
                          </span>
                        </div>
                      ))
                    }
                    </>
                  )}
                  </>
                }
              >
                <span
                  className="multiIndicator multiEnroll"
                  onClick={() => this.makeEnrolledTags()}
                >
                  +{enrolled_count}
                </span>
              </CustomTooltip>) : 
                <span
                  className="multiIndicator multiEnroll"
                >
                  +{enrolled_count}
                </span>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    globalState: state,
    userToken: state.userToken
  }
}

export default withRouter(connect(mapStateToProps)(AllTrialsMatches))
