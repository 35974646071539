import React, { useState, useEffect } from "react";
import checkInTrue from "./../../../../../../images/exclusion_inclusion/plus_full.svg";
import checkExTrue from "./../../../../../../images/exclusion_inclusion/minus_full.svg";
import checkInFalse from "./../../../../../../images/exclusion_inclusion/plus_empty.svg";
import checkExFalse from "./../../../../../../images/exclusion_inclusion/minus_empty.svg";

export default function ItemRow({
  el,
  el: {
    id,
    name,
    category,
    include,
    code,
    activeTab,
    criteria,
    isLeaf,
    parentId,
    itemsLength
  },
  makeCheck,
  removeItem,
  setCodes
}) {
  let [checkInclude, setCheckInclude] = useState(false);
  let [checkExclude, setCheckExclude] = useState(false);

  const handleCheckboxChange = e => {
    const attrName = e.target.parentNode.getAttribute("name");
    let checkedInclude = checkInclude;
    let checkedExclude = checkExclude;
    if (attrName === "include") {
      checkedInclude = !checkInclude;
      setCheckInclude(checkedInclude);
      if (checkedInclude) {
        if (checkExclude) {
          setCheckExclude(false);
          checkExclude = false;
          checkedExclude = false;
        }
      }
    } else if (attrName === "exclude") {
      checkedExclude = !checkExclude;
      setCheckExclude(checkedExclude);
      if (checkedExclude) {
        if (checkInclude) {
          setCheckInclude(false);
          checkInclude = false;
          checkedInclude = false;
        }
      }
    } else {
    }
    sendIncludeExclude(checkedInclude, checkedExclude);
  };

  const sendIncludeExclude = (checkedInclude, checkedExclude) => {
    if (checkedInclude || checkedExclude) {
      setCodes({
        id,
        code,
        category,
        include: checkedInclude,
        criteria,
        activeTab,
        name,
        isLeaf,
        parentId,
        itemsLength
      });
    } else {
      setCodes({
        id,
        code,
        category,
        include: "delete",
        criteria,
        activeTab,
        name,
        isLeaf,
        parentId,
        itemsLength
      });
    }
  };

  const deleteItem = el => {
    el.include = "delete";
    if (category) {
      makeCheck({ id, remove: true });
    }
    removeItem(el);
  };

  useEffect(() => {
    if (include) {
      setCheckInclude(true);
      setCheckExclude(false);
    } else {
      setCheckInclude(false);
      setCheckExclude(true);
    }
  }, [include]);

  return (
    <div className="si_row">
      <div className="picker-red-cross_wrapp">
        <div className="red-cross" onClick={() => deleteItem(el)} />
      </div>
      <div className="picker-name_wrapp">
        <div className={category ? "si_row_name si_row_name_b" : "si_row_name"}>
          {name}
        </div>
      </div>
      <div className="picker-inc-exc_wrapp">
        <div className="checkboxes-container">
          <div
            name="exclude"
            onClick={handleCheckboxChange}
            className="plus_minus_wrapp"
          >
            <img
              src={checkExclude ? checkExTrue : checkExFalse}
              alt="exclusion"
            />
          </div>
          <div
            name="include"
            onClick={handleCheckboxChange}
            className="plus_minus_wrapp"
          >
            <img
              src={checkInclude ? checkInTrue : checkInFalse}
              alt="inclusion"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
