import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setCurrentHadmId,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import inmunityApi from "../../api/InmunityApi";
import AllTrialsMatches from "./AllTrialsMatches";
import TrialsNav from "../TrialsNav";
import { URLGenerator } from "../../utils/trailsNavigation";
import { Sort, CustomInfinityScroll } from "../../ui";
import "../allTrials/index.css";
import "../styles/AllTrial.css";
import LoadMoreBtn from "../../ui/loadmore";
import { SpinnerSmall } from "../../ui/spinner";

const options = [
  { value: "age", label: "Age" },
  // { value: "encounter", label: "Last Encounter" }, ghost#hidden
  { value: "rate", label: "Match Rate" },
  { value: "gender", label: "Gender" },
  { value: "immunity", label: "Immunity ID" },
  { value: "name", label: "Name" },
  { value: "consented", label: "Contact Consent" },
  { value: "last_seen", label: "Last Seen" },
  { value: "next_visit", label: "Next Visit" },
];

class TrialsMatches extends Component {
  state = {
    matches: [],
    initialMatches: [],
    selectedOption: options[0],
    loading: true,
    isMore: null,
    link: null,
    matchesLength: 0,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    loadingNext: false
  };

  setHadmId(hadmId) {
    this.props.setCurrentHadmId(hadmId);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    let { setCurrentNavigationLink, setCurrentNavBarSubLink } = this.props;
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink("all");
    window.scrollTo(0, 0);
    this.mounted = true;
    this.setState({
      loading: true,
    });
    inmunityApi
      .getAllMatches()
      .then(matches => {
        if (this.mounted) {
          matches.results.sort((a, b) => b.age - a.age);
          this.setState({
            matches: matches.results,
            initialMatches: matches.results,
            loading: false,
            isMore: !!matches.next,
            link: matches.next,
            matchesLength: matches.count,
          });

        }
      })
      .catch(error =>
        console.log("Error occured while receiving search: ", error)
      );
  }

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortMatchesApi();
    });
  };

  handleChange = selectedOption => {
    this.setState(
      { selectedOption, matches: [], isMore: false, link: null },
      () => {
        this.sortMatchesApi();
      }
    );
  };

  sortMatchesApi = () => {
    this.setState({ loading: true });
    let flag = this.state.selectedOption.value;
    let sign = this.state.isReverseSort ? "-" : "";
    let query = this.state.input !== "" ? this.state.input : null;
    inmunityApi.getAllMatchesSorted(sign, query, flag).then(response => {
      this.setState({
        matches: response.results,
        loading: false,
        isMore: !!response.next,
        link: response.next,
        searchLengthResult: response.count
      });
    });
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldMatches = [...this.state.matches];
            let newMatches = oldMatches.concat(response.results);
            this.setState({
              matches: newMatches,
              initialMatches: newMatches,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          input: e.target.value,
          matches: [],
          searchLengthResult: null,
          searching: false
        },
        () => {
          this.sortMatchesApi();
        }
      );
    } else {
      this.setState({
        input: e.target.value
      });
    }
  };

  searchMatches = () => {
    this.setState(
      {
        matches: [],
        loading: true,
        isMore: false,
        link: null,
        searching: true
      },
      () => {
        this.sortMatchesApi();
      }
    );
  };

  render() {
    let {
      selectedOption,
      isReverseSort,
      searchLengthResult,
      matches,
      loading,
      searching,
      isMore,
      loadingNext
    } = this.state;
    return (
      <div className="content-body-matches" id="list">
        <TrialsNav current='matches' permissions={this.props.permissions}/>
        <div className="search-container">
          <Sort
            options={options}
            selectedOption={selectedOption}
            isRevert={isReverseSort}
            result={searchLengthResult}
            revert={this.reverseSort}
            select={this.handleChange}
            change={this.handleChangeInput}
            press={this.searchMatches}
            searching={searching}
          />
        </div>
        <div className="candidatesWrapper">
          {matches.length > 0 &&
            matches.map(function(m, i) {
              return (
                <AllTrialsMatches
                  key={i}
                  mat={m}
                  id={"trial-am-slice-" + m["hadmId"]}
                  idd={"trial-match-slice-" + m["hadmId"]}
                  idE={`trial-match-enroll-${m["hadmId"]}`}
                  setHadmId={() => this.setHadmId(m["hadmId"])}
                />
              );
            })}
            {(loading || loadingNext) && <SpinnerSmall/>}
            {!loadingNext && isMore && <LoadMoreBtn loadMoreData={e => this.loadMoreData(e)}/>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    setCurrentHadmId: id => dispatch(setCurrentHadmId(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrialsMatches);
