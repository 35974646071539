import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";

import InmunityApi from "./../../api/InmunityApi";
import { sendEmailNotification, setChangeEmailPopup } from "./../../actions/trialActions";
import Select, { components } from 'react-select'
import editIcon from "./../../images/edit_search.svg";
import crossicon from './../../images/icon-close-w.svg'
import Popup from "reactjs-popup"
import store from "../../store";
import warningIcon from '../../images/warning.svg'


import "./index.css";

import activeBell from "./../../images/bell-anticon-white.svg";
import bell from "./../../images/bell-anticon.svg";

const groupStyles = {
  padding: 5,
  background: 'white'
};

const EmailBody = ({ arr, selected, email, disabled, isDefault, setDefault }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (selected === null || selected === undefined || !Array.isArray(email) || email.length === 0) {
      setText("Please, select a physician");
      return;
    }
    setText(arr[selected].email_body);

  }, [arr, selected, email])

  useEffect( () => {
    if (isDefault) {
      setText(arr[selected].email_body_default);
      arr[selected].email_body = arr[selected].email_body_default;
    }
    setDefault(false);
  }, [selected, arr, setDefault, isDefault])

  const handleChange = (e) => {
    setText(e.target.value);
    arr[selected].email_body = e.target.value;
  }

  return (
    <textarea
      id="message"
      className="forSending"
      value={text}
      disabled={disabled}
      onChange={handleChange}
    />
  )
}

const FilesBody = ({arr, attachment, selected, uploadFile, disabled, clearFileInput }) => {
    const getFileName = (i) => {
      const current = attachment[i]
      if(typeof current !== "undefined"){
        if(current.fileName!==""){
          return current.fileName
        }
      }
    }
    return(
      <div className={`mb__lsection`}>
        <div className={`mb__lsectionforUploadImg ${attachment.length===2 || attachment.length===1 || attachment.length!==0 ? "hidden" : ""}`}>
          <input
            id={`attachment`}
            className={`forSending`}
            type="file"
            disabled={disabled}
            onChange={(e) => uploadFile(e, selected, arr, attachment)}
          />
        </div>
        <div className={`mb__lsectionWrapp ${attachment.length!==0 ? "" : "hidden"}`}>
          {attachment.length===0 || (typeof attachment[0].patch!=='undefined' &&  !attachment[0].patch) ? (
            <span className="fileName">
              {getFileName(0)}</span>
          ) : (
            <a href={attachment[0].patch} target="_blank" rel='noopener noreferrer' className="fileName">{getFileName(0)}</a>
          )}

          {attachment.length!==0 && typeof attachment[0].readOnly!=='undefined' &&  !attachment[0].readOnly && (
            <img
              src={require("../../images/close-circle-o-anticon.svg")}
              onClick={(e) => clearFileInput(e, 0, attachment[0].patch)}
              alt="Close Circle"
            />
          )}
        </div>
        <div className={`mb__lsectionforUploadImg ${attachment.length!==1 || !attachment[0].readOnly ? "hidden" : ""}`}>
          <input
            id={`attachment`}
            className={`forSending`}
            type="file"
            disabled={disabled}
            onChange={(e) => uploadFile(e, selected, arr, attachment)}
          />
        </div>
        <div className={`mb__lsectionWrapp ${attachment.length>1 ? "" : "hidden"}`}>
          {typeof attachment[1]!=='undefined' && typeof attachment[1].patch!=='undefined' &&  attachment[1].patch==='' ? (
            <span className="fileName">
              {getFileName(1)}</span>
          ) : (
            <a href={typeof attachment[1]!=='undefined' && attachment[1].patch} target="_blank" rel='noopener noreferrer' className="fileName">{getFileName(1)}</a>
          )}
          {attachment.length>1 && !attachment[1].readOnly && (
            <img
              src={require("../../images/close-circle-o-anticon.svg")}
              onClick={(e) => clearFileInput(e, 1, attachment[1].patch)}
              alt="Close Circle"
            />
          )}
        </div>
      </div>
    )
}


const EmailTabs = ({ originalData, arr, selected, setSelected, parseFormData, alreadySent }) => {
  let data = [];
  if (alreadySent.idData && alreadySent.idData.id) {
     data = alreadySent.emailFields.physician.filter(e => e.sended);

  }

  if (!Array.isArray(arr)) {
    return <></>;
  }
  else {
    data = parseFormData();
  }

  return (
    <div className="emailTabContainer">
      {
        arr.map((e, i) => {
          return (
            <div
              onClick={() => setSelected(originalData.physician.findIndex(x => x.name === data[i].name))}
              className={selected === originalData.physician.findIndex(x => x.name === data[i].name) ? "emailTabActive" : "emailTab"}>
              {data[i] ? data[i].name : 'No Name'}
            </div>
          )
        })
      }
    </div>
  )
}
const Group = props => {
  return (
    <div style={groupStyles}>
      <components.Group {...props}>
        <div className="groupRow">
          {props.children}
          <img
            onClick={() => store.dispatch(setChangeEmailPopup(true, props.label))}
            width={35}
            className="editIcon"
            src={editIcon}
            alt="Icon" />
        </div>
      </components.Group>
    </div>
  )
};

const makeOption = (val, id) => ({ value: val, label: val, id: id })

const selectStyles = {
  control: styles => ({ ...styles, display: 'flex', justifyContent: 'center', alignItems: 'center' }),
  option: styles => ({ ...styles }),
  input: styles => ({ ...styles, display: 'flex', justifyContent: 'center', alignItems: 'center' }),
  placeholder: styles => ({ ...styles }),
  singleValue: styles => ({ ...styles }),
};

class EmailPopup extends Component {
  state = {
    emailFields: "",
    dayCount: 5,
    active: false,
    deactivate: false,
    email: null,
    changeEmail: '',
    attachment: [],
    backendFiles: [],
    newData: [],
    touched: false,
    error: false,
    selected: null,
    selectedBody: [],
    disabled: true,
    isDefault: false
  };

  getDataEmailId = () => {
    InmunityApi.getEmailFields(
      this.props.idData.id
    )
      .then(emailFields => {
        let email = []
        if (!emailFields.send && !emailFields.resend) {
          this.setState({disabled: true})
        }
        emailFields.physician.forEach(r => {
          if (r.sended) {
            email.push(makeOption(r.email, r.id));
          }
        })
        emailFields.attached_files.forEach(r =>{
          this.state.attachment.push({fileName: r.name, readOnly: r.read_only, patch: r.patch})
          this.state.backendFiles.push(r)
          this.setState({attachment: this.state.attachment, backendFiles:this.state.backendFiles})
        })

        this.setState({ emailFields, email, selected: email.length > 0 ? email.length - 1 : false });
        this.parseData(emailFields.physician);
      })
      .catch(error => console.log("error getting email: ", error));
  }

  componentDidMount() {
    this.setState({disabled: false})
    if (this.props.idData &&  this.props.idData.id) {
      this.getDataEmailId();
      return ;
    }
    let studyId = this.props.candidate.studyId || this.props.studyId;
    this.props.candidate.hadmId &&
      studyId &&
      InmunityApi.getEmailFields(
        this.props.candidate.hadmId,
        studyId
      )
        .then(emailFields => {
          let email = []
        if (emailFields.send === false && emailFields.resend === false) {
          this.setState({disabled: true})
        }
        emailFields.physician.forEach(r => {
          if (r.sended) {
            email.push(makeOption(r.email, r.id));
          }
        })
        this.setState({ emailFields, email, selected: email.length > 0 ? email.length - 1 : false });
        this.parseData(emailFields.physician);
        })
        .catch(error => console.log("error getting email: ", error));
  }

  parseData = (data) => {
    let newData;

    newData = data.map((e, index) => (
      {
        label: e.name + (e.speciality ? ` (${e.speciality})` : ''),
        options: [makeOption(e.email, index)]
      }
    ))
    this.setState({ newData });
  }

  uploadFile = (e, selected, arr) => {
    let input = e.target;
    let textDoc = e.target.parentNode.nextSibling.children[0];
    if (input.files && input.files[0]) {
      if (
        input.files[0].name.substr(input.files[0].name.length - 3) === "pdf"
      ) {
        let reader = new FileReader();
        reader.onload = function (e) {
          textDoc.innerHTML = input.files[0].name;
        };
        this.state.attachment.push({
          fileName: input.files[0].name,
          readOnly: false,
        })
        this.setState({attachment: this.state.attachment})
        reader.readAsDataURL(input.files[0]);
      } else {
        alert("There is no valid file extension, please download PDF file.");
      }
    }
  };

  handleChange = (email, actionMeta) => {
    if (Array.isArray(email) && email[email.length - 1]) {
      if (email[email.length - 1] !== undefined) {
        this.setState({ email, selected: email[email.length - 1].id} )
      }
    } else {
      this.setState({email, selected: Array.isArray(email) ? email.length - 1 : 0 })
    }
  }

  handleChangeEmail = (e) => {
    console.log("Here")
    this.setState({ changeEmail: e.target.value, touched: false, error: false })
  };


  minusDay = () => {
    if (this.state.dayCount - 1 > 0)
      this.setState({
        dayCount: this.state.dayCount - 1
      });
  };

  plusDay = () => {
    this.setState({
      dayCount: this.state.dayCount + 1
    });
  };


  parseFormData = () => {
    let arr = [];
    const { emailFields, email, newData } = this.state;

    arr = email.map((e) => {
      const index = newData.findIndex(f => f.options[0].value === e.value);

      if (index === -1 ) {
        return {}
      }
      return (
        {
          email: e.value,
          id: emailFields.physician[index].id,
          name: emailFields.physician[index].name,
          speciality: emailFields.physician[index].speciality,
          email_body: emailFields.physician[index].email_body
        }
      )
    })

    return (arr);
  }

  handleUpdateEmailList = (name) => {
    const { changeEmail } = this.state;

    const validateEmail = (email) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (!validateEmail(changeEmail)) {
      this.setState({ touched: true, error: 'Invalid Email' })
      return;

    }

    let { newData } = this.state;
    store.dispatch(setChangeEmailPopup(false));
    newData.forEach((r, index) => {
      if (r.label === name) {
        r.options[0] = makeOption(changeEmail, index);
        this.setState({ email: [...(this.state.email || []), makeOption(changeEmail, index)], selected: index });
      }
    })

    this.setState({ newData });
  }

  closeLetterPopup = () => {
    this.props.closePopup();
  };

  deactivateReminder = () => {
    this.setState({ active: false, deactivate: true });
  };

  openCountDayPopup = () => {
    document.querySelector(".emailTooltip").classList.toggle("hidden");
    this.setState({
      active: this.state.deactivate ? false : true,
      deactivate: false
    });
  };

  clearFileInput = (e, i, patch) => {
    let textDoc = e.target.previousSibling;
    textDoc.innerHTML = "";
    this.state.attachment.splice(i, 1)
    this.setState({attachment: this.state.attachment})
    this.state.backendFiles.splice(
      this.state.backendFiles.indexOf(
        this.state.backendFiles.filter(item => item.patch === patch)[0]
      ), 1)
    this.setState({backendFiles: this.state.backendFiles})
    let input = e.target.parentNode.previousSibling.children[0];
    input.value = "";
  };

  sendData = e => {
    localStorage.setItem(`Email ${this.props.candidate.hadmId}`, true);
    let formData = new FormData();
    let inputs = e.target.parentNode.parentNode.parentNode.querySelectorAll(
      ".forSending"
    );
    inputs.forEach(input => {
      if (input.type !== "file") {
        if (
          input.id === "days" ||
          input.id === "message"
        ) {
          if (input.id === "to") {
            formData.append(input.id, this.to.value);
          }
          //formData.append(input.id, this.state.active ? this.state.dayCount : null)
        } else {
          formData.append(input.id, input.value);
        }
      }else{
        input.value!=='' && formData.append(input.id, input.files[0]);
      }
    });
    this.state.backendFiles.length!==0 && formData.append("attached_files", JSON.stringify(this.state.backendFiles));
    formData.append("days", this.state.dayCount);
    formData.append("active", this.state.active);
    formData.append("to", JSON.stringify(this.parseFormData()));
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        alert("works");
      }
    };
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_PATH}/patients/email/physician/${
      this.props.candidate.hadmId
      }/${this.props.candidate.studyId || this.props.studyId}/`,
      true
    );
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem('token')}`
    );
    xhr.send(formData);
    this.closeLetterPopup();
    store.dispatch(
      sendEmailNotification({
        messageSuccess: "The email is sending",
        success: undefined
      })
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        store.dispatch(
          sendEmailNotification({
            messageSuccess: "The email was sent successfully",
            success: true,
            animation: true
          })
        );

        if (this.props.postWorkFlow && this.props.param) {
          const res = JSON.parse(xhr.response);
          const p = { ...this.props.param, emailId: res.id };
          this.props.postWorkFlow(p);
        }
        else if (this.props.postWorkFlow) {
          const res = JSON.parse(xhr.response);
          let action = null;
          if (this.state.emailFields.send) {
            action = 3;
          } else if (this.state.emailFields.resend) {
            action = 10
          }
          const p = { emailId: res.id, value: action };
          this.props.postWorkFlow(p);
        }
      } else {
        store.dispatch(
          sendEmailNotification({
            messageSuccess: "An error occured while sending email",
            success: false,
            animation: true
          })
        );
      }
    };
  };


  chooseTitle = () => {
    if (this.state.emailFields.resend) {
      return (Array.isArray(this.state.email) && this.state.email.length > 1 ?
      'Resend All' : 'Resend')
    }
    return (Array.isArray(this.state.email) && this.state.email.length > 1 ?
    'Send All' : 'Send')
  }

  setDefault = (bool) => {
    if (bool) {
      const n = this.state.selected;
      const defaultEmail = this.state.emailFields.physician[n].email;
      const email = this.state.email;

      const d = this.state.newData;

      d.forEach(e => {
        const val = e.options[0].value;
        if (val === email[n].value) {
          email[n] = makeOption(defaultEmail);
          e.options[0] = makeOption(defaultEmail);
        }
      })

      this.setState({email, newData: d})
      // const data = this.state.newData.map((e, i) => i === n ? makeOption(this.state.emailFields.physician[n].email) : e);
      // this.setState({newData: data})
    }
    this.setState({isDefault: bool})
  }

  render() {
    let { emailFields, active, dayCount } = this.state;

    return (
      <div>
        <Popup
          style={{ width: 200 }}
          className="changeEmailPopup"
          open={this.props.changeEmailPopup.open}
          closeOnDocumentClick
        // onClose={this.closeModal}
        >
          {/* <a`
            className="close"
            style={{ float: 'right' }}
            onClick={() => store.dispatch(setChangeEmailPopup(false))}>
            &times;
          </a> */}
          <div className="closePendingPopupCross"
            onClick={() => store.dispatch(setChangeEmailPopup(false))}>
            <img src={crossicon} alt="Popup Cross" />
          </div>
          <div className="changeEmail">
            <h4> Change Email for {this.props.changeEmailPopup.name} </h4>
            <div className="changeEmailRow">
              <div className={`emailInputCont ${this.state.touched && this.state.error && 'border-red'}`}>
                <input
                  type="email"
                  className={'changeEmailInput'}
                  // className="text-field-account"
                  name="email"
                  placeholder="name@example.com"
                  meta={{ touched: this.state.touched, error: this.state.error }}
                  onChange={this.handleChangeEmail}
                />
                {this.state.touched && (
                  <img
                    src={warningIcon}
                    alt='Error'
                    className={'emailWarning'}
                    id='warningPassword'
                  />
                )}
              </div>
              <button
                onClick={() => this.handleUpdateEmailList(this.props.changeEmailPopup.name)}
                className="mb__rsectionBtn"> Submit </button>
            </div>
          </div>
        </Popup>
        <div className="closePendingPopupCross" onClick={this.closeLetterPopup}>
          <img src={crossicon} alt="Popup Cross" />
        </div>
        {
          !emailFields ? (
            <div className="spinner-container">
              <img
                className="medium-spinner"
                src={require("../../images/spinner.gif")}
                alt="Spinner"
              />
            </div>
          ) : (
              <div>
                <div className="selectEmailField">
                  <span className="selectEmailTitle">To:</span>
                  <Select
                    isMulti
                    isSearchable={false}
                    key={this.state.email}
                    value={this.state.email}
                    // closeMenuOnSelect={false}

                    // onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    options={this.state.newData}
                    styles={selectStyles}
                    className="sort_select withInput selectEmailInput"
                    placeholder={"Select Email"}
                    components={{ Group }}
                  />
                  {/* <input
                  id="to"
                  className="forSending"
                  defaultValue={emailFields.physicianEmail}
                  type="text"
                  ref={to => (this.to = to)}
                /> */}
                </div>
                <div className="modal__from">
                  <input
                    id="subject"
                    className="forSending"
                    type="text"
                    defaultValue="Clinical Trial Candidate - Review Required"
                  />
                </div>
                <div className="tab-reset-container">
                <EmailTabs
                  parseFormData={this.parseFormData}
                  arr={this.state.email}
                  originalData={this.state.emailFields}
                  alreadySent={{idData: this.props.idData, emailFields: this.state.emailFields}}
                  selected={this.state.selected}
                  setSelected={(selected) => this.setState({ selected })}
                />
                   </div>
                   <div className="modal__text">
                  <EmailBody
                    email={this.state.email}
                    selected={this.state.selected}
                    arr={emailFields.physician}
                    disabled={this.state.disabled}
                    isDefault={this.state.isDefault}
                    setDefault={this.setDefault}
                  ></EmailBody>
                </div>
                <div className="modal__bottombar">
                  <FilesBody
                    disabled={this.state.disabled}
                    uploadFile={this.uploadFile}
                    selected={this.state.selected}
                    arr={emailFields.physician}
                    clearFileInput={this.clearFileInput}
                    attachment={this.state.attachment}
                    />
                  <div className="mb__rsection">
                    <div
                      className={active ? "activeReminder" : "reminderWrapper"}
                      onClick={this.openCountDayPopup}
                    >
                      <span className="daysCountIcon">
                        {active ? dayCount : null}
                      </span>
                      <img src={active ? activeBell : bell} alt="Day Popup" />
                    </div>
                    <div className="emailTooltip hidden">
                      <span>Remind me to follow up with recipient in:</span>
                      <div className="emailTooltipCounter">
                        <div className="emailTooltipMP" onClick={this.minusDay}>
                          -
                    </div>
                        <div className="etcNumber">
                          <span id="days" className="forSending">
                            {dayCount}
                          </span>
                          <span>Days</span>
                        </div>
                        <div className="emailTooltipMP" onClick={this.plusDay}>
                          +
                    </div>
                      </div>
                      <div
                        className={
                          this.state.active
                            ? "deactivateReminder"
                            : "nonDeactivateReminder "
                        }
                        onClick={this.deactivateReminder}
                      >
                        {active ? "Deactivate reminder" : "Deactivated"}
                      </div>
                      <div
                        className="wrappToClose"
                        onClick={this.openCountDayPopup}
                      />
                    </div>
                    {
                      !this.state.disabled &&
                      (
                      <div className="mb__rsectionBtn" onClick={this.sendData}>
                      {
                        this.chooseTitle()
                      }
                    </div>
                      )
                    }
                  </div>
                </div>
              </div>
            )
        }
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken,
    currentUser: state.currentUser,
    changeEmailPopup: state.changeEmailPopup
  };
}

export default connect(mapStateToProps)(EmailPopup);
