import InmunityApi from "../api/InmunityApi";

export const FETCH_ANALYTICS_START = 'FETCH_ANALYTICS_START';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';
export const FETCH_PATIENT_HOSPITALS_START = 'FETCH_PATIENT_HOSPITALS_START';
export const FETCH_PATIENT_HOSPITALS_SUCCESS = 'FETCH_PATIENT_HOSPITALS_SUCCESS';
export const FETCH_PATIENT_HOSPITALS_ERROR = 'FETCH_PATIENT_HOSPITALS_ERROR';
export const FETCH_SITE_START = 'FETCH_SITE_START';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_ERROR = 'FETCH_SITE_ERROR';

const fetchAnalyticsStart = () => {
  return {
    type: FETCH_ANALYTICS_START
  }
}
const fetchAnalyticsSuccess = analiticData => ({
  type: FETCH_ANALYTICS_SUCCESS,
  analiticData
})
const fetchAnalyticsError = error => ({
  type: FETCH_ANALYTICS_ERROR,
  error
})

const fetchPatientsHospitalsStart = () => ({
  type: FETCH_PATIENT_HOSPITALS_START
})

const fetchPatientsHospitalsSuccess = (hospitals) => ({
  type: FETCH_PATIENT_HOSPITALS_SUCCESS,
  hospitals
})

const fetchPatientsHospitalsError = (error) => ({
  type: FETCH_PATIENT_HOSPITALS_ERROR,
  error
})

const fetchSiteStart = () => ({
  type: FETCH_SITE_START
})

const fetchSiteSuccess = (site) => ({
  type: FETCH_SITE_SUCCESS,
  site
})

const fetchSiteError = (error) => ({
  type: FETCH_SITE_ERROR,
  error
})

export const fetchSite = (searchID) => (dispatch, getState) => {
  if (!getState().site.loading) {
    dispatch(fetchSiteStart());
    return (
      InmunityApi.getTopChartAnalytics(searchID)
        .then(res => {
          if (res) {
            dispatch(fetchSiteSuccess(res))
          } else {
            dispatch(fetchSiteError(res))
            throw res
          }
        })
        .catch(e => console.error(e))
    )
  } else {
    return getState()
  }
}

export const fetchHospitals = (searchID) => (dispatch, getState) => {
  if (!getState().hospitals.loading) {
    dispatch(fetchPatientsHospitalsStart());
    return (
      InmunityApi.getPatientsHospitals(searchID)
        .then(res => {
          if (res) {
            dispatch(fetchPatientsHospitalsSuccess(res))
          } else {
            dispatch(fetchPatientsHospitalsError(res))
            throw res
          }
        })
        .catch(e => console.error(e))
    )
  } else {
    return getState()
  }
}

export const fetchAnalytics = (searchID) => (dispatch, getState) => {
  if (!getState().analiticData.loading) {
    dispatch(fetchAnalyticsStart());
    return (
      InmunityApi.getAnalytics(searchID)
        .then(res => {
          if (res) {
            dispatch(fetchAnalyticsSuccess(res))
          } else {
            dispatch(fetchAnalyticsError(res))
            throw res
          }
        })
        .catch(e => console.error(e))
    )
  } else {
    return getState()
  }
}
