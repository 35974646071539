import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactSlider from "react-slider";
import PhaseItem from "./PhaseItem";
import LengthStudyItem from "./LengthStudyItem";
import StartDateItem from "./StartDateItem";
import DiseaseOrConItem from "./DiseaseOrConItem";
import DrugTypeItem from "./DrugTypeItem";
import DrugAdminItem from "./DrugAdminItem";
import DrugVersusPlacebo from "./DrugVersusPlacebo";
import CriteriaInformationBlock from "./CriteriaInformationBlock";
import Filters from "./../Filters";
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return{
    permissions: state.permissions
  }
}

function TrialInfo({
  permissions,
  currentTrial = "",
  phase = [],
  study_length = 0,
  start_date = "",
  procedures = [],
  drug_type = "",
  drug_admin = "",
  drugVersusPlacebo = [],
  goal = 0,
  min_match = 0,
  age_min = 0,
  age_max = 90,
  substance_status = false,
  smoking_status = false,
  gender = "",
  globalText = "",
  eligibility_criteria = []
}) {
  return (
    <div className="trialNotes trial-info">
      <div className="tn-header">
        <h2 className="poHeader tn-title" data-test="trialFacts">
          Trial Facts
        </h2>
        {permissions.crud_trials ? (
          <Link to={`/trial/${currentTrial}/edit`} data-test="redirectEdit">
            <div className="mdForHeaderAndArrow__btn tn-btn">Edit</div> 
          </Link>
        ) : (
          <div className="mdForHeaderAndArrow__btn tn-btn-dissabled">Edit</div> 
        )
        }
        
      </div>
      <div id="trials-notes-id">
        <div className="trialFactsInfoItems" data-test="trialInfoItems">
          <PhaseItem phase={phase} />
          <LengthStudyItem study_length={study_length} />
          <StartDateItem start_date={start_date} />
          <DiseaseOrConItem procedures={procedures} />
          <DrugTypeItem drug_type={drug_type} />
          <DrugAdminItem drug_admin={drug_admin} />
        </div>
        <DrugVersusPlacebo drugVersusPlacebo={drugVersusPlacebo} />
        <div className="ntw__tf-sliderWrapp rec-goal-info">
          <span className="ntw__ti-inputlabel">Recruitment Goal</span>
          <ReactSlider min={0} max={75} disabled value={goal} withBars>
            <div className="numberBlock numberBlockInfo">{goal}</div>
          </ReactSlider>
        </div>
        <div className="ntw__tf-sliderWrapp rec-goal-info">
          <span className="ntw__ti-inputlabel">Minimum match percentage</span>
          <ReactSlider min={0} max={100} disabled value={min_match} withBars>
            <div className="numberBlock numberBlockInfo">{min_match}</div>
          </ReactSlider>
        </div>
        <div className="filters-wrapper" data-test="filters">
          <Filters
            minAge={age_min}
            maxAge={age_max}
            substance={substance_status}
            smoking={smoking_status}
            gender={gender}
          />
        </div>
        <CriteriaInformationBlock
          globalText={globalText}
          eligibility_criteria={eligibility_criteria}
        />
      </div>
    </div>
  );
}

TrialInfo.propTypes = {
  currentTrial: PropTypes.string,
  phase: PropTypes.arrayOf(PropTypes.number),
  study_length: PropTypes.number,
  start_date: PropTypes.string,
  procedures: PropTypes.array,
  drug_type: PropTypes.string,
  drug_admin: PropTypes.string,
  drugVersusPlacebo: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      percentage: PropTypes.string
    })
  ),
  goal: PropTypes.number,
  min_match: PropTypes.number,
  age_min: PropTypes.number,
  age_max: PropTypes.number,
  substance_status: PropTypes.bool,
  smoking_status: PropTypes.bool,
  gender: PropTypes.string,
  globalText: PropTypes.string,
  eligibility_criteria: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          classification: PropTypes.string,
          criteria: PropTypes.string,
          fakeId: PropTypes.string,
          frequency: PropTypes.string,
          include: PropTypes.bool,
          name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
          ]),
          snomed: PropTypes.string,
          status: PropTypes.string
        })
      ),
      fakeId: PropTypes.number,
      freeText: PropTypes.string
    })
  )
};

const connectedPage = connect(mapStateToProps, null)(TrialInfo)
export default connectedPage
