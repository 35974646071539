import React from "react";
import "./index.css";
import spinn from "./../../../images/spinner.gif";

const FullScreenLoader = () => {
  return (
    <div className="fullScreenLoader">
      <img src={spinn} className="fullScreenLoader-spinner" alt="spin" />
    </div>
  );
};
export default FullScreenLoader;
