import React from 'react'
import { NavLink } from 'react-router-dom'
import './index.css'
import { withRouter } from 'react-router'

const Nav = (permissions) => {
  console.log("Here in NAV ", permissions)
  return (
    <div className='nav'>
      <ul className='nav__items'>
        <li className='nav__item'>
          <NavLink to='/settings/notification' activeClassName='active'>
            <div className='navIcon settingsIcon' />
            Notification Settings
          </NavLink>
        </li>
        <li className='nav__item'>
          <NavLink to='/settings/users' activeClassName='active'>
            <div className='navIcon usersIcon' />
            Users
          </NavLink>
        </li>
        <li className='nav__item'>
          <NavLink to='/settings/permission' activeClassName='active'>
            <div className='navIcon rolesAndPermissionIcon' />
            Roles & Permissions
          </NavLink>
        </li>
        <li className='nav__item'>
          <NavLink to='/settings/profile' activeClassName='active'>
            <div className='navIcon myProfileIcon' />
            My Profile
          </NavLink>
        </li>
        {permissions.permissions && (
          <li className='nav__item'>
            <NavLink to='/settings/import' activeClassName='active'>
              <div className='navIcon patientImport' />
              Import
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}
export default withRouter(Nav)
