export const LOAD_TRIALS_SUCCESS = "LOAD_TRIALS_SUCCESS";
export const LOAD_TRIALS_OPTIONS_SUCCESS = "LOAD_TRIALS_OPTIONS_SUCCESS";
export const LOAD_PHYSICIANS_OPTIONS_SUCCESS = "LOAD_PHYSICIANS_OPTIONS_SUCCESS";
export const LOAD_SITES_OPTIONS_SUCCESS = "LOAD_SITES_OPTIONS_SUCCESS";
export const CURRENT_TRIAL = "CURRENT_TRIAL";
export const CURRENT_NAVIGATION_LINK = "CURRENT_NAVIGATION_LINK";
export const CURRENT_NAVIGATION_SUB_LINK = "CURRENT_NAVIGATION_SUB_LINK";
export const CURRENT_MODULE = "CURRENT_MODULE";
export const TRIAL_DESCRIPTION_SUCCESS = "TRIAL_DESCRIPTION_SUCCESS";
export const LOG_IN_OUT = "LOG_IN_OUT";
export const SET_FAR_LABEL = "SET_FAR_LABEL";
export const SET_DISEASES_LABEL = "SET_DISEASES_LABEL";
export const SET_PROCEDURES_LABEL = "SET_PROCEDURES_LABEL";
export const SET_MEDICATIONS_LABEL = "SET_MEDICATIONS_LABEL";
export const SEND_SMS = "SEND_SMS";
export const SET_CHANGE_EMAIL_POPUP = "SET_CHANGE_EMAIL_POPUP";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const RE_SEND_SMS = "RE_SEND_SMS";
export const TRIAL_CANDIDATES_SUCCESS = "TRIAL_CANDIDATES_SUCCESS";
export const TRIAL_MATCHES_SUCCESS = "TRIAL_MATCHES_SUCCESS";
export const SET_GLOBAL_SPINNER = "SET_GLOBAL_SPINNER";
export const LOAD_PATIENT_SUCCESS = "LOAD_PATIENT_SUCCESS";
export const DISEASES_SUCCESS = "DISEASES_SUCCESS";
export const MEDICATIONS_SUCCESS = "MEDICATIONS_SUCCESS";
export const NEW_MEDICATIONS_SUCCESS = "NEW_MEDICATIONS_SUCCESS";
export const LABNAMES_SUCCESS = "LABNAMES_SUCCESS";
export const LABUOMS_SUCCESS = "LABUOMS_SUCCESS";
export const LAB_LIST_SUCCESS = "LAB_LIST_SUCCESS";
export const PROCEDURES_SUCCESS = "PROCEDURES_SUCCESS";
export const LOAD_RETIRED_TRIALS_SUCCESS = "LOAD_RETIRED_TRIALS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_PENDING_USERS_SUCCESS = "GET_PENDING_USERS_SUCCESS";
export const VIEW_NAV_BAR = "VIEW_NAV_BAR";
export const TOGGLE_MODAL_GENERIC = "TOGGLE_MODAL_GENERIC";
export const LOAD_TRIALS_MATCHES = "LOAD_TRIALS_MATCHES";
export const LOAD_TRIALS_CANDIDATES = "LOAD_TRIALS_CANDIDATES";
export const UPDATE_TRIAL = "UPDATE_TRIAL";
export const IS_FETCHING = "IS_FETCHING";
export const PROFILE_PHOTO = "PROFILE_PHOTO";
export const USER_PHOTO = "USER_PHOTO";
export const NEW_USER_PHOTO = "NEW_USER_PHOTO";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const CURRENT_HADMID = "CURRENT_HADMID";
export const PATIENTS_RESULT = "PATIENTS_RESULT";
export const PATIENTS_FILTER_RESULT_START = "PATIENTS_FILTER_RESULT";
export const PATIENTS_FILTER_RESULT_SUCCESS = 'PATIENTS_FILTER_RESULT_SUCCESS';
export const PATIENTS_FILTER_RESULT_ERROR = 'PATIENTS_FILTER_RESULT_ERROR';
export const PATIENT_OVERVIEW_START = 'PATIENT_OVERVIEW_START';
export const PATIENT_OVERVIEW_SUCCESS = "PATIENT_OVERVIEW_SUCCESS";
export const PATIENT_OVERVIEW_ERROR = 'PATIENT_OVERVIEW_ERROR';
export const GLOBAL_STATE = "GLOBAL_STATE";
export const LOAD_SEARCH_SUCCESS = "LOAD_SEARCH_SUCCESS";
export const SET_SEARCH_SUCCESS = "SET_SEARCH_SUCCESS";
export const GET_SAVED_SEARCHES_SUCCESS = "GET_SAVED_SEARCHES_SUCCESS";
export const LABWITHUOM_SUCCESS = "LABWITHUOM_SUCCESS";
export const SEND_EMAIL_NOTIFICATION = "SEND_EMAIL_NOTIFICATION";
export const GET_EXCLUSION_REASONS_SUCCESS = "GET_EXCLUSION_REASONS_SUCCESS";
export const GET_LAST_STAGES_SUCCESS = "GET_LAST_STAGES_SUCCESS";
export const GEO_DATA = "GEO_DATA";
export const WORKFLOW_STUDY_ID = "WORKFLOW_STUDY_ID";
export const LOAD_ACTIONS_SUCCESS = "LOAD_ACTIONS_SUCCESS";
export const SHOW_PICKER = "SHOW_PICKER";
export const SET_RESPONSIBLE_NAMES = "SET_RESPONSIBLE_NAMES";
export const GET_MEDICATIONS_ROOT_SUCCESS = "GET_MEDICATIONS_ROOT_SUCCESS";
export const SET_MEDICATIONS_ROOT_SUCCESS = "SET_MEDICATIONS_ROOT_SUCCESS";
export const GET_DISEASES_ROOT_SUCCESS = "GET_DISEASES_ROOT_SUCCESS";
export const SET_REMINDERS = "SET_REMINDERS";
export const SET_OTHER_REMINDERS = "SET_OTHER_REMINDERS";
export const GET_PROCEDURES_ROOT_SUCCESS = "GET_PROCEDURES_ROOT_SUCCESS";
export const SET_MEDICATIONS_ATC = "SET_MEDICATIONS_ATC";
export const SET_MEDICATIONS_EPC = "SET_MEDICATIONS_EPC";
export const SET_MEDICATIONS_MOA = "SET_MEDICATIONS_MOA";
export const SET_MEDICATIONS_ATC_LIST = "SET_MEDICATIONS_ATC_LIST";
export const SET_MEDICATIONS_EPC_LIST = "SET_MEDICATIONS_EPC_LIST";
export const SET_MEDICATIONS_MOA_LIST = "SET_MEDICATIONS_MOA_LIST";
export const SET_DISEASES = "SET_DISEASES";
export const SET_DISEASES_LIST = "SET_DISEASES_LIST";
export const SET_PROCEDURES = "SET_PROCEDURES";
export const SET_PROCEDURES_LIST = "SET_PROCEDURES_LIST";
export const SET_ALLERGIES_LIST = "SET_ALLERGIES_LIST";
// new types
export const IS_FETCHING_LOGIN = "IS_FETCHING_LOGIN";
export const SET_POPUP = "SET_POPUP";
export const GET_ALL_SUBSTANCES = "GET_ALL_SUBSTANCES";
export const GET_ALL_SUBSTANCES_SEARCH = "GET_ALL_SUBSTANCES_SEARCH";
export const GET_PHYSICIANS = "GET_PHYSICIANS";
export const GET_PHYSICIANS_SEARCH = "GET_PHYSICIANS_SEARCH";
export const GET_SMOKING = "GET_SMOKING";
export const GET_ROOT_MEDICATIONS_PICKER = "GET_ROOT_MEDICATIONS_PICKER";
export const GET_ROOT_DISEASES_PICKER = "GET_ROOT_DISEASES_PICKER";
export const GET_ROOT_PROCEDURES_PICKER = "GET_ROOT_PROCEDURES_PICKER";
export const SET_DISEASES_DO = "SET_DISEASES_DO";
export const SET_PROCEDURES_ICD9 = "SET_PROCEDURES_ICD9";
export const SET_PROCEDURES_ICD10 = "SET_PROCEDURES_ICD10";
export const SET_DISEASES_ICD9CM = "SET_DISEASES_ICD9CM";
export const SET_DISEASES_ICD10CM = "SET_DISEASES_ICD10CM";
export const SET_DISEASES_DO_LIST = "SET_DISEASES_DO_LIST";
export const SET_PROCEDURES_ICD9_LIST = "SET_PROCEDURES_ICD9_LIST";
export const SET_PROCEDURES_ICD10_LIST = "SET_PROCEDURES_ICD10_LIST";
export const SET_DISEASES_ICD9CM_LIST = "SET_DISEASES_ICD9CM_LIST";
export const SET_DISEASES_ICD10CM_LIST = "SET_DISEASES_ICD10CM_LIST";
export const GET_RECENT_SEARCHES_SUCCESS = "GET_RECENT_SEARCHES_SUCCESS";
export const SET_RECENT_SEARCHES_RELOAD = "SET_RECENT_SEARCHES_RELOAD";

export const SET_FULL_CHART_NAME = "SET_FULL_CHART_NAME";
export const SET_ALLERGIES_LEAFS = "SET_ALLERGIES_LEAFS";
export const SET_ALLERGIES = "SET_ALLERGIES";


export const GET_PERMISSIONS = "GET_PERMISSIONS"
