import * as types from './actionTypes';
import { push } from 'react-router-redux';

import InmunityApi from '../api/InmunityApi';

export function loadSitesOptionsSuccess(sitesOptions) {
	return { type: types.LOAD_SITES_OPTIONS_SUCCESS, sitesOptions};
}

export function loadSites() {
  return dispatch => {
    return InmunityApi.getDashboardSites()
    .then(sites => {
      if(!sites.error) {
        dispatch(loadSitesOptionsSuccess(sites.map((s,i) => ({
					label:s.name,
					id:s.id,
					value:s.id,
				}))))
      }
    })
    .catch(error => {
      throw error;
    });
  }
}