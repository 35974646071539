import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import "./index.css";
import "create-react-class";
import { connect } from "react-redux";
import TrialInfoBlock from "./TrialInfoBlock";
import TrialFactsBlock from "./TrialFactsBlock";
import EligibilityCriteriaBlock from "./EligibilityCriteriaBlock";
import NavBarScroll from "./NavBarScroll";
import InmunityApi from "./../../../api/InmunityApi";
import cloneDeep from 'lodash/cloneDeep';
import {
  loadTrials,
  getTrialDescription,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  updateTrial,
  getMedicationsPicker,
  getDiseasesPicker
} from "./../../../actions/trialActions";
import spinn from "./../../../images/spinner.gif";
import { push } from "react-router-redux";
import store from "./../../../store";
import _ from "lodash";

class NewTrial extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollPositionY: 0,
      color: "#f44336",
      name: "",
      initialName: "",
      nameError: false,
      nct: "",
      nctError: false,
      long_description: "",
      long_descriptionError: false,
      brief_description: "",
      brief_descriptionError: false,
      sponsor: "",
      sponsorError: false,
      principal_investigator: "",
      principal_investigator_default: {},
      principal_investigatorError: false,
      phase: [],
      phaseError: false,
      study_length: 1,
      start_date: "",
      start_dateError: false,
      drug_type: "",
      drug_admin: "",
      procedures: [],
      study_arms: [],
      goal: 10,
      gender: "",
      genderError: false,
      age_min: 18,
      age_max: 35,
      smoking_status: false,
      substance_status: false,
      medication_allergy: [],
      min_match: 86,
      message: "",
      criteria: [],
      saveSuccess: "",
      showSaveTrial: false,
      currentTrial: "",
      freeTextArray: [],
      validError: false,
      eligibility_criteria: [],
      eligibilityNewFreeText: [],
      spinner: false,
      showWarningPopup: false,
      list: "",
      allData: [],
      active: 0,
      changed: false,
      defaultState: {}
    };
    this.targetWidth = 0;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];

    this.fetchTrialDescription(currentTrial, true);

    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial || "all");

    setTimeout(() => {
      this.targetWidth = document.querySelector(".ntw__mainWrapp-rs")
        ? document.querySelector(".ntw__mainWrapp-rs").offsetWidth
        : null;
      window.addEventListener("scroll", this.handleScroll, true);
    }, 100);
    this.setState({ currentTrial });

    this.getRootDis();
    this.getRootMeds();
  }

  getRootMeds = () => {
    const { medicationsPicker } = this.props;
    medicationsPicker.length === 0 && getMedicationsPicker();
  };

  getRootDis = () => {
    const { medicationsPicker } = this.props;
    medicationsPicker.length === 0 && getDiseasesPicker();
  };

  fetchTrialDescription = (currentTrial, initial = false) => {
    if (parseFloat(currentTrial)) {
      if (initial) this.setState({ spinner: true });
      store.dispatch(getTrialDescription(currentTrial));
      InmunityApi.getTrialDescription(currentTrial).then(res => {
        let study_arms = [];
        let procedures = [];
        let defaultState = cloneDeep(res)
        res.study_arms.map((item, index) =>
          study_arms.push({ ...item, id: index })
        );
        res.procedures.map((item, index) =>
          procedures.push({ label: item, value: index })
        );
        this.setState({
          ...res,
          initialName: res.name,
          principal_investigator_default: {
            value: res.principal_investigator.id,
            label: res.principal_investigator.name
          },
          principal_investigator: res.principal_investigator.id,
          eligibility_criteria: this.setIdEligibilityCriteria(
            res.eligibility_criteria
          ),
          spinner: false,
          study_arms,
          procedures,
        })
        this.setState({defaultState: defaultState, changed: false});
      });
    } else {
      return;
    }
  };

  setIdEligibilityCriteria = array => {
    if (!array) return [];
    let newArray = [];
    array.forEach((el, index) => {
      el.fakeId = index;
      el.criteria.forEach((e, i) => {
        e.fakeId = el.fakeId.toString() + i.toString();
        if(e.criteria==="Medication" && !e.specific_arg){
          e.specific_arg = {
            toggle: false,
            from: new Date(),
            to: new Date(),
          }
        }
      });
      newArray.push(el);
    });
    return newArray;
  };

  handleScroll = e => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop >= 106) {
      if (document.querySelector(".ntw__mainWrapp-rs")) {
        document.querySelector(".ntw__mainWrapp-rs").style.width = `${this
          .targetWidth - 52}px`;
        document.querySelector(".ntw__mainWrapp-rs").classList.add("fixed");
      }
    } else {
      if (document.querySelector(".ntw__mainWrapp-rs")) {
        document.querySelector(".ntw__mainWrapp-rs").classList.remove("fixed");
      }
    }

    const scrollPositionY = +window.scrollY;
    localStorage.setItem("scrollPositionY", scrollPositionY);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  sendForm = newName => {
    const makeObject = ({
      message,
      scrollPositionY,
      criteria,
      freeTextArray,
      brief_descriptionError,
      eligibility_criteriaError,
      long_descriptionError,
      nameError,
      nctError,
      phaseError,
      principal_investigatorError,
      proceduresError,
      showSaveTrial,
      sponsorError,
      start_dateError,
      study_armsError,
      validError,
      drug_adminError,
      drug_typeError,
      genderError,
      smoking_statusError,
      study_lengthError,
      eligibilityNewFreeText,
      initialName,
      eligibilityNewFreeTextError,
      global_textError,
      showWarningPopup,
      allData,
      ...rest
    }) => rest;
    const object = makeObject(this.state);
    let sendObject;
    const { currentTrial, allData } = this.state;
    let sameInEx = this.sameInclusionExclusionCheck(allData);
    if (sameInEx.length > 0) {
      sameInEx = sameInEx.join(", ");
      this.setState({ showWarningPopup: true, list: sameInEx });
      return null;
    }
    sendObject = { ...object };
    sendObject.study_arms = sendObject.study_arms.filter(
      el => el.name !== "" && el.percentage !== ""
    );
    sendObject.study_arms = sendObject.study_arms.map(el => ({
      name: el.name,
      percentage: el.percentage
    }));
    sendObject.procedures = sendObject.procedures
      .map(el => el.label)
      .filter(el => el !== undefined);
    sendObject.eligibility_criteria = this.sourceTabsMaker(
      sendObject.eligibility_criteria
    );
    sendObject.phase.sort();
    // show spinner
    this.setState({
      saveSuccess: "Saving in progress. This may take a minute...",
      showSaveTrial: true,
      defaultState: sendObject,
      changed: false,
    });
    parseFloat(currentTrial) && !newName
      ? this.editTrial(currentTrial, sendObject)
      : this.createTrial(sendObject);
  };

  sameInclusionExclusionCheck = labs => {
    if (labs.length > 0) {
      let res = [];
      const mapCallback = (el, lab) => {
        if (el.name === lab.name && el.include !== lab.include) {
          return el.name;
        }
      };
      for (let lab of labs) {
        res = labs.map(el => mapCallback(el, lab));
      }
      res = Array.from(new Set(res));
      res = _.without(res, undefined);
      return res;
    } else {
      return [];
    }
  };

  sourceTabsMaker = array => {
    if (Array.isArray(array) && array.length > 0) {
      const { diseasesPickerRoot, proceduresPickerRoot, medicationsPicker } = this.props;
      array = array.map(el => ({
        ...el,
        criteria: el.criteria.map(e => {
          if (e.criteria === "Medication") {
            return {
              ...e,
              source:
                e.activeTab === 0
                  ? medicationsPicker[0].className
                  : e.activeTab === 1
                    ? diseasesPickerRoot[1].className
                    : diseasesPickerRoot[2].className
            };
          } else if (e.criteria === "Disease") {
            return {
              ...e,
              source:
                e.activeTab === 0
                  ? diseasesPickerRoot[0].className
                  : e.activeTab === 1
                    ? diseasesPickerRoot[1].className
                    : diseasesPickerRoot[2].className
            };
          } else if (e.criteria === "Procedure") {
            return {
              ...e,
              source:
                e.activeTab === 0
                  ? proceduresPickerRoot[0].className
                  : e.activeTab === 1
                  ? proceduresPickerRoot[1].className
                  : proceduresPickerRoot[2].className
            };
          } else {
            return e;
          }
        })
      }));
      return array;
    } else {
      return array;
    }
  };

  checkForEmptiness = () => {
    const {
      name,
      nct,
      long_description,
      brief_description,
      sponsor,
      principal_investigator,
      phase,
      start_date,
      gender
    } = this.state;
    let error = false;
    if (name === "") {
      this.setState({ nameError: true });
      error = true;
    }
    if (nct === "") {
      this.setState({ nctError: true });
      error = true;
    }
    if (long_description === "") {
      this.setState({ long_descriptionError: true });
      error = true;
    }
    if (brief_description === "") {
      this.setState({ brief_descriptionError: true });
      error = true;
    }
    if (sponsor === "") {
      this.setState({ sponsorError: true });
      error = true;
    }
    if (principal_investigator === "") {
      this.setState({ principal_investigatorError: true });
      error = true;
    }
    if (phase.length === 0) {
      this.setState({ phaseError: true });
      error = true;
    }
    if (start_date === "") {
      this.setState({ start_dateError: true });
      error = true;
    }
    if (gender === "") {
      this.setState({ genderError: true });
      error = true;
    }
    this.setState({ validError: error });
    return error;
  };

  getCriteria = (criteria, freeTextArray) => {
    this.setState({ criteria, freeTextArray });
  };

  editTrial = (trialId, obj) => {
    InmunityApi.editTrialTest(trialId, obj)
      .then(response => {
        // debugger;
        if (response.status !== 200) {
          return response.text().then(text => {
            if (
              text ===
              '{"name":["trial with this name already exists."]}'
            ) {
              this.setState({
                saveSuccess: `Trial with name ${obj.name} already exists.
              Please choose a different, unique trial name.`
              });
            } else {
              this.setState({
                saveSuccess: "An error occurred while saving"
              });
            }
            throw new Error(text);
          });
        }
        this.setState({
          saveSuccess: "Trial edited successfully"
        });
        store.dispatch(updateTrial(obj));
        store.dispatch(getTrialDescription(trialId));
        this.props.loadTrials();
        this.fetchTrialDescription(trialId);
      })
      .catch(err => {
        console.log("ERROR: ", err);
      });
  };

  createTrial = data => {
    const { loadTrials, relocate } = this.props;
    InmunityApi.createTrialTest(data)
      .then(response => {
        if (response.status !== 201) {
          return response.text().then(text => {
            if (
              text ===
              '{"name":["trial with this name already exists."]}'
            ) {
              this.setState({
                saveSuccess: `Trial with name ${data.name} already exists.
              Please choose a different, unique trial name.`,
                procedures: [],
                study_arms: []
              });
            } else if (
              text === '{"nct":["trial with this nct already exists."]}'
            ) {
              this.setState({
                saveSuccess: `Trial with nct ${data.nct} already exists.
              Please choose a different nct for this trial.`,
                procedures: [],
                study_arms: []
              });
            } else {
              this.setState({
                saveSuccess: "An error occurred while saving",
                procedures: [],
                study_arms: []
              });
            }
            throw new Error(text);
          });
        }

        this.setState({ saveSuccess: "New trial created successfully" }, () => {
          setTimeout(() => {
            loadTrials();
            relocate("/trials");
          }, 1000);
        });
      })
      .catch(err => console.log("error occured while creating: ", err));
  };

  setValuesFromDivs = (inputArray, outputArray, filter) => {
    inputArray.forEach(el => {
      if (el.value !== "") {
        if (filter === "procedures") {
          outputArray.push(el.value);
        } else {
          if (
            el.querySelector(".study-arm-name").value &&
            el.querySelector(".study-arm-value").value
          ) {
            let objArm = {};
            objArm.name = el.querySelector(".study-arm-name").value;
            objArm.percentage = el.querySelector(".study-arm-value").value;
            outputArray.push(objArm);
          } else {
            return "";
          }
        }
      }
    });
    return outputArray;
  };

  setInfo = (name, value) => {
    if (name === "eligibility_criteria" || name === "eligibilityNewFreeText") {
      value = this.setIdEligibilityCriteria(value);
    }
    this.setState({ [name]: value }, () => {
      if (name === "eligibility_criteria") {
        this.makeAllData(false);
        // this.setState({changed: true})
      } else if (name === "eligibilityNewFreeText") {
        this.makeAllData(true);
      }

      if (JSON.stringify(this.state.defaultState) !== '{}') {
        if ((this.state[name] !== "" || this.state[name].length > 0) && (this.state.defaultState[name] !== value )) {
          let nameError = `${name}Error`;
          this.setState({ [nameError]: false, validError: false });
          this.setState({changed: true})
        }
      }
    });
  };

  setList = list => {
    this.setState({ list });
  };

  openWarningPopup = () => {
    this.setState({ showWarningPopup: true });
  };

  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false });
  };

  makeAllData = freeText => {
    const { eligibilityNewFreeText, eligibility_criteria } = this.state;
    let allData;
    if (freeText) {
      allData = eligibilityNewFreeText.reduce(
        (result, current) => result.concat(...current.criteria),
        []
      );
    } else {
      allData = eligibility_criteria.reduce(
        (result, current) => result.concat(...current.criteria),
        []
      );
    }
    this.setState({ allData });
  };

  scrollHandler = ({ target }) => {
    const height = target.scrollTop;
    if (height >= 0 && height < 528) {
      this.setState({ active: 0 });
    } else if (height >= 528 && height < 1088) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 2 });
    }
  };

  render() {
    const {
      saveSuccess,
      showSaveTrial,
      color,
      currentTrial,
      name,
      initialName,
      nameError,
      nct,
      nctError,
      brief_description,
      brief_descriptionError,
      long_description,
      long_descriptionError,
      sponsor,
      sponsorError,
      principal_investigator_default,
      principal_investigatorError,
      study_length,
      start_date,
      start_dateError,
      drug_type,
      drug_admin,
      procedures,
      study_arms,
      goal,
      phase,
      phaseError,
      min_match,
      validError,
      genderError,
      eligibility_criteria,
      gender,
      age_min,
      age_max,
      smoking_status,
      substance_status,
      spinner,
      medication_allergy,
      eligibilityNewFreeText,
      global_text,
      showWarningPopup,
      list,
      allData,
      active
    } = this.state;
    return (
      <div className="content-body-matches matchDetailsWrapper newTrialWrapp">
        <div className="ntw__headerWrapp">
          <h1>{parseFloat(currentTrial) ? "Edit Trial" : "New Trial"}</h1>
        </div>
        {spinner ? (
          <div id="loading-candidates" className="loadingCandidates">
            <img className="big-spinner" src={spinn} alt="Spinner" />
          </div>
        ) : (
            <div className="ntw__mainWrapp">
              <div className="ntw__mainWrapp-ls" onScroll={this.scrollHandler}>
                <TrialInfoBlock
                  setInfo={this.setInfo}
                  defaultColor={color}
                  defaultName={name}
                  nameError={nameError}
                  defaultNCT={nct}
                  nctError={nctError}
                  defaultBrief={brief_description}
                  brief_descriptionError={brief_descriptionError}
                  defaultLongDescription={long_description}
                  long_descriptionError={long_descriptionError}
                  defaultSponsor={sponsor}
                  sponsorError={sponsorError}
                  defaultPI={principal_investigator_default}
                  principal_investigatorError={principal_investigatorError}
                />
                <TrialFactsBlock
                  setInfo={this.setInfo}
                  defaultSudyLength={study_length}
                  defaultStartDate={start_date}
                  start_dateError={start_dateError}
                  defaultDrugType={drug_type}
                  defaultDrugAdmin={drug_admin}
                  defaultProcedures={procedures}
                  defalutSudyArms={study_arms}
                  defaultGoal={goal}
                  defaultPhase={phase}
                  phaseError={phaseError}
                />
                <EligibilityCriteriaBlock
                  defaultGender={gender}
                  defaultAgeMin={age_min}
                  defaultAgeMax={age_max}
                  defaultSmokingStatus={smoking_status}
                  defaultSubstanceStatus={substance_status}
                  defaultMedicationAllergy={medication_allergy}
                  setInfo={this.setInfo}
                  genderError={genderError}
                  getCriteria={this.getCriteria}
                  eligibilityNewFreeText={eligibilityNewFreeText}
                  defaultEligibilityCriteria={eligibility_criteria}
                  defaultGlobalText={global_text}
                  allData={allData}
                  makeAllData={this.makeAllData}
                />
              </div>
              <div
                className="ntw__mainWrapp-rs forImportPopup"
                id="trials-details-id"
              >
                <NavBarScroll
                  name={name}
                  initialName={initialName}
                  setInfo={this.setInfo}
                  sendForm={this.sendForm}
                  successMsg={saveSuccess}
                  showSave={showSaveTrial}
                  activeSave={this.state.changed}
                  defaultMinMatch={min_match}
                  checkForEmptiness={this.checkForEmptiness}
                  validError={validError}
                  currentTrial={currentTrial}
                  showWarningPopup={showWarningPopup}
                  closeWarningPopup={this.closeWarningPopup}
                  list={list}
                  active={active}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    diseasesPickerRoot: state.diseasesPickerRoot,
    medicationsPicker: state.medicationsPicker,
    proceduresPickerRoot: state.proceduresPickerRoot,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrialDescription: id => dispatch(getTrialDescription(id)),
    loadTrials: () => dispatch(loadTrials()),
    relocate: url => dispatch(push(url)),
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getMedicationsPicker: () => dispatch(getMedicationsPicker()),
    getDiseasesPicker: () => dispatch(getDiseasesPicker())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewTrial)
);
