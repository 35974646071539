import React, { Component } from "react";
import InmunityApi from "../../../api/InmunityApi";
import "../../trials/index.css";
import i18n from "../../../i18n";
import { Reminders } from "./reminders";
import { Visits } from "./visits";
import { Form } from "./form";
import moment from "moment";
import { withRouter } from "react-router-dom";
import inmunityApi from "../../../api/InmunityApi";
class CalendarList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reminders: {
				list: [],
				next: "",
				count: 0,
			},
			visits: this.props.events,
			formOpen: [],
			tabActive: 1,
			active: false,
			modalVisible: false,
			isCreatePopup: true,
			currentReminderId: null,
			physicianTooltip: {},
			responsibleTooltip: {
				name: "",
				id: "",
			},
			patientIdTooltip: {
				immunity_id: "",
				hadm_id: "",
			},
			description: "",
			reminderDate: moment(),
			responsibleOptions: [],
			physicianOptions: [],
			query: this.props.query,
			isMore: false,
		};
	}

	componentDidMount() {
		InmunityApi.getDashboardReminders().then(res => {
			this.setState({
				reminders: {
					list: [...this.state.reminders.list, ...res.results],
					next: res.next,
					count: res.count,
				},
			});
		});
		InmunityApi.getAllPhysicians().then(physicianOptions => {
			this.setState({
				physicianOptions,
			});
		});
		InmunityApi.getAllHadmAndImmunityIds().then(hadmImmunityIdsOptions => {
			this.setState({
				hadmImmunityIdsOptions,
			});
		});
		InmunityApi.getResponsibleNames().then(responsibleOptions => {
			this.setState({
				responsibleOptions,
			});
		});
		if (
			this.props.history.location.params &&
			this.props.history.location.params.reminderId
		) {
			this.changeReminder(this.props.history.location.params.reminderId);
		}
	}

	onChangeTab = tab => {
		this.setState({ tabActive: tab });
	};
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.history.location.params &&
			prevProps.history.location.params.reminderId !==
				this.state.currentReminderId
		) {
			this.changeReminder(this.props.history.location.params.reminderId);
		}
	}

	sortCell = name => {
		if (name === this.state.query) {
			this.setState({ query: `-${name}` });
			InmunityApi.getDashboardReminders(null, `-${name}`).then(res => {
				this.setState({
					reminders: {
						list: res.results,
						next: res.next,
						count: res.count,
					},
				});
			});
		} else {
			this.setState({ query: name });
			InmunityApi.getDashboardReminders(null, name).then(res => {
				this.setState({
					reminders: {
						list: res.results,
						next: res.next,
						count: res.count,
					},
				});
			});
		}
	};
	closePopup = () => {
		this.setState({
			modalVisible: false,
			isCreatePopup: true,
			currentReminderId: null,
			physicianTooltip: {},
			responsibleTooltip: {
				name: "",
				id: "",
			},
			patientIdTooltip: {
				immunity_id: "",
				hadm_id: "",
			},
			description: "",
			reminderDate: moment(),
			responsibleOptions: [],
		});
		this.props.history.push("/dashboard");
	};
	openPopup = () => {
		this.setState({ modalVisible: true });
	};

	loadMore = () => {
		this.setState({ loadingNext: true });
		if (this.state.tabActive === 1) {
			this.setState({ loadingNext: true });
			this.props.events.next && this.props.getAllEvents(this.props.events.next.split("?")[1])
			this.setState({loadingNext: false})
		} else {
			this.setState({ loadingNext: true });

			this.state.reminders.next &&
				InmunityApi.getDashboardReminders(this.state.reminders.next).then(
					res => {
						this.setState({
							reminders: {
								list: [...this.state.reminders.list, ...res.results],
								next: res.next,
								count: res.count,
							},
							loadingNext: false,
						});
					}
				);
		}
	};

	infinityScroll = e => {
		if (
			!this.state.loadingNext &&
			e.currentTarget.scrollHeight - e.currentTarget.offsetHeight <=
				e.currentTarget.scrollTop
		) {
			this.loadMore();
		}
	};

	goToDetails = (hadmId, status) => {
		if (hadmId) {
			let { history } = this.props;
			let path
			switch (status) {
				case "CD":
					path = `/candidates/details/${hadmId}`
					break
				case "EX":
					path = `/exclusions/details/${hadmId}`
					break
				case "EN":
					path = `/enrolled/details/${hadmId}`
					break
				default:
					path = `/matches/details/${hadmId}`
					break
			}
			history.push({
				pathname: path,
				id: hadmId,
				detailTitle: "Candidate Details",
			});
		}
	};

	changeReminder = id => {
		InmunityApi.getPrepopulationOfReminder(id).then(res => {
			this.setState({
				modalVisible: true,
				isCreatePopup: false,
				currentReminderId: id,
				active: res.active,
				physicianTooltip: {
					name: res.physician.name,
					id: res.physician.id,
				},
				responsibleTooltip: {
					name: res.responsible.name,
					id: res.responsible.id,
				},
				patientIdTooltip: {
					immunity_id: res.immunity_id,
					hadm_id: res.hadm_id,
				},
				description: res.description,
				reminderDate: moment(res.date),
			});
		});
	};

	setFieldValue = (field, value) => {
		let newState = { ...this.state };
		newState[field] = value;
		this.setState(newState);
	};

	createReminder = () => {
		InmunityApi.createDashboardReminder({
			description: this.state.description,
			date: moment(this.state.reminderDate).format("MMM DD YYYY"),
			active: true,
			immunity_id: this.state.patientIdTooltip.immunity_id,
			physician_id: this.state.physicianTooltip.id,
			hadm_id: this.state.patientIdTooltip.hadm_id,
			responsible_id: this.state.responsibleTooltip.id,
		}).then(() => {
			InmunityApi.getDashboardReminders().then(res => {
				this.setState({
					reminders: {
						list: [...res.results],
						next: res.next,
						count: res.count,
					},
				});
			});
		});
		this.setState({
			modalVisible: false,
			isCreatePopup: false,
		});
	};

	editReminder = () => {
		InmunityApi.updateDashboardReminder(this.state.currentReminderId, {
			description: this.state.description,
			date: moment(this.state.reminderDate).utc().format("MMM DD YYYY"),
			active: true,
			physician_id: this.state.physicianTooltip.id,
			immunity_id: this.state.patientIdTooltip.immunity_id,
			hadm_id: this.state.patientIdTooltip.hadm_id,
			responsible_id: this.state.responsibleTooltip.id,
		}).then(() => {
			InmunityApi.getDashboardReminders().then(res => {
				this.setState({
					reminders: {
						list: [...res.results],
						next: res.next,
						count: res.count,
					},
				});
			});
		});
		this.setState({
			modalVisible: false,
			isCreatePopup: false,
		});
	};

	findPatientId = ({ target }) => {
		let patientIDs = [...this.state.hadmImmunityIdsOptions];
		if (target.value.length > 0 && patientIDs && patientIDs.length > 0) {
			let query = target.value;
			let result = patientIDs
				.filter(el => {
					return el.immunity_id.toString().startsWith(query);
				})
				.slice(0, 10);
			if (result.length > 0) {
				target.classList.remove("redBorder");
				target.onkeypress = function (e) {};
				this.setState({ patientIdTooltip: result, patientIdError: false });
			} else {
				result.length = 0;
				this.setState({ patientIdTooltip: result, patientIdError: true });
				target.classList.add("redBorder");
				target.onkeypress = function (e) {
					target.value.length !== 0 && e.preventDefault();
				};
			}
		} else {
			this.setState({ patientIdTooltip: [], patientIdError: true });
			target.classList.add("redBorder");
		}
	};

	findResponsibleName = ({ target }) => {
		let responsibleNames = this.state.responsibleOptions;
		if (
			target.value.length > 0 &&
			responsibleNames &&
			responsibleNames.length > 0
		) {
			let query = target.value.toLowerCase();
			let result = responsibleNames
				.filter(el => {
					return el.name.toLowerCase().startsWith(query);
				})
				.slice(0, 10);
			if (result.length > 0) {
				target.classList.remove("redBorder");
				target.onkeypress = function (e) {};
				this.setState({ responsibleTooltip: result, responsibleError: false });
			} else {
				result.length = 0;
				this.setState({ responsibleTooltip: result, responsibleError: true });
				target.classList.add("redBorder");
				target.onkeypress = function (e) {
					target.value.length !== 0 && e.preventDefault();
				};
			}
		} else {
			this.setState({ responsibleTooltip: [], responsibleError: true });
			target.classList.add("redBorder");
		}
	};

	responsiblePicked = (obj, whereToFill) => {
		whereToFill.value = obj.name;
		this.setState({ responsibleTooltip: obj });
	};

	findPhysicianName = ({ target }) => {
		let physicianNames = [...this.state.physicianOptions];
		if (
			target.value.length > 0 &&
			physicianNames &&
			physicianNames.length > 0
		) {
			let query = target.value.toLowerCase();
			let result = physicianNames
				.filter(el => {
					return el.name.toLowerCase().startsWith(query);
				})
				.slice(0, 10);
			if (result.length > 0) {
				target.classList.remove("redBorder");
				target.onkeypress = function (e) {};
				this.setState({ physicianTooltip: result, physicianNameError: false });
			} else {
				result.length = 0;
				this.setState({ physicianTooltip: result, physicianNameError: true });
				target.classList.add("redBorder");
				target.onkeypress = function (e) {
					target.value.length !== 0 && e.preventDefault();
				};
			}
		} else {
			this.setState({ physicianTooltip: [], physicianNameError: true });
			target.classList.add("redBorder");
		}
	};
	physicianPicked = (obj, whereToFill) => {
		whereToFill.value = obj.name;
		this.setState({ physicianTooltip: obj });
	};
	patientPicked = (obj, whereToFill) => {
		whereToFill.value = obj.immunity_id;
		this.setState({ patientIdTooltip: obj });
	};

	deleteReminder = () => {
		InmunityApi.deleteReminder(this.state.currentReminderId).then(res => {
			this.props.getAllReminders();
			this.setState({
				modalVisible: false,
			});
		});
	};

	render() {
		return (
			<div className="calendar-list-container forImportPopup newTrialWrapp">
				<h3 className="title-section">{i18n.t("calendarList.calendarList")}</h3>
				<div className="prescreening-data">
					<div className="calendar-list-tabs">
						<div className="tabs-calendar-list">
							<div
								onClick={() => this.onChangeTab(1)}
								className={
									this.state.tabActive === 1
										? "calendar-list-tab tab-active"
										: "calendar-list-tab"
								}
							>
								{i18n.t("calendarList.patientVisits")}
							</div>

							<div
								onClick={() => this.onChangeTab(2)}
								className={
									this.state.tabActive === 2
										? "calendar-list-tab tab-active"
										: "calendar-list-tab"
								}
							>
								{i18n.t("calendarList.reminders")}
							</div>
						</div>
					</div>
					<div className="calendar-list-table" onScroll={this.infinityScroll}>
						{this.state.tabActive === 1 && (
							<Visits
							visits={this.props.events}
							redirect={this.goToDetails} />
							)}
						{this.state.tabActive === 2 && (
							<Reminders
								reminders={this.state.reminders.list}
								changeReminder={this.changeReminder}
								currentSort={this.state.currentSort}
								sortCell={this.sortCell}
							/>
						)}
					</div>
					<div className="plusBtnCalLV" onClick={this.openPopup}>
						<div className="non-rotatable">+</div>
					</div>
				</div>
				<Form
					modalVisible={this.state.modalVisible}
					createReminder={this.createReminder}
					closePopup={this.closePopup}
					isCreatePopup={this.state.isCreatePopup}
					description={this.state.description}
					reminderDate={this.state.reminderDate}
					responsibleTooltip={this.state.responsibleTooltip}
					findResponsibleName={this.findResponsibleName}
					responsiblePicked={this.responsiblePicked}
					findPatientId={this.findPatientId}
					findPhysicianName={this.findPhysicianName}
					physicianTooltip={this.state.physicianTooltip}
					setFieldValue={this.setFieldValue}
					editReminder={this.editReminder}
					physicianPicked={this.physicianPicked}
					patientIdTooltip={this.state.patientIdTooltip}
					patientPicked={this.patientPicked}
					active={this.state.active}
					deleteReminder={this.deleteReminder}
				/>
			</div>
		);
	}
}

export default withRouter(CalendarList);
