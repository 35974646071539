import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function diseasesReducer(state = initialState.diseases, action) {
  switch(action.type) {
    case types.DISEASES_SUCCESS:
      return action.diseases
    default:
      return state;
  }
}
