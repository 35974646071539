import React from "react";
// import Comorbidities from './Comorbidities'
import Ethnicity from "./Ethnicity";
import AgeDistribution from "./AgeDistribution";
import SexDistribution from "./SexDistribution";

export default function HospitalGraphs({
  additionalData,
  spinner
}) {
  return (
    <div className="patients_graphs_wrapp">
      {/* <Comorbidities /> */}
      <Ethnicity data={additionalData.ethnicity} spinner={spinner} />
      <AgeDistribution data={additionalData.age} spinner={spinner} />
      <SexDistribution data={additionalData.gender} spinner={spinner} />
    </div>
  );
}
