import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function loaderReducer (state = initialState.loader, action) {
  switch (action.type) {
    case types.IS_FETCHING_LOGIN:
      return { ...state, login_loader: action.isFetching }
    default:
      return state
  }
}
