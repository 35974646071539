import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getExclusionReasonsReducer(
  state = initialState.exclusionReasons,
  action
) {
  switch (action.type) {
    case types.GET_EXCLUSION_REASONS_SUCCESS:
      return action.exclusionReasons;
    default:
      return state;
  }
}
