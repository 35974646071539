import React from "react";
import study_start_date from "./../../../../../images/study_start_date.svg";
import moment from "moment";

export default function StartDateItem({ start_date }) {
  return (
    <div className="infoItem">
      <img src={study_start_date} alt="Study start date" />
      <div className="tfc-tc">
        <div className="tfc-title">Study start date</div>
        <div className="tfc-content">
          <span data-test="studyStartDate">
            {start_date
              ? moment(start_date).format("MMM DD YYYY")
              : "no data"}
          </span>
        </div>
      </div>
    </div>
  );
}
