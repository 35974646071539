import React, { Fragment, useState } from "react";
import Popup from "reactjs-popup";
import store from "./../../../../../store";
import crossicon from "./../../../../../images/icon-close-w.svg";
import InmunityApi from "./../../../../../api/InmunityApi";
import {
	loadTrials,
	getTrialDescription,
} from "../../../../../actions/trialActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const RetireUnretire = ({ trial: { status, id, can_delete }, history, permissions }) => {
	const openRetireDeleteWrapp = () => {
		setShowDeleteRetire(false)
		setShowDeleteRetire(!showDeleteRetire);
	};
	const closeRetirePopup = () => {
		setShowDeleteRetire(false)
		setRetirePopup(false);
	};
	const [showDeleteRetire, setShowDeleteRetire] = useState(false);
	const [retirePopup, setRetirePopup] = useState(false);
	const [retiringMessage, setRetiringMessage] = useState("");
	const [isDelete, toggleDelete] = useState(false);
	const toggleConfirmPopup = bool => {
		setRetiringMessage("");
		setRetirePopup(bool);
		toggleDelete(bool);
	};
	const moveWithTrial = text => {
		setRetirePopup(true);
		setRetiringMessage("spinner");
		toggleDelete(false);
		if (text === "delete" && permissions.crud_trials) {
			InmunityApi.deleteTrialTest(id)
				.then(resolve => {
					if (resolve.status === 400) {
						resolve.json().then(e => {
							setRetiringMessage(e.message)
						})
						throw resolve;
					}
					else if (resolve.status !== 204) {
						setRetiringMessage("An error occurred while deleting a trial");
						throw resolve;
					}
					setRetiringMessage("Trial was deleted successfully");
					store.dispatch(loadTrials());
					setTimeout(() => {
						history.push({ pathname: "/trials" });
					}, 1000);
				})
				.catch(err => {
					console.log("ERROR WHILE DELETING A TRIAL: ", err);
				});
		} else if (text === "retire") {
			InmunityApi.retireTrialTest(id)
				.then(resolve => {
					if (resolve.status !== 200) {
						setRetiringMessage("An error occurred while retiring a trial");
						throw resolve;
					}
					setRetiringMessage("Trial was retired successfully");
					store.dispatch(loadTrials())
					store.dispatch(getTrialDescription(id))
				})
				.catch(err => {
					console.log("ERROR WHILE RETIRING A TRIAL: ", err);
				});
		} else {
			InmunityApi.unretireTrialTest(id)
				.then(resolve => {
					if (resolve.status !== 200) {
						setRetiringMessage("An error occurred while unretiring a trial");
						throw resolve;
					}
					setRetiringMessage("Trial was unretired successfully");
					store.dispatch(loadTrials());
					store.dispatch(getTrialDescription(id));
				})
				.catch(err => {
					console.log("ERROR WHILE UNRETIRING A TRIAL: ", err);
				});
		}
	};
	return (
		<Fragment>
			<div className="threeDotBtn" onClick={openRetireDeleteWrapp}>
				<div className="circle" />
				<div className="circle" />
				<div className="circle" />
			</div>
			<div
				className={
					showDeleteRetire ? "retireDeleteWrapp" : "retireDeleteWrapp hidden"
				}
			>
				<div className="retireDeleteWrappTriangle" />
				{status !== "a" && (
					<div className="text" onClick={() => moveWithTrial("unretire")}>
						Unretire
					</div>
				)}
				{status === "a" && (
					<div className="text" onClick={() => moveWithTrial("retire")}>
						Retire
					</div>
				)}
				{permissions.crud_trials && can_delete ? (
					<div className="text" onClick={() => { toggleConfirmPopup(true); }} >
						Delete Trial
					</div>
				) : (
					<div className="text-dissabled">
						Delete Trial
					</div>
				)}
			</div>
			<Popup
				open={retirePopup}
				closeOnDocumentClick
				onClose={closeRetirePopup}
				className="saveUserPopup"
			>
				<div>
					<div className="closePendingPopupCross" onClick={closeRetirePopup}>
						<img src={crossicon} alt="Cross" />
					</div>
					{!retiringMessage && isDelete && (
						<>
							<center >
								<h2>Are you sure to delete this trial</h2>
							</center>
							<div className="wrapForRsBtn">
								<div className="edit_btns_wrapp">
									<button
										style={{ width: "40%" }}
										className="ntw__headerWrapp-btn"
										onClick={() => moveWithTrial("delete")}
									>
										Yes
									</button>
									<button
										style={{ width: "40%" }}
										className="ntw__headerWrapp-btn ntw__headerWrapp-btn-cancel"
										onClick={() => toggleConfirmPopup(false)}
									>
										NO
									</button>
								</div>
							</div>
						</>
					)}
					{retiringMessage === "spinner" ? (
						<h1 className="header-spinner">
							<img
								className="small-spinner"
								src={require("./../../../../../images/spinner.gif")}
								alt=""
							/>
						</h1>
					) : (
						<div className="saveUserPopupText">{retiringMessage}</div>
					)}
				</div>
			</Popup>
		</Fragment>
	);
};

function mapStateToProps(state) {
	return {
		trial: state.trial,
		permissions: state.permissions
	};
}

export default withRouter(connect(mapStateToProps)(RetireUnretire));
