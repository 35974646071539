import React, { PureComponent } from 'react'
import Popup from 'reactjs-popup'
import crossicon from '../../images/icon-close-w.svg'
import spinner from '../../images/spinner.gif'
import User from './User'
import PendingUser from './PendingUser'
import './index.css'
import InmunityApi from '../../api/InmunityApi'
import checkPermission from '../../utils/checkPermission'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const AdminPendingRequests = ({ showPendingPopup, len }) => {
  return (
    checkPermission() &&
    <div className='pending' onClick={ len > 0 ? showPendingPopup : () => {}}>
      <span>{len} Pending Requests</span>
      <div className='pending-circle' />
    </div>
  )
}
class Users extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      pendingPopup: false,
      users: [],
      pendingUsers: [],
      loading: false
    }
  }

  showPendingPopup = () => {
    let pendingPopup = true
    this.setState({ pendingPopup })
  }

  closePendingPopup = () => {
    let pendingPopup = false
    this.setState({ pendingPopup })
  }

  redirectToRoles = () => {
    this.props.history.push({
      pathname: '/settings/permission',
      fromPending: true
    })
  }

  updateList = () => {
    this.setState({ loading: true })
    InmunityApi.getPendingUsers()
      .then(res => {
        let pendingUsers = []
        res.forEach(el => {
          pendingUsers.push(
            <PendingUser
              firstName={el.first_name}
              lastName={el.last_name}
              email={el.email}
              phone1={el.phone1}
              phone2={el.phone2}
              id={el.user_id}
              data={el.request_data ? el.request_data : '-'}
              updateList={this.updateList}
            />
          )
        })
        this.setState({
          pendingUsers,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          pendingUsers: [],
          loading: false
        })
        // throw new Error(error)
      })
  }

  fetchUsers = () => {
    const getUsers = InmunityApi.getAllUsers()
    // const getOnline = InmunityApi.getOnlineUsers()
    Promise.all([getUsers]).then(res => {
        let users = []
        res[0].forEach(el => {
          users.push(
            <User
              firstName={el.firstName}
              lastName={el.lastName}
              email={el.email}
              photo={el.photo}
              register={el.register}
              role={el.role}
              id={el.id}
              online={false}
            />
          )
        })
        this.setState({
          users
        })
      })
      .catch(error => {
        this.setState({
          pendingUsers: [],
          loading: false
        })
        // throw new Error(error)
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.location.showPopup) {
      this.setState({ pendingPopup: true })
    }

    this.setState({ loading: true })
    this.fetchUsers()
    InmunityApi.getPendingUsers()
      .then(res => {
        let pendingUsers = []
        res.forEach(el => {
          pendingUsers.push(
            <PendingUser
              firstName={el.first_name}
              lastName={el.last_name}
              email={el.email}
              phone1={el.phone1}
              phone2={el.phone2}
              id={el.user_id}
              data={el.request_data ? el.request_data : '-'}
              updateList={this.updateList}
              fetchUsers={this.fetchUsers}
            />
          )
        })
        this.setState({
          pendingUsers,
          loading: false
        })
      })
      .catch(error => {
        // throw new Error(error)
      })
  }

  render() {
    const { pendingUsers, pendingPopup, loading, users } = this.state
    return (
      <div className='profile'>
        <div className='user_head'>
          <h1 className='profile__title'>Users</h1>
          <AdminPendingRequests
            len={pendingUsers.length}
            showPendingPopup={this.showPendingPopup}
          />

          <Popup
            open={pendingPopup}
            closeOnDocumentClick
            onClose={this.closePendingPopup}
            className='popup-pendingReq'
          >
            <div>
              <div className='pendingReq__container'>
                <h1>Pending User Requests</h1>
                {loading ? (
                  <div className='spinner-center'>
                    <img
                      className='medium-spinner'
                      src={require('../../images/spinner.gif')}
                      alt='Big Spinner'
                    />
                  </div>
                ) : (
                    <div className='pendingUsersBlock'>
                      {pendingUsers.map((component, i) => {
                        return (
                          <div className='marginBottom26' key={i}>
                            {component}
                          </div>
                        )
                      })}
                    </div>
                  )}
                {!loading && pendingUsers.length === 0 ? (
                  <div className='noPendUsers'>No pending users</div>
                ) : (
                    ''
                  )}
              </div>
              <div className='more-info-roles' onClick={this.redirectToRoles}>
                More information on Roles & Permissions
              </div>
              <div
                className='closePendingPopupCross'
                onClick={this.closePendingPopup}
              >
                <img src={crossicon} alt='Cross' />
              </div>
            </div>
          </Popup>
        </div>

        {users.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={spinner} height='50px' alt='spinner' />
          </div>
        )}

        {users.length !== 0 && (
          <div className='users-wrapper'>
            {users.map((component, i) => {
              return (
                <div className='users-wrapper__item' key={i}>
                  {component}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken
  }
}

export default withRouter(connect(mapStateToProps)(Users))
