import React from 'react'
import Popup from 'reactjs-popup'
import { Collapse } from "react-collapse";
import classNames from "classnames";
import i18n from '../../i18n'
import ContactInfo from './ContactInfo'
import '../styles/TopBar.css'
import ImmunityApi from './../../api/InmunityApi'
import 'font-awesome/css/font-awesome.min.css';
import { Spinner } from '../../ui'
export default class Contacts extends React.Component {
  state = {
    contacts: [],
    contact: {},
    contactSearch: '',
    newContactPopup: false,
    create: false,
    loading: false,
    isMore: null,
    link: null,
    contactsLength: 0,
    noContacts: false,
    activeIndex: null,
    allOpen: false,
  }

  componentDidMount () {
    this.mounted = true
    this.setState({ loading: true })
    this.fetchData(this.mounted)
  }

  toggleClass(index, e) {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index,
    });
  }

  moreLess(index) {
    if (this.state.activeIndex === index || this.state.allOpen) {
      return (
        <i className="fa fa-angle-down" />
      );
    } else {
      return (
        <i className="fa fa-angle-right" />
      );
    }
  }

  fetchData = mounted => {
    ImmunityApi.getContacts()
      .then(contacts => {
        if (mounted) {
          console.log(contacts)
          this.setState({
            contacts: contacts,
            loading: false,
            isMore: !!contacts.next,
            link: contacts.next,
            contactsLength: contacts.count,
            noContacts: false
          })
        }
      })
      .catch(e => console.log('Error getting contacts: ', e))
  }

  componentWillUnmount () {
    this.mounted = false
  }

  handleInputSearchChange = event => {
    const value = event.target.value
    this.setState({ contactSearch: value })
    if (!value) {
      this.fetchData(true)
    }
  }

  keyPressHandler = event => {
    if (event.charCode === 13) {
      this.searchContatcs()
    }
  }

  searchContatcs = () => {
    this.setState({
      contacts: [],
      isMore: false,
      loading: true,
      noContacts: false
    })
    const { contactSearch } = this.state
    ImmunityApi.getContactsBySearch(contactSearch)
      .then(contacts => {
        this.setState({
          contacts: contacts,
          loading: false,
          allOpen: contactSearch === '' ? false : true,
          noContacts: !(contacts.length > 0)
        })
      })
      .catch(e => console.log('Error getting contacts: ', e))
  }

  showNewContactPopup = (create, id = false) => {
    if (id) {
      ImmunityApi.getContact(id)
        .then(contact => this.setState({ contact }))
        .catch(e => console.log('Error getting contact info: ', e))
      create = false
    } else {
      create = true
    }
    let newContactPopup = true
    this.setState({ newContactPopup, create })
  }

  closeNewContactPopup = () => {
    let newContactPopup = false
    let create = false
    this.setState({ newContactPopup, create })
  }

  closePopupAndFetchData = () => {
    this.fetchData(true)
    this.closeNewContactPopup()
  }

  render () {
    const { contacts, contact, loading, create, noContacts, activeIndex, allOpen } = this.state
    return (
      <div className='nav-dropdown-contact forImportPopup'>
        <div className='rombo-contact' />
        <div className='contact-container'>
          <div className='contact-search space-btw'>
            <input
              type='text'
              className='field-search field-search-contacts'
              name='search'
              id='search'
              placeholder={`${i18n.t('general.search')}`}
              value={this.state.contactSearch}
              onChange={this.handleInputSearchChange}
              onKeyPress={this.keyPressHandler}
            />
            <img
              className='input-left-icon contact-search-btn'
              src={require('../../images/search.svg')}
              alt='Search'
              onClick={this.searchContatcs}
            />
          </div>
          <div
            className='contact-list'
            ref={ref => (this.scrollParentRef = ref)}
          >
            {!noContacts && contacts.map((contact, index) => {
              return (
                <li key={ index }>
                  <div 
                    className='contact-group contact-name'
                    onClick={this.toggleClass.bind(this, index)}
                  >
                    {this.moreLess(index)} { contact.type }
                  </div>
                  <Collapse isOpened={ allOpen ? allOpen : activeIndex === index }>
                    <div
                      className={classNames("contact-item", {
                        show: allOpen ? allOpen : activeIndex === index,
                        hide: allOpen ? false : activeIndex !== index
                      })}
                    >
                      <span style={{color: "red"}}>{contact.contacts.map((card, i) => {
                        let cardName = card.name.split(' ')
                        let firstName = cardName[0].substr(0, 1).toUpperCase()
                        let lastName = ""
                        if (cardName.length > 1) {
                          lastName = cardName[1].substr(0, 1).toUpperCase()
                        }
                        return (
                          <div
                            className='contact-item'
                            key={i}
                            onClick={() => {
                              this.showNewContactPopup(false, card.id)
                            }}
                          >
                            <div className='contact-image'>{firstName + lastName}</div>
                            <div className='contact-text'>
                              <div className='contact-name'>{card.name}</div>
                              <div className='contact-phone'>{card.phone1}</div>
                            </div>
                          </div>
                        )
                      })}</span>
                    </div>
                  </Collapse>
                </li>
              )
            })}
            {loading && <Spinner />}
            {noContacts && (
              <div className='loadingCandidates'>
                <div className='contact-phone'>No contacts found</div>
              </div>
            )}
          </div>
          <div
            className='contact-create'
            onClick={() => this.showNewContactPopup(true)}
          >
            <img
              className='navtop-icon-dropdown'
              src={require('../../images/plus-icon.svg')}
              alt=''
            />
            {i18n.t('menu.createContact')}
          </div>
        </div>

        <Popup
          open={this.state.newContactPopup}
          closeOnDocumentClick
          onClose={this.closePopupAndFetchData}
          className='popup-newtrial widthauto pad0 contact-pop'
        >
          <ContactInfo
            onClose={this.closeNewContactPopup}
            contact={contact}
            create={create}
          />
        </Popup>
      </div>
    )
  }
}
