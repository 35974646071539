import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function trialsOptionsReducer (state = initialState.trials, action) {
  switch (action.type) {
    case types.LOAD_TRIALS_OPTIONS_SUCCESS:
      return action.trialsOptions;
    default:
      return state
  }
}
