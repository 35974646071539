import React from "react";
import link from "./../../../../images/link.png";
import SearchLabInfo from './SearchLabInfo'

export default function SearchLabResults({
  result,
  spinner,
  initial,
  setLabValue
}) {
  const redirect = url => {
    window.open(url);
  };

  const chooseLab = (e, el) => {
    setLabValue(el);
  };

  return spinner ? (
    <div className="lab_search_wrapp" style={{ height: "calc(50vh + 20px)" }}>
      <div className="spinner-container">
        <img
          className="medium-spinner"
          src={require("./../../../../images/spinner.gif")}
          alt="Spinner"
        />
      </div>
    </div>
  ) : result.length > 0 ? (
    <div className="lab_search_wrapp">
      <div className="lab_search-res-header">
        <div className="lab-res-item-header" style={{textAlign: 'left', flex: 1.4}}>Code</div>
        <div className="lab-res-item-header" style={{textAlign: 'center', flex: 1.4}}>Long name</div>
        <div className="lab-res-item-header"style={{textAlign: 'center', flex: 2.4}}>Short name</div>
        <div className="lab-res-item-header"style={{textAlign: 'center', flex: 2.2}}>Display name</div>
        <div className="lab-res-item-header"style={{textAlign: 'center', flex: 0.6}}>
          Link
        </div>
        <div className="lab-res-item-header" style={{position: 'relative', left: 7, textAlign: 'right', flex: 0.7, display: 'flex'}}>
          Rank
          <SearchLabInfo></SearchLabInfo>
        </div>
      </div>
      <div className="lab_search-res-wr">
        {result.map(el => (
          <div
            key={el.code}
            className="lab-res-item-wrapp"
            onClick={e => chooseLab(e, el)}
          >
            <div className="lab-res-item-code">{el.code}</div>
            <div
              className="lab-res-item lab-res-i-lng_name"
              style={{ textAlign: el.longName ? "left" : "center" }}
            >
              {el.longName ? el.longName : "-"}
            </div>
            <div className="lab-res-item">
              {el.shortName ? el.shortName : "-"}
            </div>
            <div
              className="lab-res-item"
              style={{ textAlign: el.displayName ? "left" : "center" }}
            >
              {el.displayName ? el.displayName : "-"}
            </div>
            <div
              className="lab-res-item"
              style={{ textAlign: "center", width: "40px" }}
              onClick={() => redirect(el.url)}
            >
              <img src={link} alt="link-icon" />
            </div>
            <div className="lab-res-item"    style={{ textAlign: "center", width: "20px" }}>
              {el.rank ? el.rank : "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : initial ? (
    <div className="lab_search-nores lab_search_wrapp">press enter to start search</div>
  ) : (
          <div className="lab_search-nores lab_search_wrapp">No results</div>
        );
}
