import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import Popup from "reactjs-popup";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import EmailPopup from "./../EmailPop";
import InmunityApi from "../../api/InmunityApi";
import PatientOverview from "./../PatientOverview";
import { InfoIcon } from "../../ui";
class AllCandidates extends Component {
  state = {
    letterPopup: false,
    dayCount: 5,
    emailFields: "",
    showOverview: false,
    details: {}
  };

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains("button-center") &&
      !target.closest(".pat-over-wrapp") &&
      (!target.classList.contains("button__icon") &&
        !target.classList.contains("popup-overlay"))
    ) {
      let { history, candidate } = this.props;
      let hadmId = candidate.hadmId;
      let studyId = candidate.studyId;
      history.push({
        pathname: `/trial/${studyId}/matches/details/${hadmId}`,
        id: hadmId,
        detailTitle: "Candidate Details"
      });
    }
  };

  redirect = () => {
    let { history, candidate } = this.props;
    let hadmId = candidate.hadmId;
    let studyId = candidate.studyId;
    history.push({
      pathname: `/trial/${studyId}/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: "Candidate Details"
    });
  };

  makeTrials = obj => {
    let result = "";
    obj.sort((a, b) => b.protocolMatch - a.protocolMatch);
    obj.forEach(trial => {
      let precntage = trial.protocolMatch;
      result += ` ${trial.protocol} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  showLetterPopup = () => {
    let letterPopup = true;
    this.setState({ letterPopup });
  };

  closeLetterPopup = () => {
    let letterPopup = false;
    this.setState({ letterPopup });
  };

  showPatientOverview = () => {
    this.setState({ showOverview: true });
    InmunityApi.getPatientWorkflow(
      this.props.candidate.hadmId,
      this.props.candidate.studyId
    ).then(details => {
      this.setState({
        details
      });
    });
  };

  closePatientOverview = () => {
    this.setState({ showOverview: false });
  };

  render() {
    const {
      immunity,
      contactConsent,
      treatingPhysician,
      upcomingVisit,
      lastSeen,
      protocol,
      name,
      matchTime,
      status
    } = this.props.candidate;
    var trials = this.makeTrials(protocol);
    let uv, ls, mt;
    if (upcomingVisit) {
      uv = new Date(upcomingVisit).toLocaleDateString("en-us");
    }
    if (lastSeen) {
      ls = new Date(lastSeen).toLocaleDateString("en-us");
    }
    if (matchTime) {
      mt = moment(matchTime).format("MMM DD YYYY - hh:mm A");
    }
    return (
      <div className="candidatesWr forToolTip" onClick={this.goToDetails}>
        <div className="candidates pad15 hadmId">
          <span className="imm-id">Candidate Information</span>
          <span className="td-content">{immunity}</span>
          <span className="td-content">{name}</span>
          {protocol.length !== 0 && (
            <span
              className="td-content candidatesAmTrial"
              data-tip={trials}
              data-class="toolTip"
              data-for={this.props.id}
              data-multiline
            >
              {protocol.length > 1
                ? "Candidate for " + protocol.length + " other Trials"
                : ""}
              {protocol.length === 1 &&
                "Candidate for " + protocol.length + " other Trial"}
            </span>
          )}
          <ReactTooltip
            id={this.props.id}
            place="bottom"
            type="light"
            effect="solid"
          />
        </div>
        <div className="candidates pad15 forlsuv">
          <div className="c-lastSeen">
            <span>Last Seen</span>
            <span className="td-content">{moment(ls).format("MMM DD YYYY") || "-"}</span>
          </div>
          <div className="c-upcomingVisit">
            <span>Upcoming Visit</span>
            <span className="td-content">{moment(uv).format("MMM DD YYYY") || "-"}</span>
          </div>
          <div className="c-lastSeen c-matchTime">
            <span>Candidate timestamp</span>
            <span className="td-content">{mt || "-"}</span>
          </div>
        </div>
        <div className="candidates pad15 c-physician">
          <span className="forIcon">
            {treatingPhysician.length === 1 ? "Physician" : "Physicians"}
          </span>
          <span className="td-content">
            {treatingPhysician.map((el, index) => {
              return (
                <span key={index} className="td-content-physic">
                  {el}
                </span>
              );
            })}
          </span>
        </div>
        <div
          className={
            "candidates pad15 " +
            (contactConsent ? "contact-consent-can" : "non-contact-consent-can")
          }
        >
          <span>Contact Consent</span>
        </div>
        <div className="candidates pad15 non-proceed-to-trial statusOfPatient">
          <span>{status || "-"}</span>
          <div className="forTwoIcons">
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            {/* <MailButton click={this.showLetterPopup} /> */}
            {/* <div className='greenIcon' onClick={this.showLetterPopup} /> */}
            <Popup
              open={this.state.letterPopup}
              closeOnDocumentClick
              onClose={this.closeLetterPopup}
            >
              <EmailPopup
                candidate={this.props.candidate}
                currentUser={this.props.currentUser}
                closePopup={this.closeLetterPopup}
              />
            </Popup>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(AllCandidates));
