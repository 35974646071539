import React, {Component} from "react";
import {connect} from "react-redux";

import InmunityApi from "./../../api/InmunityApi";
import crossicon from "./../../images/icon-close-w.svg";

import "./index.css";

const EmailBody = ({data}) => {
    return (
        <textarea
            id="message"
            className="forSending"
            value={data.email_body}
            disabled={true}
        />
    );
};


class PrimitivePopup extends Component {
    state = {
        data: [],
        active: false,
        deactivate: false,
    };

    componentDidMount() {
      if (!this.props.idData || !this.props.idData) {
        return ;
      }
        InmunityApi.getPatientEmails(this.props.idData)
            .then(data => {
                this.setState({data});
            })
            .catch(error => console.log("error getting email: ", error));
    }

    closeLetterPopup = () => {
        this.props.closePopup();
    };

    deactivateReminder = () => {
        this.setState({active: false, deactivate: true});
    };

    openCountDayPopup = () => {
        document.querySelector(".emailTooltip").classList.toggle("hidden");
        this.setState({
            active: this.state.deactivate ? false : true,
            deactivate: false
        });
    };
    render() {
        let {data} = this.state;

        return (
            <div>
                <div
                    className="closePendingPopupCross"
                    onClick={this.closeLetterPopup}
                >
                    <img src={crossicon} alt="Popup Cross" />
                </div>
                {!data ? (
                    <div className="spinner-container">
                        <img
                            className="medium-spinner"
                            src={require("../../images/spinner.gif")}
                            alt="Spinner"
                        />
                    </div>
                ) : (
                    <div>
                        <div className="selectEmailField">
                            <span className="selectEmailTitle">To:</span>
                            <input
                                id="to"
                                className="inputSendTo"
                                disabled
                                type="text"
                                value={this.state.data.to?eval(this.state.data.to).join():"[private]"}
                            />
                        </div>
                        <div className="modal__from">
                            <input
                                id="subject"
                                className="forSending"
                                type="text"
                                disabled
                                value={this.state.data.subject?this.state.data.subject:"Patient Consent Email"}
                            />
                        </div>
                        <div className="modal__text">
                            <EmailBody
                            data={data}
                            ></EmailBody>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userToken: state.userToken,
        currentUser: state.currentUser,
        changeEmailPopup: state.changeEmailPopup
    };
}

export default connect(mapStateToProps)(PrimitivePopup);
