import React from 'react'
import './index.css'
const DiseasesTooltip = ({ diseases, Dpicked }) => {
  return (
    <div className='diseases-container'>
      {diseases.map((d, i) => {
        return (
          <div
            className='diseases-container__item'
            onClick={e => Dpicked(d.name)}
            key={i}
          >
            {d.name}
          </div>
        )
      })}
    </div>
  )
}
export default DiseasesTooltip
