import React, {useState} from 'react'
import './styles.css'

/**
 * @constructor
 * @param {ReactNode | string} children
 * @param {ReactNode | string} tooltipContent
 * @param {void} onHide
 * @param {void} onShow
 * @param {string} className
 */

export const CustomTooltip = ({children, tooltipContent, onHide, onShow, className, tooltipClass}) => {
    const [visible, setVisible] = useState(false);
    const onHideTooltip = () => {
        onHide && onHide()
        setVisible(false)
    }

    const onShowTooltip = () => {
        onShow && onShow()
        setVisible(true)
    }

    return(
        <button className={tooltipClass} onBlur={onHideTooltip} onClick={onShowTooltip}>
            {children}
            {visible && (
                <div className={`tooltip-body ${className}`}>
                    {tooltipContent}
                </div>
            )}
        </button>
    )
}