import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { logInUser, getUserByToken, getUserSuccess} from "../../actions/trialActions";
import store from '../../store'
import InmunityApi from '../../api/InmunityApi'
import { validate } from "../../utils";
import { SimpleInput, Checkbox } from "../../ui";
import CompanyPicker from './CompanyPicker'
import logo from "../../images/logo-w.png";
import "./style.css";


class Login extends Component {
  state = {
    ShowError: false,
    ShowText: "",
    pickedCompany: null,
    showCompanyPicker: false
  };

  componentDidMount() {
    const token = localStorage.getItem('token');

    if (token) {
      this.props.relocate("/dashboard");
    }
  }

  getUserDebug = () => {
    //ghost#debug
    InmunityApi
      .getUser()
      .then(user => {
        store.dispatch(getUserSuccess(user));
      })
      .catch(error => {
        throw error;
      });
  }

handleSelectCompany = (selected) => {
  this.setState({ pickedCompany: this.state.companyData[selected], showCompanyPicker: false })
}

sendAuthRequest = async (companyData, login, login_data, relocate) => {
  let companyName;


  if (companyData.length > 0) {
    const timeout = async ms => new Promise(res => setTimeout(res, ms));
    this.setState({ showCompanyPicker: true, companyData });
    while (this.state.showCompanyPicker === true) { await timeout(50) }
    // wait for user input async
    // prevent browser from freeze ghost#fn
    companyName = this.state.pickedCompany.name;
  } else {
    companyName = companyData[0].name;
  }

  try {
    const res = await login(login_data.email, login_data.password, companyName);
    if (res.token) {
      this.getUserDebug();
      relocate("/dashboard");
    }
    else {
      this.setState({ ShowError: true, ShowText: res.error });
    }
  }
  catch (e) {
    this.setState({ ShowError: true, ShowText: "Unable to login with the provided credentials" })
  };
}

handleSubmit = async values => {
  values.preventDefault();
  let { login, login_data, valid, relocate } = this.props;
  this.setState({ ShowError: !valid, ShowText: "" });
  if (valid) {
    if (login_data.remember) {
      localStorage.setItem("email", login_data.email);
    }
 
    try {
      const companyData = await InmunityApi.queryUserCompany(login_data.email, login_data.password);
      if (!Array.isArray(companyData))
        throw new Error('Invalid company response!');
      await this.sendAuthRequest(companyData, login, login_data, relocate);

    }
    catch (e) {
      this.setState({ ShowError: true, ShowText: "Email and Password don't match or invalid" })
    };
  }
};

render() {
  let { ShowError, ShowText } = this.state;
  let { loading, relocate } = this.props;
  return (
    <div className="base-container forBlueBC">
      <div className="base-container">
        <div className="login-container">
          <div className="left-login">
            <img src={logo} alt="Inmunity" />
          </div>
          <div className="right-login">
            <div className="login-fields">
              <h1 className="title-h1">{ShowError ? "Uh-Oh!" : "Welcome"}</h1>
              <h4 className="title-h4">
                {ShowError
                  ? ShowText !== ""
                    ? ShowText
                    : "The username or password is incorrect. Please try again."
                  : "Immunity Health Account Login"}
              </h4>
              <form onSubmit={this.handleSubmit} className="login-fields">
                <Field
                  focus
                  name="email"
                  type="email"
                  component={SimpleInput}
                  label="E-mail"
                  placeholder="name@example.com"
                />
                <Field
                  name="password"
                  type="password"
                  component={SimpleInput}
                  label="Password"
                  placeholder="•••••"
                />
                <CompanyPicker
                  options={this.state.companyData}
                  onSelect={(selected) => this.handleSelectCompany(selected)}
                  show={this.state.showCompanyPicker} />
                <div>
                  <Field
                    name="remember"
                    component={Checkbox}
                    type="checkbox"
                  />
                </div>


                {loading ? (
                  <div className="center-loader">
                    <div className="ldsEllipsis green">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                ) : (
                    <div>
                      <button className="login-button" type="submit">
                        Submit
                      </button>
                    </div>
                  )}
                <div
                  className="text-small forgot"
                  onClick={e => {
                    e.preventDefault();
                    relocate("/forgot-password");
                  }}
                >
                  Forgot password?
                  </div>
              </form>
              {/* <p className="text-small sign-up-login">
                Don’t have an account?{" "}
                <span
                  className="text-bold color-blueLink td-none"
                  onClick={e => {
                    e.preventDefault();
                    relocate("/account-registration");
                  }}
                >
                  Sign Up
                  </span>
              </p> ghost#hiddent*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}
const LoginWrapper = reduxForm({
  form: "login",
  validate,
  initialValues: {
    // email: "",
    password: "",
    email: localStorage.getItem("email") || ""
  }
})(Login);

const mapDispatchToProps = dispatch => ({
  login: (email, password, company) => dispatch(logInUser(email, password, company)),
  relocate: link => dispatch(push(link)),
  getUserByToken: () => dispatch(getUserByToken())
});

const mapStateToProps = state => ({
  login_data: getFormValues("login")(state),
  loading: state.loader.login_loader,
  popup: state.popup
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginWrapper);