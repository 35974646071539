import React from "react";
import PropTypes from "prop-types";
import i18n from "./../../../../i18n";

export default function SearchInput({ handleInputSearchWord }) {
  return (
    <div className="search-word-container">
      <img
        className="search-word-icon"
        src={require("../../../../images/search.svg")}
        alt="Search"
        data-test="inputImg"
      />
      <input
        type="text"
        className="search-word"
        name="search-word"
        id="search-word"
        placeholder={`${i18n.t("general.search")}`}
        onChange={handleInputSearchWord}
      />
    </div>
  );
}

SearchInput.propTypes = {
  handleInputSearchWord: PropTypes.func
};
