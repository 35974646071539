import React from 'react'
import { withRouter } from 'react-router'
import Popup from 'reactjs-popup'

import InmunityApi from '../api/InmunityApi'

import './styles/PatientDetails.css'
import MaskedInput from "react-text-mask";

class PatientDetails extends React.Component {
  constructor(props, context) {
    super(props, context);
      this.state = {
        token: null,
        loading: false,
        error: false,
        hadm: null,
        immunity: null,
        patientEmail: null,
        patientName: null,
        patientPhone: null,
        studyId: null,
        studyName: null,
        studyPhase: null,
        sponsor: null,
        apiProcess: false,
        validEmail: false,
        validPhone: false,
        message: "",
        regexPhone: /\+1[0-9]{10}/,
        regexEmail: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
    let urlString = window.location.href
    let url = new URL(urlString)
    let token = url.searchParams.get('token')
    this.setState(
      {
        token
      },
      () => {
        token &&
          InmunityApi.getPatientDetails(token)
            .then(res => {
              if (res.status !== 200) {
                let { history } = this.props
                this.setState(
                  {
                    error: true
                  },
                  () => {
                    setTimeout(() => {
                      history.push({
                        pathname: '/'
                      })
                    }, 3000)
                  }
                )
                throw res
              }
              return res.json()
            })
            .then(resp => {
              this.setState({
                immunity: resp.immunity,
                hadm: resp.hadm,
                patientEmail: resp.patientEmail,
                patientName: resp.patientName,
                patientPhone: resp.patientPhone,
                studyId: resp.studyId,
                studyName: resp.studyName,
                studyPhase: resp.studyPhase,
                studyDescription: resp.studyDescription,
                loading: false,
                sponsor: resp.sponsor,
                error: false,
                validEmail: !!resp.patientEmail,
                validPhone: !!resp.patientPhone,
              })
            })
            .catch(error => {
              console.log(error)
              this.setState({
                loading: false
              })
            })
      }
    )
  }

  handleInputChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    let state = this.state;

    if (name === "email") {
      if (state.regexEmail.test(value)) {
        this.setState({
          message: "",
          validEmail: true,
          patientEmail: value
        });
        event.target.classList.remove("redBorder");
      } else {
        this.setState({
          validEmail: false
        });
        event.target.classList.add("redBorder");
      }
    }
    if (name === "phone") {
      value = value
        .split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
      if (this.state.regexPhone.test(value)) {
        this.setState({
          message: "",
          validPhone: true,
          patientPhone: value
        });
        event.target.classList.remove("redBorder");
      } else {
        if (name !== "phoneTwo" && value !== "") {
          this.setState({
            validPhone: false
          });
          event.target.classList.add("redBorder");
        }
      }
    }
    state[name] = value;
    this.setState({ state });
  }

  closeModal = () => {
    this.setState({
      error: false
    })
  }

  setPatientDetail = () => {
    let obj = {}
    this.setState({
      loading: true,
      apiProcess: true
    })
    obj.hadm = this.state.hadm
    obj.name = this.state.patientName
    obj.email = this.state.patientEmail
    obj.phone = this.state.patientPhone
    obj.study = this.state.studyId
    obj.token = this.state.token
    if (this.state.validEmail && this.state.validPhone) {
      InmunityApi.setPatientDetails(obj).then(res => {
        if (res.status === 201) {
          let { history } = this.props
          history.push({
            pathname: '/physician-consented'
          })
          return res;
        }
        else if (res.status === 403) {
          return res.json();
        }
      })
        .then(j => {
          let { history } = this.props
          history.push({
            pathname: `/patient-consented-error/${j.detail || 'Error'}`
          })
        })
    }
  }

  decline = () => {
    let obj = {}
    obj.name = this.state.patientName
    obj.email = this.state.patientEmail
    obj.phone = this.state.patientPhone
    obj.token = this.state.token
    let dest;
    this.setState({
      loading: true,
      apiProcess: true
    })
    if (this.state.validEmail && this.state.validPhone) {
      InmunityApi.declinePatientDetails(obj).then(res => {
        if (res.status === 201) {
          res.text().then(res => {
            let { history } = this.props;
            if (window.location.pathname === '/patient-details/') {
              let urlString = window.location.href
              let url = new URL(urlString)
              let token = url.searchParams.get('token')
              dest = {
                pathname: `/reason-decline-login/?token=${token}`,
                token,
                obj: JSON.parse(res)
              }
            }
            else {
              dest = {
                pathname: '/reason-decline-login',
                obj: JSON.parse(res)
              }
            }
            history.push(dest);
          })
        }
        else if (res.status === 403) {
          let { history } = this.props
          history.push({
            pathname: `/patient-consented-error/${res.detail || 'Error'}`
          })
          return (res.json());
        }
      })
    }
  }

  changeDetails = ({ target }) => {
    this.setState({ [target.id]: target.value })
  }

  render() {

    let phase
    if (this.state.studyPhase === '1') {
      phase = 'I'
    } else if (this.state.studyPhase === '2') {
      phase = 'II'
    } else if (this.state.studyPhase === '3') {
      phase = 'III'
    } else if (this.state.studyPhase === '4') {
      phase = 'IV'
    } else {
      phase = ''
    }
    return (
      <div className='base-container forPD forBlueBC forImportPopup'>
        {this.state.loading && (
          <div className='spinner-container'>
            <img
              className='small-spinner'
              style={{ height: '50px' }}
              src={require('../images/spinner.gif')}
              alt='Spinner'
            />
          </div>
        )}
        {!this.state.loading && (
          <div className='base-container'>
            <div className='login-container patientDetails'>
              <div className='left-login'>
                <img src={require('../images/logo-w.png')} alt='' />
                <div className='patientDetails__leftText'>
                  <span>The study:</span>

                  <span>
                    {this.state.studyName} # {this.state.studyId} {this.state.sponsor}: A Phase{' '}
                    {phase}, {this.state.studyDescription}
                  </span>

                  <span>
                    If you think the patient would be interested in learning
                    more about clinical trials, please confirm or enter the
                    appropriate contact information and press “Submit”
                  </span>

                  <span>
                    If you do not think the patient is interested in learning
                    more about clinical trials, please press "Decline"
                  </span>
                </div>
              </div>

              <div className='right-login'>
                <div className='inputFields'>
                  <h1 className='heading'>Enter Patient Details</h1>

                  <label htmlFor='name' className='text-small'>
                    Name *
                  </label>
                  <div className='input-field'>
                    <input
                      disabled //ghost#temp
                      onChange={this.changeDetails}
                      type='name'
                      className='text-field login'
                      name='name'
                      id='patientName'
                      placeholder=''
                      defaultValue={this.state.patientName}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>

                  <label htmlFor='email' className='text-small'>
                    E-mail address *
                  </label>
                  <div className='input-field'>
                    <input
                      onChange={this.handleInputChange}
                      type='email'
                      className={this.state.validEmail ? 'text-field login' : 'text-field login redBorder'}
                      name='email'
                      id='patientEmail'
                      placeholder=''
                      defaultValue={this.state.patientEmail}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>

                  <label htmlFor='phone' className='text-small'>
                    Phone Number *
                  </label>
                  <div className='input-field'>
                    <MaskedInput
                      mask={[
                        "+",
                        "1",
                        " ",
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                      placeholder="(303) 456 7890"
                      className={this.state.validPhone ? 'text-field login' : 'text-field login redBorder'}
                      id="patientPhone"
                      type='text'
                      name='phone'
                      defaultValue={this.state.patientPhone}
                      onChange={this.handleInputChange}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>
                </div>

                <div className='btnsWrapp'>
                  {!this.state.apiProcess && this.state.validPhone && this.state.validEmail ?
                    <div className='decline' onClick={this.decline}>
                      Decline
                    </div>
                    :
                    <div className='decline'>
                      Decline
                    </div>
                  }

                  {!this.state.apiProcess && this.state.validPhone && this.state.validEmail ?
                    <div className='submit' onClick={this.setPatientDetail}>
                      Submit
                    </div>
                    :
                    <div className='submit'>
                      Submit
                    </div>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.loading && (
          <Popup
            open={this.state.error}
            onClose={this.closeModal}
            className='saveUserPopup'
          >
            <div>
              <div className='saveUserPopupText'>Invalid token</div>
            </div>
          </Popup>
        )}
      </div>
    )
  }
}

export default withRouter(PatientDetails)
