import React, { useEffect, useState, useCallback } from "react";
import HighchartsReact from "highcharts-react-official";
import { analyticsLineChartOptions } from "./../../../utils/analytics";
import Highcharts from "highcharts";
import { withRouter } from "react-router-dom";
import { getAuthData } from "./../../../api/getData";
import Select from "react-select";
import moment from "moment";
import "./style.css";

const customStyles = {
  container: base => ({
    ...base,
    height: 50
  })
};

const LineChart = props => {
  const [data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState({
    value: 4,
    label: "Candidate for trial"
  });
  const [options, setOptions] = useState([]);
  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  let getChartOptions = useCallback(chart => {
    if (!chart) {
      return {};
    }
    let data = [];
    chart.forEach(el => {
      data.push([parseInt(moment(el.date).format("X")) * 1000, el.value]);
    });
    data.sort((a, b) => a[0] - b[0]);
    const chartOptions = {
      chart: {
        zoomType: "x",
        marginTop: 60,
        resetZoomButton: {
          relativeTo: "chart"
        }
      },
      title: {
        text: ""
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? "Click and drag in the plot area to zoom in"
            : "Pinch the chart to zoom in"
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ""
        },
        min: 0
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.Color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba")
              ]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },

      series: [
        {
          type: "column",
          name:
            selectedOption.label === "Match for trial"
              ? "Matches"
              : selectedOption.label === "Candidate for trial"
              ? "Candidates"
              : "Enrolled",
          data
        }
      ]
    };
    return chartOptions;
  }, [selectedOption.label]);

  const getChartData = useCallback(() => {
    let { location } = props;
    let id = location.pathname.split("/")[2];
    getAuthData(`charts/${selectedOption.value}${id ? "/" + id : ""}`).then(
      res => {
        setData(getChartOptions(res.chart));
      }
    );
    setOptions(analyticsLineChartOptions());
  },
  [getChartOptions, props, selectedOption.value]
  );
  useEffect(() => {
    getChartData();
  }, [getChartData]);

  useEffect(() => {
    getChartData();
  }, [selectedOption,getChartData]);

  return (
    <div className="containerForAnalytics">
      <div className="cfa__rg">
        <span className="forcfa__label">Enrollment Progress</span>
        <div className="analyticsLineChart__graphic">
          <div>
            <Select
              value={selectedOption}
              onChange={handleChange}
              styles={customStyles}
              options={options}
            />
          </div>
          <div className="workflow_chart">
            <HighchartsReact highcharts={Highcharts} options={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LineChart);
