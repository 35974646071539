import React from "react";
import "./style.css";
import check from "./../../images/icon-check-green.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import InmunityApi from "../../api/InmunityApi";
import {
	setCurrentTrial,
	getTrialDescription,
	setCurrentNavigationSubLink,
} from "../../actions/trialActions";
class Notification extends React.Component {
	onClickNotification = () => {
		if (this.props.content.type === "trial") {
			this.props.setCurrentNavBarLink(this.props.content.payload.trialId);
			this.props.getTrialDescription(this.props.content.payload.trialId);
			this.props.setCurrentTrial(this.props.content.payload.trialId);
			this.props.relocate(
				`/trials/${this.props.content.payload.trialId}/matches`
			);
		} else if (this.props.content.type === "search") {
			this.getPartionSearchInfo(this.props.content.payload.searchId);
		} else if (this.props.content.type === "workflow") {
			this.goToTrials(this.props.content);
			// this.props.relocate(`/trials/${this.props.content.payload.trialId}/matches`)
		} else if (this.props.content.type === "reminder") {
			this.props.history.push({
				pathname: "/dashboard",
				params: {
					reminderId: this.props.content.payload.reminderId,
				},
			});
		}
	};
	goToTrials = item => {
		InmunityApi.getPatientStatus(
			item.payload.patientId,
			item.payload.trialId
		).then(res => {
			this.props.relocate(
				`/trial/${item.payload.trialId}/matches/details/${item.payload.patientId}`
			);
		});
	};
	getPartionSearchInfo = id => {
		InmunityApi.getPatientSearchInfo(id)
			.then(resp => {
				if (resp.status !== 200) {
					this.setState({
						open: true,
						loadingMessage: "An error occured while receiving result",
						closePopupOnError: true,
					});
				}
				return resp.json();
			})
			.then(info => {
				if (info.count === 0) {
					this.setState({
						open: true,
						loadingMessage: "Results not found",
						closePopupOnError: true,
					});
				} else {
					this.setState({ flag: true });
					this.props.history.push({
						pathname: `/patients-search/${id ? id + "/" : ""}`,
					});
				}
			})
			.catch(error =>
				console.log("Error occured while creating a search: ", error)
			);
	};
	render() {
		const { content, toggle } = this.props;
		return (
			<div
				className={`notification-custom ${toggle ? "open" : "hide"}`}
				onClick={() => this.onClickNotification()}
			>
				<div className="notification-message">
					<img className="notification-check" alt='notification' src={check} />
					<span>Message {content.message}</span>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getTrialDescription: id => dispatch(getTrialDescription(id)),
		setCurrentTrial: id => dispatch(setCurrentTrial(id)),
		setCurrentNavBarLink: id => dispatch(setCurrentNavigationSubLink(id)),
		relocate: path => dispatch(push(path)),
	};
}

export default withRouter(connect(null, mapDispatchToProps)(Notification));
