import React, {useState} from 'react';
import './style.css'

const CustomSelect = (props) => {
  const [focused, changeFocus] = useState(false)
  const [selected, changeSelect] = useState("")
  const [position, changePosition] = useState({})
  const show = () => {
    const element = document.getElementsByClassName("custom-select-container")[0];
    changePosition({ top: element.offsetTop- element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop +40, left: element.offsetLeft-12, width: element.getBoundingClientRect().width+10});
    changeFocus(!focused)
  }
  const onChange = (item) => {
    changeFocus(false)
    changeSelect(item)
    props.onChange && props.onChange(item)
  }
  return(<div className="custom-select">
    <div className="custom-select-container">
      <div className="select-value">{selected!=="" ? (<span>{selected.label}</span>) : "Select..."}</div>
      <div className="select-arrow" onClick={() => show()}>
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
      </div>
    </div>
    {focused && (
      <div className="custom-select-options" style={{top: position.top, left: position.left, width: position.width}}>
        {props.options && props.options.map((item,i) => (
          <div key={item.value} onClick={() => onChange(item)}>{item.label}</div>
        ))}
      </div>
    )}
  </div>)
}

export default CustomSelect
