import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function getUserReducer(state = initialState.viewRender, action) {
  switch(action.type) {
    case types.VIEW_NAV_BAR:
      return {...state, view: action.view}
    case types.IS_FETCHING:
      return {...state, isFetching: action.isFetching}
    default:
      return state;
  }
}
