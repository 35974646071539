import * as types from "../actions/actionTypes";
const initialState = { navBar: 1, navBarSublink: "all" };

export default function currentNavigationReducer(state = initialState, action) {
  switch (action.type) {
    case types.CURRENT_NAVIGATION_LINK:
      return { ...state, navBar: action.link };
    case types.CURRENT_NAVIGATION_SUB_LINK:
      return {
        ...state,
        navBarSublink:
          action.sublink === "all"
            ? action.sublink
            : parseInt(action.sublink, 10)
      };
    default:
      return state;
  }
}
