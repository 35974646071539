import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getPhysiciansSearchReducer(
  state = initialState.physiciansSearch,
  action
) {
  switch (action.type) {
    case types.GET_PHYSICIANS_SEARCH:
      return action.physiciansSearch;
    default:
      return state;
  }
}
