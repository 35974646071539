import React, { useState, useEffect, useRef } from "react";
import TreeComponent from "./../TreeComponent";
import ChildrenSpinner from "./../Spinners/LeafSpinner";
import {
	setMedicationsATCList,
	setMedicationsEPCList,
	setMedicationsMOAList,
	setDiseasesDOList,
	setDiseasesICD9CMList,
	setDiseasesICD10CMList,
	setProceduresICD9List,
	setProceduresICD10List,
	setProceduresList,
	setAllergiesLeafs,
} from "../../../../actions/trialActions";
import { connect } from "react-redux";
// import allergiesLeafs from "../../../../reducers/allergiesLeafs";
// import allergiesList from "../../../../reducers/allergiesList";

const CategoriesList = props => {
	const {
		medications,
		activeTab,
		setCategoryId,
		setSpinner,
		setCategorySpinner,
		makeActive,
		active,
		setCodes,
		filter,
		categoryId,
		makeCheck,
		listName,
		dispatch,
		medicationsATCList,
		medicationsEPCList,
		medicationsMOAList,
		diseasesDOList,
		diseasesICD9CMList,
		diseasesICD10CMList,
		proceduresList,
		proceduresICD9List,
		proceduresICD10List,
		makeFindActive,
		setArrayOfCodes,
		allergiesLeafs,
	} = props;
	const myRef = useRef();
	const [distanceATC, setDistanceATC] = useState(0);
	const [distanceEPC, setDistanceEPC] = useState(0);
	const [distanceMOA, setDistanceMOA] = useState(0);
	const [view, setView] = useState([]);

	const scrollHandler = e => {
		const distance = e.target.scrollTop;
		if (activeTab === 0) {
			setDistanceATC(distance);
		} else if (activeTab === 1) {
			setDistanceEPC(distance);
		} else if (activeTab === 2) {
			setDistanceMOA(distance);
		}
	};

	const makeItemsList = (list, disNames, tab) => {
		let parentId = disNames[0].parentId;
		let findList = list.filter(el => el.id === parentId);
		let findIndex = list.findIndex(el => el.id === parentId);
		if (findList.length > 0) {
			list.splice(findIndex, 1, { id: parentId, leafs: disNames });
		} else {
			list.push({ id: parentId, leafs: disNames });
		}
		makeFindActive(tab, parentId);
		return list;
	};

	useEffect(() => {
		const renderTree = (array, search = false) => {
			let res = [];
			let disNames = [];
			if (Array.isArray(array)) {
				for (let el of array) {
					if (el.isLeaf) {
						disNames.push(el);
					} else if (el.children) {
						res.push(
							<ul key={el.id}>
								<TreeComponent
									myRef={myRef}
									activeTab={activeTab}
									name={el.name}
									id={el.id}
									key={el.id}
									children={el.hasChildren}
									code={el.code}
									isLeaf={el.hasLeafs}
									rootId={el.parentId}
									setCategoryId={setCategoryId}
									setSpinner={setSpinner}
									setCategorySpinner={setCategorySpinner}
									makeActive={makeActive}
									active={el.id === active}
									setCodes={setCodes}
									filter={filter}
									open={el.children ? true : false}
									search={true}
									searchCategory={el.search_category}
									categoryId={categoryId}
									makeCheck={makeCheck}
									setArrayOfCodes={setArrayOfCodes}
								/>
								{renderTree(el.children, el.search)}
							</ul>
						);
					} else {
						res.push(
							<ul key={el.id}>
								<TreeComponent
									myRef={myRef}
									activeTab={activeTab}
									name={el.name}
									id={el.id}
									key={el.id}
									children={el.hasChildren}
									code={el.code}
									isLeaf={el.hasLeafs}
									rootId={el.parentId}
									setCategoryId={setCategoryId}
									setSpinner={setSpinner}
									setCategorySpinner={setCategorySpinner}
									makeActive={makeActive}
									active={el.id === active}
									setCodes={setCodes}
									filter={filter}
									open={el.children ? true : false}
									search={el.search}
									searchCategory={el.search_category}
									categoryId={categoryId}
									makeCheck={makeCheck}
									setArrayOfCodes={setArrayOfCodes}
								/>
							</ul>
						);
						// }
					}
				}
				if (disNames.length > 0) {
					if (filter === "Medication Filters") {
						if (listName === "ATC") {
							let list = [...medicationsATCList];
							list = makeItemsList(list, disNames, 0);
							dispatch(setMedicationsATCList(list));
						} else if (listName === "EPC") {
							let list = [...medicationsEPCList];
							list = makeItemsList(list, disNames, 1);
							dispatch(setMedicationsEPCList(list));
						} else if (listName === "MOA") {
							let list = [...medicationsMOAList];
							list = makeItemsList(list, disNames, 2);
							dispatch(setMedicationsMOAList(list));
						}
					} else if (filter === "Disease Filters") {
						if (listName === "DO") {
							let list = [...diseasesDOList];
							list = makeItemsList(list, disNames, 0);
							dispatch(setDiseasesDOList(list));
						} else if (listName === "ICD9CM") {
							let list = [...diseasesICD9CMList];
							list = makeItemsList(list, disNames, 1);
							dispatch(setDiseasesICD9CMList(list));
						} else if (listName === "ICD10CM") {
							let list = [...diseasesICD10CMList];
							list = makeItemsList(list, disNames, 2);
							dispatch(setDiseasesICD10CMList(list));
						}
					} else if (filter === "Procedure History") {
						if (listName === "ICD9") {
							let list = [...proceduresICD9List];
							list = makeItemsList(list, disNames, 0);
							dispatch(setProceduresICD9List(list));
						} else if (listName === "ICD10") {
							let list = [...proceduresICD10List];
							list = makeItemsList(list, disNames, 0);
							dispatch(setProceduresICD10List(list));
						}
					} else if (filter === "Allergy Filters") {
						let list = [...allergiesLeafs];
						list = makeItemsList(list, disNames, 0);
						dispatch(setAllergiesLeafs(list));
					}
				}
				setView(res);
				return res;
			} else {
				return [];
			}
		};
		renderTree(medications);
		setCategorySpinner(false);
		setSpinner(false);
	}, [medications]);

	useEffect(() => {
		const { current } = myRef;
		if (current) {
			if (activeTab === 0) {
				current.scrollTop = distanceATC;
			} else if (activeTab === 1) {
				current.scrollTop = distanceEPC;
			} else if (activeTab === 2) {
				current.scrollTop = distanceMOA;
			}
		}
	}, [activeTab, distanceATC, distanceEPC, distanceMOA]);

	console.log("Medication => ", medications)

	return medications.length === 0 ? (
		<ChildrenSpinner />
	) : Array.isArray(medications) ? (
		<div
			className="importPopupContainer-trial disease-popup-content"
			ref={myRef}
			onScroll={scrollHandler}
		>
			{view}
		</div>
	) : null;
};

const mapStateToProps = state => ({
	medicationsATCList: state.medicationsATCList,
	medicationsEPCList: state.medicationsEPCList,
	medicationsMOAList: state.medicationsMOAList,
	diseasesDOList: state.diseasesDOList,
	diseasesICD9CMList: state.diseasesICD9CMList,
	diseasesICD10CMList: state.diseasesICD10CMList,
	proceduresICD9List: state.proceduresICD9List,
	proceduresICD10List: state.proceduresICD10List,
	proceduresList: state.proceduresList,
	allergiesList: state.allergiesList,
	allergiesLeafs: state.allergiesLeafs,
});

export default connect(mapStateToProps)(CategoriesList);
