import React from "react";
import { connect } from "react-redux";

import NotificationElement from './NotificationElement';

import "../styles/Notification.css";
import ImmunityApi from "./../../api/InmunityApi";
// getNotification
// getNotificationStatus
// readNotification
// markAllReadNotification
import { Spinner } from "../../ui";

class Notification extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: [],
      next: "",
      total: 0,
      loading: true,
      loadingNext: false,
      isMore: false
    }
  }
  componentDidMount(){
    this.setState({loading: true})
    ImmunityApi.getNotification().then((res) => {
      this.setState({list: res.result, total: res.count, next: res.next, loading: false})
      if(res.count > this.state.list.length ) {
        this.setState({isMore : true})
      }else{
        this.setState({isMore : false})
      }
    }).catch(e => {
      this.setState({next: ""})
    })
  }
  readNotification = (i) => {
    ImmunityApi.readNotification(this.state.list[i].id).then(() => {
      const newState = this.state.list
      newState[i].is_read = true
      this.setState({list: newState})
    })
  }
  loadMore = () => {
    this.setState({loadingNext: true})
    this.state.next!=='' && ImmunityApi.getNotification(this.state.next).then((res) => {
      res.result.map(item => this.state.list.push(item))
      this.setState({
        list: this.state.list,
        total: res.count,
        next: res.next,
        loadingNext: false
      })
      if(res.count > this.state.list.length ) {
        this.setState({isMore : true})
      }else{
        this.setState({isMore : false})
      }
    }).catch(e => {
      this.setState({isMore : false})
      this.setState({next: ""})
    })
    if (this.state.next === '') {
      this.setState({loadingNext: false})
    }
  }
  infinityScroll = (e) => {
    if(!this.state.loadingNext && e.currentTarget.scrollHeight - e.currentTarget.offsetHeight <= e.currentTarget.scrollTop){
      this.loadMore()
    }
  }
  markAllRead = () => {
    this.state.list && ImmunityApi.markAllReadNotification().then((res) => {
      this.props.markAllRead()
      this.state.list.map(item => item.is_read = true)
      this.setState({list: this.state.list})
    })
  }
  render() {
    return (
      <div className="nav-dropdown-notification">
        <div className="contact-container">
          <div className="allread" onClick={() => this.markAllRead()}>Mark all as read</div>
          <div className="notificationsWrapp" onScroll={(e) => this.infinityScroll(e)}>
            <div>
                {this.state.list && this.state.list.map((item, i) => (
                  <NotificationElement
                    key={i}
                    nonRead={!item.is_read}
                    index={i}
                    readNotification={this.readNotification}
                    item={item}/>
                ))}
                {this.state.loadingNext && (
                  <Spinner/>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {

  };
}

export default connect(mapStateToProps)(Notification);
