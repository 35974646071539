import * as types from './actionTypes';
import { push } from 'react-router-redux';

import InmunityApi from '../api/InmunityApi';

export function loadPhysiciansOptionsSuccess(physiciansOptions) {
	return { type: types.LOAD_PHYSICIANS_OPTIONS_SUCCESS, physiciansOptions};
}

export function loadPhysicians() {
  return dispatch => {
    return InmunityApi.getAllPhysicians()
    .then(physicians => {
      
      if(!physicians.error) {
        dispatch(loadPhysiciansOptionsSuccess(physicians.map((p,i) => ({
          label: p.name,
          physicianId: p.id,
          value: p.id,
        }))))
      }
    })
    .catch(error => {
      throw error;
    });
  }
}