import React from "react";
import "./index.css";
const PhysiciansTooltip = ({
	physiciansNames,
	Mpicked,
	physicianOptions,
	setShow,
}) => {
	function picked(e, obj) {
		if (e.target.classList.contains("diseases-container__item")) {
			let whereToFill = e.target.parentNode.previousSibling;
			Mpicked(obj, whereToFill);
		}
	}
	return (
		<div className="diseases-container">
			{physiciansNames.map((d, i) => {
				return (
					<div
						className="diseases-container__item"
						onClick={e => {
							picked(e, d);
							setShow && setShow(false);
						}}
						key={i}
					>
						{d.name}
					</div>
				);
			})}
		</div>
	);
};
export default PhysiciansTooltip;
