import axios from "axios";
import {
	getData,
	getAuthData,
	getAuthDataFullLink,
	getAuthDataNoJSON,
	getDataNoJSON,
} from "./getData";
import { postData, postAuthData } from "./postData";
import { putData, putAuthData } from "./putData";
import { deleteAuthData } from "./deleteData";
import { patchAuthData } from "./patchData";
import {
	getAuthDataNotification,
	putAuthDataNotification,
	patchAuthDataNotification,
} from "./socketInstance";
import {NoJSONRequest, simpleRequest} from "./request";
class InmunityApi {
	static getAllTrialsNavCounts(id){
		return id
			? getAuthData(`studies/stats/${id}/`)
			: getAuthData(`studies/stats/`)
	}

	static getPatientOverview(id) {
		return getAuthData(`search/details/${id}/`);
	}

	static makeSearchNeo4j(object) {
		return postAuthData(
			object,
			`${process.env.REACT_APP_MICROSERVICE_PATH}/search/`,
			true
		);
	}
	static saveSearch(object) {
		return postAuthData(
			object,
			`${process.env.REACT_APP_MICROSERVICE_PATH}/search/save/`,
			true
		);
	}
	static cancelSearchParam(searcId, object) {
		return postAuthData(
			object,
			`${process.env.REACT_APP_MICROSERVICE_PATH}/search/${searcId}/exclude`,
			true
		);
	}
	static searchInMedicationPicker(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/medication/search/${id}?label=${label}`,
			true
		);
	}

	static searchInDiseasePicker(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/diseases/search/${id}?label=${label}`,
			true
		);
	}

	static searchInProcedurePicker(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/procedures/search/${id}?label=${label}`,
			true
		);
	}

	static searchInAllergyPicker(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/allergies/search/${id}`,
			true
		);
	}
	static diseasePickerSearch(key, label) {
		return getAuthDataNoJSON(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/diseases/search/?label=${label}&q=${key}`,
			true
		);
	}

	static medicationPickerSearch(key) {
		return getAuthDataNoJSON(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/medication/search?q=${key}`,
			true
		);
	}

	static procedurePickerSearch(key, label) {
		return getAuthDataNoJSON(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/procedures/search/?label=${label}&q=${key}`,
			true
		);
	}

	static allergiesPickerSearch(key) {
		return getAuthDataNoJSON(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/allergies/search/?q=${key}`,
			true
		);
	}
	static labPickerSearch(key) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/labs/?q=${key}`,
			true
		);
	}

	static getRetiredTrials() {
		return getAuthData("studies/retired/");
	}

	static getActiveTrials() {
		return getAuthData("studies/");
	}

	//new
	static getTrialsSort(type, sign, query, sortQuery) {
		let url = query
			? `studies/${
				type === "active" ? "" : `${type}/`
			}?q=${query}&ordering=${sign}${sortQuery}`
			: `studies/${
				type === "active" ? "" : `${type}/`
			}?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getPhysiciansDashboard(dateFrom, dateTo) {
		let params = ""

		if (dateFrom && dateTo) {
			params = `?dateFrom=${dateFrom}&dateTo=${dateTo}`
		} else if (dateFrom && !dateTo) {
			params = `?dateFrom=${dateFrom}`
		} else if (!dateFrom && dateTo) {
			params = `?dateTo=${dateTo}`
		}
		return getAuthData(`patients/dashboard/physicians/${params}`);
	}
	static createDashboardReminder(obj) {
		return postAuthData(obj, "patients/dashboard/reminder/");
	}
	static updateDashboardReminder(id, obj) {
		return patchAuthData(obj, `patients/dashboard/reminder/${id}/`);
	}

	static getResponsibleNames() {
		return getAuthData(`users/contacts/`);
	}

	static treeSearch(object) {
		return postData(object, `search/patients/tree/`);
	}

	static getMedicationsRoot() {
		return getAuthData(`medical/medication/`);
	}

	// static getDiseasesRoot() {
	//   return getAuthData(
	//     `${process.env.REACT_APP_MICROSERVICE_PATH}/diseases`
	//   );
	// }

	static getProceduresRoot() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/procedures`,
			true
		);
	}

	static getMedicationsLeaf(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/medication/${id}/leafs`,
			true
		);
	}

	static getDiseasesLeaf(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/diseases/${id}/leafs?label=${label}`,
			true
		);
	}

	static getProceduresLeaf(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/procedures/${id}/leafs?label=${label}`,
			true
		);
	}

	static getMedicationsChildren(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/medication/${id}`,
			true
		);
	}

	static getAllergies(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/allergies`,
			true
		);
	}
	static getAllergiesChildren(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/allergies/${id}`,
			true
		);
	}

	static getAllergiesLeaf(id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/allergies/${id}/leafs`,
			true
		);
	}

	static getDiseasesChildren(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/diseases/${id}?label=${label}`,
			true
		);
	}

	static getProceduresChildren(id, label) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/procedures/${id}?label=${label}`,
			true
		);
	}

	static getContacts() {
		return getAuthData(`users/contacts/`);
	}

	static getContactsBySearch(search) {
		return getAuthData(`users/contacts/?q=${search}`);
	}

	static getContactTypes() {
		return getAuthData(`users/contacts/type/`);
	}

	static getContact(userId) {
		return getAuthData(`users/contacts/${userId}/`);
	}

	static getPatientsInfo(immunity_id, code, label, id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/match/${immunity_id}/${code}?label=${label}&searchId=${id}`,
			true
		);
	}

	static createContact(obj) {
		return postAuthData(obj, `users/contacts/`);
	}

	static createCompany(company, token) {
		return postData({}, `users/pending/create/${company}/${token}/`);
	}

	static editContact(userId, user) {
		return patchAuthData(user, `users/contacts/${userId}/`);
	}

	static getAllNewMedications() {
		return getAuthData(`studies/medications/search/`);
	}

	static getCriteriaStudy(studyId) {
		let urlString = window.location.href;
		let url = new URL(urlString);
		let token = url.searchParams.get("token");

		if (token) {
			return getData(`studies/${studyId}/criteria/?token=${token}`);
		}
		return getAuthData(`studies/${studyId}/criteria/`);
	}

	static getReminders(obj) {
		return postAuthData(obj, `patients/dashboard/reminders/`);
	}

	static deleteReminder(id) {
		return deleteAuthData(`patients/dashboard/reminders/${id}/`);
	}

	static getDeclineReasons() {
		return getData(`patients/getdeclinereasons/`);
	}

	static getWorkFlowActions() {
		return getAuthData("patients/workflow/actions/");
	}

	static getWorkFlowAll(hadmId) {
		return getAuthData(`patients/workflow/${hadmId}/all/`);
	}

	static getWorkFlow(hadmId, studyId) {
		return getAuthData(`patients/workflow/${hadmId}/${studyId}`);
	}

	static postWorkFlow(hadmId, studyId, obj) {
		return postAuthData(obj, `patients/workflow/${hadmId}/${studyId}/`);
	}

	static postCandidateEmail(hadmId, studyId) {
		return postAuthData({}, `patients/email/${hadmId}/${studyId}/`);
	}

	static postMakematch(hadmId, studyId) {
		return postAuthData({}, `patients/${hadmId}/makematch/${studyId}/`);
	}

	static postExcludeTrial(hadmId, studyId, obj) {
		let urlString = window.location.href;
		let url = new URL(urlString);
		let token = url.searchParams.get("token");

		if (token) {
			return postData(
				obj,
				`patients/exclusion/${hadmId}/${studyId}/?token=${token}}`
			);
		}
		return postAuthData(obj, `patients/exclusion/${hadmId}/${studyId}/`);
	}

	static postCoordinatorNotes(hadmId, studyId, text) {
		return postAuthData(
			text,
			`patients/notes/coordinator/${hadmId}/${studyId}/`
		);
	}

	static getPatientIds(search) {
		return getData(`patients/getpatientids/${search}/`);
	}

	static getRecentMatches() {
		return getAuthData("patients/recent/matches/");
	}

	static getRecentCandidates() {
		return getAuthData(`patients/recent/candidates/`);
	}

	static getAllTrialCandidates() {
		return getAuthData(`patients/candidates/`);
	}

	static getAllMatches() {
		return getAuthData(`studies/matches/`);
	}

	static getAllCandsSearch(query) {
		return getAuthData(`patients/candidates/?q=${query}`);
	}

	static getAllCandsSorted(sign, query, sortQuery) {
		let url = query
			? `patients/candidates/?q=${query}&ordering=${sign}${sortQuery}`
			: `patients/candidates/?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getAllMatchesSearch(query) {
		return getAuthData(`studies/matches/?q=${query}`);
	}

	static getAllMatchesSorted(sign, query, sortQuery) {
		let url = query
			? `studies/matches/?q=${query}&ordering=${sign}${sortQuery}`
			: `studies/matches/?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getAllExclusionsSearch(query) {
		return getAuthData(`studies/exclusions/?q=${query}`);
	}

	static getAllExclusionsSorted(options) {
		return getAuthData(`studies/exclusions/?${options}`);
	}

	static getMatchesSearch(id, query) {
		return getAuthData(`studies/matches/${id}/?q=${query}`);
	}

	static getMatchesSorted(id, sign, query, sortQuery) {
		let url = query
			? `studies/matches/${id}/?q=${query}&ordering=${sign}${sortQuery}`
			: `studies/matches/${id}/?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getCandidatesSearch(id, query) {
		return getAuthData(`patients/candidates/${id}/?q=${query}`);
	}

	static getCandidatesSorted(id, sign, query, sortQuery) {
		let url = query
			? `patients/candidates/${id}/?q=${query}&ordering=${sign}${sortQuery}`
			: `patients/candidates/${id}/?ordering=${sign}${sortQuery}`;

		return getAuthData(url);
	}

	static getExclusionsSearch(id, query) {
		return getAuthData(`studies/exclusions/${id}/?q=${query}`);
	}

	static getExclusionsSorted(id, option) {
		return getAuthData(`studies/exclusions/${id}/?${option}`);
	}

	static getEnrolledSearch(id, query) {
		return getAuthData(`studies/enrolls/${id ? id + "/" : ""}?q=${query}`);
	}

	static getEnrolledSorted(id, sign, query, sortQuery) {
		let url = query
			? `studies/enrolls${
				id ? "/" + id + "/" : ""
			}?q=${query}&ordering=${sign}${sortQuery}`
			: `studies/enrolls${
				id ? "/" + id + "/" : ""
			}?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getNewFromPrescreeningSorted(sign, query, sortQuery) {
		let url =
			query === "matches"
				? `patients/recent/matches/?ordering=${sign}${sortQuery}`
				: `patients/recent/candidates/?ordering=${sign}${sortQuery}`;
		return getAuthData(url);
	}

	static getAllPatients(trial_id) {
		return getAuthData(`patients/candidates/${trial_id}/`);
	}

	static getTrialName(id) {
		return getAuthData(`studies/${id}/short/`);
	}

	static getCSVexport(trial_id, workflow, value, ordering) {
		let params = value !== "" ? `?q=${value}&ordering=${ordering}` : `?ordering=${ordering}`
		console.log(params)
		return getAuthDataNoJSON(`studies/${trial_id}/export/${workflow}${params}`);
	}

	static getAllTrialMatches(trial_id) {
		return getAuthDataNoJSON(`studies/matches/${trial_id}/`);
	}

	static getAllDiseases() {
		return getAuthData(`studies/diseases/`);
	}

	static getLabNames() {
		return getData(`patients/getlabname/`);
	}

	static getLabList() {
		return getAuthData(`studies/labs/`);
	}

	static getUom(q) {
		return getData(`patients/uom/${q}`);
	}

	static getUoms() {
		return getData(`patients/getuom/`);
	}

	static getLabWithUom() {
		return getData(`patients/getlabwithuom/`);
	}

	static getLabValues(hadmId) {
		return getAuthData(`patients/labs/${hadmId}/`);
	}
	static getTrialDescription(id) {
		return getAuthData(`studies/${id ? id + "/" : ""}`);
	}

	static getPatientDescription(id) {
		return getAuthData(`patients/${id}/`);
	}

	static getPatientChart(hadmId, studyID = null, searchId = null) {
		let url = `${process.env.REACT_APP_MICROSERVICE_PATH}/chart/${hadmId}`;

		if (studyID && searchId) {
			url += `?study=${studyID}&search=${searchId}`;
		} else if (studyID) {
			url += `?study=${studyID}`;
		} else if (searchId) {
			url += `?search=${searchId}`;
		}

		return getAuthData(url, true);
	}

	static getPatientNotes(hadmId) {
		return getAuthData(`patients/${hadmId}/notes/`);
	}

	static getPatientNotesList(hadmId) {
		return getAuthData(`patients/${hadmId}/notes/list`);
	}
	static getPatientNotesText(hadmId, id) {
		return getAuthData(`patients/${hadmId}/notes/${id}`);
	}
	static getPatientSchedule(id) {
		return getData(`patients/schedule/${id}/`);
	}

	static getEmailFields(id, studyId) {
		if (studyId) {
			return getAuthData(`patients/email/physician/${id}/${studyId}/`);
		}
		return getAuthData(`patients/email/physician/${id}/`);
	}

	static getEmptyContactFields(id) {
		return getAuthData(`patients/${id}/personal/`);
	}

	static patchEmptyContactFields(id, data) {
		return patchAuthData(data, `patients/${id}/personal/`);
	}

	static getPatientEmails(id) {
		return getAuthData(`patients/email/${id}/`);
	}

	static changePassword(object) {
		return postAuthData(object, `users/password/`);
	}

	static createAccount(user) {
		return postAuthData(user, `users/createAccount/`);
	}

	static editAccount(user) {
		const headers = ["SCHEMA", "public"];
		return patchAuthData(user, `users/createAccount/`, headers);
	}

	static editProfile(data) {
		const headers = Object.assign({
			Authorization: "Bearer " + localStorage.getItem("token"),
			SCHEMA: "public",
		});

		return new Promise(function (resolve, reject) {
			axios
				.patch(
					`${process.env.REACT_APP_API_PATH}/users/edit/`,
					data,
					{
						headers: headers,
					}
				)
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					reject(error);
				});
		});

		// const request = new Request(
		// 	process.env.REACT_APP_API_PATH + "/users/edit/",
		// 	{
		// 		method: "PATCH",
		// 		headers: headers,
		// 		body: data,
		// 	}
		// );
		//
		// return simpleRequest(request)
	}

	static sendProfilePhoto(photo, id) {
		const headers = Object.assign({
			Authorization: "Bearer " + localStorage.getItem("token"),
			// "Content-Type":
			// 	"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
			SCHEMA: "public",
		});
		const request = new Request(
			process.env.REACT_APP_API_PATH + "/users/uploadPhoto/" + id + "/",
			{
				method: "PATCH",
				headers: headers,
				body: photo,
			}
		);

		return NoJSONRequest(request)
	}

	static getPatientAttachments(immunityID) {
		return getAuthData(`patients/${immunityID}/attachment/`);
	}

	static getPatientImportHistory(url) {
		let ulrAddr = url
		if (!ulrAddr) {
			ulrAddr = `${process.env.REACT_APP_API_PATH}/patients/import/`
		}
		return getAuthDataFullLink(ulrAddr);
	}

	static uploadPatient(file) {
		const data = new FormData();
		data.append("file", file);

		const token = localStorage.getItem("token");

		return new Promise(function (resolve, reject) {
			axios
				.post(
					`${process.env.REACT_APP_API_PATH}/patients/import/`,
					data,
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				)
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	static uploadPatientAttachment(immunityID, file) {
		const data = new FormData();
		data.append("file", file);

		const token = localStorage.getItem("token");

		return new Promise(function (resolve, reject) {
			axios
				.post(
					`${process.env.REACT_APP_API_PATH}/patients/${immunityID}/attachment/`,
					data,
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				)
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	static deletePatientAttachment(immunityID, fileID) {
		return deleteAuthData(`patients/${immunityID}/attachment/${fileID}/`);
	}

	static downloadPatientAttachment(immunityID, fileID) {
		return getAuthDataNoJSON(`patients/${immunityID}/attachment/${fileID}/`);
	}

	static getUser() {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/whoami/`, false, headers);
	}
	static getNotification(url = "api/notification") {
		return getAuthDataNotification(url);
	}

	static getPatientStatus(patient, trial) {
		return getAuthData(`patients/${patient}/${trial}/status/`);
	}

	static getNotificationStatus() {
		return getAuthDataNotification(`api/notification/status`);
	}
	static readNotification(id) {
		return putAuthDataNotification(null, `api/notification/${id}`);
	}
	static markAllReadNotification() {
		return putAuthDataNotification(null, `api/notification/allread`);
	}

	static externalCreateAccount(user) {
		return postData(user, `users/pending/create/`);
	}

	static resetPassword(email) {
		return postData(email, "users/password/reset/");
	}

	static setNewPassword(obj, uidb64, token) {
		return putData(obj, `users/password/reset/?user=${uidb64}&token=${token}`);
	}

	static createTrial(trial) {
		return postAuthData(trial, `studies/`);
	}

	static createTrialTest(trial) {
		return postAuthData(trial, "studies/");
	}

	static editTrialTest(id, obj) {
		return putAuthData(obj, `studies/${id}/`);
	}

	static getTrialNotes(hadmId) {
		return getData(`patients/trialnotes/${hadmId}/`);
	}

	static saveTrialNotes(hadmId, data) {
		return postData({}, `patients/trialnotes/${hadmId}/`);
	}

	static getPhysiciansSearch() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/practitioners`,
			true
		);
	}

	static getAllStudies() {
		return getAuthData("studies/all/");
	}

	static getDashboardSites(dateFrom, dateTo) {
		let params = ""

		if (dateFrom && dateTo) {
			params = `?dateFrom=${dateFrom}&dateTo=${dateTo}`
		} else if (dateFrom && !dateTo) {
			params = `?dateFrom=${dateFrom}`
		} else if (!dateFrom && dateTo) {
			params = `?dateTo=${dateTo}`
		}
		return getAuthData(`patients/dashboard/sites/${params}`);
	}

	static getUnmatchedTrials(hadmId) {
		return getAuthData(`patients/${hadmId}/studies/notmatch/`);
	}

	static getStudiesByPhysician(physicianId) {
		return getData(`studies/physician/${physicianId}`);
	}

	static getPrepopulationOfReminder(id) {
		return getAuthData(`patients/dashboard/reminder/${id}/`);
	}

	static patchReminder(id, obj) {
		return patchAuthData(obj, `patients/dashboard/reminders/${id}/`);
	}

	static editTrial(id, trial) {
		return patchAuthData(trial, `studies/${id}/`);
	}

	static editUserRole(id, role) {
		const headers = ["SCHEMA", "public"];
		return patchAuthData({}, `users/roles/${id}/${role}/`, headers);
	}

	static simulateTrial(trial) {
		return postData(trial, `search/`);
	}

	static retireTrial(id) {
		return deleteAuthData(`studies/${id}/`);
	}

	static retireTrialTest(id) {
		return putAuthData(null, `studies/retired/${id}/`);
	}

	static deleteTrial(id) {
		return deleteAuthData(`studies/delete/${id}/`);
	}

	static deleteTrialTest(id) {
		return deleteAuthData(`studies/${id}/`);
	}

	static unretireTrial(id) {
		return postData({}, `studies/${id}/unretire/`);
	}

	static unretireTrialTest(id) {
		return deleteAuthData(`studies/retired/${id}/`);
	}

	static getAllCharts() {
		return getData("charts/");
	}

	static getCharts(id) {
		return getData(`charts/${id}/`);
	}

	static newSession(user, password, company) {
		const headers = Object.assign({
			"Content-Type": "application/json",
			SCHEMA: "public",
		});
		const request = new Request(
			process.env.REACT_APP_API_PATH + "/users/session/",
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					username: user,
					password: password,
					company: company,
				}),
			}
		);
		return fetch(request)
			.then(response => {
				return response.status === 200 ? response.json() : null;
			})
			.catch(error => {
				return error;
			});
	}

	static queryUserCompany(user, password) {
		const headers = Object.assign({
			"Content-Type": "application/json",
			SCHEMA: "public",
		});
		const request = new Request(
			process.env.REACT_APP_API_PATH + "/users/companies/",
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify({ username: user, password: password }),
			}
		);
		return fetch(request)
			.then(response => {
				return response.status === 200 ? response.json() : null;
			})
			.catch(error => {
				return error;
			});
	}

	static getUserSettings() {
		return getAuthDataNotification(`api/permission`);
	}

	static editUserSettings(data) {
		const headers = ["SCHEMA", "public"];
		return patchAuthDataNotification(data, `api/permission`);
	}

	static getAllUsers() {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/`, false, headers);
	}
	static getOnlineUsers() {
		return getAuthDataNotification(`api/users`);
	}

	// -------------------------------------------------
	static getPendingUsers() {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/pending/`, false, headers);
	}

	static getAllRoles() {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/roles/`, false, headers);
	}

	static createNewRole(object) {
		return postAuthData(object, `users/roles/`);
	}

	static editRole(data, roleId) {
		return patchAuthData(data, `users/roles/${roleId}/`);
	}

	static getAllRolesShort() {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/roles/short/`, false, headers);
	}

	static pendingUserAccept(pendingId, roleObj) {
		const headers = ["SCHEMA", "public"];
		return postAuthData(roleObj, `users/pending/${pendingId}/`, false, headers);
	}

	static pendingUserDecline(pendingId) {
		const headers = ["SCHEMA", "public"];
		return deleteAuthData(`users/pending/${pendingId}/`, headers);
	}

	static getUserById(userId) {
		const headers = ["SCHEMA", "public"];
		return getAuthData(`users/${userId}/`, false, headers);
	}

	static editUserById(data, userId) {
		return patchAuthData(data, `users/${userId}/`);
	}

	static deleteUserById(userId) {
		const headers = ["SCHEMA", "public"];
		return deleteAuthData(`users/${userId}/`, headers);
	}

	static inviteEmail(obj) {
		return postAuthData(obj, `users/email/invite/`);
	}

	static getSavedSearches() {
		return getAuthData(`search/patients/saved/`);
	}

	static getSearchStatus(id) {
		return getAuthData(`search/patients/status/${id}/`);
	}

	static getRecentSearches() {
		return getAuthData("search/patients/recent/");
	}

	static patientSearch(obj) {
		return postAuthData(obj, `search/patients/save/`);
	}

	static getPatientSearchInfoId(id) {
		const path = process.env.REACT_APP_MICROSERVICE_PATH + `/search/${id}`;
		return getAuthDataNoJSON(path, true);
	}

	static getPatientSearchInfo(id) {
		const path =
			process.env.REACT_APP_MICROSERVICE_PATH + `/search/${id}/result`;
		return getAuthDataNoJSON(path, true);
	}
	static getPatientSearchInfoFiltered(id, option) {
		const path =
			process.env.REACT_APP_MICROSERVICE_PATH +
			`/search/${id}/result?${option}`;

		return getAuthDataFullLink(path);
	}

	static getPrepopulationOfSearch(id) {
		return getAuthData(`search/${id}/`);
	}

	static patientSaveSearch(obj) {
		return postAuthData(obj, `search/patients/save/`);
	}

	static deletePatientSearchFilter(obj, id) {
		const path =
			process.env.REACT_APP_MICROSERVICE_PATH +
			`/search/${id}/result?exclude=${obj.criteria}`;
		return getAuthDataNoJSON(path, true);
	}

	static getAllExclusionPatients() {
		return getAuthData(`studies/exclusions/`);
	}

	static getExclusionPatients(studyId) {
		return getAuthData(`studies/exclusions/${studyId}/`);
	}

	static getEnrolledPatients(studyId) {
		return getAuthData(`studies/enrolls/${studyId ? studyId : ""}`);
	}

	static getPatientSearchPaginationFullUrl(url) {
		const path = process.env.REACT_APP_MICROSERVICE_PATH + url.substring(4);
		return getAuthDataFullLink(path);
	}

	static getPatientSearchPagination(url) {
		return getAuthDataFullLink(url);
	}

	static getPatientSearchFilters(id) {
		const path =
			process.env.REACT_APP_MICROSERVICE_PATH + `/search/${id}/criteria`;
		return getAuthDataFullLink(path);
	}

	static patientDeleteFilters(obj, id) {
		const path =
			process.env.REACT_APP_MICROSERVICE_PATH +
			`/search/${id}/criteria?exclude=${obj.criteria}`;
		return getAuthDataFullLink(path);
	}

	//   static getPatientSearchFilters(id) {
	//   return getAuthData(`search/patients/criteria/${id}/`);
	// }

	static runSearch(searchId) {
		return getAuthDataNoJSON(`search/patients/run/${searchId}/`);
	}

	static getExclusionReasons() {
		return getData("patients/exclusionreason/");
	}

	static getLastStages() {
		return getAuthData("patients/laststage/");
	}

	static getMatchedCriteria(hadmId, studyId) {
		return getAuthData(`patients/getmatchedcriteria/${hadmId}/${studyId}/`);
	}

	static getPatientWorkflow(hadmId, studyId) {
		return getAuthData(
			`patients/${hadmId}/short/${studyId ? "?study=" + studyId : ""}`
		);
	}

	static getPatientMatchedCriteria(immunityId, studyId) {
		return getAuthData(
			`studies/${studyId}/criteria/${immunityId}`
		);
	}

	static getPatientMatchedStatus(immunityId, studyId) {
		if (studyId) {
			return getAuthData(`patients/${immunityId}/status/matches/?study=${studyId}`)
		} else {
			return getAuthData(`patients/${immunityId}/status/matches/`)
		};
	}

	static getPatientEnrolledStatus(immunityId, studyId) {
		if (studyId) {
			return getAuthData(`patients/${immunityId}/status/enrolled/?study=${studyId}`)
		} else {
			return getAuthData(`patients/${immunityId}/status/enrolled/`)
		}
	}

	static getPatientCandidateStatus(immunityId, studyId) {
		if (studyId) {
			return getAuthData(`patients/${immunityId}/status/candidates/?study=${studyId}`)
		} else {
			return getAuthData(`patients/${immunityId}/status/candidates/`);
		}
	}

	static getDashboardEvents(status, studyId, physicianId) {
		return getAuthData(
			`patients/dashboardevents/?status=${status}&study=${studyId}&physician=${physicianId}`
		);
	}

	static getDashboardEventsNew(params) {
		return getAuthData(`patients/dashboard/?${params}`);
	}

	static getVisitsEventsNew(params) {
		return getAuthData(`patients/dashboard/visits/?${params}`);
	}

	static getDashboardReminders(url, query) {
		if (url) {
			return getAuthData(`${url}${query ? "?ordering=" + query : ""}`, true);
		} else {
			return getAuthData(
				`${process.env.REACT_APP_API_PATH}/patients/dashboard/reminder/${
					query ? "?ordering=" + query : ""
				}`,
				true
			);
		}
	}
	static getGeographyData() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/hospitals`,
			true
		);
	}

	static sendContactNotes(hadmId, studyId, obj) {
		return postAuthData(obj, `patients/notes/contact/${hadmId}/${studyId}/`);
	}

	static getAllPhysicians() {
		return getAuthData(`patients/physicians/`);
	}

	static getAllHadmAndImmunityIds() {
		return getAuthData(`patients/`);
	}

	static createReminder(obj) {
		return postAuthData(obj, `patients/dashboard/reminders/create/`);
	}

	static getPatientDetails(token) {
		return getDataNoJSON(`patients/consent/physician/?token=${token}`);
	}

	static setPatientDetails(obj) {
		return postData(obj, "patients/consent/physician/");
	}

	static getPatientAuthorization(token) {
		return getDataNoJSON(`patients/consent/patient/?token=${token}`);
	}

	static postPatientAuthorization(object) {
		return postData(object, `patients/consent/patient/?token=${object.token}`);
	}

	static declinePatientAuthorization(object) {
		return postData(object, "patients/decline/patient/");
	}

	static declinePatientDetails(object) {
		return postData(object, "patients/decline/physician/");
	}

	static getChartsGoal(id) {
		return getAuthData(`charts/goal/${id ? `${id}/` : ""}`);
	}

	static getAllChartsGoal() {
		return getAuthData(`charts/goal/`);
	}

	static getChartsExclusion(id) {
		return getAuthData(`charts/exclusion/${id ? `${id}/` : ""}`);
	}

	static getAllChartsExclusion() {
		return getAuthData(`charts/exclusion/`);
	}

	static getPhysicianBehavior(id) {
		return getAuthData(`charts/physician/${id ? `${id}/` : ""}`);
	}

	static getAllPhysicianBehavior() {
		return getAuthData(`charts/physician/`);
	}

	static getPatientBehavior(id) {
		return getAuthData(`charts/patient/${id ? `${id}/` : ""}`);
	}

	static getAllPatientBehavior() {
		return getAuthData(`charts/patient/`);
	}

	static getPatientsSites(search_id, name = "") {
		if (name) {
			return getAuthData(`charts/search/${search_id}/?hospital=${name}`);
		} else {
			return getAuthData(`charts/search/${search_id}/`);
		}
	}

	static getAllSites(search_id) {
		return getAuthData(`charts/search/${search_id}/details/`);
	}

	static getPatientsHospitalSite(search_id) {
		return getAuthData(`charts/search/${search_id}/chart/`);
	}

	static getAllSubstances() {
		return getAuthData(`studies/substances/`);
	}

	static getAllSubstancesSearch() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/substance`,
			true
		);
	}

	static getSmoking() {
		return getAuthData(`studies/smoking/`);
	}

	static getMedicationsRootNew() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/medication`,
			true
		);
	}

	static getDiseasesRootNew() {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/diseases`,
			true
		);
	}

	static getAnalytics(search_id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}`,
			true
		);
	}
	static getTopChartAnalytics(search_id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/top`,
			true
		);
	}

	static getHospitalsGender(search_id, id = null) {
		let url = `${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/gender`;
		if (id) url += `?id=${id}`;
		return getAuthData(url, true);
	}

	static getHospitalsAge(search_id, id = null) {
		let url = `${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/age`;
		if (id) url += `?id=${id}`;
		return getAuthData(url, true);
	}
	static getHospitalsEthnicity(search_id, id = null) {
		let url = `${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/ethnicity`;
		if (id) url += `?id=${id}`;
		return getAuthData(url, true);
	}
	static getPatientsHospitals(search_id) {
		return getAuthData(
			`${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals`,
			true
		);
	}

	static getHospitalPatientInfo(search_id, name, id) {
		if (name === "All Sites") {
			return getAuthData(
				`${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/details`,
				true
			);
		} else {
			return getAuthData(
				`${process.env.REACT_APP_MICROSERVICE_PATH}/analytic/${search_id}/hospitals/details?id=${id}`,
				true
			);
		}
	}

	static getUserPremissions = () => {
		return getAuthData(`users/permission/`)
	}

}

export default InmunityApi;
