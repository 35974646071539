import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import { Circle } from "rc-progress";
import moment from "moment";

class AllExclusions extends Component {
  state = { currentTrialNumber: 0 };

  componentDidMount() {
    this.setState({
      exclusions: this.props.candidate.exclusions
    });
  }

  goToDetails = ({ target }) => {
    if (!target.classList.contains("arrow-next-page")) {
      let { history } = this.props;
      let hadmId = this.props.candidate.hadmId;
      let studyId = this.props.candidate.studyId;
      studyId
        ? history.push({
          pathname: `/trial/${studyId}/matches/details/${hadmId}`,
          id: hadmId,
          detailTitle: "Patient Details"
        })
        : history.push({
          pathname: `/matches/details/${hadmId}`,
          id: hadmId,
          detailTitle: "Patient Details"
        });
    }
  };

  makeExclusions = exclusionCriteria => {
    let result = "";
    exclusionCriteria &&
      exclusionCriteria.forEach(criteria => {
        criteria.criteria.forEach(el => {
          result += `${el}<br>`;
        });
      });

    return result;
  };

  makeTrials = obj => {
    let result = "";
    obj.sort((a, b) => b.protocolMatch - a.protocolMatch);
    obj.forEach(trial => {
      let precntage = trial.protocolMatch;
      result += ` ${trial.protocol} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeExclusionsTrials = array => {
    let result = "";
    array.sort((a, b) => b.name - a.name);
    array.forEach(trial => {
      let precntage = trial.rate * 100;
      result += ` ${trial.name} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeExclusionsReasons = array => {
    let result = {};
    result.res = "";
    result.crOther = "";
    result.crMatched = "";
    result.crUnMatched = "";
    let ar = array.filter(el => {
      return el.criteria.length === 0;
    });
    let arrayWithCriteria = array.filter(el => {
      return el.criteria.length !== 0;
    });
    let a = [];
    arrayWithCriteria.length > 0
      ? (a = arrayWithCriteria)
      : a.push(array[0] ? array[0] : []);
    result.arrayWithCriteria = a;
    if (arrayWithCriteria.length > 0) {
      result.count = ar.length;
    } else {
      ar.splice(0, 1);
      result.count = ar.length;
    }
    ar.sort((a, b) => b.name - a.name);
    ar.forEach(elem => {
      result.res += ` ${elem.name}<br>`;
    });
    arrayWithCriteria.forEach(e => {
      if (e.name === "Other") {
        e.criteria.forEach(el => {
          result.crOther += `${el}<br>`;
        });
      } else if (e.name === "Matched exclusion criteria") {
        e.criteria.forEach(el => {
          result.crMatched += `${el}<br>`;
        });
      } else if (e.name === "Did not match inclusion criteria") {
        e.criteria.forEach(el => {
          result.crUnMatched += `${el}<br>`;
        });
      }
    });
    return result;
  };

  changeCurrentTrialInfo = way => {
    if (way === "left") {
      if (this.state.currentTrialNumber === 0) {
        this.setState({
          currentTrialNumber: this.state.exclusions.length - 1
        });
      } else {
        this.setState({
          currentTrialNumber: this.state.currentTrialNumber - 1
        });
      }
    } else if (way === "right") {
      if (this.state.currentTrialNumber === this.state.exclusions.length - 1) {
        this.setState({
          currentTrialNumber: 0
        });
      } else {
        this.setState({
          currentTrialNumber: this.state.currentTrialNumber + 1
        });
      }
    }
  };

  render() {
    let { immunityId, studyTags, name } = this.props.candidate;
    let trials = this.makeTrials(studyTags);
    let exclusions =
      this.state.exclusions &&
      this.makeExclusionsReasons(
        this.state.exclusions[this.state.currentTrialNumber].reasons
      );
    return (
      <div
        className="candidatesWr exclusionsPage allEnrolled"
        onClick={this.goToDetails}
        style={{ zIndex: 1 }}
      >
        <div
          className="candidates hadmId forExclusionTooltip"
          style={{ width: "20%" }}
        >
          <span className="imm-id">Candidate Information</span>
          <span className="td-content">{immunityId}</span>
          <span className="td-content">{name}</span>
          {studyTags
            ? studyTags.length !== 0 && (
              <span
                className="td-content candidatesAmTrial"
                data-tip={trials}
                data-class="toolTip"
                data-for={this.props.id}
                data-multiline={true}
              >
                {studyTags.length > 1 && `${studyTags.length} trials`}
                {studyTags.length === 1 && `${studyTags.length} trial`}
              </span>
            )
            : ""}
          {studyTags
            ? studyTags.length !== 0 && (
              <ReactTooltip
                place="bottom"
                type="light"
                id={this.props.id}
                effect="solid"
              />
            )
            : ""}
        </div>
        <div className="candidates c-physician" style={{ width: "20%" }}>
          <span className="forIcon">
            {`Excluded${
              this.state.exclusions && this.state.exclusions.length > 1
                ? ` (${this.state.exclusions.length})`
                : ""
              }`}
          </span>
          <div className="illich_arrows">
            {this.state.exclusions && this.state.exclusions.length > 1 && (
              <img
                src={require("./../../images/left-b.png")}
                alt="Left"
                height="12px"
                style={{
                  paddingRight: "5px",

                }}
                className="arrow-next-page"

                onClick={() => this.changeCurrentTrialInfo("left")}

              />
            )}
            <span className="td-content">
              {this.state.exclusions
                ? this.state.exclusions[this.state.currentTrialNumber].name
                : ""}
            </span>
            {this.state.exclusions && this.state.exclusions.length > 1 && (
              <img
                src={require("./../../images/left-b.png")}
                alt="Left"
                height="12px"
                className="arrow-next-page"
                style={{
                  paddingRight: "5px",
                  transform: "rotate(180deg)",
                }}
                onClick={() => this.changeCurrentTrialInfo("right")}

              />
            )}
          </div>
        </div>
        <div
          className="candidates forlsuv"
          style={{ width: "20%", alignItems: "flex-start" }}
        >
          <div className="c-lastSeen">
            <span>Date of exclusion</span>
            <span className="td-content">
              {this.state.exclusions
                ? moment(
                  this.state.exclusions[this.state.currentTrialNumber]
                    .exclusionTime
                ).format("MMM DD YYYY")
                : ""}
            </span>
          </div>
        </div>
        <div
          // className="candidates c-physician lastencForCircle"
          className="candidates c-physician"
          style={{ width: "20%" }}
        >
          <span className="pad0">Reason for exclusion</span>
          {this.state.exclusions && (
            <div className="centerItems">
              <div
                className={
                  exclusions.arrayWithCriteria && exclusions.count > 0
                    ? "width90"
                    : ""
                }
              >
                {exclusions.arrayWithCriteria &&
                  exclusions.arrayWithCriteria.length > 0 &&
                  exclusions.arrayWithCriteria.map((el, i) => {
                    return (
                      <div key={i} className="centerItems">
                        <span className="td-content pad0">{el.name}</span>
                        {el.name === "Other" && exclusions.crOther !== "" && (
                          <span className="infoToolTip pad0mT10">
                            <span
                              data-tip={exclusions.crOther}
                              data-class="toolTip"
                              className="toolTipInfoEx"
                              data-multiline={true}
                              data-for={`infoEx${this.state.currentTrialNumber +
                                "cTi" +
                                immunityId +
                                "i" +
                                i}`}
                            >
                              i
                            </span>
                            <ReactTooltip
                              place="bottom"
                              id={`infoEx${this.state.currentTrialNumber +
                                "cTi" +
                                immunityId +
                                "i" +
                                i}`}
                              type="light"
                              effect="solid"
                            />
                          </span>
                        )}
                        {el.name === "Matched exclusion criteria" &&
                          exclusions.crMatched !== "" && (
                            <span className="infoToolTip pad0mT10">
                              <span
                                data-tip={exclusions.crMatched}
                                data-class="toolTip"
                                className="toolTipInfoEx"
                                data-multiline={true}
                                data-for={`infoEx${this.state
                                  .currentTrialNumber +
                                  "cTi" +
                                  immunityId +
                                  "i" +
                                  i}`}
                              >
                                i
                              </span>
                              <ReactTooltip
                                place="bottom"
                                id={`infoEx${this.state.currentTrialNumber +
                                  "cTi" +
                                  immunityId +
                                  "i" +
                                  i}`}
                                type="light"
                                effect="solid"
                              />
                            </span>
                          )}
                        {el.name === "Did not match inclusion criteria" &&
                          exclusions.crUnMatched !== "" && (
                            <span className="infoToolTip pad0mT10">
                              <span
                                data-tip={exclusions.crUnMatched}
                                data-class="toolTip"
                                className="toolTipInfoEx"
                                data-multiline={true}
                                data-for={`infoEx${this.state
                                  .currentTrialNumber +
                                  "cTi" +
                                  immunityId +
                                  "i" +
                                  i}`}
                              >
                                i
                              </span>
                              <ReactTooltip
                                place="bottom"
                                id={`infoEx${this.state.currentTrialNumber +
                                  "cTi" +
                                  immunityId +
                                  "i" +
                                  i}`}
                                type="light"
                                effect="solid"
                              />
                            </span>
                          )}
                      </div>
                    );
                  })}
              </div>
              {exclusions.arrayWithCriteria && exclusions.count > 0 && (
                <div
                  className="multiIndicator exclusionIndicator"
                  data-tip={exclusions.res}
                  data-class="match"
                  data-for={`reasonEx${this.state.currentTrialNumber +
                    "cTn" +
                    immunityId}`}
                  data-multiline={true}
                >
                  +{exclusions.count}
                </div>
              )}
              <ReactTooltip
                id={`reasonEx${this.state.currentTrialNumber +
                  "cTn" +
                  immunityId}`}
                place="bottom"
                type="light"
                effect="solid"
              />
            </div>
          )}
        </div>
        <div className="candidates enrolled lastenc" style={{ width: "22%" }}>
          <div>
            <span>Last Stage</span>
            <span className="td-content td-contant-wrap">
              {this.state.exclusions
                ? this.state.exclusions[this.state.currentTrialNumber]
                  .last_stage
                : ""}
            </span>
          </div>
          <div className="lastencForCircle">
            <Circle
              percent={
                this.state.exclusions
                  ? this.state.exclusions[this.state.currentTrialNumber].rate
                  : ""
              }
              strokeWidth="5"
              trailWidth="0"
              strokeColor="#5BBEBF"
              className="lastenCircle"
            />
            <span className="circleNumber">
              {this.state.exclusions
                ? Math.round(
                  this.state.exclusions[this.state.currentTrialNumber].rate
                ) + "%"
                : ""}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AllExclusions);
