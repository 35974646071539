import React, { useState, useEffect } from "react";
import DataArray from "./DataArray";
import LabDataArray from "./LabDataArray";
import SmokingDataArray from "./SmokingDataArray";

export default function CriteriaInformationBlock({
  globalText,
  eligibility_criteria
}) {
  const [isFreetext, setIsFreetext] = useState(false);
  const [medsStructured, setMedsStructured] = useState([]);
  const [disStructured, setDisStructured] = useState([]);
  const [procStructured, setProcStructured] = useState([]);
  const [labStructured, setLabStructured] = useState([]);
  const [allergyStructured, setAllergyStructured] = useState([]);
  const [smokesStructured, setSmokesStructured] = useState([]);
  const [subsStructured, setSubsStructured] = useState([]);
  const [noFreetextAvailable, setNoFreeTextAvailable] = useState(true);
  const changeView = () => {
    setIsFreetext(!isFreetext);
  };
  useEffect(() => {
    globalText ? setIsFreetext(true) : setIsFreetext(false);
  }, [globalText]);
  useEffect(() => {
    let meds = [],
      dis = [],
      proc = [],
      lab = [],
      subs = [],
      smokes = [],
      allergy = [];
    eligibility_criteria &&
      eligibility_criteria.forEach(el => {
        const { criteria, freeText } = el;
        freeText.length > 0 && setNoFreeTextAvailable(false);
        criteria.forEach(elem => {
          if (elem.criteria === "Medication") {
            meds.push(elem);
          }
          if (elem.criteria === "Disease") {
            dis.push(elem);
          }
          if (elem.criteria === "Procedure") {
            proc.push(elem);
          }
          if (elem.criteria === "Lab") {
            lab.push(elem);
          }
          if (elem.criteria === "Substance") {
            subs.push(elem);
          }
          if (elem.criteria === "Smoking") {
            smokes.push(elem);
          }
          if (elem.criteria === "Allergy") {
            allergy.push(elem)
          }
        });
      });
    setMedsStructured(meds);
    setDisStructured(dis);
    setProcStructured(proc);
    setLabStructured(lab);
    setAllergyStructured(allergy);
    setSubsStructured(subs);
    setSmokesStructured(smokes);
  }, [eligibility_criteria]);
  return (
    <div
      className="ntw__trialInformation ntw__ElegibilityCriteria El-info"
      id="criteria"
    >
      <div className="ntw__EC-header">
        <h1>Eligibility Criteria</h1>
        <div className="EC-changer" onClick={changeView}>
          <span className={isFreetext ? "freetext active" : "freetext"}>
            Free-text Version
          </span>
          <span className={!isFreetext ? "dynamic active" : "dynamic"}>
            Structured Version
          </span>
        </div>
      </div>
      {!isFreetext && (
        <div className="structuredVersionWrapp" data-test="noFreeText">
          <DataArray data={medsStructured} title="Medication Filters" />
          <DataArray data={disStructured} title="Disease Filters" />
          <DataArray data={procStructured} title="Procedure History" />
          <DataArray data={allergyStructured} title="Allergy" />
          <DataArray data={subsStructured} title="Substance Use" />
          <LabDataArray data={labStructured} />
          <SmokingDataArray data={smokesStructured} />
        </div>
      )}
      {isFreetext && (
        <div
          className="freetextVersionWrapp wrappForLists"
          data-test="freeText"
        >
          <div className="ntw__ti-inputs ntw__ti-input ntw__ti-iDescr globalTextWrapp">
            <div className="tfc-title">Full Eligibility Criteria</div>
            <div className="tfc-content">
              <textarea defaultValue={globalText} disabled />
            </div>
          </div>
          {!noFreetextAvailable &&
            eligibility_criteria.map((el, i) => {
              if (el.freeText) {
                return (
                  <div
                    className="ntw__ti-inputs ntw__ti-input  ntw__ti-ifreetext ntw__ti-ifreetext93 list"
                    key={i}
                  >
                    <span className="ntw__ti-inputlabel">
                      Eligibility Criterion (free-text)
                    </span>
                    <textarea
                      className="get_form"
                      defaultValue={el.freeText}
                      maxLength="10000"
                      style={{ marginBottom: "25px" }}
                      disabled
                    />
                    <DataArray
                      data={el.criteria.filter(
                        el => el.criteria === "Medication"
                      )}
                      title="Medication Filters"
                    />
                    <DataArray
                      data={el.criteria.filter(el => el.criteria === "Disease")}
                      title="Disease Filters"
                    />
                    <DataArray
                      data={el.criteria.filter(
                        el => el.criteria === "Procedure"
                      )}
                      title="Procedure History"
                    />
                    <LabDataArray
                      data={el.criteria.filter(el => el.criteria === "Lab")}
                    />
                    <SmokingDataArray
                      data={el.criteria.filter(el => el.criteria === "Smoking")}
                    />
                    <DataArray
                      data={el.criteria.filter(
                        el => el.criteria === "Allergy"
                      )}
                      title="Allergy"
                    />
                    <DataArray
                      data={el.criteria.filter(
                        el => el.criteria === "Substance"
                      )}
                      title="Substance Use"
                    />
                  </div>
                );
              } else {
                return undefined
              }
            })}
        </div>
      )}
      {isFreetext && noFreetextAvailable && (
        <div
          className="freetextVersionWrapp wrappForLists"
          data-test="noFreeTextAvailable"
        >
          <div className="noFreetextAvailable">
            No free-text criteria version available
          </div>
        </div>
      )}
    </div>
  );
}
