import React from 'react'
import information from '../images/information.svg'
import email from '../images/envelope-FontAwesome.svg'
import arrow from '../images/left.png'
import crossicon from '../images/icon-close-w.svg'
import './button.css'
export const InfoIcon = ({ click }) => {
  return (
    <div className='button-center button-blue' onClick={click}>
      <img className='button__icon' src={information} alt='I' />
    </div>
  )
}
export const MailButton = ({ click }) => {
  return (
    <div className='button-center button-green' onClick={click}>
      <img className='button__icon' src={email} alt='E-mail' />
    </div>
  )
}
export const GreenButton = ({ click, text, position }) => {
  return (
    <div className={`medium__button-${position}`}>
      <div className='medium__button-green' onClick={click}>
        {text}
      </div>
    </div>
  )
}

export const DisabledButton = ({text, position}) => {
    return (
        <div className={`medium__button-${position}`}>
            <div className='medium__button-disabled'>
                {text}
            </div>
        </div>
    )
}


export const ArrowUp = ({ click }) => {
  return (
    <div className='arrow-up__button' onClick={click}>
      <img src={arrow} alt='Arrow' />
    </div>
  )
}

export const GreenSubmitButton = ({
  width = 300,
  text,
  wrapper_class = ''
}) => {
  return (
    <div className={wrapper_class}>
      <button
        style={{ width: width }}
        className='submit__button-green'
        type='submit'
      >
        {text}
      </button>
    </div>
  )
}

export const CloseIcon = ({ close }) => {
  return (
    <div className='closePendingPopupCross' onClick={close}>
      <img src={crossicon} alt='Cross' />
    </div>
  )
}
