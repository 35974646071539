import React from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../../../images/icon-close-w.svg";
import WarningPopup from "./../../../../Patients/TreeSelect/Footer/WarningPopup";

export default function ConfirmSavePopup({
  showSaveTrial,
  handleCloseStatus,
  sendForm,
  saveSuccess,
  currentTrial,
  showWarningPopup,
  list,
  closeWarningPopup,
  name,
  saveAs
}) {
  return (
    <Popup
      open={showSaveTrial}
      closeOnDocumentClick
      onClose={handleCloseStatus}
      className="popup-newtrial wider"
    >
      <div>
        <WarningPopup
          open={showWarningPopup}
          closeWarningPopup={closeWarningPopup}
          list={list}
        />
        <div className="closePendingPopupCross" onClick={handleCloseStatus}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="newTrialPopupWrapper">
          {saveSuccess === "" ? (
            <div className="import-container__header">
              <h1>
                {`Please confirm to ${
                  parseFloat(currentTrial) || parseFloat(currentTrial) === 0
                    ? saveAs
                      ? "save"
                      : "edit"
                    : "save"
                }
                ${name}`}
              </h1>
              <div className="forNTWBtns">
                <div className="fromScratch" onClick={() => sendForm(saveAs)}>
                  Confirm
                </div>
                <div className="fromTemplate" onClick={handleCloseStatus}>
                  Continue Set-up
                </div>
              </div>
            </div>
          ) : (
            <div className="import-container__header">
              {saveSuccess !== "spinner" ? (
                <h1>{saveSuccess}</h1>
              ) : (
                <h1>Saving in progress. This may take a minute...</h1>
              )}
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
}
