import * as types from '../actions/actionTypes'
const initialState = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : ''

export default function getUserReducer (state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      let user = JSON.stringify(action.user)
      localStorage.setItem('user', user)
      return action.user
    default:
      return state
  }
}
