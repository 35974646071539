export const URLGenerator = (trialID) => {
  return trialID
    ? [
      {
        name: `Information`,
        path: `/trials/${trialID}`,
        count: null,
        trial: trialID
      },
      {
        name: 'Matches',
        path: `/trials/${trialID}/matches`,
        count: null,
        trial: trialID
      },
      {
        name: 'Candidates',
        path: `/trials/${trialID}/candidates`,
        count: null,
        trial: trialID
      },
      {
        name: 'Exclusions',
        path: `/trials/${trialID}/exclusions`,
        count: null,
        trial: trialID
      },
      {
        name: 'Enrolled',
        path: `/trials/${trialID}/enrolled`,
        count: null,
        trial: trialID
      },
      {
        name: "Analytics",
        path: `/trials/${trialID}/analytics`,
        count: null,
        trial: trialID
      }
    ]
    : [
      {
        name: 'Matches',
        count: null,
        path: "/trials-matches",
        trial: null
      },
      {
        name: 'Candidates',
        count: null,
        path: "/all-trials",
        trial: null
      },
      {
        name: 'Exclusions',
        count: null,
        path: "/trials-exclusions",
        trial: null
      },
      {
        name: 'Enrolled',
        count: null,
        path: "/trials-enrolled",
        trial: null
      },
      {
        name: "Analytics",
        path: "/all-analytics",
        count: null,
        trial: null
      }
    ];
};
