import React, { Component } from "react";
import TrialsNav from "../../TrialsNav";
import { connect } from "react-redux";
import ELBlock from "../NewTrial/ELBlock";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  getTrialDescription
} from "../../../actions/trialActions";
import { URLGenerator } from "../../../utils/trailsNavigation";
import "./index.css";
import SearchInput from "./SearchInput";
import TrialDetails from "./TrialDetails";
import TrialInfo from "./TrialInfo";

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trialDetailsContent: "",
      trialNotesContent: "",
      arrayELBlock: [],
      searchText: [],
      noFreetextAvailable: !!(
        this.props.trial.freeCriteria &&
        this.props.trial.freeCriteria.length === 0
      )
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial);
    getTrialDescription(currentTrial);
    this.setState({
      trialDetailsContent: document.getElementById("trials-details-id")
        .innerHTML,
      currentTrial: currentTrial,
      trialNotesContent: document.getElementById("trials-notes-id").innerHTML,
    });
    this.fillArr();
    localStorage.setItem("fromTrial", this.props.history.location.pathname);
  }

  fillArr = () => {
    let arrayELBlock = this.state.arrayELBlock;

    this.props.trial.freeCriteria &&
      this.props.trial.freeCriteria.forEach(el => {
        if (el.criteria.length > 0) {
          let bigObj = {};
          bigObj.arrayOfBlocks = [];
          bigObj.freetext = el.freeText;
          el.criteria.forEach(block => {
            let obj = {};
            obj.criteria = block.criteriaType;
            obj.name = block.criteriaValue;
            obj.include = block.toInclude ? "Include" : "Exclude";
            obj.priority = block.priority;
            if (block.labDetails) {
              obj.labUoM = block.labDetails.uom;
              if (block.labDetails.dateFrom && block.labDetails.dateTo) {
                obj.dateRangeFrom = block.labDetails.dateFrom.slice(0, 10);
                obj.dateRangeTo = block.labDetails.dateTo.slice(0, 10);
              } else {
                obj.dateRangeFrom = "";
                obj.dateRangeTo = "";
              }
              obj.dateRangeIE = block.toInclude ? "Include" : "Exclude";
              if (block.labDetails.minValue && block.labDetails.maxValue) {
                obj.minValue = block.labDetails.minValue;
                obj.maxValue = block.labDetails.maxValue;
                obj.range = "Between";
              } else if (block.labDetails.minValue) {
                obj.range = "Min Value";
                obj.labvalue = block.labDetails.minValue;
              } else {
                obj.range = "Max Value";
                obj.labvalue = block.labDetails.maxValue;
              }
            }
            bigObj.arrayOfBlocks.push(obj);
          });
          arrayELBlock.push(<ELBlock elItem={bigObj} />);
          this.setState({
            arrayELBlock
          });
        }
      });
  };

  handleInputSearchWord = event => {
    const { value } = event.target;
    if (value.length > 1) {
      let searchText = [value];
      this.setState({ searchText });
    } else {
      this.setState({ searchText: [] });
    }
  };

  render() {
    const {
      gender,
      sponsor,
      study_arms,
      color,
      procedures,
      name,
      nct,
      brief_description,
      long_description,
      principal_investigator,
      phase,
      study_length,
      start_date,
      drug_type,
      drug_admin,
      goal,
      min_match,
      age_min,
      age_max,
      substance_status,
      smoking_status,
      global_text,
      eligibility_criteria
    } = this.props.trial;
    let { currentTrial, searchText } = this.state;
    return (
      <div className="content-body-matches">
        <div className="study-name" data-test="trialName">
          {name}
        </div>
        {
          currentTrial && <TrialsNav trialID={currentTrial} current={'information'} permissions={this.props.permissions}/>
        }
        <div className="matchDetailsWrapper">
          <div className="mdpotn">
            <div className="trial-details-container" data-test="trialDetails">
              <SearchInput handleInputSearchWord={this.handleInputSearchWord} />
              <TrialDetails
                searchText={searchText}
                sponsor={sponsor}
                name={name}
                color={color}
                nct={nct}
                brief_description={brief_description}
                long_description={long_description}
                principal_investigator={principal_investigator}
              />
            </div>
            <TrialInfo
              currentTrial={currentTrial}
              gender={gender}
              drugVersusPlacebo={study_arms}
              procedures={procedures}
              phase={phase}
              study_length={study_length}
              start_date={start_date}
              drug_type={drug_type}
              drug_admin={drug_admin}
              goal={goal}
              min_match={min_match}
              age_min={age_min}
              age_max={age_max}
              substance_status={substance_status}
              smoking_status={smoking_status}
              globalText={global_text}
              eligibility_criteria={eligibility_criteria}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trials: state.trials,
    trial: state.trial,
    retired: state.retired,
    permissions: state.permissions
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Information);
