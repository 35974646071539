import React from "react";
import "./index.css";

const StudyArm = ({
  remove,
  add,
  handleChangeStudyArms,
  defaultStudyArms,
  id
}) => {
  return (
    <div className="ntw_ti-container" style={{ marginTop: 0 }}>
      <div className="study-arm__wrapper">
        <input
          type="text"
          className="study-arm-name"
          placeholder="Study Arm Name"
          defaultValue={defaultStudyArms ? defaultStudyArms.name : null}
          onChange={e => handleChangeStudyArms(id, e.target.value, "name")}
        />
        <input
          type="number"
          className="study-arm-value"
          placeholder="Study Arm %"
          defaultValue={defaultStudyArms ? defaultStudyArms.percentage : null}
          onChange={e =>
            handleChangeStudyArms(id, e.target.value, "percentage")
          }
        />
      </div>
      <div className="study-arm__wrapper-icon">
        <div
          className="red-cross study-arm-red-cross"
          onClick={() => remove(id)}
        />
        {id === 0 ? (
          <div className="green-plus" onClick={add}>
            +
          </div>
        ) : (
          <div className="empty-plus" />
        )}
      </div>
    </div>
  );
};

export default StudyArm;
