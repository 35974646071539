import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setProceduresICD9ListReducer(
  state = initialState.proceduresICD9List,
  action
) {
  switch (action.type) {
    case types.SET_PROCEDURES_ICD9_LIST:
      return action.proceduresICD9List;
    default:
      return state;
  }
}
