import React, { PureComponent } from "react";
import crossicon from "../../../images/icon-close-w.svg";
import checkmark from "../../../images/icon-check-w.svg";
import Select from "react-select";
import Popup from "reactjs-popup";
import InmunityApi from "../../../api/InmunityApi";
import { connect } from "react-redux";

class PendingUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      declinePopup: false,
      acceptPopup: false,
      roleOption: null,
      acceptDecline: "",
      loading: false,
      roleOptions: []
    };
  }

  handleChangeRoleOption = roleOption => {
    this.setState({ roleOption });
  };

  openDeclinePopup = () => {
    this.setState({
      declinePopup: true,
      acceptDecline: ""
    });
  };

  closeDeclinePopup = () => {
    this.setState({
      declinePopup: false
    });
  };

  openAcceptPopup = e => {
    if (this.state.roleOption) {
      this.setState({
        acceptPopup: true,
        acceptDecline: ""
      });
      e.target
        .closest(".pendingUser")
        .children[2].children[0].classList.remove("redBorder");
    } else {
      e.target
        .closest(".pendingUser")
        .children[2].children[0].classList.add("redBorder");
    }
  };

  closeAcceptPopup = () => {
    this.setState({
      acceptPopup: false
    });
  };

  acceptUser = () => {
    //show spinner
    this.setState({ loading: true });
    const { updateList, fetchUsers } = this.props;
    const { roleOption, id } = this.state;

    let obj = {
      role: roleOption.value
    };

    InmunityApi.pendingUserAccept(id, obj)
      .then(response => {
        if (response.status !== 200) {
          return response.text().then(text => {
            this.setState({
              loading: false,
              acceptDecline: "An error occurred while accepting"
            });
            throw new Error(text);
          });
        }
        this.setState({
          loading: false,
          acceptDecline: "User accepted successfully"
        });
        updateList();
        fetchUsers();
      })
      .catch(err => {
        console.log("ERROR: ", err);
      });
  };

  declineUser = () => {
    //show spinner
    this.setState({ loading: true });

    InmunityApi.pendingUserDecline(this.state.id)
      .then(response => {
        if (response.status !== 204) {
          return response.text().then(text => {
            this.setState({
              loading: false,
              acceptDecline: "An error occurred while declining"
            });
            throw new Error(text);
          });
        }
        this.setState({
          loading: false,
          acceptDecline: "User declined successfully"
        });
        this.props.updateList();
      })
      .catch(err => {
        console.log("ERROR: ", err);
      });
  };

  componentDidMount() {
    InmunityApi.getAllRolesShort()
      .then(res => {
        let roleOptions = [];
        res.forEach(role => {
          let obj = {};
          obj.value = role.id;
          obj.label = role.name;
          roleOptions.push(obj);
        });
        this.setState({ roleOptions });
      })
      .catch(error =>
        console.log("Error occured while getting roles: ", error)
      );
  }

  render() {
    return (
      <div className="pendingUser">
        <div className="pendingUser_first firstCol">
          <span className="pendingUserName">
            {this.props.firstName} {this.props.lastName}
          </span>
          <span className="pendingRequestDate">
            Requested on {this.props.data}
          </span>
        </div>
        <div className="pendingUser_first secondCol">
          <span className="pendingUserName">{this.props.email}</span>
          <span className="pendingUserPhone">{this.props.phone1}</span>
        </div>
        <div className="pendingUser_first forSelectWrapp newTrialWrapp">
          <Select
            value={this.state.roleOption}
            onChange={this.handleChangeRoleOption}
            options={this.state.roleOptions}
            className="sort_role withInput"
            placeholder="Assign a Role"
          />
        </div>
        <div className="wrappercrossiconcheckmark">
          <div
            className="pendingUser_first forBigIcon crossicon"
            onClick={this.openDeclinePopup}
          >
            <img src={crossicon} alt="Cross" />
          </div>
          <div
            className="pendingUser_first forBigIcon checkmark"
            onClick={e => this.openAcceptPopup(e)}
          >
            <img src={checkmark} alt="Check" />
          </div>
        </div>
        <Popup
          open={this.state.declinePopup}
          closeOnDocumentClick
          onClose={this.closeDeclinePopup}
          className="popup-declineAccept"
        >
          <div>
            {this.state.loading ? (
              <div className="center-align">
                <img
                  className="small-spinner"
                  src={require("../../../images/spinner.gif")}
                  alt=""
                />
              </div>
            ) : this.state.acceptDecline === "" ? (
              <div className="">
                <div className="title-form">
                  Please confirm declining {this.props.firstName}{" "}
                  {this.props.lastName} from the Immunity Health Platform.
                </div>
                <div className="button-container">
                  <div
                    className="search-container__plusbtn"
                    onClick={this.declineUser}
                  >
                    Decline user
                  </div>
                  <div
                    className="search-container__plusbtn-return"
                    onClick={this.closeDeclinePopup}
                  >
                    Return
                  </div>
                </div>
              </div>
            ) : (
              <div className="center-align">
                <div className="title-form">{this.state.acceptDecline}</div>
              </div>
            )}
            <div
              className="closePendingPopupCross"
              onClick={this.closeDeclinePopup}
            >
              <img src={crossicon} alt="Cross" />
            </div>
          </div>
        </Popup>

        <Popup
          open={this.state.acceptPopup}
          closeOnDocumentClick
          onClose={this.closeAcceptPopup}
          className="popup-declineAccept"
        >
          <div>
            {this.state.loading ? (
              <div className="center-align">
                <img
                  className="small-spinner"
                  src={require("../../../images/spinner.gif")}
                  alt=""
                />
              </div>
            ) : this.state.acceptDecline === "" ? (
              <div className="">
                <div className="title-form">
                  Please confirm accepting {this.props.firstName}{" "}
                  {this.props.lastName} as a{" "}
                  {this.state.roleOption ? this.state.roleOption.label : ""} to
                  the Immunity Health Platform.
                </div>
                <div className="button-container">
                  <div
                    className="search-container__plusbtn"
                    onClick={this.acceptUser}
                  >
                    Accept user
                  </div>
                  <div
                    className="search-container__plusbtn-return"
                    onClick={this.closeAcceptPopup}
                  >
                    Return
                  </div>
                </div>
              </div>
            ) : (
              <div className="center-align">
                <div className="title-form">{this.state.acceptDecline}</div>
              </div>
            )}
            <div
              className="closePendingPopupCross"
              onClick={this.closeAcceptPopup}
            >
              <img src={crossicon} alt="Cross" />
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken
  };
}

export default connect(mapStateToProps)(PendingUser);
