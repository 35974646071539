export const classOptions = [
  { label: "Smoker", value: "Smoker" },
  { label: "NonSmoker", value: "NonSmoker" },
  { label: "Unknown", value: "Unknown" }
];

export const statusOptions = [
  { label: "Current", value: "Current" },
  { label: "Past", value: "Past" },
  { label: "Unknown", value: "Unknown" }
];

export const frequencyOptions = [
  { label: "every day", value: "every day" },
  { label: "heavy", value: "heavy" },
  { label: "light", value: "light" },
  { label: "some day", value: "some day" }
];

export const amountOptions = [
  {
    value: "<1 cigarette per day",
    label: "<1 cigarette per day"
  },
  {
    value: "1-10 cigarettes per day",
    label: "1-10 cigarettes per day"
  },
  {
    value: "10-20 cigarettes per day",
    label: "10-20 cigarettes per day"
  },
  {
    value: ">1 pack per day",
    label: ">1 pack per day"
  }
];

export const inExOptions = [
  { value: "Include", label: "Include" },
  { value: "Exclude", label: "Exclude" }
];
