import React from "react";
import PhaseOfStudy from "./PhaseOfStudy";
import DisOrCondition from "./DisOrCondition";
import StudyCalendar from "./StudyCalendar";
import RecruitmentGoal from "./RecruitmentGoal";

export default function TrialFactsBlock({
  setInfo,
  defaultSudyLength,
  defaultStartDate,
  defaultDrugType,
  defaultDrugAdmin,
  defaultProcedures,
  defalutSudyArms,
  defaultGoal,
  defaultPhase,
  start_dateError,
  drug_typeError,
  drug_adminError,
  phaseError,
  study_armsError,
  proceduresError
}) {
  return (
    <section id="facts">
      <div className="ntw__trialInformation ntw__trialFacts">
        <h1>Trial facts</h1>
        <div className="ntw__ti-inputs ntw__tf-inputs">
          {defaultPhase && (
            <PhaseOfStudy
              setInfo={setInfo}
              defaultPhase={defaultPhase}
              phaseError={phaseError}
            />
          )}
          <StudyCalendar
            setInfo={setInfo}
            defaultSudyLength={defaultSudyLength}
            start_dateError={start_dateError}
            defaultStartDate={defaultStartDate}
          />
          <DisOrCondition
            setInfo={setInfo}
            defaultDrugType={defaultDrugType}
            defaultDrugAdmin={defaultDrugAdmin}
            defaultProcedures={defaultProcedures}
            defalutSudyArms={defalutSudyArms}
          />

          <RecruitmentGoal setInfo={setInfo} defaultGoal={defaultGoal} />
        </div>
      </div>
    </section>
  );
}
