import React from "react";
import moment from "moment";
const Reminders = ({ reminders, changeReminder, currentSort, sortCell }) => {
	return (
		<>
			<div className="prescreening-headers">
				<div
					className="prescreening-head rem-id"
					onClick={() => sortCell("id")}
				>
					ID
					<img
						className="arrow-sort"
						src={require("../../../images/arrow-sort.svg")}
						alt="Sort Arrow"
					/>
				</div>
				<div
					className="prescreening-head rem-det"
					onClick={() => sortCell("date")}
				>
					Details
					<img
						className="arrow-sort"
						src={require("../../../images/arrow-sort.svg")}
						alt="Sort Arrow"
					/>
				</div>
				<div className="prescreening-head rem-note">Reminder Note</div>
			</div>
			{reminders && reminders.length > 0 ? (
				reminders.map((row, i) => (
					<div
						className="calendar-list-row"
						key={row.id}
						onClick={() => changeReminder(row.id)}
					>
						<div className="cl-cell-event-type">
							{row.immunity_id ? row.immunity_id : "-"}
						</div>
						<div className="cl-cell-physician-date">
							<div className="cell-physician">
								{row.date ? moment(row.date).format("MMM DD YYYY - hh:mm A") : "-"}
							</div>
						</div>
						<div className="cl-cell-notes">{row.note ? row.note : "-"}</div>
					</div>
				))
			) : (
				<div className="spinner-container filters-social__title">
					Currently no reminders to show
				</div>
			)}
		</>
	);
};

export { Reminders };
