import React, {Component} from "react";
import "./index.css";
import CriteriaPopup from "./CriteriaPopup";
import {withRouter} from "react-router";
import Popup from "reactjs-popup";
import crossicon from "./../../../images/icon-close-w.svg";
import saveImage from "./../../../images/savee.svg";
import img from "../../../images/plus-icon.svg";
import {connect} from "react-redux";
import InmunityApi from "../../../api/InmunityApi";
import {
  getSavedSearches,
  getRecentSearches,
  showPicker,
} from "../../../actions/trialActions";

import store from "./../../../store";
import Switch from "react-switch";
import ElBlockSearch from "./ElBlockSearch";
import _ from "lodash";
import WarningPopup from "./../TreeSelect/Footer/WarningPopup";
import {SpinnerSmall} from "../../../ui";

let id = 0;

class NewSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secretDevelopment: !false,
      savePopup: false,
      searchName: "",
      loadingMessage: "",
      open: false,
      labPopup: false,
      closePopupOnError: false,
      newAddCriteria: this.newAddCriteria,
      search: {},
      exactSearch: false,
      flag: false,
      finalObj: {},
      labValue: {},
      criteriaPopup: false,
      selectedCriteria: null,
      searchId: null,
      // ---- array of new inputs --- //
      ElBlockSearchArray: [],
      refsArray: [],

      // ---- for sending from medication picker ----- //
      sendForMedication: [],
      arrayOfData: {atc: [], epc: [], moa: []},
      showWarningPopup: false,
      list: "",
      name: "Name given to the search",
    };
  }

  componentWillUnmount() {
    // if (this.state.flag) {
    //   let { finalObj } = this.state;
    //   finalObj.id = "initial";
    //   this.props.dispatch(setSearchObject(finalObj));
    // } else {
    //   this.props.dispatch(setSearchObject({}));
    // }
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    // console.log("prevProps.medicationArray", prevProps.medicationArray);
    if (
      !_.isEqual(this.props.arrayOfData, prevProps.arrayOfData) ||
      (!_.isEqual(this.props.medicationArray, prevProps.medicationArray) &&
        Array.isArray(this.props.medicationArray) &&
        this.props.medicationArray.length > 0)
    ) {
      this.addElBlockSearch();
      // this.addElBlockSearch();
    }
  }

  static getDerivedStateFromProps(
    props,
    {newAddCriteria, search, sendForMedication, arrayOfData}
  ) {
    if (search.id !== props.search.id) {
      // document.querySelectorAll(".width100").forEach(el => {
      //   el.style.display = "block";
      // });
      if (Object.keys(props.search).length > 0) {
        id = id + 100;
        let array = newAddCriteria(props.search);
        return {
          search: props.search,
          ElBlockSearchArray: array,
          exactSearch: props.search.search_type === "e" ? true : false,
        };
      } else {
        return null;
      }
    }

    if (
      !_.isEqual(arrayOfData, props.arrayOfData) &&
      !_.isEqual(sendForMedication, props.medicationArray)
    ) {
      return {
        arrayOfData: props.arrayOfData,
        sendForMedication: props.medicationArray,
      };
    }
    return null;
  }

  addElBlockForPrepop = object => {
    let {id, outputState, picker, array, sameSearch} = object;
    let criteria;
    let data = {};
    data.atc = [];
    data.epc = [];
    data.moa = [];
    console.log(picker);
    if (picker === "Medications") {
      criteria = "Medication Filters";
    } else if (picker === "Diseases") {
      criteria = "Disease Filters";
    } else if (picker === "Procedures") {
      criteria = "Procedure History";
    } else if (picker === "Allergies") {
      criteria = "Allergy Filters";
    }
    array.forEach(el => {
      if (el.activeTab === 0) {
        data.atc.push(el);
      } else if (el.activeTab === 1) {
        data.epc.push(el);
      } else if (el.activeTab === 2) {
        data.moa.push(el);
      }
    });
    outputState.push(
      <ElBlockSearch
        searchPage
        criteriaFromSearch={criteria}
        criteriaSearch={criteria}
        criteriaType={true}
        delete={this.removeElBlockSearch}
        searchOptions={true}
        id={outputState.length + id}
        ref={e => {
          setTimeout(() => {
            let refsArray = [...this.state.refsArray];
            refsArray.push(e);
            this.setState({
              refsArray,
            });
          }, outputState.length * 100);
        }}
        picker={data}
        sendForMedication={array}
        fromSearch={true}
        sameSearch={sameSearch}
      />
    );
    id++;
  };

  // ----- NEW SEARCH POPULATION WITH ELBLOCKSEARCH ----- //
  newSearchPopulation = (
    array,
    outputState,
    id,
    picker = false,
    criteria,
    count
  ) => {
    const {arrayOfData, sendForMedication} = this.state;
    const sameSearch = count > 1 ? true : false;
    let obj = {id, outputState, picker, array, arrayOfData, sameSearch};
    if (picker) {
      if (array && array.length > 0) {
        this.addElBlockForPrepop(obj);
      }
    } else {
      array &&
      array.length > 0 &&
      array.forEach(el => {
        outputState.push(
          <ElBlockSearch
            searchPage
            criteriaFromSearch={array[0].displayName ? "Lab Filters" : ""}
            criteriaType={true}
            delete={this.removeElBlockSearch}
            searchOptions={true}
            id={outputState.length + id}
            ref={e => {
              setTimeout(() => {
                let refsArray = [...this.state.refsArray];
                refsArray.push(e);
                this.setState({
                  refsArray,
                });
              }, outputState.length * 100);
            }}
            picker={arrayOfData}
            sendForMedication={sendForMedication}
            nameSearch={el.name}
            rxcui={el.rxcui}
            medId={el.id}
            includeSearch={el.include}
            criteriaSearch={criteria || el.criteria}
            prioritySearch={el.priority}
            requiredSearch={el.required}
            range={el.range}
            labValue={el.value}
            minValue={el.minValue}
            maxValue={el.maxValue}
            min={el.ageMin}
            max={el.ageMax}
            sex={el.sex}
            statusMenOption={el.statusMenOption}
            code={el.code}
            labUoM={el.uom}
            dateRangeFrom={el.startDate}
            dateRangeTo={el.endDate}
            physicianId={el.id}
            //for geography
            city={el.city}
            hospital={el.hospital}
            stateOption={el.state}
            radius={el.radius}
            fromSearch={true}
            //substance
            include={el.include}
            substances={criteria === "Substance" ? el.name : null}
            //smoking
            classification={el.classification}
            status={el.status}
            amount={el.amount}
            date_quit={el.date_quit}
            frequency={el.frequency}
            pack_years={el.pack_years}
            sameSearch={sameSearch}
          />
        );
      });
    }
    return outputState;
  };

  newAddCriteria = (data = false) => {
    if (Object.keys(data).length !== 0) {
      let searchCount = 0;
      data.searchFilters.diseases.length > 0 && searchCount++;
      data.searchFilters.medication.length > 0 && searchCount++;
      data.searchFilters.geo.length > 0 && searchCount++;
      // data.searchFilters.immunitys.length > 0 && searchCount++;
      data.searchFilters.physicians.length > 0 && searchCount++;
      data.searchFilters.procedures.length > 0 && searchCount++;
      data.searchFilters.labs.length > 0 && searchCount++;
      data.searchFilters.demography.length > 0 && searchCount++;
      data.searchFilters.substances.length > 0 && searchCount++;
      // data.searchFilters.smoking.length > 0 && searchCount++;
      let ElBlockSearchArray = [];
      data.searchFilters.diseases &&
      data.searchFilters.diseases.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.diseases,
        ElBlockSearchArray,
        data.id,
        "Diseases",
        searchCount
      );
      data.searchFilters.medication &&
      data.searchFilters.medication.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.medication,
        ElBlockSearchArray,
        data.id,
        "Medications",
        searchCount
      );
      data.searchFilters.geo &&
      data.searchFilters.geo.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.geo,
        ElBlockSearchArray,
        data.id,
        false,
        "Geography",
        searchCount
      );
      data.searchFilters.immunitys &&
      data.searchFilters.immunitys.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.immunitys,
        ElBlockSearchArray,
        data.id,
        false,
        "Immunityid",
        searchCount
      );
      data.searchFilters.physicians &&
      data.searchFilters.physicians.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.physicians,
        ElBlockSearchArray,
        data.id,
        false,
        "Physician",
        searchCount
      );
      data.searchFilters.procedures &&
      data.searchFilters.procedures.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.procedures,
        ElBlockSearchArray,
        data.id,
        "Procedures",
        searchCount
      );
      data.searchFilters.labs &&
      data.searchFilters.labs.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.labs,
        ElBlockSearchArray,
        data.id,
        false,
        "Lab",
        searchCount
      );
      data.searchFilters.demography &&
      data.searchFilters.demography.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.demography,
        ElBlockSearchArray,
        data.id,
        false,
        "Demographic",
        searchCount
      );
      data.searchFilters.substances &&
      data.searchFilters.substances.length > 0 &&
      this.newSearchPopulation(
        this.substanceValuesGroup(data.searchFilters.substances),
        ElBlockSearchArray,
        data.id,
        false,
        "Substance",
        searchCount
      );
      data.searchFilters.smoking &&
      data.searchFilters.smoking.length > 0 &&
      this.newSearchPopulation(
        data.searchFilters.smoking,
        ElBlockSearchArray,
        data.id,
        false,
        "Smoking",
        searchCount
      );
      return ElBlockSearchArray;
    } else {
      this.setState({
        ElBlockSearchArray: [],
      });
    }
  };

  substanceValuesGroup = array => {
    if (!array || array.length === 0) return;
    const include = {
      name: array.map(el => el.include && el.name).filter(el => el !== false),
      include: true,
      criteria: "Substance",
    };
    const exclude = {
      name: array.map(el => !el.include && el.name).filter(el => el !== false),
      include: false,
      criteria: "Substance",
    };
    return include.name.length > 0 && exclude.name.length > 0
      ? [include, exclude]
      : include.name.length > 0
        ? [include]
        : [exclude];
  };

  openCriteriaPopup = () => {
    this.setState({
      criteriaPopup: true,
    });
  };

  closeCriteriaPopup = () => {
    this.setState({
      criteriaPopup: false,
    });
  };

  // ------- NEW FUNCTIONS FOR ELBLOCKSEARCH ------- //

  addElBlockSearch = () => {
    let ElBlockSearchArray = [...this.state.ElBlockSearchArray];
    const {selectedCriteria} = this.state;
    const {arrayOfData, medicationArray} = this.props;
    id++;
    ElBlockSearchArray.push(
      <ElBlockSearch
        searchPage
        criteriaFromSearch={selectedCriteria}
        criteriaType={true}
        delete={this.removeElBlockSearch}
        searchOptions={true}
        id={id}
        ref={e => {
          let refsArray = [...this.state.refsArray];
          refsArray.push(e);
          this.setState({
            refsArray,
          });
        }}
        picker={arrayOfData}
        sendForMedication={medicationArray}
        fromSearch={true}
      />
    );
    this.setState({
      ElBlockSearchArray,
      criteriaPopup: false,
    });
  };

  removeElBlockSearch = index => {
    this.props.cleanMedicationArr();
    let ElBlockSearchArray = [...this.state.ElBlockSearchArray];
    let refsArray = [...this.state.refsArray];
    let NewElBlockSearchArray = [...this.state.ElBlockSearchArray];
    ElBlockSearchArray.map((item, i) => {
      if (item.props.id === index) {
        NewElBlockSearchArray.splice(i, 1);
        refsArray.splice(i, 1);
      }
      return null;
    });
    this.setState(
      {ElBlockSearchArray: NewElBlockSearchArray, refsArray},
      () => {
        let data = [];
        this.state.refsArray.forEach(el => {
          if (el !== null && el.getWrappedInstance() !== null) {
            el.getWrappedInstance().state.sendForMedication.forEach(el => {
              data.push(el);
            });
          }
        });
        this.props.setAllData(data);
      }
    );
  };

  // ------- END OF NEW FUNCTIONS FOR ELBLOCKSEARCH ------- //

  search = () => {
    const {ElBlockSearchArray} = this.state;
    if (ElBlockSearchArray.length > 0) {
      this.newSearchLevel("recent");
    }
  };

  saveSearch = () => {
    let name = document.querySelector(".searchName-input").value;
    this.newSearchLevel("save", name);
  };

  openSavePopup = () => {
    this.setState({savePopup: true});
  };

  closeSavePopup = () => {
    this.setState({savePopup: false});
  };

  closeModal = () => {
    this.setState({open: false, message: "", closePopupOnError: false});
  };

  handleInputChange = e => {
    this.setState({searchName: e.target.value});
  };

  handleChangeExactSearch = exactSearch => {
    this.setState({exactSearch});
  };

  showPicker = (filter = "") => {
    this.props.dispatch(showPicker({status: true, filter, showLab: false}));
    this.closeCriteriaPopup();
  };

  // Searching with new type of inputs //
  newSearchLevel = (saveRecent, name) => {
    if (this.state.ElBlockSearchArray.length > 0) {
      let arrOfObj = [];
      this.state.refsArray.forEach(e => {
        let data = null;
        if (e !== null && e.getWrappedInstance() !== null) {
          data = e.getWrappedInstance().state;
          let type =
            data.testOption.label.split(" ")[0] !== "ID"
              ? data.testOption.label
              : "Immunityid";
          let obj = {};
          if (type === "Geography") {
            obj.criteria = type;
            obj.state = data.stateOption.label ? data.stateOption.label : null;
            obj.city = data.cityOption.label ? data.cityOption.label : null;
            obj.hospital = data.hospitalOption.label
              ? data.hospitalOption.label
              : null;
            obj.radius = parseFloat(data.radius);
            // obj.priority = data.priorityOption.label;
          } else if (type === "Medication Filters") {
            obj.criteria = type;
            obj.sendForMedication = data.sendForMedication.map(e => ({
              ...e,
              label: this.props.farLabel,
              specific_arg: {...data.specific_arg},
            }));
          } else if (type === "Allergy Filters") {
            obj.criteria = type;
            obj.sendForMedication = data.sendForMedication.map(e => ({
              ...e,
              label: this.props.farLabel,
              specific_arg: {...data.specific_arg},
            }));
          } else if (type === "Disease Filters") {
            obj.criteria = type;
            obj.sendForMedication = data.sendForMedication.map(e => ({
              ...e,
              label: this.props.farLabel,
              specific_arg: {...data.specific_arg},
            }));
          } else if (type === "Demographic") {
            obj.ageMin = data.minValue;
            obj.ageMax = data.maxValue;
            obj.sex = data.sexOption.value;
            obj.statusMenOption = data.statusMenOption.value;
          } else if (type === "Physician") {
            obj.criteria = type;
            obj.name = data.physicianTooltip.name;
            obj.id = data.physicianTooltip.id;
          } else if (type === "Immunityid") {
            obj.criteria = type;
            obj = {...obj, id: data.immunity};
          } else if (type === "Name") {
            obj.criteria = type;
            obj = {...obj, name: data.name};
          } else if (type === "Procedure History") {
            obj.criteria = type;
            console.log("creteria =>", obj)
            obj.sendForMedication = data.sendForMedication.map(e => ({
              ...e,
              label: this.props.farLabel,
              specific_arg: {...data.specific_arg},
            }));
          } else if (type === "Substance") {
            this.dataMakerForSubstances(data, obj);
          } else if (type === "Smoking") {
            this.dataMakerForSmoking(data, obj);
          } else if (type === "Lab Filters") {
            console.log("Lab => ", obj)
            obj.criteria = type.split(" ")[0];
            obj.labs = {};
            obj.labs.name = data.labNamesToolTip.shortName;
            obj.labs.code = data.labNamesToolTip.code;
            obj.labs.include =
              data.dateRangeOption.label === "Include";
            obj.labs.priority = parseInt(data.priorityOption.label, 10);
            obj.labs.startDate = data.from || null;
            obj.labs.endDate = data.to || null;
            obj.labs.minValue = String(data.minLabValue);
            obj.labs.maxValue = String(data.maxLabValue);
            obj.labs.uom = data.labUoMOption ? data.labUoMOption.label : null;
            obj.labs.required = data.requiredOption.label === "True";
          }
          arrOfObj.push(obj);
        }
      });

      let finalObj = {};
      finalObj.searchFilters = {};

      // --- exact/approximate --- //
      finalObj.id = 1;
      finalObj.searchType = this.state.exactSearch ? "e" : "a";
      finalObj.searchFilters.medication = [];
      finalObj.searchFilters.diseases = [];
      finalObj.searchFilters.labs = [];
      finalObj.searchFilters.procedures = [];
      finalObj.searchFilters.geo = [];
      finalObj.searchFilters.physicians = [];
      finalObj.searchFilters.immunitys = [];
      finalObj.searchFilters.names = [];
      finalObj.searchFilters.ageMin = [];
      finalObj.searchFilters.demography = [];
      finalObj.searchFilters.substances = [];
      finalObj.searchFilters.smoking = [];
      finalObj.searchFilters.allergies = [];
      finalObj.saved =
        saveRecent === "save" || this.state.name !== "Name given to the search"
          ? true
          : false;
      if (finalObj.saved) {
        finalObj.name = this.state.searchName;
      }
      arrOfObj.forEach(el => {
        if (el.criteria === "Medication Filters") {
          finalObj.searchFilters.medication.push(...el.sendForMedication);
        } else if (el.criteria === "Allergy Filters") {
          finalObj.searchFilters.allergies.push(...el.sendForMedication);
        } else if (el.criteria === "Disease Filters") {
          finalObj.searchFilters.diseases.push(...el.sendForMedication);
        } else if (el.criteria === "Lab") {
          finalObj.searchFilters.labs.push(el.labs);
        } else if (el.criteria === "Procedure History") {
          finalObj.searchFilters.procedures.push(...el.sendForMedication);
        } else if (el.criteria === "Immunityid") {
          finalObj.searchFilters.immunitys.push(el);
        } else if (el.criteria === "Name") {
          finalObj.searchFilters.names.push(el);
        } else if (el.criteria === "Geography") {
          delete el.criteria;
          finalObj.searchFilters.geo.push(el);
        } else if (el.criteria === "Physician") {
          finalObj.searchFilters.physicians.push(el);
        } else if (el.sex || el.statusMenOption) {
          finalObj.searchFilters.demography.push(el);
        } else if (el.criteria === "Substance") {
          finalObj.searchFilters.substances.push(...el.substances);
        }
        // else if (el.criteria === "Smoking") {
        //   finalObj.searchFilters.smoking.push(el);
        // }
      });

      let sameInEx = this.sameInclusionExclusionCheck(
        finalObj.searchFilters.labs
      );

      if (sameInEx.length > 0) {
        sameInEx = sameInEx.join(", ");
        this.setState({list: sameInEx, showWarningPopup: true});
        return null;
      }
      this.setState({
        open: true,
        loadingMessage:
          "Your search is in progress and results will load shortly.",
        savePopup: false,
      });
      finalObj.searchFilters.medication = this.itemsTabNameGiverMeds(
        finalObj.searchFilters.medication
      );
      finalObj.searchFilters.diseases = this.itemsTabNameGiverDis(
        finalObj.searchFilters.diseases
      );
      finalObj.searchFilters.procedures = this.itemsTabNameGiverPros(
        finalObj.searchFilters.procedures
      );
      if (this.state.searchId !== null) {
        finalObj.searchId = this.state.searchId;
      }
      // new picker search
      // console.log("finalObj = ", finalObj);
      if (saveRecent === "save") {
        InmunityApi.saveSearch(finalObj)
          .then(res => res.json())
          .then(res => {
            this.setState({searchId: res.searchId});
            this.props.toggleUpdate();
            this.props.dispatch(getSavedSearches());
          });
      }
      if (saveRecent === "recent") {
        InmunityApi.makeSearchNeo4j(finalObj)
          .then(res => {
            if (res.status === 202) {
              // this.setState({
              //   finalObj
              // });
              this.setState({
                loadingMessage:
                  "The search is in progress. A notification will be sent when the search is complete.",
                closePopupOnError: true,
              });
              setTimeout(() => {
                this.setState({
                  open: false,
                });
              }, 3000);
              // return res.json();
            } else if (res.status === 200) {
              res
                .json()
                .then(resp => {
                  InmunityApi.getPatientSearchInfo(resp.searchId)
                    .then(response => {
                      return response.json();
                    })
                    .then(info => {
                      if (info.count === 0) {
                        this.setState({
                          open: true,
                          loadingMessage: "Results not found",
                          closePopupOnError: true,
                        });
                      } else {
                        this.props.history.push({
                          pathname: `/patients-search/${
                            resp.searchId ? resp.searchId + "/" : ""
                          }`,
                        });
                      }
                    })
                    .catch(error => {
                      this.setState({
                        open: true,
                        loadingMessage: "Results not found",
                        closePopupOnError: true,
                      });
                      console.log(
                        "Error occured while creating a search: ",
                        error
                      );
                    });
                })
                .catch(err => {
                  console.log(err);
                  this.setState({
                    open: true,
                    loadingMessage: "Results not found",
                    closePopupOnError: true,
                  });
                });
            } else if (res.status === 404) {
              this.setState({
                open: true,
                loadingMessage: "Results not found",
                closePopupOnError: true,
              });
            } else if (res.status === 502) {
              this.setState({
                open: true,
                loadingMessage:
                  "The search is in progress. A notification will be sent when the search is complete.",
                closePopupOnError: true,
              });
            } else {
              this.setState({
                loadingMessage: "Error occured while creating a search",
                closePopupOnError: true,
              });
            }
          })
          .catch(error => {
            this.setState({
              open: true,
              loadingMessage: "Error occured while creating a search",
              closePopupOnError: true,
            });
            console.log("Error occured while creating a search: ", error);
          })
          .then(res => {
            if (res) {
              this.getPartionSearchInfo(res.searchId, finalObj);
              if (this.state.name !== "Name given to the search") {
                store.dispatch(getSavedSearches(this.props.userToken.token));
              }
            }
            store.dispatch(getRecentSearches(undefined, true));
          });
      } else if (saveRecent === "save") {
        this.setState({
          loadingMessage: "Search was saved.",
          closePopupOnError: true,
          name,
        });
      }
    }
  };

  itemsTabNameGiverMeds = array => {
    const {medicationsPicker} = this.props;
    if (medicationsPicker.length) {
      array = array.map(el => {
        return {
          ...el,
          source:
            el.activeTab === 0
              ? medicationsPicker[0].className
              : el.activeTab === 1
              ? medicationsPicker[1].className
              : medicationsPicker[2].className,
        };
      });
    }
    return array;
  };

  itemsTabNameGiverDis = array => {
    const {diseasesPickerRoot} = this.props;
    if (diseasesPickerRoot.length) {
      array = array.map(el => {
        return {
          ...el,
          source:
            el.activeTab === 0
              ? diseasesPickerRoot[0].className
              : el.activeTab === 1
              ? diseasesPickerRoot[1].className
              : diseasesPickerRoot[2].className
          ,
        };
      });
    }
    return array;
  };

  itemsTabNameGiverPros = array => {
    const {proceduresPickerRoot} = this.props;
    if (proceduresPickerRoot.length) {
      array = array.map(el => {
        return {
          ...el,
          source:
            el.activeTab === 0
              ? proceduresPickerRoot[0].className
              : proceduresPickerRoot[1].className
        };
      });
    }
    return array;
  };

  dataMakerForSubstances = ({include, substances}, object) => {
    const array = substances.map(el => ({
      name: el.value,
      criteria: "Substance",
      include: include.value === "Include" ? true : false,
    }));
    object.substances = array;
    object.criteria = "Substance";
    return object;
  };

  dataMakerForSmoking = (
    {
      classification,
      status,
      amount,
      frequency,
      pack_years,
      date_quit,
      include,
      snomed,
    },
    object
  ) => {
    object.criteria = "Smoking";
    object.classification = classification ? classification.value : "";
    object.status = status ? status.value : "";
    object.amount = amount ? amount.value : "";
    object.frequency = frequency ? frequency.value : "";
    object.pack_years = pack_years;
    object.date_quit = date_quit;
    object.include = include.value === "Include" ? true : false;
    object.snomed = snomed;
    return object;
  };

  sameInclusionExclusionCheck = labs => {
    if (labs.length > 0) {
      let res = [];
      const mapCallback = (el, lab) => {
        if (el.name === lab.name && el.include !== lab.include) {
          return el.name;
        }
      };
      for (let lab of labs) {
        res = labs.map(el => mapCallback(el, lab));
      }
      res = Array.from(new Set(res));
      res = _.without(res, undefined);
      return res;
    } else {
      return [];
    }
  };

  selectCriteria = e => {
    if (e.target.classList.contains("criteriasBox-item")) {
      e.target.parentNode.parentNode.querySelector(
        ".criteriasBox-item-active"
      ) &&
      e.target.parentNode.parentNode
        .querySelector(".criteriasBox-item-active")
        .classList.remove("criteriasBox-item-active");

      e.target.classList.add("criteriasBox-item-active");
      this.setState(
        {
          selectedCriteria:
            e.target.textContent === "Substance Use"
              ? "Substance"
              : e.target.textContent,
        },
        () => {
          const {selectedCriteria} = this.state;
          if (
            selectedCriteria === "Medication Filters" ||
            selectedCriteria === "Disease Filters" ||
            selectedCriteria === "Procedure History" ||
            selectedCriteria === "Allergy Filters"
          ) {
            this.showPicker(selectedCriteria);
          } else {
            this.addElBlockSearch();
          }
        }
      );
    }
  };

  closeWarningPopup = () => {
    this.setState({showWarningPopup: false});
  };

  render() {
    let {
      loadingMessage,
      closePopupOnError,
      open,
      savePopup,
      searchName,
      criteriaPopup,
      secretDevelopment,
      ElBlockSearchArray,
      exactSearch,
      showWarningPopup,
      list,
      name,
    } = this.state;
    return (
      <div className="patNewSearch_Wrapper forImportPopup">
        <div className="search_name_wrapper">
          <span className="search_name">{name}</span>
          <div className="save_search_wrapper">
            <span className="save_search_text">Save this search</span>
            {ElBlockSearchArray.length > 0 ?
              <div className="save_search_btn" onClick={this.openSavePopup}>
                <img src={saveImage} alt="save-icon"/>
              </div>
              :
              <div className="save_search_btn_disabled">
                <img src={saveImage} alt="save-icon"/>
              </div>
            }
          </div>
        </div>
        <WarningPopup
          open={showWarningPopup}
          closeWarningPopup={this.closeWarningPopup}
          list={list}
        />
        <Popup
          open={open}
          closeOnDocumentClick={closePopupOnError}
          onClose={this.closeModal}
          className="saveUserPopup"
        >
          <div>
            {closePopupOnError && (
              <div className="closePendingPopupCross" onClick={this.closeModal}>
                <img src={crossicon} alt="Cross"/>
              </div>
            )}
            <div className="saveUserPopupText">{loadingMessage}</div>
            {!closePopupOnError &&
            loadingMessage !== "Results not found" &&
            loadingMessage !== "An error occured while receiving result" &&
            loadingMessage !== "Search ended successfuly with results" && (
              <SpinnerSmall/>
            )}
          </div>
        </Popup>
        <Popup
          open={savePopup}
          closeOnDocumentClick
          onClose={this.closeSavePopup}
          className="popup-search"
        >
          <div>
            <div className="searchName-popup-content">
              <h1>Save new search</h1>
              <p className="search-notify">
                To save this search please give it a name to identify later.
              </p>
              <input
                type="text"
                value={searchName}
                placeholder="Search name"
                onChange={this.handleInputChange}
                className="searchName-input"
              />
              <div className="searchName-btn-wrapper">
                <div className="searchName-btn" onClick={this.saveSearch}>
                  Continue
                </div>
              </div>
            </div>
            <div
              className="closePendingPopupCross"
              onClick={this.closeSavePopup}
            >
              <img src={crossicon} alt="Cross"/>
            </div>
          </div>
        </Popup>

        <CriteriaPopup
          criteriaPopup={criteriaPopup}
          closeCriteriaPopup={this.closeCriteriaPopup}
          selectCriteria={this.selectCriteria}
          permissions={this.props.permissions}
        />

        {/*
        ------   New Inputs for Search -------
        */}

        {secretDevelopment && (
          <div className="search_container wrappForInputLists ntw__ti-inputs newTrialWrapp newWrappElBlockSearch">
            {ElBlockSearchArray && (
              <div className="searchInputs EC-main__listfields-extrawrapp">
                {ElBlockSearchArray
                  ? ElBlockSearchArray.map((component, i) => {
                    console.log(component)
                    return (
                      <div
                        className="width100 searchItem"
                        key={component.props.id}
                      >
                        {component}
                      </div>
                    );
                  })
                  : null}
                <div
                  className="newCriteriaBtn"
                  onClick={this.openCriteriaPopup}
                >
                  <img src={img} alt="Plus"/>
                  <span>New Criteria</span>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="searchBtnWrapper">
          <div className="wrappExactSearch">
            <span>Exact Search</span>
            <Switch
              onChange={this.handleChangeExactSearch}
              checked={exactSearch}
              onColor="#00B3A9"
              offColor="#dedede"
              onHandleColor="#FFF"
              handleDiameter={16}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={18}
              width={36}
              className="reminder"
            />
          </div>
          <div className="verticalGreyLine"/>
          <div
            className={
              ElBlockSearchArray && ElBlockSearchArray.length === 0
                ? "searchBtn disabledSearchBtn"
                : "searchBtn"
            }
            onClick={this.search}
          >
            <span>Search</span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken,
    search: state.search,
    geoData: state.geoData,
    diseasesPickerRoot: state.diseasesPickerRoot,
    proceduresPickerRoot: state.proceduresPickerRoot,
    rootMedications: state.rootMedications,
    medicationsPicker: state.medicationsPicker,
    farLabel: state.farLabel,
    permissions: state.permissions
  };
}

export default withRouter(
  connect(mapStateToProps, null, null, {withRef: true})(NewSearch)
);
