export default {
  trials: [],
  trial: {},
  trialsOptions: [],
  physiciansOptions: [],
  sitesOptions: [],
  analiticData: {loading: false, error: null},
  hospitals: {loading: false, error: null},
  site: {loading: false, error: null},
  userToken: {
    token: localStorage.getItem("token") ? localStorage.getItem("token") : ""
  },
  currentUser: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "",
  currentTrial: "",
  currentHadmId: "",
  diseases: [],
  medications: [],
  newMedications: [],
  labNames: [],
  labUoms: [],
  labList: [],
  viewRender: { viewNavBar: true, isFetching: false },
  modalGeneric: { modalVisible: false },
  matches: [],
  candidates: [],
  profilePhoto: {},
  userPhoto: {},
  newUserPhoto: {},
  procedures: [],
  roles: [],
  patientsResult: {
    loading: false,
    error: null
  },
  patientOverview: {},
  patientsFilterResult: {},
  globalState: {},
  search: {},
  searches: {},
  labWithUom: [],
  emailNotification: {},
  lastStages: [],
  exclusionReasons: [],
  geoData: localStorage.getItem("geo")
    ? JSON.parse(localStorage.getItem("geo"))
    : "",
  workFlowStudyId: {},
  actions: {},
  responsibles: [],
  showPicker: { show: false, filter: "", showLab: false, freeText: false },
  reminders: [],
  otherReminders: [],
  rootMedications: [],
  rootMedicationsTree: [],
  rootProcedures: [],
  diseasesDO: [],
  diseasesICD9CM: [],
  diseasesICD10CM: [],
  diseasesICD19CM: [],
  diseasesICD110CM: [],
  medicationsATC: [],
  medicationsEPC: [],
  medicationsMOA: [],
  diseasesPicker: [],
  proceduresPicker: [],
  medicationsATCList: [],
  medicationsEPCList: [],
  medicationsMOAList: [],
  diseasesList: [],
  proceduresList: [],
  loader: { login_loader: false },
  popup: { text: "", show: false },
  substances: [],
  substancesSearch: [],
  physicians: [],
  physiciansSearch: [],
  smoking: [],
  medicationsPicker: [],
  diseasesPickerRoot: [],
  proceduresPickerRoot: [],
  proceduresICD9: [],
  proceduresICD10: [],
  proceduresICD9List: [],
  proceduresICD10List: [],
  diseasesDOList: [],
  diseasesICD9CMList: [],
  diseasesICD10CMList: [],
  searchesRecent: {
    next: null,
    count: 0,
    previous: null,
    results: [],
    reload: false
  }
};
