import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";

export default class StatusFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonOption: "",
      options: [
        { label: "All", value: "both" },
        { label: "Living", value: "living" },
        { label: "Death", value: "death" }
      ]
    };
  }

  handleSelectChange = reasonOption => {
    this.setState({ reasonOption });
    this.props.getValue(reasonOption.value);
    if (reasonOption !== "") {
      document.querySelector(".ex-filter-select").classList.remove("redBorder");
    }
  };

  render() {
    let { options, stageOption } = this.state;
    return (
      <div className="filterContent">
        <div className="dateWrapper newTrialWrapp">
          <div className="datePicker">Status</div>
          <Select
            value={stageOption}
            placeholder="Select..."
            onChange={this.handleSelectChange}
            options={options}
            className="sort_select withInput ex-filter-select"
            components={makeAnimated()}
          />
        </div>
      </div>
    );
  }
}
