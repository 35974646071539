import React from "react";
import { Spinner } from "./../../../../../../ui";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import desc from "./../../../../../../images/descQuest.svg";
import ReactTooltip from "react-tooltip";

export default function AgeDistribution({ data, spinner }) {
  const getData = () => {
    if (!data || !Array.isArray(data.data)) {
      return null;
    }
    let chartData = [];
    data.data.forEach(el => {
      chartData.push([el.name, parseInt(el.count, 10)]);
    });
    return chartData;
  };
  const options = {
    chart: {
      type: "column"
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif"
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Patient visits"
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      pointFormat: "Patients: <b>{point.y}</b>"
    },
    series: [
      {
        name: "Population",
        data: getData()
      }
    ]
  };
  return (
    <div className="age_dis_wrapp workflow_chart">
      <div className="pat_graph_title">
        Patient Age Distribution{" "}
        <img
          src={desc}
          alt="desc-icon"
          height="20"
          width="20"
          data-tip={data && data.description ? data.description : ""}
          data-class="toolTip"
          data-multiline
        />
        <ReactTooltip place="top" type="light" effect="solid" />
      </div>
      {spinner ? (
        <Spinner />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
}
