import React from "react";
import moment from "moment";

export default function SmokingDataArray({ data }) {
  return data && data.length > 0 ? (
    <div className="ntw__EC-main">
      <span className="filters-social__title">Smoking</span>
      <table className="smoke_table">
        <thead>
          <tr className="thead_wr">
            <td className="">Classification</td>
            <td className="">Status</td>
            <td className="">Amount</td>
            <td className="">Frequency</td>
            <td className="">Pack Years</td>
            <td className="">Date Quit</td>
            <td className="" />
          </tr>
        </thead>
        <tbody className="smoke_table_body">
          {data.map((component, i) => {
            return (
              <tr className="" key={i}>
                <td className="">{component.classification}</td>
                <td className="">{component.status}</td>
                <td className="">
                  {component.amount ? component.amount : "-"}
                </td>
                <td className="">
                  {component.frequency ? component.frequency : "-"}
                </td>
                <td className="">
                  {component.pack_years ? component.pack_years : "-"}
                </td>
                <td className="">
                  {component.date_quit
                    ? moment(component.date_quit).format("LL")
                    : "-"}
                </td>
                <td className="">
                  {component.include ? "Include" : "Exclude"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
}
