import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import {
  setCurrentTrial,
  getTrialDescription,
  setCurrentNavigationSubLink
} from '../../../actions/trialActions'

const Trial = ({
  currentNavigation,
  trial_id,
  href,
  title,
  setCurrentNavBarLink,
  getTrialDescription,
  setCurrentTrial,
  relocate
}) => {
  return (
    <Link
      className={`mdc-list-item middle-nav-item ${
        currentNavigation && currentNavigation.navBarSublink === trial_id
          ? ' active-trial'
          : ''
      }`}
      to={href}
      onClick={e => {
        e.preventDefault()
        setCurrentTrial(trial_id)
        setCurrentNavBarLink(trial_id)
        getTrialDescription(trial_id)
        relocate(href)
      }}
    >
      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {title}
      </span>
    </Link>
  )
}

function mapStateToProps (state) {
  return {
    trial: state.trial,
    retired: state.retired,
    current_trial: state.currentTrial,
    viewRender: state.viewRender,
    currentNavigation: state.currentNavigation
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getTrialDescription: id => dispatch(getTrialDescription(id)),
    setCurrentTrial: id => dispatch(setCurrentTrial(id)),
    setCurrentNavBarLink: id => dispatch(setCurrentNavigationSubLink(id)),
    relocate: path => dispatch(push(path))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trial)
