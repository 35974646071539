import {handleFetchErrors} from "../utils/handleNullDate";
import InmunityApi from "../api/InmunityApi";
import {formatMatchingCriteria, setPatientOverviewMiddleware} from "./trialActions";

export const FETCH_PATIENT_SEARCH_RESULT_START = 'FETCH_PATIENT_SEARCH_RESULT_START';
export const FETCH_PATIENT_SEARCH_RESULT_SUCCESS = 'FETCH_PATIENT_SEARCH_RESULT_SUCCESS';
export const FETCH_PATIENT_SEARCH_RESULT_ERROR = 'FETCH_PATIENT_SEARCH_RESULT_ERROR';
export const FETCH_PATIENT_SEARCH_RESULT_PAGINATED_START = 'FETCH_PATIENT_SEARCH_RESULT_PAGINATED_START';
export const FETCH_PATIENT_SEARCH_RESULT_PAGINATED_SUCCESS = 'FETCH_PATIENT_SEARCH_RESULT_PAGINATED_SUCCESS';
export const FETCH_PATIENT_SEARCH_RESULT_PAGINATED_ERROR = 'FETCH_PATIENT_SEARCH_RESULT_PAGINATED_ERROR';
export const SORT_PATIENT_SEARCH_RESULT_START = 'SORT_PATIENT_SEARCH_RESULT_START';
export const SORT_PATIENT_SEARCH_RESULT_SUCCESS = 'SORT_PATIENT_SEARCH_RESULT_SUCCESS';
export const SORT_PATIENT_SEARCH_RESULT_ERROR = 'SORT_PATIENT_SEARCH_RESULT_ERROR';

const sortPatientSearchResultStart = (old) => ({
  type: SORT_PATIENT_SEARCH_RESULT_START,
  patientsResult: {...old}
})
const sortPatientSearchResultSuccess = patientsResult => ({
  type: SORT_PATIENT_SEARCH_RESULT_SUCCESS,
  patientsResult
})
const sortPatientSearchResultError = error => ({
  type: SORT_PATIENT_SEARCH_RESULT_ERROR,
  error
})

const fetchPatientSearchResultStart = (old) => ({
  type: FETCH_PATIENT_SEARCH_RESULT_START,
  patientsResult: {...old}
})
const fetchPatientSearchResultSuccess = patientsResult => ({
  type: FETCH_PATIENT_SEARCH_RESULT_SUCCESS,
  patientsResult
})
const fetchPatientSearchResultError = error => ({
  type: FETCH_PATIENT_SEARCH_RESULT_ERROR,
  error
})

const fetchPatientSearchResultPaginatedStart = (oldRes) => ({
  type: FETCH_PATIENT_SEARCH_RESULT_PAGINATED_START,
  patientsResult: {...oldRes}
})
const fetchPatientSearchResultPaginatedSuccess = patientsResult => ({
  type: FETCH_PATIENT_SEARCH_RESULT_PAGINATED_SUCCESS,
  patientsResult
})
const fetchPatientSearchResultPaginatedError = error => ({
  type: FETCH_PATIENT_SEARCH_RESULT_PAGINATED_ERROR,
  error
})

export const fetchPatientSearchPaginationFullUrl = (link, prevRes) => (dispatch, getState) => {
  if (!getState().patientsResult.loading) {
    dispatch(fetchPatientSearchResultPaginatedStart(getState().patientsResult));
    return InmunityApi.getPatientSearchPaginationFullUrl(link)
      .then(
        (response) => {
          if (response.result && response.result.length > 0) {
            response.result = prevRes.concat(response.result.map((r) => ({
              age: r.age,
              gender: r.gender,
              hadm_id: r.hadmId,
              immunity_id: r.immunityId,
              last_seen: r.lastSeen,
              chart: formatMatchingCriteria(r.matchingCriteria, r.missing),
              next_visit: r.nextVisit,
              rate: r.rate,
              label: r.label
            })));
            dispatch(fetchPatientSearchResultPaginatedSuccess(response));
          } else {
            dispatch(fetchPatientSearchResultPaginatedError());
            throw new Error('no results');
          }
        }
      )
      .catch(e => console.error(e))
  }
};

export const sortPatientSearchResult = (searchID, url) => (dispatch, getState) => {
  if (!getState().patientsResult.loading) {
    dispatch(sortPatientSearchResultStart(getState().patientsResult))
    return (
      InmunityApi.getPatientSearchInfoFiltered(searchID, url || 'ordering=-score')
        .then(res => {
          if (res.status !== 404) {
            res.result = res.result.map((r) => ({
              age: r.age,
              gender: r.gender,
              hadm_id: r.hadmId,
              immunity_id: r.immunityId,
              last_seen: r.lastSeen,
              chart: formatMatchingCriteria(r.matchingCriteria, r.missing),
              next_visit: r.nextVisit,
              rate: r.rate,
              label: r.label
            }));
            dispatch(sortPatientSearchResultSuccess(res));
            dispatch(setPatientOverviewMiddleware(res.result[0]));
          } else {
            dispatch(sortPatientSearchResultError(res))
            throw res
          }
        })
        .catch(err => console.error(err))
    )
  } else {
    return getState()
  }
}

export const fetchPatientSearchResult = (searchID) => (dispatch, getState) => {
  if (!getState().patientsResult.loading) {
    dispatch(fetchPatientSearchResultStart(getState().patientsResult));
    return (
      InmunityApi.getPatientSearchInfo(searchID)
        .then(res => {
          if (res.status !== 404) {
            return res.json()
          } else {
            dispatch(fetchPatientSearchResultError(res))
            throw res
          }
        })
        .then(json => {
          json.result = json.result.map((r) => ({
            age: r.age,
            gender: r.gender,
            hadm_id: r.hadmId,
            immunity_id: r.immunityId,
            last_seen: r.lastSeen,
            chart: formatMatchingCriteria(r.matchingCriteria, r.missing),
            next_visit: r.nextVisit,
            rate: r.rate,
            label: r.label
          }))
          dispatch(fetchPatientSearchResultSuccess(json));
          dispatch(setPatientOverviewMiddleware(json.result[0]));
        })
        .catch(e => console.error(e))
    )
  } else {
    return getState()
  }
}