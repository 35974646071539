import React from 'react'
import { connect } from 'react-redux'
import { getExclusionReasons } from '../actions/trialActions'
import ExclusionReasonsSelect from './MatchDetails/ExclusionReasonsSelect'
import './styles/PatientDetails.css'

class ReasonDecline extends React.Component {
  state = {
    declineReasons: [],
    criteriasTrial: []
  }

  componentDidMount () {
    const { declineReasons } = this.props
    declineReasons.length > 0
      ? this.makeOptions(declineReasons)
      : (async () => {
        await this.props.dispatch(getExclusionReasons())
        this.makeOptions(this.props.declineReasons)
      })()
  }

  makeOptions = array => {
    let declineReasons = []
    array.forEach(el => {
      declineReasons.push({ value: el.id, label: el.name })
    })
    this.setState({ declineReasons })
  }

  render () {
    const { criteriasTrial, declineReasons } = this.state
    if (this.props.location.obj) {
      const { study_id, hadm_id } = this.props.location.obj
      return (
        <div className='base-container forPD forBlueBC'>
          <div className='base-container'>
            <div className='login-container patientDetails reasonDecline reasonDeclineLogIn'>
              <div className='left-login'>
                <img src={require('../images/logo-w.png')} alt='Logo' />
                <div className='patientDetails__leftText'>
                  <span>
                    We appreciate you don’t want to participate. To help us
                    improve our process, here is a list of potential reasons –
                    Please click all that apply. Thanks for helping!
                  </span>
                </div>
              </div>
              <div className='right-login'>
                <div className='inputFields'>
                  <img
                    src={require('../images/left-icon.svg')}
                    className='mdarrowback'
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                    alt='Left Icon'
                  />
                  <h1 className='heading'>Reason for declining.</h1>
                  <p className='plsSelect'>Please select an option below.</p>
                  <ExclusionReasonsSelect
                    declineReasons={declineReasons}
                    criteriasTrial={criteriasTrial}
                    id={study_id || ''}
                    hadmID={hadm_id || ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userToken: state.userToken,
    declineReasons: state.exclusionReasons
  }
}

export default connect(mapStateToProps)(ReasonDecline)
