import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setPatientOverview(state = initialState.patientOverview, action) {
  switch (action.type) {
    case types.PATIENT_OVERVIEW_START:
      return {
        loading: true,
        ...action.patientOverview
      }
    case types.PATIENT_OVERVIEW_SUCCESS:
      return {
        loading: false,
        ...action.patientOverview
      }
    case types.PATIENT_OVERVIEW_ERROR:
      return {
        loading: false,
        error: action.error,
      }
    default:
      return state;
  }
}
