import React, { useRef, useState, useEffect } from "react";
import Popup from "reactjs-popup";
import img from "./../../../../../images/plus-icon.svg";
import crossicon from "./../../../../../images/icon-close-w.svg";

export default function GlobalTextPopup({
  globalText,
  open,
  closeGlobalTextPopup,
  openPicker
}) {
  const [text, setText] = useState("");
  const areaRef = useRef();

  const setFreeText = () => {
    if (!text) {
      return null;
    } else {
      openPicker(text);
      closePopup();
    }
  };

  const closePopup = () => {
    setText("");
    closeGlobalTextPopup();
  };

  const selectText = () => {
    if (areaRef.current) {
      const globalTextValue = areaRef.current.value;
      const selectedValue = window.getSelection().toString();
      if (selectedValue !== "") {
        if (globalTextValue.indexOf(selectedValue) !== -1) {
          setText(selectedValue);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("selectionchange", selectText, false);
    return () => document.removeEventListener("selectionchange", selectText);
  });

  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      onClose={closePopup}
      className="popup-criterias"
    >
      <div className="freetextWrapp ">
        <div className="ntw__ti-inputs ntw__ti-input ntw__ti-iDescr forSymbolCounter">
          <span className="ntw__ti-inputlabel">Full Eligibility Criteria</span>
          <span className="el_cr_note">
            Please highlight each criterion in the text and click “Add Criteria”
            to create structured eligibility criteria.
          </span>
          <textarea
            ref={areaRef}
            className="get_global_text"
            value={globalText}
            readOnly
            maxLength="30000"
            name="global_text"
          />
        </div>
        <div
          className={`contactWrapp addELBlock-btn${text ? "" : " oDisabled"}`}
          onClick={setFreeText}
          id="add_criteria_btn"
        >
          <img src={img} alt="Plus" />
          <span>Add Criteria</span>
        </div>
        <div className="closePendingPopupCross" onClick={closePopup}>
          <img src={crossicon} alt="Cross" />
        </div>
      </div>
    </Popup>
  );
}
