import React from 'react'
import { connect } from 'react-redux';

import './styles/PatientDetails.css';

class WishContinue extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {

    }
  }

  render() {
    return (
      <div className="base-container forPD forBlueBC">
        <div className="base-container">
          <div className="login-container patientDetails wishContinue ">
            <div className="left-login">
              <img src={require("../images/logo-w.png")} alt="logo"/>
              <div className="patientDetails__leftText">
                <p className="helloName">
                  Hello John,
                </p>
                <span>
                  You have been preselected as a potential candidate for a clinical trial. If you wish to receive more information, you must accept the terms and conditions described in the next window.
                </span>
              </div>
            </div>
            <div className="right-login">
              <div className="inputFields">
                <h1 className="heading">Do you wish to continue?</h1>
                <div className="yesnoWrapper">
                  <div className="choice choiceNo">
                    <img src={require('../images/icon-close-w.svg')} alt="Close"/>
                    <span className="choiceText">No</span>
                  </div>
                  <div className="choice choiceYes">
                    <img src={require('../images/icon-check-w.svg')} alt="Check"/>
                    <span className="choiceText choiceTextYes">Yes</span>
                  </div>
                </div>
                <div className="btnsWrapp">
                  <div className="submit">Submit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userToken: state.userToken
  };
}

export default connect(mapStateToProps)(WishContinue);
