import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function proceduresReducer(
  state = initialState.procedures,
  action
) {
  switch (action.type) {
    case types.PROCEDURES_SUCCESS:
      return action.procedures;
    default:
      return state;
  }
}
