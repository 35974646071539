import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function getTrialCandidatesReducer(state = initialState.candidates, action) {
  switch(action.type) {
    case types.LOAD_TRIALS_CANDIDATES:
      return action.candidates
    default:
      return state;
  }
}