import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import WorkflowMultiple from './../PatientsSearch/WorkflowMultiple'

import './index.css'

import close from './../../images/close-blue-icon.svg'

export default class PatientOverview extends Component {
  static propTypes = {
    close: PropTypes.func,
    details: PropTypes.object
  }

  close = () => {
    this.props.close()
  }

  redirect = id => {
    this.props.redirect(id)
  }

  render () {
    let {
      immunity,
      hadmId,
      name,
      age,
      dob,
      dod,
      gender,
      diagnosis,
      physicians,
      lastEncounter,
      nextApointment,
      pastMedical,
      pastSurgical,
      medication,
      allergies
    } = this.props.details

    return (
      <div className='patientInfoWrapper pat-over-wrapp'>
        <div className='patient_overview'>
          <div className='title-with-close'>
            <h2 className='patTitle'>Patient Overview</h2>
            <div onClick={this.close} className='close-overview'>
              Close
              <img src={close} alt='close-icon' />
            </div>
          </div>
          {Object.keys(this.props.details).length === 0 ? (
            <div className='spinner-container'>
              <img
                className='medium-spinner'
                src={require('./../../images/spinner.gif')}
                alt='Spinner'
              />
            </div>
          ) : (
            <Fragment>
              <div className='patInfo'>
                <span className='patInfo_title'>ID</span>
                <span className='patInfo_value'>{immunity}</span>
              </div>
              <div className='patInfo'>
                <span className='patInfo_title'>Name</span>
                <span className='patInfo_value'>{name}</span>
              </div>
              <div className='patInfo'>
                <span className='patInfo_title'>Age</span>
                <span className='patInfo_value'>{age}</span>
              </div>
              <div className='patInfo'>
                <span className='patInfo_title'>Birthday</span>
                <span className='patInfo_value'>
                  {dob !== "" ? moment(dob).format('DMMM DD YYYY').toLowerCase() : "-"}
                </span>
              </div>
              {dod !== "-" || dod !== "" && 
              <div className='patInfo'>
                <span className='patInfo_title'>Death date</span>
                <span className='patInfo_value'>
                  {dod !== "-" ? moment(dod).format('MMM DD YYYY').toLowerCase() : "-"}
                </span>
              </div>
              }
              <div className='patInfo'>
                <span className='patInfo_title'>Gender</span>
                <span className='patInfo_value'>
                  {gender === 'M'
                    ? 'Male'
                    : gender === 'F'
                      ? 'Female'
                      : gender === 'B'
                        ? 'Both'
                        : ''}
                </span>
              </div>
              <div className='patInfo'>
                <span className='patInfo_title'>Diagnosis</span>
                <span className='patInfo_value'>{diagnosis}</span>
              </div>
              <div className='pograyLine' />
              <div className='patEdInfo'>
                <span className='patInfo_title'>Physicians</span>
                <span className='patInfo_value'>
                  {physicians
                    ? physicians.map((el, i) => (
                      <div key={i}>
                        {el.name} ({el.specialty})
                      </div>
                    ))
                    : ''}
                </span>
              </div>
              <div className='patEdInfo'>
                <span className='patInfo_title'>Last Encounter</span>
                <span className='patInfo_value'>
                  {lastEncounter
                    ? moment(lastEncounter.date).format('MMM DD YYYY - hh:mm A') +
                      ' - ' +
                      lastEncounter.name
                    : ''}{' '}
                </span>
              </div>
              <div className='patEdInfo'>
                <span className='patInfo_title'>Next Apointment</span>
                <span className='patInfo_value'>
                  {nextApointment
                    ? moment(nextApointment.date).format('MMM DD YYYY - hh:mm A') +
                      ' - ' +
                      nextApointment.name
                    : ''}{' '}
                </span>
              </div>
            </Fragment>
          )}
          <div className='pograyLine' />
        </div>
        {Object.keys(this.props.details).length === 0 ? (
          <div className='sinner-container'>
            <img
              className='medium-spinner'
              src={require('./../../images/spinner.gif')}
              alt='Spinner'
            />
          </div>
        ) : (
          <Fragment>
            <div className='patient_chart'>
              <h2 className='patTitle'>Workflow</h2>
              <section className='patient_sec patient_secForWorkflow'>
                <WorkflowMultiple
                  fromPOWorkflow={this.props.details.workflow}
                />
              </section>
              <div className='pograyLine' />
            </div>
            <div className='patient_chart'>
              <h2 className='patTitle'>Patient Chart</h2>
              {pastMedical && pastMedical.length > 0 && (
                <section className='patient_sec'>
                  <div className='sec_title'>Past medical history</div>
                  <div className='patientDP_Wrapper'>
                    <div className='patientDP width100'>
                      <div className='forPMitems'>
                        {pastMedical.map((el, i) => {
                          return (
                            <div className='plusminusItems' key={i}>
                              <span className='black-circle' />
                              <span className='patientText width95'>{el}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {pastSurgical && pastSurgical.length > 0 && (
                <section className='patient_sec'>
                  <div className='sec_title'>Past surgical history</div>
                  <div className='patientDP_Wrapper'>
                    <div className='patientDP width100'>
                      <div className='forPMitems'>
                        {pastSurgical.map((el, i) => {
                          return (
                            <div className='plusminusItems' key={i}>
                              <span className='black-circle' />
                              <span className='patientText width95'>{el}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {medication && medication.length > 0 && (
                <section className='patient_sec'>
                  <div className='sec_title'>Medications</div>
                  <div className='patientDP_Wrapper'>
                    <div className='patientDP width100'>
                      <div className='forPMitems'>
                        {medication.map((el, i) => {
                          return (
                            <div className='plusminusItems' key={i}>
                              <span className='black-circle' />
                              <span className='patientText width95'>{el}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {allergies && allergies.length > 0 && (
                <section className='patient_sec'>
                  <div className='sec_title'>Allergies</div>
                  <div className='patientDP_Wrapper'>
                    <div className='patientDP width100'>
                      <div className='forPMitems'>
                        {allergies.map((el, i) => {
                          return (
                            <div className='plusminusItems' key={i}>
                              <span className='black-circle' />
                              <span className='patientText width95'>
                                {el.name}
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              <div
                className='editPatient_btn'
                onClick={() => this.redirect(hadmId)}
              >
                Patient Details
              </div>
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}
