import * as types from "../actions/actionTypes";

export default function allergiesLeafs(state = [], action) {
	switch (action.type) {
		case types.SET_ALLERGIES_LEAFS:
			return action.leafs;
		default:
			return state;
	}
}
