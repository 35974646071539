import React from "react";

export default function FilterResult({ name, value, removeFilter, index }) {
  // const deleteResult = e => {
  //   let filters = document
  //     .querySelector(".exclusionsResultsWrapper")
  //     .querySelectorAll(".searchItem");
  //   filters.forEach((el, i) => {
  //     if (e.target.parentNode === el) {
        
  //     }
  //   });
  // };
  return (
    <div className="searchItem">
      <span className="searchCrType">{name}:</span>
      <span className="searchCrType__value">{value}</span>
      <span className="crossIconOnSI" onClick={() => removeFilter(index, value, name)}>
        +
      </span>
    </div>
  );
}
