import React from "react";
import { Spinner } from "./../../../../../../ui";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import desc from "./../../../../../../images/descQuest.svg";
import ReactTooltip from "react-tooltip";

export default function Ethnicity({ data, spinner }) {
  const getData = () => {
    if (!data || !Array.isArray(data.data)) {
      return null;
    }
    let chartData = [];
    data.data.forEach(el => {
      chartData.push({ name: el.name, y: parseInt(el.count, 10) });
    });
    return chartData;
  };
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    },
    title: {
      text: ""
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black"
          }
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: "Patients",
        colorByPoint: true,
        data: getData()
      }
    ]
  };
  return (
    <div className="ethnicity_wrapp workflow_chart">
      <div className="pat_graph_title">
        Patient Ethnicity
        <img
          src={desc}
          alt="desc-icon"
          height="20"
          width="20"
          data-tip={data && data.description ? data.description : ""}
          data-class="toolTip"
          data-multiline
        />
        <ReactTooltip place="top" type="light" effect="solid" />
      </div>
      {spinner ? (
        <Spinner />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
}
