import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentHadmIdReducer(state = initialState.currentHadmId, action) {
  switch(action.type) {
    case types.CURRENT_HADMID:
      return action.currentHadmId
    default:
      return state;
  }
}
