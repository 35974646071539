import React from "react";
import length_of_study from "./../../../../../images/length_of_study.svg";

export default function LengthStudyItem({ study_length }) {
  return (
    <div className="infoItem">
      <img src={length_of_study} alt="Length of study" />
      <div className="tfc-tc">
        <div className="tfc-title">Length of study</div>
        <div className="tfc-content">
          <span data-test="lengthItemDisplay">
            {study_length ? study_length + " months" : "no data"}
          </span>
        </div>
      </div>
    </div>
  );
}
