import React from "react";

export default function LabDataArray({ data }) {
  const renderMinValue = component => {
    if (!component.maxValue && component.minValue) {
      return (
        <span className="lab-col lab-value-third-full mmValue">
          {component.minValue}
        </span>
      );
    }
    if (component.minValue) {
      return (
        <span className="lab-col lab-value-third mmValue">
          {component.minValue}
        </span>
      );
    }
    if (component.labValue) {
      return (
        <span className="lab-col lab-value-third">{component.minValue}</span>
      );
    }
    return null;
  };

  const renderMaxValue = component => {
    // debugger;
    if (!component.minValue && component.maxValue) {
      return (
        <span className="lab-col lab-value-third-full mmValue">
          {component.maxValue}
        </span>
      );
    }
    if (component.maxValue) {
      return (
        <span className="lab-col lab-value-third mmValue">
          {component.maxValue}
        </span>
      );
    }
    return null;
  };
  return data && data.length > 0 ? (
    <div className="ntw__EC-main" data-test="labData">
      <span className="filters-social__title">Lab Filters</span>
      <div className="spanline__wrapp">
        <span className="ntw__ti-inputlabel spanlineName">Laboratory Name</span>
        <span className="ntw__ti-inputlabel spanlineRange">Range</span>
        <span className="ntw__ti-inputlabel spanlineValue">Lab Value</span>
        <span className="ntw__ti-inputlabel spanlineUom">Lab UoM</span>
        <span className="ntw__ti-inputlabel spanlineInclude">Include</span>
        <span className="ntw__ti-inputlabel spanlineRequired">Required</span>
      </div>
      <div className="ntw__ti ntw__tablePlaceboDrugs">
        {data.map((component, i) => {
          return (
            <div className="ntw__tableRow" key={i}>
              <span className="lab-col lab-value-first" data-test="labName">
                {component.name}
              </span>
              <span className="lab-col lab-value-second" data-test="labRange">
                {component.minValue !== 0 && component.maxValue !== 0
                  ? "Between"
                  : component.minValue === 0
                  ? "Smaller than"
                  : "Greater than"}
              </span>
              <div className="lab-value-third-wrapper">
                {renderMinValue(component)}
                {renderMaxValue(component)}
              </div>
              <span className="lab-col lab-value-fourth" data-test="labUom">
                {component.uom}
              </span>
              <span className="lab-col lab-value-fourth" data-test="labInclude">
                {component.include ? "Include" : "Exclude"}
              </span>
              <span className="lab-col lab-value-fourth" data-test="labRequired">
                {component.required ? "True" : "False"}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}
