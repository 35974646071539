import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setUserPhoto(state = initialState.userPhoto, action) {
  switch(action.type) {
    case types.USER_PHOTO:
      return action.userPhoto
    default:
      return state;
  }
}
