import React, { PureComponent } from "react";
import Filters from "./../Filters";
import TreeSelect from "./../../../Patients/TreeSelect";
import CriteriaPopup from "./CriteriaPopup";
import GlobalTextPopup from "./GlobalTextPopup";
import DataStructured from "./DataStructured";
import FreetextData from "./FreetextData";
import { showPicker } from "./../../../../actions/trialActions";
import { connect } from "react-redux";
import "./index.css";

class EligibilityCriteriaBlock extends PureComponent {
	state = {
		arrayCompLabFilters: [],
		freeText: "",
		criteriaPopup: false,
		globalTextOpen: false,
		selectedCriteria: "",
		ElBlockSearchArray: [],
		medicaitonsArray: [],
		diseasesArray: [],
		proceduresArray: [],
		refsArray: [],
		activeTab: 0,
	};

	componentWillUnmount() {
		this.props.dispatch(
			showPicker({ status: false, filter: "", showLab: false, freeText: false })
		);
	}

	toggleView = e => {
		if (e.target.className === "dynamic") {
			e.target.classList.toggle("active");
			e.target.previousSibling.classList.toggle("active");
			e.target.parentNode.parentNode.nextSibling.classList.toggle("hidden");
			e.target.parentNode.parentNode.nextSibling.nextSibling.classList.toggle(
				"hidden"
			);
		} else if (e.target.className === "freetext") {
			e.target.classList.toggle("active");
			e.target.nextSibling.classList.toggle("active");
			e.target.parentNode.parentNode.nextSibling.classList.toggle("hidden");
			e.target.parentNode.parentNode.nextSibling.nextSibling.classList.toggle(
				"hidden"
			);
		}
	};

	openTextAreaPopup = () => {
		this.setState({ globalTextOpen: true });
	};

	closeGlobalTextPopup = () => {
		this.setState({ globalTextOpen: false });
	};

	openPicker = freeText => {
		this.setState({ criteriaPopup: true, freeText });
	};

	showPicker = (filter, version = false, text) => {
		const {
			defaultEligibilityCriteria,
			eligibilityNewFreeText,
			setInfo,
		} = this.props;
		let newElegibilityCriteria;
		if (filter === "Lab Filters") {
			if (version) {
				newElegibilityCriteria = [...eligibilityNewFreeText];
				newElegibilityCriteria.push({
					freeText: "",
					criteria: [
						{ criteria: "Lab", uom: "", range: "Greater than", include: true },
					],
					flag: filter,
				});
				setInfo("eligibilityNewFreeText", newElegibilityCriteria);
			} else {
				let flag = false;
				newElegibilityCriteria = [...defaultEligibilityCriteria];
				if (newElegibilityCriteria.length > 0) {
					newElegibilityCriteria.forEach(criteria => {
						if (criteria.freeText === "") {
							flag = true;
							criteria.criteria.push({
								criteria: "Lab",
								uom: "",
								range: "Greater than",
								include: true,
							});
						}
					});
				} else {
					flag = true;
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								criteria: "Lab",
								uom: "",
								range: "Greater than",
								include: true,
							},
						],
					});
				}
				if (!flag) {
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								criteria: "Lab",
								uom: "",
								range: "Greater than",
								include: true,

							},
						],
					});
				}

				setInfo("eligibility_criteria", newElegibilityCriteria);
			}
		} else if (filter === "Smoking") {
			if (version) {
				newElegibilityCriteria = [...eligibilityNewFreeText];
				newElegibilityCriteria.push({
					freeText: "",
					criteria: [
						{
							amount: "<1 cigarette per day",
							classification: "Smoker",
							criteria: "Smoking",
							date_quit: "",
							frequency: "every day",
							include: true,
							pack_years: "",
							snomed: "449868002",
							status: "Current",
						},
					],
					flag: filter,
				});
				setInfo("eligibilityNewFreeText", newElegibilityCriteria);
			} else {
				let flag = false;
				newElegibilityCriteria = [...defaultEligibilityCriteria];
				if (newElegibilityCriteria.length > 0) {
					newElegibilityCriteria.forEach(criteria => {
						if (criteria.freeText === "") {
							flag = true;
							criteria.criteria.push({
								amount: "<1 cigarette per day",
								classification: "Smoker",
								criteria: "Smoking",
								date_quit: "",
								frequency: "every day",
								include: true,
								pack_years: "",
								snomed: "449868002",
								status: "Current",
							});
						}
					});
				} else {
					flag = true;
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								amount: "<1 cigarette per day",
								classification: "Smoker",
								criteria: "Smoking",
								date_quit: "",
								frequency: "every day",
								include: true,
								pack_years: "",
								snomed: "449868002",
								status: "Current",
							},
						],
					});
				}
				if (!flag) {
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								amount: "<1 cigarette per day",
								classification: "Smoker",
								criteria: "Smoking",
								date_quit: "",
								frequency: "every day",
								include: true,
								pack_years: "",
								snomed: "449868002",
								status: "Current",
							},
						],
					});
				}
				setInfo("eligibility_criteria", newElegibilityCriteria);
			}
		} else if (filter === "Substance") {
			if (version) {
				newElegibilityCriteria = [...eligibilityNewFreeText];
				newElegibilityCriteria.push({
					freeText: "",
					criteria: [
						{
							criteria: "Substance",
							name: [],
							include: true,
						},
					],
					flag: filter,
				});
				setInfo("eligibilityNewFreeText", newElegibilityCriteria);
			} else {
				let flag = false;
				newElegibilityCriteria = [...defaultEligibilityCriteria];
				if (newElegibilityCriteria.length > 0) {
					newElegibilityCriteria.forEach(criteria => {
						if (criteria.freeText === "") {
							flag = true;
							criteria.criteria.push({
								criteria: "Substance",
								name: [],
								include: true,
							});
						}
					});
				} else {
					flag = true;
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								criteria: "Substance",
								name: [],
								include: true,
							},
						],
					});
				}
				if (!flag) {
					newElegibilityCriteria.push({
						freeText: "",
						criteria: [
							{
								criteria: "Substance",
								name: [],
								include: true,
							},
						],
					});
				}
				setInfo("eligibility_criteria", newElegibilityCriteria);
			}
		} else {
			const { dispatch } = this.props;
			version
				? dispatch(
						showPicker({
							status: true,
							filter,
							showLab: false,
							freeText: version,
						})
				  )
				: dispatch(
						showPicker({
							status: true,
							filter,
							showLab: false,
							freeText: false,
						})
				  );
		}
		this.setState({
			selectedCriteria: filter,
		});
	};

	closeCriteriaPopup = () => {
		this.setState({
			criteriaPopup: false,
		});
	};

	removeLabFilterList = index => {
		let { arrayCompLabFilters } = this.state;
		arrayCompLabFilters = arrayCompLabFilters.filter(
			el => el.props.id !== index
		);
		this.setState({ arrayCompLabFilters });
	};

	handleChangeTab = id => this.setState({ activeTab: id });

	selectCriteria = (e, freeTextVersion) => {
		if (e.target.classList.contains("criteriasBox-item")) {
			this.setState(
				{
					selectedCriteria:
						e.target.textContent === "Substance Use"
							? "Substance"
							: e.target.textContent,
				},
				() => {
					const { selectedCriteria } = this.state;
					this.showPicker(selectedCriteria, freeTextVersion);
				}
			);
		}
	};

	sendForMedication = (codes, freeTextVersion) => {
		codes = codes.map(e => ({ ...e, label: this.props.farLabel }));
		if (freeTextVersion) {
			const { eligibilityNewFreeText, setInfo } = this.props;
			let newData = [...eligibilityNewFreeText];
			let flag = "";
			if (codes[0].criteria === "Medication") {
				flag = "Medication Filters";
			} else if (codes[0].criteria === "Disease") {
				flag = "Disease Filters";
			} else if (codes[0].criteria === "Procedure") {
				flag = "Procedure History";
			} else if (codes[0].criteria === "Lab") {
				flag = "Lab Filters";
			} else if (codes[0].criteria === "Allergy Filters") {
				flag = "Allergy Filters";
			}
			newData.push({
				freeText: "",
				criteria: [...codes],
				flag,
			});
			setInfo("eligibilityNewFreeText", newData);
		} else {
			const { defaultEligibilityCriteria, setInfo } = this.props;
			let newData = [...defaultEligibilityCriteria];
			newData.push({
				freeText: "",
				criteria: [...codes],
			});
			setInfo("eligibility_criteria", newData);
		}
	};

	addFreeTextBlock = () => {
		const { eligibilityNewFreeText, setInfo } = this.props;
		let defaultEligibilityCriteria = [...this.props.defaultEligibilityCriteria];
		const { freeText } = this.state;
		let result = {};
		result.criteria = [];
		result.freeText = freeText;
		eligibilityNewFreeText.forEach(el => {
			el.criteria.forEach(elem => {
				result.criteria.push(elem);
			});
		});
		defaultEligibilityCriteria.push(result);
		setInfo("eligibility_criteria", defaultEligibilityCriteria);
		setInfo("eligibilityNewFreeText", []);
	};

	handleChange = e => {
		const { name, value } = e.target;
		this.props.setInfo(name, value);
	};

	removeFreetextBlock = index => {
		let arrayOfBlocks = [...this.state.arrayOfBlocks];
		arrayOfBlocks = arrayOfBlocks.filter(el => el.props.id !== index);
		this.setState({ arrayOfBlocks });
	};

	// shouldComponentUpdate(p, s) {
	//    shouldComponentUpdate: function(nextProps, nextState) {
	//   return shallowCompare(this, nextProps, nextState);
	// },
	// }

	render() {
		const {
			criteriaPopup,
			ElBlockSearchArray,
			globalTextOpen,
			freeText,
			selectedCriteria,
			activeTab,
		} = this.state;
		const {
			setInfo,
			defaultGender,
			genderError,
			defaultEligibilityCriteria,
			defaultAgeMin,
			defaultAgeMax,
			defaultSmokingStatus,
			defaultSubstanceStatus,
			defaultMedicationAllergy,
			eligibilityNewFreeText,
			defaultGlobalText,
			showPicker: { status },
			allData,
		} = this.props;

		// console.log("block !!! ");
		return (
			<section id="criteria" className="forImportPopup criteria-pop disPopup">
				<CriteriaPopup
					criteriaPopup={criteriaPopup}
					closeCriteriaPopup={this.closeCriteriaPopup}
					selectCriteria={this.selectCriteria}
					selectedCriteria={selectedCriteria}
					freeText={freeText}
					ElBlockSearchArray={ElBlockSearchArray}
					cleanUp={this.cleanUp}
					defaultEligibilityCriteria={eligibilityNewFreeText}
					showPicker={this.showPicker}
					setInfo={setInfo}
					addFreeTextBlock={this.addFreeTextBlock}
					newFdata={eligibilityNewFreeText}
					allData={allData}
				/>
				<GlobalTextPopup
					open={globalTextOpen}
					globalText={defaultGlobalText}
					closeGlobalTextPopup={this.closeGlobalTextPopup}
					openPicker={this.openPicker}
				/>
				{status && (
					<TreeSelect
						sendForMedication={this.sendForMedication}
						allData={allData}
					/>
				)}
				<div className="ntw__trialInformation ntw__ElegibilityCriteria">
					<Filters
						setInfo={setInfo}
						genderError={genderError}
						defaultGender={defaultGender}
						defaultAgeMin={defaultAgeMin}
						defaultAgeMax={defaultAgeMax}
						defaultSmokingStatus={defaultSmokingStatus}
						defaultSubstanceStatus={defaultSubstanceStatus}
						defaultMedicationAllergy={defaultMedicationAllergy}
					/>
					<div className="pograyLine" />
					<div className="ntw__EC-header">
						<div className="EC-changer" onClick={this.toggleView}>
							<span
								className={`freetext ${activeTab === 0 && "active"}`}
								onClick={() => this.handleChangeTab(0)}
							>
								Free-text Version
							</span>
							<span
								className={`dynamic ${activeTab === 1 && "active"}`}
								onClick={() => this.handleChangeTab(1)}
							>
								Structured Version
							</span>
						</div>
					</div>
					{activeTab === 1 ? (
						<div className="dynamicWrapp">
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Medication Filters"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Disease Filters"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Procedure History"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Lab Filters"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Allergy Filters"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
							{/* <DataStructured
                data={defaultEligibilityCriteria}
                showPicker={this.showPicker}
                flag="Smoking"
                setInfo={setInfo}
                freeTextVersion={false}
              /> */}
							<DataStructured
								data={defaultEligibilityCriteria}
								showPicker={this.showPicker}
								flag="Substance"
								setInfo={setInfo}
								freeTextVersion={false}
							/>
						</div>
					) : (
						<FreetextData
							globalText={defaultGlobalText}
							handleChange={this.handleChange}
							openTextAreaPopup={this.openTextAreaPopup}
							defaultEligibilityCriteria={defaultEligibilityCriteria}
							setInfo={setInfo}
							selectedCriteria={selectedCriteria}
							showPicker={this.showPicker}
							newFdata={eligibilityNewFreeText}
						/>
					)}
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	showPicker: state.showPicker,
	farLabel: state.farLabel,
});

export default connect(mapStateToProps)(EligibilityCriteriaBlock);
