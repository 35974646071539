import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getRootMedicationsReducer(
  state = initialState.medicationsPicker,
  action
) {
  switch (action.type) {
    case types.GET_ROOT_MEDICATIONS_PICKER:
      return action.medicationsPicker;
    default:
      return state;
  }
}
