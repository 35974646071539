import React from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import InmunityApi from "../../../api/InmunityApi";
import i18n from "../../../i18n";
import ExclusionReasonSelect from "./../ExclusionReasonsSelect";
import "./index.css";

class ActionsOverview extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showActions: false,
      idAction: props.idAction !== undefined ? props.idAction : 0,
      trials: [],
      declineReasons: [],
      reasonExclusion: [],
      criteriasTrial: [],
      id: null,
      status: null
    };
    this.setAction = this.setAction.bind(this);
  }

  showActions = () => {
    this.setState({ showActions: !this.state.showActions }, () => {
      if (this.state.showActions) {
        InmunityApi.getUnmatchedTrials(this.props.hadmID)
          .then(umatchedTrials => {
            let trials = umatchedTrials.map(trial => ({
              value: trial.id,
              label: trial.name
            }));
            if (this.mounted) {
              this.setState({ trials });
            }
          })
          .catch(error =>
            console.log("Error getting unmatched trials: ", error)
          );
      }
    });
  };

  setAction(value) {
    this.setState({
      idAction: value,
      reasonExclusion: []
    });
    this.props.setAction && this.props.setAction();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.Id.id !== state.id || props.Id.status !== state.status) {
      return {
        id: props.Id.id,
        status: props.Id.status
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.mounted = true;
    InmunityApi.getUnmatchedTrials(this.props.hadmID)
      .then(umatchedTrials => {
        let trials = umatchedTrials.map(trial => ({
          value: trial.id,
          label: trial.name
        }));
        if (this.mounted) {
          this.setState({ trials });
        }
      })
      .catch(error => console.log("Error getting unmatched trials: ", error));
    let declineReasons = [];
    if (this.props.exclusionReasons && this.props.exclusionReasons.length > 0) {
      this.props.exclusionReasons.forEach(el => {
        declineReasons.push({ value: el.id, label: el.name });
      });
      if (this.mounted) {
        this.setState({ declineReasons });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let {
      status,
      idAction,
      declineReasons,
      trials,
      criteriasTrial
    } = this.state;
    return (
      <div className="actions-overview-container">
        {!this.props.decline !== 0 && (
          <div className="button-toggle" onClick={this.showActions}>
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
          </div>
        )}

        {!this.props.decline && this.state.showActions && (
          <div className="actions-box">
            <div className="triangle" />
            <div className="actions-container">
              <div className="action-title" onClick={() => this.setAction(1)}>
                {this.props.workflow.length > 0
                  ? i18n.t("actionsOverview.addOtherTrial")
                  : i18n.t("actionsOverview.AddToTrial")}
              </div>
              {this.props.workflow.length > 0 &&
                !status.includes(3) &&
                !status.includes(2) &&
                !status.includes(6) &&
                !status.includes(7) &&
                !status.includes(11) &&
                !status.includes(12) && (
                  <div
                    className="action-title"
                    onClick={() => this.setAction(2)}
                  >
                    {i18n.t("actionsOverview.excludeTrial")}
                  </div>
                )}
              {this.props.workflow.length > 0 &&
                !status.includes(2) &&
                !status.includes(6) &&
                !status.includes(11) && (
                  <div
                    className="action-title"
                    onClick={() => this.setAction(3)}
                  >
                    {i18n.t("actionsOverview.excludeTrials")}
                  </div>
                )}
            </div>
          </div>
        )}

        <Popup
          open={idAction !== 0}
          closeOnDocumentClick
          onClose={() => {
            this.setAction(0);
          }}
          className="actions-popup"
        >
          <div className="actions-form-container">
            <div className="img-close-container">
              <img
                className="icon-close"
                src={require("../../../images/icon-close-grey.svg")}
                alt="Close"
                onClick={() => {
                  this.props.hideSpinner && this.props.hideSpinner();
                  this.setAction(0);
                }}
              />
            </div>
            <h3 className="title">
              {idAction === 1
                ? i18n.t("actionsOverview.addingAnotherTrial")
                : this.state.idAction === 2
                ? i18n.t("actionsOverview.excludeTrialCandidate")
                : i18n.t("actionsOverview.excludeTrialsCandidate")}
            </h3>
            <p className="sub-title">
              {idAction === 1
                ? i18n.t("actionsOverview.selectTrial")
                : i18n.t("actionsOverview.reasonExclusion")}
            </p>
            <ExclusionReasonSelect
              trials={trials}
              declineReasons={declineReasons}
              criteriasTrial={criteriasTrial}
              idAction={idAction}
              id={this.state.id}
              hadmID={this.props.hadmID}
              setAction={this.setAction}
              updateWorkflow={this.props.updateWorkflow}
              fullUpdateWorkflow={this.props.fullUpdateWorkflow}
              workflow={this.props.workflow}
              currentTrial={this.props.currentTrial}
              closeActions={this.showActions}
              hideSpinner={this.props.hideSpinner}
              studyId={this.props.studyId}
            />
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial,
    trials: state.trials,
    exclusionReasons: state.exclusionReasons,
    globalState: state.globalState,
    Id: state.workFlowStudyId
  };
}

export default connect(mapStateToProps)(ActionsOverview);
