import React from 'react'
import { CirclePicker } from 'react-color'

export default function TrialColor ({ setInfo, defaultColor }) {
  const handleChangeComplete = color => {
    setInfo('color', color.hex)
  }

  return (
    <div className='ntw__trialInformation-heading'>
      <h1>Trial information</h1>
      <div
        className='colorPicker'
        onClick={e => {
          if (e.target.className !== 'circlePicker') {
            document.querySelector('.circlePicker').classList.toggle('hidden')
          }
        }}
      >
        <div className='selectedColor' style={{ background: defaultColor }} />
        <div className='circlePicker hidden'>
          <span className='selectColor'>Select a Color</span>
          <CirclePicker
            circleSize={17}
            color={defaultColor}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      </div>
    </div>
  )
}
