import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Popup from "reactjs-popup";

import "./index.css";

import crossicon from "../../../images/icon-close-w.svg";

const options = [
  { value: "First", label: "First" },
  { value: "Second", label: "Second" }
];

class SearchPopup extends React.Component {
  state = {
    simplifySearch: false,
    advancedSearch: false,
    selectedOption: null
  };

  closeSimplifySearch = () => {
    this.setState({
      simplifySearch: false
    });
  };

  closeAdvancedSearch = () => {
    this.setState({
      advancedSearch: false
    });
  };

  handleChangeSelectedOption = selectedOption => {
    this.setState({
      selectedOption
    });
  };

  toggleSearch = () => {
    this.setState({
      simplifySearch: !this.state.simplifySearch,
      advancedSearch: !this.state.advancedSearch
    });
  };

  render() {
    return (
      <div>
        <Popup
          open={this.state.simplifySearch}
          closeOnDocumentClick
          onClose={this.closeSimplifySearch}
          className="popup-simplifySearch"
        >
          <div>
            <div
              className="closePendingPopupCross"
              onClick={this.closeSimplifySearch}
            >
              <img src={crossicon} alt="Cross" />
            </div>

            <div className="import-container matchDetailsSearchPopup newTrialWrapp">
              <div className="import-container__header">
                <h1>You are searching for relevant trials for this patient.</h1>
                <p>Please select a therapeutic area</p>
                <Select
                  value={this.state.selectedOption}
                  onChange={this.handleChangeSelectedOption}
                  options={options}
                  className="sort_select"
                />
                <div className="mdForHeaderAndArrow__btn">Apply</div>
                <div className="advancedSearch" onClick={this.toggleSearch}>
                  Advanced Search
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          open={this.state.advancedSearch}
          closeOnDocumentClick
          onClose={this.closeAdvancedSearch}
          className="popup-simplifySearch popup-advancedSearch"
        >
          <div className="advancedSearch">
            <div
              className="closePendingPopupCross"
              onClick={this.closeAdvancedSearch}
            >
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="import-container matchDetailsSearchPopup newTrialWrapp">
              <div className="import-container__header">
                <h1>Advanced Trial Search</h1>
                <p>Please add one or more search criteria</p>
                <Select
                  value={this.state.selectedOption}
                  onChange={this.handleChangeSelectedOption}
                  options={options}
                  className="sort_select"
                />
                <div className="mdForHeaderAndArrow__btn">Apply</div>
                <div className="advancedSearch" onClick={this.toggleSearch}>
                  Simplified Search
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps)(SearchPopup);
