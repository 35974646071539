import React, { useState, useEffect, useRef } from "react";
import Item from "./SelectItem";
import Spinner from "./../Spinners/Spinner";

const DiseaseList = props => {
	const {
		disNames,
		disItems,
		spinner,
		categoryId,
		setSpinner,
		active,
		setCodes,
		filter,
		activeTab,
		include,
	} = props;
	const [state, setState] = useState({
		list: [],
	});
	const myRef = useRef(null);
	useEffect(() => {
		console.log("Dis names => ", disNames)
		disNames.forEach(el => {
			if (el.id === categoryId) {
				const list = el.leafs;
				list.sort((a, b) => a.name.localeCompare(b.name));
				setState(prevState => ({
					...prevState,
					list,
				}));
				setSpinner(false);
			}
		});
	}, [disNames, categoryId, setSpinner]);

	useEffect(() => {
		disNames.forEach(el => {
			if (el.id === active) {
				console.log(el.id);
				const list = el.leafs;
				list.sort((a, b) => a.name.localeCompare(b.name));
				setState(prevState => ({
					...prevState,
					list,
				}));
				setSpinner(false);
			}
		});
	}, [active, setSpinner, disNames]);
	console.log(state.list);
	return spinner ? (
		<div className="list-btn-container">
			<div className="dis-list-container">
				<Spinner disList={true} />
			</div>
		</div>
	) : state.list.length > 0 ? (
		<div className="list-btn-container">
			<div ref={myRef} className="dis-list-container">
				{state.list.map(el => {
					{console.log("elems =>", el)}
					return (
						<Item
							myRef={myRef}
							name={el.name}
							key={el.id}
							id={el.id}
							checkedId={categoryId}
							parentId={el.parentId}
							disItems={disItems}
							search={el.search}
							setCodes={setCodes}
							filter={filter}
							activeTab={activeTab}
							list={state.list}
							include={include}
						/>
					);
				})}
			</div>
		</div>
	) : (
		<div className="list-btn-container">
			<div className="dis-list-container">
				<div className="spinner-dis-picker">No information</div>
			</div>
		</div>
	);
};
export default DiseaseList;
