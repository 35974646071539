import React, {useState, useEffect, memo} from "react";
import {TitleSelectorGroup, Title, ContentGroup, NotesDataBlock} from './ChartBoxComponents'
import {StyledBlockWrapper} from './styled'
import Select from "react-select";
import "./index.css";
import store from '../../../store'
import {setFullChartName} from '../../../actions/trialActions'
import InmunityApi from '../../../api/InmunityApi'
import arrowleft from "../../../images/left.png";
import arrowright from "../../../images/right.png";
import Medications from './Medications'

const ChartBox = ({changeTab, title, data, tabs, contentType, searchText, currentTab, onChangeNotesTab}) => {
  const [tab, setTab] = useState(0);
  return (
    <div id="patientChartSubContent">
      <div className="ppWrapper">
        <Title
          title={title}
          searchText={searchText}/>
        <TitleSelectorGroup
          hideCount={contentType === 'identified'}
          tabs={tabs}
          data={data}
          currentTab={currentTab ? currentTab : tab}
          searchText={searchText}
          onClick={changeTab ? (n) => changeTab(n) : (n) => setTab(n)}
        />

        <StyledBlockWrapper>

          {
            contentType !== 'identified'
              ? <ContentGroup
                searchText={searchText}
                content={data[tab]}
                extended={contentType !== 'medications' && tab === 1}
                contentType={contentType}
                onChangeNotesTab={onChangeNotesTab}
              />
              :
              <NotesDataBlock
                searchText={searchText}
                content={data[tab]}
                contentType={contentType}
                onChangeNotesTab={onChangeNotesTab}
              />
          }

        </StyledBlockWrapper>
      </div>
    </div>
  )
}

const ChartContainer = memo(({searchId, hadmId, medications, procedures, diseases, identifiedData, searchText, allergies, trialName, history}) => {
  /***** new state management *****/
  const [state, setState] = useState({
      options: [],
      allText: [],
      currentNotesTab: "",
      currentTab: 0,
      linkNext: "",
      allTabs: [],
      totalCount: '',
      oldData: {},
      disabled: false,
      currentPart: 0,
    }),
    {
      options,
      allText,
      currentNotesTab,
      currentTab,
      linkNext,
      allTabs,
      totalCount,
      oldData,
      disabled,
      currentPart,
    } = state;
  /*************************************************************/

  useEffect(() => {
    InmunityApi.getPatientNotesList(hadmId)
      .then(res => {
        setState({...state, options: res.map(note => ({value: note.id, label: `${note.date} ${note.category}`}))});
      });
    InmunityApi.getPatientNotes(hadmId)
      .then(res => {
        setState({
          ...state,
          allTabs: [...res.results.map(tab => tab.date)],
          allText: res.results.map(text => text.text),
          count: res.count,
          linkNext: res.next,
          oldData: {
            link: res.next,
            allTabs: res.results.map(tab => tab.date),
            allText: res.results.map(text => text.text),
            count: res.count
          }
        })
      });

    store.dispatch(setFullChartName(trialName));
  }, [hadmId, trialName])

  const cutTrilName = (str) => {
    if (str.length <= 14) return str;
    return (`${str.substring(0, 14)}...`);
  }

  const onClickNext = () => {
    if (!disabled) {
      // allTabs.slice(currentPart, currentPart + 5).length > currentTab + 1 && changeCurrentTab(currentTab + 1)
      allTabs.slice(currentPart, currentPart + 5).length > currentTab + 1 && setState({
        ...state,
        currentTab: currentTab + 1
      })
      if (currentTab === 4) {
        if (linkNext !== null) {
          // toggleDisabled(true)
          setState({...state, disabled: true})
          let token = localStorage.getItem("token");
          fetch(linkNext, {
            method: "GET",
            headers: {
              "Authorization": "Bearer " + token,
              "Content-Type": "application/json",
            }
          }).then(response => {
            return response.json()
          }).then(response => {
            const newAllTabs = response.results.map(tab => tab.date)
            const newAllText = response.results.map(text => text.text)

            setState({
              ...state,
              allTabs: [...newAllTabs],
              allText: [...newAllText],
              linkNext: response.next,
              currentTab: 0,
              currentPart: currentPart + 5,
              oldData: {link: response.next, allTabs: allTabs, allText: allText, count: totalCount}
            })

            // }).then(() => toggleDisabled(false))
          }).then(() => setState({...state, disabled: false}))
        } else if (currentPart < totalCount) {
          setState({
            ...state,
            currentTab: 0,
            currentPart: currentPart + 5
          })
          // changeCurrentTab(0)
          // changePart(currentPart + 5)
        }
      }
    }
  }
  const onClickBack = () => {
    if (currentTab === 0 && currentPart > 0) {
      setState({
        ...state,
        currentTab: 4,
        currentPart: currentPart - 5
      })
      // changePart(currentPart - 5)
      // changeCurrentTab(4)
    } else if (currentTab !== 0) {
      setState({
        ...state,
        currentTab: currentTab - 1,
      })
      // changeCurrentTab(currentTab - 1)
    }
  }
  const onChangeNotesTab = stageOption => {
    setState({
      ...state,
      oldData: {link: linkNext, allTabs: allTabs, allText: allText, count: totalCount},
      currentNotesTab: stageOption,
      allText: ['Loading...']
    })

    InmunityApi.getPatientNotesText(hadmId, stageOption.value)
      .then(res => {
        setState({
          ...state,
          allText: [res.text],
          allTabs: [res.date],
          currentTab: 0,
          currentPart: 0,
          totalCount: 1,
          linkNext: null
        })
      })
  }
  const onClear = () => {
    setState({
      ...state,
      allTabs: oldData.allTabs,
      allText: oldData.allText,
      totalCount: oldData.count,
      linkNext: oldData.link,
      currentTab: 0,
      currentPart: 0,
      currentNotesTab: ''
    })
    // changeAllTabs(oldData.allTabs)
    // changeAllText(oldData.allText)
    // changeCount(oldData.count)
    // changeLinkNext(oldData.link)
    // changeCurrentTab(0)
    // changePart(0)
    // changeNotesTab('')
  }
  const onTabClick = (n) => {
    // changeCurrentTab(n)
    setState({...state, currentTab: n})
  }
  const getDirrectionLock = move => {
    if (move === "next") {
      if (allTabs.slice(currentPart, currentPart + 5).length === currentTab + 1 && totalCount === allTabs.length && currentPart > allTabs.length - 5) {
        return true
      }
    } else {
      if (currentTab === 0 && currentPart === 0) {
        return true
      }
    }
  }

  const matchedTab = trialName ? `Matched To ${cutTrilName(trialName)}` : null;
  const searchTab = searchId ? `Matched To Search` : null

  return (
    <>
      <ChartBox
        title="Past Medical History"
        contentType="diseases"
        data={
          [
            diseases.all,
            {
              inclusion: diseases.matchedInc,
              exclusion: diseases.matchedExc,
            },
            {
              inclusion: diseases.searchInc,
              exclusion: diseases.searchExc,
            }
          ]
        }
        tabs={['All', matchedTab, searchTab]}
        // onChangeNotesTab={onChangeNotesTab}
        onChangeNotesTab={() => null}
        searchText={searchText}
      />
      <ChartBox
        title="Past Surgical History"
        data={
          [
            procedures.all,
            {
              inclusion: procedures.matchedInc,
              exclusion: procedures.matchedExc,
            },
            {
              inclusion: procedures.searchInc,
              exclusion: procedures.searchExc,
            }
          ]
        }
        tabs={['All', matchedTab, searchTab]}
        contentType="procedures"
        searchText={searchText}
      />
      <Medications
        medications={medications}
        searchText={searchText}
        tabs={['All', matchedTab, searchTab]}
      />
      <ChartBox
        title="Allergies"
        data={
          [
            allergies.all,
            {
              inclusion: allergies.matchedInc,
              exclusion: allergies.matchedExc,
            },
            {
              inclusion: allergies.searchInc,
              exclusion: allergies.searchExc,
            }
          ]
        }
        tabs={['All', matchedTab, searchTab]}
        contentType="allergies"
        searchText={searchText}
      />
      <div className="notes-list">
        <ChartBox
          title="Clinical Notes"
          contentType="identified"
          data={allText.slice(currentPart, currentPart + 5)}
          tabs={allTabs.slice(currentPart, currentPart + 5)}
          currentTab={currentTab}
          searchText={searchText}
          changeTab={onTabClick}
        />
        <div className="notes-select-container">
          <img
            src={arrowleft}
            alt={'arrow-left'}
            className={getDirrectionLock("forward") && `disabled`}
            onClick={() => onClickBack()}
          />
          <img
            src={arrowright}
            alt={'arrow-right'}
            className={getDirrectionLock("next") && `disabled`}
            onClick={() => onClickNext()}
          />
          <Select
            className="notes-select"
            name="identifiedData"
            placeholder="Select one"
            options={options}
            value={currentNotesTab}
            onChange={onChangeNotesTab}
          />
          {currentNotesTab !== "" && (
            <div className="red-cross" onClick={() => onClear()}/>
          )}
        </div>
      </div>
    </>
  )
})

export default ChartContainer;
