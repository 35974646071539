import initialState from "./initialState";
import {
  FETCH_ANALYTICS_ERROR,
  FETCH_ANALYTICS_START,
  FETCH_ANALYTICS_SUCCESS, FETCH_PATIENT_HOSPITALS_ERROR,
  FETCH_PATIENT_HOSPITALS_START, FETCH_PATIENT_HOSPITALS_SUCCESS, FETCH_SITE_ERROR, FETCH_SITE_START, FETCH_SITE_SUCCESS
} from "../actions/sitesDataActions";

export function setAnalytics(state = initialState.analiticData, action) {
  switch (action.type) {
    case FETCH_ANALYTICS_START:
      return {
        ...action.analiticData,
        loading: true,
        error: null
      }
    case FETCH_ANALYTICS_SUCCESS:
      return {
        ...action.analiticData,
        loading: false,
        error: null
      }
    case FETCH_ANALYTICS_ERROR:
      return {
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}

export function setHospitals(state = initialState.hospitals, action) {
  switch (action.type) {
    case FETCH_PATIENT_HOSPITALS_START:
      return {
        loading: true, error: null
      }
    case FETCH_PATIENT_HOSPITALS_SUCCESS:
      return {
        items: [...action.hospitals],
        loading: false,
        error: null
      }
    case FETCH_PATIENT_HOSPITALS_ERROR:
      return {
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}

export function setSite(state = initialState.site, action) {
  switch (action.type) {
    case FETCH_SITE_START:
      return {
        loading: true,
        error: null
      }
    case FETCH_SITE_SUCCESS:
      return {
        items: [...action.site],
        loading: false,
        error: null
      }
    case FETCH_SITE_ERROR:
      return {
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}