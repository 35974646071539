import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setUserPhoto, setNewUserPhoto } from '../../../actions/trialActions'
import { withRouter } from 'react-router'

class ImageView extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      profilePhoto: null,
      photoURL: ''
    }
  }

  componentDidMount() {
    if (this.props.location.pathname.endsWith('newuser')) {
      localStorage.removeItem('infoAboutUser')
    }
    let infoAboutUser = JSON.parse(localStorage.getItem('infoAboutUser'))
    if (infoAboutUser) {
      this.setState({
        photoURL: infoAboutUser.photo
      })
    }
  }

  changePhoto = e => {
    let input = e.target
    e.target.parentNode.previousSibling.id = ''
    input.id = 'photo'
    let img = e.target.parentNode.previousSibling
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = function (e) {
        img.setAttribute('src', e.target.result)
      }
      this.setState(
        {
          profilePhoto: input.files[0]
        },
        () => {
          if (this.props.location.pathname.endsWith('edituser')) {
            this.props.dispatch(setUserPhoto(this.state.profilePhoto))
          } else {
            this.props.dispatch(setNewUserPhoto(this.state.profilePhoto))
          }
        }
      )
      reader.readAsDataURL(input.files[0])
    }
  }

  render() {
    let { photoURL, profilePhoto } = this.state
    return (
      <div className='myProfile__ls'>
        <div className='uploadPhoto'>
          <img
            src={photoURL}
            className={
              photoURL || profilePhoto ? 'uploadedImg' : 'uploadedImg hidden'
            }
            alt=''
          />
          {/* <div className='changePhoto'>
            <input type='file' onChange={this.changePhoto} />
            <span>Change Photo</span>
          </div> */}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default withRouter(connect(mapStateToProps)(ImageView))
