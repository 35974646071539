import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import _ from "lodash";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import NewTrialPopup from "./Popup/NewTrialPopup";
import ImportTrialPopup from "./Popup/ImportTrialPopup";
import Trial from "./Trial";
import i18n from "../../i18n";
import "./index.css";
import { withRouter } from "react-router";
import InmunityApi from "../../api/InmunityApi";
import { SpinnerSmall, Sort, GreenButton, DisabledButton } from "../../ui";

const options = [
  { value: "name", label: "Trial Name" },
  { value: "nct", label: "NCT" },
  { value: "sponsor", label: "Sponsor" },
  { value: "principal_investigator", label: "Principal Investigator" }
];

class Trials extends Component {
  state = {
    tabActive: 1,
    selectedOption: options[0],
    // active trials
    trials: [],
    isMoreActiveTrials: false,
    linkActiveTrials: null,
    trialsAmount: 0,
    // retired trials
    retired: [],
    isMoreRetiredTrials: false,
    linkRetiredTrials: null,
    retiredAmount: 0,
    importPopup: false,
    newTrialPopup: false,
    importTrialPopup: false,
    isReverseSort: true,
    loading: false,
    input: ""
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let { setCurrentNavigationLink, setCurrentNavigationSubLink } = this.props;
    setCurrentNavigationLink(3);
    setCurrentNavigationSubLink("all");
    this.setState({ loading: true });
    InmunityApi.getActiveTrials().then(activeTrials => {
      if (!activeTrials.error) {
        this.setState({
          trials: this.defultSortByID(activeTrials.results),
          loading: false,
          isMoreActiveTrials: !!activeTrials.next,
          linkActiveTrials: activeTrials.next,
          trialsAmount: activeTrials.count
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
    InmunityApi.getRetiredTrials().then(retiredTrials => {
      if (!retiredTrials.error) {
        this.setState({
          retired: this.defultSortByID(retiredTrials.results),
          loading: false,
          isMoreRetiredTrials: !!retiredTrials.next,
          linkRetiredTrials: retiredTrials.next,
          retiredAmount: retiredTrials.count
        });
      } else {
        this.setState({
          loading: false
        })
      }
    });
  }

  defultSortByID = array => {
    return array.sort((a, b) => {
      return b.id - a.id;
    });
  };

  sortTrials = () => {
    this.setState({ loading: true });
    let { tabActive, selectedOption, isReverseSort, input } = this.state;
    let flag = selectedOption.value;
    let sign = isReverseSort ? "-" : "";
    let query = input !== "" ? input : null;
    let type = tabActive === 1 ? "active" : "retired";
    InmunityApi.getTrialsSort(type, sign, query, flag).then(response => {
      if (tabActive === 1) {
        this.setState({
          trials: response.results,
          loading: false,
          isMoreActiveTrials: !!response.next,
          linkActiveTrials: response.next,
          trialsAmount: response.count
        });
      } else {
        this.setState({
          retired: response.results,
          loading: false,
          isMoreRetiredTrials: !!response.next,
          linkRetiredTrials: response.next,
          retiredAmount: response.count
        });
      }
    });
  };

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortTrials();
    });
  };

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        trials: [],
        retired: [],
        loading: true
      },
      () => {
        this.sortTrials();
      }
    );
  };

  clickTrial = trialId => {
    let { relocate } = this.props;
    relocate("/trials/" + trialId + "/candidates");
  };

  showNewTrialPopup = () => {
    this.setState({ newTrialPopup: true });
  };

  closeNewTrialPopup = () => {
    this.setState({ newTrialPopup: false });
  };

  showImportTrial = () => {
    this.closeNewTrialPopup();
    this.setState({ importTrialPopup: true });
  };

  closeImportTrialPopup = () => {
    this.setState({ importTrialPopup: false });
  };
  gotoImportPage = () => { };

  loadMoreData = tab => {
    let {
      isMoreActiveTrials,
      isMoreRetiredTrials,
      linkActiveTrials,
      linkRetiredTrials,
      retired,
      trials
    } = this.state;
    if (tab === 1) {
      if (isMoreActiveTrials) {
        InmunityApi.getPatientSearchPagination(linkActiveTrials).then(
          response => {
            let linkActiveTrials = response.next;
            let isMoreActiveTrials = !!linkActiveTrials;
            let oldTrials = JSON.parse(JSON.stringify(trials));
            let NewTrials = JSON.parse(JSON.stringify(oldTrials));
            let test = oldTrials.splice(oldTrials.length - 10, 10);
            if (!_.isEqual(test, response.results)) {
              NewTrials = trials.concat(response.results);
            }
            this.setState({
              helpfulActiveTrials: trials,
              trials: NewTrials,
              linkActiveTrials,
              isMoreActiveTrials
            });
          }
        );
      }
    } else {
      if (isMoreRetiredTrials) {
        InmunityApi.getPatientSearchPagination(linkRetiredTrials).then(
          response => {
            let linkRetiredTrials = response.next;
            let isMoreRetiredTrials = !!linkRetiredTrials;
            let oldTrials = [...retired];
            let NewTrials = oldTrials;
            if (oldTrials !== response.results) {
              NewTrials = oldTrials.concat(response.results);
            }
            this.setState({
              helpfulRetiredTrials: trials,
              retired: NewTrials,
              linkRetiredTrials,
              isMoreRetiredTrials
            });
          }
        );
      }
    }
  };

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          input: e.target.value,
          loading: true,
          trials: [],
          retired: []
        },
        () => {
          this.sortTrials();
        }
      );
    } else {
      this.setState({
        input: e.target.value
      });
    }
  };

  render() {
    let {
      tabActive,
      retired,
      trials,
      trialsAmount,
      retiredAmount,
      isReverseSort,
      selectedOption,
      loading,
      isMoreActiveTrials,
      isMoreRetiredTrials,
      newTrialPopup
    } = this.state;
    return (
      <div className="container-n">
        <h1 className="title-container">{i18n.t("menu.trials")}</h1>
        <div className="status-trials">
          <div className="trial-tabs">
            <div
              className={`active-trials ${tabActive === 1 &&
                "tab-trials--active"}`}
              onClick={() => this.setState({ tabActive: 1 })}
            >
              {i18n.t("general.activeTrials")} ({trialsAmount})
            </div>
            <div
              className={`retired-trials ${tabActive === 2 &&
                "tab-trials--active"}`}
              onClick={() => this.setState({ tabActive: 2 })}
            >
              {i18n.t("general.retiredTrials")} ({retiredAmount})
            </div>
          </div>
          <div className="trial-tabs forImportPopup">
            {newTrialPopup && (
              <NewTrialPopup
                onClose={this.closeNewTrialPopup}
                relocate={() => this.props.relocate("/trial/new")}
                importTrial={this.showImportTrial}
              />
            )}
            {this.state.importTrialPopup && (
              <ImportTrialPopup
                onClose={this.closeImportTrialPopup}
                relocate={this.gotoImportPage}
              />
            )}
            {this.props.permissions.crud_trials ? (
                <GreenButton
                    click={this.showNewTrialPopup}
                    text={"New Trial"}
                    position={"right"}
                />
            ) : (
                <DisabledButton
                    click={this.showNewTrialPopup}
                    text={"New Trial"}
                    position={"right"}
                />
            )}

          </div>
        </div>
        <div className="search-container">
          <Sort
            options={options}
            selectedOption={selectedOption}
            isRevert={isReverseSort}
            result={null}
            revert={this.reverseSort}
            select={this.handleChange}
            change={this.handleChangeInput}
            press={this.sortTrials}
          />
        </div>
        <div className="trials-blocks">
          <div className="trial-headings">
            <div className="trial-heading-item">Trial NCT</div>
            <div className="trial-heading-item">Trial Name</div>
            <div className="trial-heading-item">Sponsor</div>
            <div className="trial-heading-item">Principal Investigator</div>
          </div>
          {this.props.permissions.view_trials | this.props.permissions.crud_trials ? (
            !loading ? (
              tabActive === 1 ? (
                <Trial
                  trials={trials}
                  isMoreActiveTrials={isMoreActiveTrials}
                  loadMoreData={() => this.loadMoreData(1)}
                  loading={loading}
                  clickTrial={this.clickTrial}
                  name="active"
                />
              ) : (
                  <Trial
                    trials={retired}
                    isMoreRetiredTrials={isMoreRetiredTrials}
                    loadMoreData={() => this.loadMoreData(2)}
                    loading={loading}
                    clickTrial={this.clickTrial}
                    name="retired"
                  />
                )
            ) : (
                <SpinnerSmall />
              )
          ) : (
            <div className='noResFound'>No trials available</div>          
            )} 
          
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return{
    permissions: state.permissions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    relocate: path => dispatch(push(path)),
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavigationSubLink: id => dispatch(setCurrentNavigationSubLink(id))
  };
}
export default withRouter(
  connect(
      mapStateToProps,
    mapDispatchToProps
  )(Trials)
);

