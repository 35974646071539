import React, { Component } from "react";
import i18n from "../../../i18n";
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
import crossicon from "../../../images/icon-close-w.svg";
import PhysiciansTooltip from "../../trials/NewTrial/PhysiciansTooltip";
import PatientIdTooltip from "../../trials/NewTrial/PatientIdTooltip";
class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			responsibleTooltip: {
				name: "",
			},
		};
	}
	handleChangeCalendar = e => {
		this.setState({ reminderDate: e });
	};

	checkReminders = el => {
		return el.reminders && el.reminders.length > 0;
	};
	handleChangeCalendar = reminderDate => {
		this.setState({
			reminderDate,
		});
	};

	responsiblePicked = (obj, whereToFill) => {
		whereToFill.value = obj.name;
		this.setState({ responsibleTooltip: obj });
	};

	render() {
		return (
			<Popup
				open={this.props.modalVisible}
				onClose={this.props.closePopup}
				className="popup-createReminder"
			>
				<div className="">
					<div className="contact-form createReminderPopup">
						<div
							className="closePendingPopupCross"
							onClick={this.props.closePopup}
						>
							<img src={crossicon} alt="Pupup Cross" />
						</div>
						<h3 className="title-form">
							{this.props.isCreatePopup
								? i18n.t("calendarList.newReminder")
								: "Reminder"}
						</h3>
						<form
							className="new-reminder__form"
							action="#"
							onSubmit={e => {
								e.preventDefault();
							}}
						>
							<div className="input-container">
								<label htmlFor="reminderDate" className="label-modal">
									{i18n.t("calendarList.dateReminder")}
								</label>
								<DatePicker
									name="reminderDate"
									className="date-picker-calendar"
									selected={this.props.reminderDate}
									onChange={e => this.props.setFieldValue("reminderDate", e)}
									showTimeSelect
									timeFormat="HH:mm"
									dateFormat="LLL"
									timeCaption="time"
								/>
							</div>
							<div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
								<label htmlFor="patientId" className="label-modal">
									{i18n.t("calendarList.patientId")}
								</label>
								<input
									type="text"
									name="patientId"
									className="get-freetext-name"
									onChange={this.props.findPatientId}
									defaultValue={this.props.patientIdTooltip.immunity_id}
								/>
								{Array.isArray(this.props.patientIdTooltip) &&
									this.props.patientIdTooltip.length > 0 && (
										<PatientIdTooltip
											patientIds={this.props.patientIdTooltip}
											Mpicked={this.props.patientPicked}
											setPatientId
										/>
									)}
							</div>
							<div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
								<label htmlFor="physicianName" className="label-modal">
									{i18n.t("calendarList.physicianName")}
								</label>
								<input
									type="text"
									name="physicianName"
									className="get-freetext-name"
									onChange={this.props.findPhysicianName}
									defaultValue={this.props.physicianTooltip.name}
								/>
								{Array.isArray(this.props.physicianTooltip) &&
									this.props.physicianTooltip.length > 0 && (
										<PhysiciansTooltip
											physiciansNames={this.props.physicianTooltip}
											Mpicked={this.props.physicianPicked}
											isReminder
										/>
									)}
							</div>
							<div className="input-container">
								<label htmlFor="description" className="label-modal">
									{i18n.t("calendarList.description")}
								</label>
								<textarea
									className="textarea-modal"
									name="description"
									placeholder={i18n.t("calendarList.addDescription")}
									onChange={e =>
										this.props.setFieldValue(
											"description",
											e.currentTarget.value
										)
									}
									defaultValue={this.props.description}
								/>
							</div>
							<div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
								<label htmlFor="responsible" className="label-modal">
									{i18n.t("calendarList.responsible")}
								</label>
								<input
									type="text"
									name="responsible"
									className="get-freetext-name"
									onChange={this.props.findResponsibleName}
									defaultValue={this.props.responsibleTooltip.name}
								/>
								{Array.isArray(this.props.responsibleTooltip) &&
									this.props.responsibleTooltip.length > 0 && (
										<PhysiciansTooltip
											physiciansNames={this.props.responsibleTooltip}
											Mpicked={this.props.responsiblePicked}
											isRemin
											der
										/>
									)}
							</div>
						</form>
						<div className="button-container">
							{!this.props.isCreatePopup && (
								<div
									className="cancel-button"
									onClick={() => console.log("hi")}
								>
									{"Deactivate"}
								</div>
							)}
							<div
								className="cancel-button"
								onClick={
									this.props.isCreatePopup
										? this.props.closePopup
										: this.props.deleteReminder
								}
							>
								{this.props.isCreatePopup ? i18n.t("general.cancel") : "Delete"}
							</div>
							<div
								onClick={
									this.props.isCreatePopup
										? this.props.createReminder
										: this.props.editReminder
								}
								className="mdForHeaderAndArrow__btn"
							>
								{this.props.isCreatePopup
									? i18n.t("calendarList.createReminder")
									: "Edit reminder"}
							</div>
						</div>
					</div>
				</div>
			</Popup>
		);
	}
}

export { Form };
