import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { connect } from "react-redux";

class ExclusionReasonFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonOption: "",
      options: []
    };
  }

  componentDidMount() {
    let options = [];
    let array = [...this.props.exclusionReasons];
    array.forEach(el => {
      let obj = {};
      obj.value = obj.label = el.name;
      options.push(obj);
    });
    this.setState({ options });
  }

  handleSelectChange = reasonOption => {
    let res = "";
    this.setState({ reasonOption }, () => {
      let options = [...this.state.reasonOption];
      options.forEach((el, i) => {
        i === options.length - 1
          ? (res += el.value + "")
          : (res += el.value + ", ");
      });
      this.props.getValue(res);
      if (options.length > 0) {
        document
          .querySelector(".ex-filter-select")
          .classList.remove("redBorder");
      }
    });
  };

  render() {
    return (
      <div className="filterContent">
        <div className="dateWrapper newTrialWrapp">
          <div className="datePicker">Reason</div>
          <Select
            value={this.state.reasonOption}
            placeholder="Select one or more"
            closeMenuOnSelect={false}
            onChange={this.handleSelectChange}
            options={this.state.options}
            className="sort_select withInput ex-filter-select"
            isMulti={true}
            components={makeAnimated()}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exclusionReasons: state.exclusionReasons
  };
}

export default connect(mapStateToProps)(ExclusionReasonFilter);
