import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { URLGenerator } from "../../utils/trailsNavigation";
import AllCandidates from "./AllCandidates";
import inmunityApi from "../../api/InmunityApi";
import TrialsNav from "../TrialsNav";
import {
  getTrialDescription,
  setCurrentTrial,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import { Sort, CustomInfinityScroll } from "../../ui";
import "./index.css";
import { SpinnerSmall } from "../../ui/spinner";
import LoadMoreBtn from "../../ui/loadmore";

const options = [
  // { value: "visit", label: "Upcoming Visit" } ghost#hidden,
  { value: "consented", label: "Contact Consent" },
  { value: "immunity", label: "Immunity ID" },
  { value: "name", label: "Name" },
  { value: "last_seen", label: "Last Visit" },
  { value: "next_visit", label: "Next Visit" },
];

class Candidates extends Component {
  state = {
    candidates: [],
    selectedOption: options[0],
    loading: true,
    searching: false,
    isMore: null,
    link: null,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    currentTrial: "",
    anchor: false,
    loadingNext: false
  };

  componentDidMount() {
    this.mounted = true;
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription,
      setCurrentTrial
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial);
    this.setState(
      {
        currentTrial: currentTrial
      },
      () => this.defaultCandidates()
    );
    this.trialName(currentTrial)
    getTrialDescription(currentTrial);
    setCurrentTrial(currentTrial);
  }

  componentWilUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let currentTrial = this.props.location.pathname.split("/")[2];
      this.trialName()
      this.setState(
        {
          currentTrial: currentTrial
        }
      );
      this.defaultCandidates()
    }
  }

  static componentWillReceiveProps(newProps) {
    console.log("update list")
    if (newProps.location.pathname !== this.location.pathname) {
      let currentTrial = newProps.location.pathname.split("/")[2];
      this.trialName()
      this.setState(
        {
          currentTrial: currentTrial
        },
        () => {
          this.defaultCandidates()
        }
      );
    }
  }

  defaultCandidates = () => {
    this.setState({ loading: true });
    let { currentTrial } = this.state;
    inmunityApi.getAllPatients(currentTrial).then(candidates => {
      if (this.mounted) {
        if (candidates.results) {
          candidates.results.sort(
            (a, b) => new Date(b.upcomingVisit) - new Date(a.upcomingVisit)
          );
          this.setState({
            candidates: candidates.results,
            loading: false,
            isMore: !!candidates.next,
            link: candidates.next,
          });
        }
      }
    });
  };

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        candidates: []
      },
      () => {
        this.sortCandidatesApi();
      }
    );
  };

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortCandidatesApi();
    });
  };

  sortCandidatesApi = () => {
    this.setState({ loading: true });
    let flag = this.state.selectedOption.value;
    let sign = this.state.isReverseSort ? "-" : "";
    let query = this.state.input !== "" ? this.state.input : null;
    inmunityApi
      .getCandidatesSorted(this.state.currentTrial, sign, query, flag)
      .then(response => {
        this.setState({
          candidates: response.results,
          loading: false,
          isMore: !!response.next,
          link: response.next,
          searchLengthResult: response.count
        });
      });
  };

  trialName = (id) => {
    this.setState({ loading: true });
    inmunityApi
      .getTrialName(id)
      .then(response => {
        this.setState({
          name: response.name,
          loading: false,
        });
      });
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldCandidates = [...this.state.candidates];
            let newCandidates = oldCandidates.concat(response.results);
            this.setState({
              candidates: newCandidates,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };

  handleChangeInput = ({ target }) => {
    if (target.value.length === 0) {
      this.setState(
        {
          input: target.value,
          candidates: [],
          searching: false
        },
        () => {
          this.sortCandidatesApi();
        }
      );
    } else {
      this.setState({
        input: target.value
      });
    }
  };

  searchCands = () => {
    this.setState(
      {
        candidates: [],
        isMore: false,
        searching: true
      },
      () => {
        this.sortCandidatesApi();
      }
    );
  };

  render() {
    let {
      selectedOption,
      candidates,
      loading,
      currentTrial,
      searching,
      isReverseSort,
      searchLengthResult,
      isMore,
      loadingNext,
      name,
    } = this.state;
    let trialID = this.props.location.pathname.split("/")[2]
    let nameNow = (this.props.trials.length > 0 && trialID) ? this.props.trials.find(x => x.studyID == trialID) : ""

    if (nameNow !== undefined) {
      name = nameNow.studyName
    }

    return (
      <div className="content-body-matches" id="list">
        <div className="study-name">{name}</div>
        {
          trialID && <TrialsNav trialID={trialID} current={'candidates'} permissions={this.props.permissions}/>
        }
        <Sort
          options={options}
          selectedOption={selectedOption}
          isRevert={isReverseSort}
          result={searchLengthResult}
          revert={this.reverseSort}
          select={this.handleChange}
          change={this.handleChangeInput}
          press={this.searchCands}
          name={name}
          paths="candidates"
          currentTrial={trialID}
          searching={searching}
        />
        <div className="candidatesWrapper">
          {candidates.length > 0 &&
            candidates.map((candidate, i) => (
              <AllCandidates
                key={"trial-candidate-slice-" + candidate["hadmId"] + i}
                id={"trial-candidate-slice-" + candidate["hadmId"]}
                candidate={candidate}
                studyID={trialID}
              />
          ))}
          {(loading || loadingNext) && <SpinnerSmall/>}
          {!loadingNext && isMore && <LoadMoreBtn loadMoreData={e => this.loadMoreData(e)}/>}
          {candidates.length === 0 && searching && !loading && (
						<div className="noResFound">No candidates yet for this trial.</div>
					)}
{candidates.length === 0 && !searching && !loading && (
						<div id="loading-candidates" className="loadingCandidates">
							<div className="noResFound">No results found</div>
						</div>
					)}
        </div>


        {/* <CustomInfinityScroll
          data={candidates}
          searching={searching}
          loading={loading}
          isMore={isMore}
          textNoResult="No candidates yet for this trial."
          textNoResultSearch="No results found"
          loadMoreData={this.loadMoreData}
          loadingNext={loadingNext}
        >
          {candidates.length > 0 &&
            candidates.map((candidate, i) => (
              <AllCandidates
                key={"trial-candidate-slice-" + candidate["hadmId"] + i}
                id={"trial-candidate-slice-" + candidate["hadmId"]}
                candidate={candidate}
                studyID={currentTrial}
              />
            ))}
        </CustomInfinityScroll> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial,
    trials: state.trials,
    permissions: state.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id)),
    setCurrentTrial: id => dispatch(setCurrentTrial(id))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Candidates)
);
