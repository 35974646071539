import React, { useEffect, useState } from "react";
import ElBlockSearch from "./../../../../Patients/NewSearch/ElBlockSearch";
import { connect } from "react-redux";

function Item(props) {
	const {
		data,
		ourCriteria,
		flag,
		remove,
		setInfo,
		allData,
		freeTextVersion,
		freeTextBlock,
		newFdata,
		fullData,
		farLabel,
	} = props;

	const [structuredData, setStructuredData] = useState([]);

	const freeTextBlockMakeResult = inObj => {
		let structuredData = [];
		const meds = inObj.criteria.filter(el => el.criteria === "Medication"),
			dis = inObj.criteria.filter(el => el.criteria === "Disease"),
			proc = inObj.criteria.filter(el => el.criteria === "Procedure"),
			labs = inObj.criteria.filter(el => el.criteria === "Lab"),
			sub = inObj.criteria.filter(el => el.criteria === "Substance"),
			smokes = inObj.criteria.filter(el => el.criteria === "Smoking"),
			allergies = inObj.criteria.filter(
				el => el.criteria === "Allergy Filters" || el.criteria === "Allergy"
			);
		if (meds.length > 0) {
			structuredData.push({
				criteria: meds,
				flag: "Medication Filters",
			});
		}
		if (dis.length > 0) {
			structuredData.push({ criteria: dis, flag: "Disease Filters" });
		}
		if (proc.length > 0) {
			structuredData.push({
				criteria: proc,
				flag: "Procedure History",
			});
		}
		if (labs.length > 0) {
			console.log("Labs => ", labs)
			structuredData.push({ criteria: labs, flag: "Lab Filters" });
		}
		if (sub.length > 0) {
			structuredData.push({ criteria: sub, flag: "Substance" });
		}
		if (smokes.length > 0) {
			structuredData.push({ criteria: smokes, flag: "Smoking" });
		}
		if (allergies.length > 0) {
			structuredData.push({ criteria: allergies, flag: "Allergy Filters" });
		}

		structuredData.forEach(g => {
			if (Array.isArray(g.criteria)) {
				g.criteria = g.criteria.map(e => ({ ...e, label: farLabel }));
			}
		});

		setStructuredData(structuredData);
	};

	const makeResult = data => {
		let structuredData = [];
		data &&
			data.criteria.forEach(elem => {
				if (freeTextVersion) {
					structuredData.push({ ...elem });
				} else {
					if (ourCriteria === elem.criteria) {
						structuredData.push({ ...elem });
					}
				}
			});
		setStructuredData(structuredData);
	};

	useEffect(() => {
		freeTextBlock ? freeTextBlockMakeResult(data) : makeResult(data);
	}, [data, allData]);
	return freeTextBlock ? (
		structuredData.map((el, i) => {
			return el.flag === "Lab Filters" ||
				el.flag === "Substance" ||
				el.flag === "Smoking" ? (
				el.criteria.map((item, index) => {
					return (
						<ElBlockSearch
							key={index}
							searchPage
							criteriaFromSearch={el.flag}
							criteriaType={true}
							delete={remove}
							searchOptions={true}
							id={el.fakeId}
							sendForMedication={[item]}
							setInfo={setInfo}
							data={allData}
							freeTextVersion={freeTextVersion}
							newFdata={newFdata}
							fullData={fullData}
							freeTextBlock={freeTextBlock}
						/>
					);
				})
			) : el.flag === "Medication Filters" || el.flag === "Allergy Filters" ? (
				<ElBlockSearch
					key={i}
					searchPage
					criteriaFromSearch={el.flag}
					criteriaType={true}
					delete={remove}
					searchOptions={true}
					id={el.fakeId}
					sendForMedication={el.criteria}
					setInfo={setInfo}
					data={allData}
					freeTextVersion={freeTextVersion}
					newFdata={newFdata}
					fullData={fullData}
					freeTextBlock={freeTextBlock}
				/>
			) : (
				<ElBlockSearch
					key={i}
					searchPage
					criteriaFromSearch={el.flag}
					criteriaType={true}
					delete={remove}
					searchOptions={true}
					id={el.fakeId}
					sendForMedication={el.criteria}
					setInfo={setInfo}
					data={allData}
					freeTextVersion={freeTextVersion}
					newFdata={newFdata}
					fullData={fullData}
					freeTextBlock={freeTextBlock}
				/>
			);
		})
	) : (
		<>
			{(ourCriteria === "Lab" ||
				ourCriteria === "Substance" ||
				ourCriteria === "Smoking") &&
			!freeTextVersion
				? structuredData.length > 0 &&
				  structuredData.map((el, i) => {
						return (
							<ElBlockSearch
								key={el.fakeId}
								searchPage
								criteriaFromSearch={
									ourCriteria === "Lab" ? "Lab Filters" : ourCriteria
								}
								criteriaType={true}
								delete={remove}
								searchOptions={true}
								sendForMedication={[el]}
								setInfo={setInfo}
								data={allData}
								fullData={allData}
								freeTextVersion={freeTextVersion}
								newFdata={newFdata}
							/>
						);
				  })
				: structuredData.length > 0 && (
						<ElBlockSearch
							searchPage
							criteriaFromSearch={flag}
							criteriaType={true}
							delete={remove}
							searchOptions={true}
							sendForMedication={structuredData}
							setInfo={setInfo}
							data={allData}
							freeTextVersion={freeTextVersion}
							newFdata={newFdata}
						/>
				  )}
		</>
	);
}

function mapStateToProps(state) {
	return {
		farLabel: state.farLabel,
	};
}

export default connect(mapStateToProps)(Item);
