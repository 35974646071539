import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { setProfilePhoto, getUserByToken } from "../../../actions/trialActions";
class ImageView extends Component {
  state = {
    samePassword: true,
    currentUser: {},
    profilePhoto: null,
    photoURL: ""
  };

  componentDidMount() {
    if (this.props.currentUser !== this.state.currentUser) {
      console.log(this.state.currentUser)
      this.setState({ currentUser: this.props.currentUser });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      photoURL: nextProps.currentUser.photo
    };
  }

  changePhoto = e => {
    let input = e.target;
    e.target.parentNode.previousSibling.id = "";
    input.id = "photo";
    let img = e.target.parentNode.previousSibling;
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.onload = function(e) {
        img.setAttribute("src", e.target.result);
      };
      this.setState(
        {
          profilePhoto: input.files[0]
        },
        () => {
          this.props.setProfilePhoto(this.state.profilePhoto);
        }
      );
      reader.readAsDataURL(input.files[0]);
    }
  };

  render() {
    let { photoURL, profilePhoto } = this.state;
    return (
      <div className="myProfile__ls">
        <div
          className="uploadPhoto"
          style={
            photoURL.length > 0
              ? {
                  backgroundImage: `url(${photoURL})`,
                  backgroundSize: "cover"
                }
              : {}
          }
        >
          <img
            src={photoURL}
            className={
              photoURL || profilePhoto ? "uploadedImg" : "uploadedImg hidden"
            }
            alt="Profile"
          />
          <div className="changePhoto">
            <input type="file" onChange={this.changePhoto} />
            <span>Change Photo</span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.currentUser,
    profilePhoto: state.profilePhoto
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProfilePhoto: photo => dispatch(setProfilePhoto(photo)),
    getUserByToken: () => dispatch(getUserByToken())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageView);
