export default function (date) {
    return (
        !date || date === "Invalid date" ? '-' : date
    )
}

export function handleFetchErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}