import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import InmunityApi from '../../../api/InmunityApi'
import Popup from "reactjs-popup";
import crossicon from "../../../images/icon-close-w.svg";

import ActionsOverview from '../ActionsOverview'

import './index.css'

 
class Overview extends React.Component {
  state = {
    open: false,
    uploadedFiles: [],
    hadmId: this.props.details.hadmId ? this.props.details.hadmId : '',
    gender: this.props.details.gender ? this.props.details.gender : '',
    age: this.props.details.age ? this.props.details.age : '',
    dob: this.props.details.dob ? this.props.details.dob : '',
    dod: this.props.details.dod ? this.props.details.dod : '',
    email: this.props.details.email ? this.props.details.email : '',
    phone: this.props.details.phone ? this.props.details.phone : '',
    import_date: this.props.details.import_date ? this.props.details.import_date : '',
    update_date: this.props.details.update_date ? this.props.details.update_date : '',
    lastEncounter: this.props.details.lastEncounter
      ? this.props.details.lastEncounter
      : '',
    upcomingVisit: this.props.details.upcomingVisit
      ? this.props.details.upcomingVisit
      : '',
    physicians: this.props.details.physicians
      ? this.props.details.physicians
      : '',
    diagnosis: this.props.details.diagnosis ? this.props.details.diagnosis : '',
    protocol: this.props.details.protocol ? this.props.details.protocol : '',
    patientName: this.props.details.patientName
      ? this.props.details.patientName
      : '',
    immunity: this.props.details.immunity ? this.props.details.immunity : '',
    workflow: this.props.details.workflow ? this.props.details.workflow : ''
  }

  uploadFile = e => {
    let input = e.target
    let uploadedFiles = [...this.state.uploadedFiles]
    let obj = {}
    if (input.files && input.files[0]) {
      InmunityApi.uploadPatientAttachment(this.state.immunity, input.files[0])
        .then(res => {
          obj = {
            id: res.data,
            title: input.files[0].name
          }

          uploadedFiles.push(obj)
          this.setState({
            uploadedFiles
          })
        }
      ).catch(function (error) {
        console.log(error);
      });
    }
  }

  deleteFile = attachment => {
    InmunityApi.deletePatientAttachment(this.state.immunity, attachment.id)
      .then(res => {
        let uploadedFiles = [...this.state.uploadedFiles]
        uploadedFiles.forEach((file, i) => {
          // Remove the file from the state at its index.
          if (file === attachment) {
            uploadedFiles.splice(i, 1)
            this.setState({
              uploadedFiles
            })
          }
        })
      }
    );
  }

  downloadFile = attachment => {
    InmunityApi.downloadPatientAttachment(this.state.immunity, attachment.id)
      .then(res => {
        // The response will return a URL which is used to download the file
        // from a simulated link click.
        if (res.status === 200){
          res.text().then(url => {
            const element = document.createElement('a');
            url = url.slice(1, -1)
            element.setAttribute('href', url);
            element.setAttribute('download', attachment.title);
  
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          });
        }
        else if (res.status === 500){
          this.setState({
            open: true,
            message: "An error occurred while downloading the file."
          });
        }
        else if (res.status === 202){
          res.json().then(
            data => {
              this.setState({
                open: true,
                message: data.message
              });
            }
          )
        }
      }
    );
  }

  componentDidMount = e => {
    InmunityApi.getPatientAttachments(this.state.immunity)
      .then(res => {
        this.setState({
          uploadedFiles: res.map(attachment => ({
            id: attachment.id,
            title: attachment.name
          }))
        })

      }
    );
  }

//   static getDerivedStateFromProps (props, state) {
//     return {
//       patientName: props.details.workflow[0].patient
     
//   }
// }

  closeModal = () => {
    this.setState({ open: false, message: "", closePopupOnError: false });
  };

  render () {
    const {
      hadmId,
      gender,
      age,
      dob,
      dod,
      email,
      phone,
      import_date,
      update_date,
      lastEncounter,
      upcomingVisit,
      physicians,
      diagnosis,
      patientName,
      immunity,
      workflow
    } = this.state

    return (
      <div className='patientOverview'>
        <div className='poHeader-container'>
          <div className='poHeader'>Patient Overview</div>
          <ActionsOverview
            hadmID={hadmId}
            workflow={workflow}
            updateWorkflow={this.props.updateWorkflow}
            currentTrial={!!this.props.studyId}
            studyId={this.props.studyId}
          />
        </div>
        <div className='po'>
          <div className='wrapForTV'>
            <span className='potitle'>ID</span>
            <span className='potitlevalue'>{immunity}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Name</span>
            <span className='potitlevalue'>{patientName}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Age</span>
            <span className='potitlevalue'>{age}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Birthday</span>
            <span className='potitlevalue'>
              {dob !== "" ? moment(dob)
                .format('MMM DD YYYY')
                : "-"}
            </span>
          </div>
          {dod !== "-" || dod !== "" && <div className='wrapForTV'>
            <span className='potitle'>Death date</span>
            <span className='potitlevalue'>
              {dod !== "-" ? moment(dod)
                .format('MMM DD YYYY')
                : "-"}</span>
          </div>}
          <div className='wrapForTV'>
            <span className='potitle'>Gender</span>
            <span className='potitlevalue'>
              {gender === 'F' ? 'Female' : 'Male'}
            </span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Email</span>
            <span className='potitlevalue'>
              {this.props.details.email ? this.props.details.email : !this.props.details.email ? "N/A" : "-"}
            </span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Phone</span>
            <span className='potitlevalue'>
              {this.props.details.phone ? this.props.details.phone : !this.props.details.phone ? "N/A" : "-"}
            </span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Diagnosis</span>
            <span className='potitlevalue'>{diagnosis}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Import date</span>
            <span className='potitlevalue'>
                {import_date !== "" ? moment(import_date)
                    .format('MMM DD YYYY h:mm:ss a')
                  : "-"}
              </span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Last update date</span>
            <span className='potitlevalue'>
                {update_date !== "" ? moment(update_date)
                    .format('MMM DD YYYY h:mm:ss a')
                  : "-"}
              </span>
          </div>
        </div>
        <div className='pograyLine' />
        <div className='poPLN'>
          <div className='poPLN_wrap'>
            <span className='pln__title'>{`${
              physicians && physicians.length > 1 ? 'Physicians' : 'Physician'
            }`}</span>
            <div className='pln__value fdColumn'>
              {physicians && physicians.length > 0
                ? physicians.map((value, i) => {
                  return i === physicians.length - 1 ? (
                    <span key={i}>
                      {value.name} {value.specialty && `(${value.specialty})`}
                    </span>
                  ) : (
                    <span key={i}>
                      {value.name} {value.specialty && `(${value.specialty})`}
                    </span>
                  )
                })
                : null}
            </div>
          </div>
          <div className='poPLN_wrap'>
            <span className='pln__title'>Last Encounter</span>
            <span className='pln__value'>
              {lastEncounter.date ? moment(lastEncounter.date)
                .format('MMM DD YYYY - hh:mm A')
                : "-"} {lastEncounter.name && (lastEncounter.name)}
            </span>
          </div>
          <div className='poPLN_wrap'>
            <span className='pln__title'>Next Appointment</span>
            <span className='pln__value'>
            {upcomingVisit.date ? moment(upcomingVisit.date)
                .format('MMM DD YYYY - hh:mm A')
                : "-"} {upcomingVisit.name && (upcomingVisit.name)}
            </span>
          </div>
        </div>
        <div className='pograyLine' />
        <div className='attachmentsWrapp'>
          <span className='attachmentsWrappTitle'>Attachments</span>
          <div className='filesWrapp'>
            {this.state.uploadedFiles &&
              this.state.uploadedFiles.length > 0 &&
              this.state.uploadedFiles.map((el, i) => {
                return (
                  <div className='uploadedFile' key={el.title + i}>
                    <span
                      onClick={this.downloadFile.bind(this, el)}
                    >{el.title}
                     <Popup
                  open={this.state.open}
                  closeOnDocumentClick
                  onClose={this.closeModal}
                  className="saveUserPopup"
                >
                  <div>
                    <div
                      className="closePendingPopupCross"
                      onClick={this.closeModal}
                    >
                      <img src={crossicon} alt="Cross" />
                    </div>
                    <div className="saveUserPopupText">
                      {this.state.message}
                    </div>
                  </div>
                </Popup>
                    </span>
                    <div className='cross'>
                      <img
                        src={require('../../../images/close-circle-o-anticon.svg')}
                        alt='Close Circle'
                        onClick={this.deleteFile.bind(this, el)}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='addNew'>
            <img
              src={require('../../../images/clip.svg')}
              alt='Close Circle'
              height='16px'
              style={{ marginRight: '6px' }}
            />
            Add New
            <input
              id='attachment'
              className='forSending'
              type='file'
              onChange={this.uploadFile}
            />
          </div>
        </div>
        <div className='pograyLine' />
        <div className='last-status-workflow'>
          <div className='title'>Status</div>
          <div className='status'>
            {this.props.globalState.workFlowState &&
              this.props.globalState.workFlowState.status}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    globalState: state.globalState
  }
}

export default connect(mapStateToProps)(Overview)
