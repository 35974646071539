import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function trialReducer(state = initialState.trials, action, selectedTrials) {
  switch (action.type) {
    case types.LOAD_TRIALS_SUCCESS:
      return action.trials.sort((a, b) => a.studyName.localeCompare(b.studyName))

    default:
      return state
  }
}
