import React, { Component } from "react";
import Calendar from "react-calendar";
import moment from "moment";

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendarFrom: false,
      dateResultFrom: "select date",
      showCalendarTo: false,
      dateResultTo: "select date"
    };
  }

  showCalendarFrom = () => {
    this.setState({ showCalendarFrom: !this.state.showCalendarFrom });
  };

  dateChangeFrom = date => {
    let dateResultFrom = moment(date).format("MMM DD YYYY");
    this.setState({ dateResultFrom }, () => {
      if (this.state.dateResultFrom !== "select date") {
        document.querySelector("#dateFrom").classList.remove("redBorder");
      }
    });
    this.showCalendarFrom();
    this.props.dateFrom(dateResultFrom);
  };

  showCalendarTo = () => {
    this.setState({ showCalendarTo: !this.state.showCalendarTo });
  };

  dateChangeTo = date => {
    let dateResultTo = moment(date).format("MMM DD YYYY");
    this.setState({ dateResultTo }, () => {
      if (this.state.dateResultTo !== "select date") {
        document.querySelector("#dateTo").classList.remove("redBorder");
      }
    });
    this.showCalendarTo();
    this.props.dateTo(dateResultTo);
  };
  render() {
    return (
      <div className="filterContent">
        <div className="dateWrapper">
          <div className="datePicker">From</div>
          <div
            className="checkedCo cal-newtrial cal-filter"
            id="dateFrom"
            onClick={this.showCalendarFrom}
          >
            {this.state.dateResultFrom}
          </div>
          {this.state.showCalendarFrom && (
            <Calendar onChange={this.dateChangeFrom} locale="en-GB" />
          )}
        </div>
        <div className="dateWrapper dateWrapperTo">
          <div className="datePicker">To</div>
          <div
            className="checkedCo cal-newtrial cal-filter"
            id="dateTo"
            onClick={this.showCalendarTo}
          >
            {this.state.dateResultTo}
          </div>
          {this.state.showCalendarTo && (
            <Calendar onChange={this.dateChangeTo} locale="en-GB" />
          )}
        </div>
      </div>
    );
  }
}
