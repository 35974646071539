import React from "react";
import checkmark from "../../../images/icon-check-green.svg";
import crossicon from "../../../images/icon-close-grey.svg";
import "./index.css";
import checkPermission from "../../../utils/checkPermission";

const AdminEditRole = ({ onClick }) => {
  return (
    checkPermission() &&
    <img
      src={require("../../../images/edit.png")}
      onClick={onClick}
      alt="Edit Icon"
    />
  )
}

const AdminCountUsers = ({ usersCount }) => {
  return (
    <span className="countUsers">
      {usersCount ? `${usersCount} users` : "0 users"}
    </span>
  )
}

const Role = ({
  id,
  roleName,
  crud_trials,
  view_trials,
  add_user,
  add_includion,
  analytics_coordinator,
  analytics_patient,
  analytics_trials,
  give_access_trial,
  import_trial,
  view_data_own,
  view_data,
  view_deind_data,
  schedule_view,
  patient_import,
  editRole,
  usersCount
}) => {
  function editBlock() {
    let permissionObj = {
      id,
      roleName,
      crud_trials,
      view_trials,
      add_user,
      add_includion,
      analytics_coordinator,
      analytics_patient,
      analytics_trials,
      give_access_trial,
      import_trial,
      view_data_own,
      view_data,
      view_deind_data,
      schedule_view,
      patient_import
    };

    localStorage.setItem("permissionObj", JSON.stringify(permissionObj));
    localStorage.setItem("roleName", roleName);

    editRole(permissionObj);
  }
  return (
    <div className="block">
      <div className="status-blocks">
        <div className="nameEditCount">
          <div className="block-edit">
            <span className="role">{roleName}</span>
            <AdminEditRole onClick={editBlock} />
          </div>
          <AdminCountUsers usersCount={usersCount} />
        </div>
      </div>
      <div className="block-item">
        <img
          src={crud_trials ? checkmark : crossicon}
          alt={crud_trials ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={view_trials ? checkmark : crossicon}
          alt={view_trials ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={add_user ? checkmark : crossicon}
          alt={add_user ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={analytics_trials ? checkmark : crossicon}
          alt={analytics_trials ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={view_data_own ? checkmark : crossicon}
          alt={view_data_own ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={view_data ? checkmark : crossicon}
          alt={view_data ? "Checkmark" : "Cross"}
        />
      </div>
      <div className="block-item">
        <img
          src={patient_import ? checkmark : crossicon}
          alt={patient_import ? "Checkmark" : "Cross"}
        />
      </div>
    </div>
  );
};
export default Role;
