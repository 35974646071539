import React from "react";
import PatientCount from "./PatientCount";
import PatientMatchBreakdown from "./PatientMatchBreakdown";

export default function CountChartBlock({
  count,
  spinner,
  graphsData,
  graphSpinner
}) {
  return (
    <div className="count_chartB-wrapp">
      <PatientCount count={count} spinner={spinner} />
      <PatientMatchBreakdown
        graphsData={graphsData}
        graphSpinner={graphSpinner}
      />
    </div>
  );
}
