import React from "react";
import { SpinnerSmall } from "./../../../../../../../ui";

export default function PatientCount({ count, spinner = true }) {
  return (
    <div className="pat_count_wrapp">
      <div className="inc_title">Patients Found</div>
      <div className="pat_count">{spinner ? <SpinnerSmall /> : count}</div>
    </div>
  );
}
