import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import trials from "./trialReducer";
import trialsOptions from "./trialsOptionsReducer";
import physiciansOptions from "./physiciansOptionsReducer";
import sitesOptions from "./sitesOptionsReducer";
import allergiesList from "./allergiesList";
import allergiesLeafs from "./allergiesLeafs";
import changeEmailPopup from "./changeEmailPopup";
import currenNavigation from "./currentNavigationReducer";
import currentTrial from "./currentTrialReducer";
import currentHadmId from "./currentHadmIdReducer";
import trial from "./getTrialDescriptionReducer";
import userToken from "./authReducer";
import diseases from "./diseasesReducer";
import medications from "./medicationsReducer";
import newMedications from "./newMedicationsReducer";
import labNames from "./labNamesReducer";
import globalSpinner from "./globalSpinner";
import labUoms from "./labUomsReducer";
import currentUser from "./getUserReducer";
import viewRender from "./viewRender";
import modalGeneric from "./modalGeneric";
import trialMatches from "./getTrialMatchesReducer";
import trialCandidates from "./getTrialCandidatesReducer";
import profilePhoto from "./profilePhotoReducer";
import userPhoto from "./userPhotoReducer";
import newUserPhoto from "./newUserPhotoReducer";
import procedures from "./proceduresReducer";
import patientsResult from "./patientsResultReducer";
import patientsFilterResult from "./patientsFilterReducer";
import patientOverview from "./patientOverviewReducer";
import roles from "./getAllRolesReducer";
import globalState from "./globalStateReducer";
import search from "./getSearchForPopulationReducer";
import searches from "./getSavedSearchesReducer";
import labWithUom from "./getLabWithUomReducer";
import labList from "./labListReducer";
import emailNotification from "./sendEmailNotificationReducer";
import exclusionReasons from "./getExclusionReasonsReducer";
import lastStages from "./getLastStagesReducer";
import geoData from "./geoDataReducer";
import workFlowStudyId from "./setWorkFlowIdReducer";
import actions from "./getWFActionsReducer";
import responsibles from "./setResponsibleNames";
import showPicker from "./showPickerReducer";
import reminders from "./setRemindersReducer";
import otherReminders from "./setOtherRemindersReducer";
import rootMedications from "./getRootMedicationsReducer";
import rootMedicationsTree from "./setRootMedicationsTree";
import rootProcedures from "./getRootProceduresReducer";
import medicationsATC from "./setMedicationsATCReducer";
import medicationsEPC from "./setMedicationsEPCReducer";
import medicationsMOA from "./setMedicationsMOAReducer";
import medicationsATCList from "./setMedicationsATCListReducer";
import medicationsEPCList from "./setMedicationsEPCListReducer";
import medicationsMOAList from "./setMedicationsMOAListReducer";
import diseasesPicker from "./setDiseasesPickerReducer";
import pickerLabel from "./setPickerLabel";
import farLabel from "./farLabel";
import medicationsLabel from "./setMedicationsLabel";
import proceduresLabel from "./setProcedureLabel";
import proceduresPicker from "./setProceduresPickerReducer";
import proceduresList from "./setProceduresListReducer";
import loader from "./loaderReducer";
import popup from "./popupReducer";
import substances from "./getAllSubstancesReducer";
import substancesSearch from "./getAllSubstancesSearchReducer";
import physicians from "./getPhysiciansReducer";
import physiciansSearch from "./getPhysiciansSearchReducer";
import smoking from "./getSmokingReducer";
import medicationsPicker from "./getMedicationsPickerReducer";
import diseasesPickerRoot from "./getDiseasesPickerReducer";
import proceduresPickerRoot from "./getProceduresPickerReducer";
import diseasesDO from "./setDiseasesDOReducer";
import proceduresICD9 from "./setProceduresICD9Reducer";
import proceduresICD10 from "./setProceduresICD10Reducer";
import diseasesICD9CM from "./setDiseasesICD9CMReducer";
import diseasesICD10CM from "./setDiseasesICD10CMReducer";
import diseasesDOList from "./setDiseasesDOListReducer";
import proceduresICD9List from "./setProceduresICD9ListReducer";
import proceduresICD10List from "./setProceduresICD10ListReducer";
import fullChartName from "./setFullChartName";
import diseasesICD9CMList from "./setDiseasesICD9CMListReducer";
import diseasesICD10CMList from "./setDiseasesICD10CMListReducer";
import searchesRecent from "./getRecentSearchesReducer";
import permissions from './permissions'
import { reducer as formReducer } from "redux-form";
import {setAnalytics, setHospitals, setSite} from "./sitesDataReducer";

export default combineReducers({
	trials,
	trialsOptions,
	physiciansOptions,
	sitesOptions,
	analiticData: setAnalytics,
	hospitals: setHospitals,
	site: setSite,
	permissions,
	trial,
	userToken,
	currentUser,
	currentTrial,
	currentHadmId,
	currentNavigation: currenNavigation,
	diseases,
	newMedications,
	medications,
	labNames,
	labUoms,
	labList,
	fullChartName,
	routing: routerReducer,
	viewRender,
	modalGeneric,
	trialMatches,
	trialCandidates,
	profilePhoto,
	userPhoto,
	procedures,
	newUserPhoto,
	farLabel,
	globalSpinner,
	roles,
	patientsResult,
	patientOverview,
	globalState,
	search,
	searches,
	allergiesList,
	allergiesLeafs,
	labWithUom,
	emailNotification,
	patientsFilterResult,
	exclusionReasons,
	lastStages,
	geoData,
	workFlowStudyId,
	actions,
	responsibles,
	showPicker,
	reminders,
	otherReminders,
	rootMedications,
	rootMedicationsTree,
	rootProcedures,
	medicationsATC,
	medicationsEPC,
	medicationsMOA,
	medicationsATCList,
	medicationsEPCList,
	changeEmailPopup,
	medicationsMOAList,
	diseasesPicker,
	pickerLabel,
	medicationsLabel,
	proceduresLabel,
	proceduresPicker,
	proceduresList,
	form: formReducer,
	loader,
	popup,
	substances,
	substancesSearch,
	physicians,
	physiciansSearch,
	smoking,
	medicationsPicker,
	diseasesPickerRoot,
	proceduresPickerRoot,
	diseasesDO,
	proceduresICD9,
	proceduresICD10,
	diseasesICD9CM,
	diseasesICD10CM,
	diseasesDOList,
	proceduresICD9List,
	proceduresICD10List,
	diseasesICD9CMList,
	diseasesICD10CMList,
	searchesRecent,
});
