import styled from 'styled-components'


const borderColor = '#ebebeb';
const fontColor = '#3c3c3c';
const rowHeight = 35;

export const StyledTableRow = styled.div`
    display: flex;
    flex-direction: column,
    min-height: ${rowHeight}px;
    margin: 0 !important;
    border: solid 1px ${borderColor};
    border-top: ${ props => props.isFirst ? 'solid 1px' + borderColor : 'none'};
`

export const StyledLabTitle = styled.span`
    display: flex;
    flex: 1;
    text-align: left;
    alignItems: center;
    justifyContent: center;
    padding: 10px;
    textAlign: center;
    min-height: ${rowHeight}px !important;
    `

export const StyledLabRowElement = styled.div`
    display: flex;
    width: ${props => props.type === true ? "20%" : "65%"};
`

export const StyledLabTable = styled.div`
    font-size: 14px;
    flex: 3;
`


export const StyledTab = styled.div`
    padding: 0 12px 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 350px;
    min-width: 170px !important;
    height: 37px !important;
    height: ${rowHeight}px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
    border-width:
    0
    ${props => props.rightCorner ? '1px' : '1px'}
    0
    ${props => props.leftCorner ? '1px' : '0'};

    border-style: solid;
    border-color: ${borderColor};
    position: relative;
    // top: 1px;
    border-bottom: 0px solid white !important;
    background: ${props => props.active ? 'white' : 'none'};

`

export const StyledLabHeader = styled.div`
    display: flex;
    padding-bottom: 10px;
    padding-top: 30px;
    min-hight: ${rowHeight}px;
    font-size: 12px;
    color: ${fontColor};
    font-weight: 500;
`

export const StyledLabDetailedHeader = styled.div`
    display: flex;
    width: ${props => props.type ? "20%" : "65%"};
    align-items: center;
    justify-content: space-beetween;

`

export const StyledLabName = styled.div`
    display: flex;
    flex: 1;

`


export const LabScrollContainer = styled.div`
max-height: 400px;
overflow-y: scroll;
`

export const StyledBlockWrapper = styled.div`
    background: white;
    padding: 26px 16px;
    border-radius: 0 4px 4px 4px;
    max-height: 400px;
    overflow-y: hidden;
    border: solid 1px ${borderColor};
`


export const TopWhiteMargin = styled.div`
    margin-top: 20px;
    background:red !important;

`
