import React, {Component} from "react";
import {connect} from "react-redux";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import WeekHeaders from "./weekHeaders";
import WeekEvents from "./weekEvent";
import InmunityApi from "../../api/InmunityApi";
import ToolBar from "./toolbar";
import PreScreening from "./PreScreening";
import CalendarList from "./CalendarList";
import {
  setReminders,
  setOtherReminders,
  loadDiseases,
  loadDiseasesSuccess,
} from "../../actions/trialActions";
import {loadSites} from '../../actions/sitesActions';
import "./index.css";

moment.locale("ko", {
  week: {
    dow: 1,
    doy: 1,
  },
});

BigCalendar.momentLocalizer(moment);

class Dashboard extends Component {
  state = {
    selectedTrials: JSON.parse(localStorage.getItem("selectedTrials")) || [],
    selectedSites: JSON.parse(localStorage.getItem("selectedSites")) || [],
    selectedPhysicians: JSON.parse(localStorage.getItem("selectedPhysicians")) || [],
    startOfWeek: localStorage.getItem("dashboardWeeks")
      ? this.parseJSONDate('startOfWeek', 'startOf')
      : moment(moment(new Date()).startOf("isoWeek")._d).format("MMM DD YYYY"),
    endOfWeek: localStorage.getItem("dashboardWeeks")
      ? this.parseJSONDate('endOfWeek', 'endOf')
      : moment(moment(new Date()).endOf("isoWeek")._d).format("MMM DD YYYY"),
    allEvents: JSON.parse(localStorage.getItem("allEvents")) || [],
    query: [],
    calendarEvents: {
      results: [],
      next: "",
      count: 0,
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    localStorage.setItem("i18nextLng", "en-EN");

    if (this.mounted) {
      this.props.loadSitesOptions();
    }
  }

  parseJSONDate(dateName, methodName) {
    return moment(
      JSON.parse(localStorage.getItem("dashboardWeeks"))[dateName]
    )[methodName]("isoWeek")._d
  }

  handleSelectChange = (newOptions, itemsToReplace, localStorageName) => {
    const newState = {};
    newState[itemsToReplace] = newOptions;

    this.setState(newState, () => {
      localStorage.setItem(localStorageName, JSON.stringify(this.state[itemsToReplace]));
    })
  }

  handleChangeSite = siteOptions => {
    this.setState({siteOptions}, () => {
      localStorage.setItem("selectedSites", JSON.stringify(this.state.siteOptions));
    });
  };

  getAllEvents = () => {
    const {selectedTrials: trialsOptions, selectedSites: sitesOptions, selectedPhysicians: physiciansOptions} = this.state;
    let obj = {};
    if (!this.candidates) return;
    obj.status = 1;
    if (this.candidates.checked && this.matches.checked) {
      obj.status = 3;
    } else if (this.candidates.checked && !this.matches.checked) {
      obj.status = 1;
    } else if (!this.candidates.checked && this.matches.checked) {
      obj.status = 2;
    }

    obj.studies = trialsOptions && trialsOptions.map(el => el.studyId);
    obj.physicians = physiciansOptions && physiciansOptions.map(el => el.value);
    obj.sites = sitesOptions && sitesOptions.map(el => el.value);
    obj.from = this.state.startOfWeek;
    obj.to = this.state.endOfWeek;

    let params = [];
    if (trialsOptions.length > 0) {
      params.push(`studies=${obj.studies.join(",")}`);
    }
    if (physiciansOptions.length > 0) {
      params.push(`physicians=${obj.physicians.join(",")}`);
    }
    if (sitesOptions.length > 0) {
      params.push(`sites=${obj.sites.join(",")}`);
    }

    params.push(`dateFrom=${moment(this.state.startOfWeek).format("MMM DD YYYY")}`);
    params.push(`dateTo=${moment(this.state.endOfWeek).format("MMM DD YYYY")}`);
    params.push(`status=${obj.status}`);

    this.setState({query: params})

    if (params.length > 3) {
      this.setState({calendarSpinner: true});
      InmunityApi.getDashboardEventsNew(params.join("&"))
        .then(response => {
          if (response.length === 0) {
            this.setState(
              {
                allEvents: [],
                calendarSpinner: false,
              },
              () => {
                const now = new Date();
                localStorage.setItem("lastDayOfGettingEvents", now);
                let eventsJSON = JSON.stringify(this.state.allEvents);
                localStorage.setItem("allEvents", eventsJSON);
                localStorage.setItem("whatWasChecked", obj.status);
              }
            );
          }
          // 	throw response;
          // }
          return response;
        })
        .then(allEvents => {
          this.setState(
            {
              allEvents: [...allEvents],
              calendarSpinner: false,
            },
            () => {
              const now = new Date();
              localStorage.setItem("lastDayOfGettingEvents", now);
              let eventsJSON = JSON.stringify(allEvents);
              localStorage.setItem("allEvents", eventsJSON);
              localStorage.setItem("whatWasChecked", obj.status);
            }
          );
        })
        .catch(error => {
          if (error.status !== 404) {
            console.log("Error occured while receiving events", error);
          }
          this.setState({calendarSpinner: false});
        });
      this.getCalendarData(params.join("&"));
    }
  };

  getAllReminders = () => {
    const {selectedTrials: trialsOptions, selectedPhysicians: physiciansOptions} = this.state;
    let {startOfWeek, endOfWeek} = this.state;
    let dateFromTo = {from: startOfWeek, to: endOfWeek};

    if (trialsOptions.length === 0 && physiciansOptions.length === 0) return;

    InmunityApi.getReminders(dateFromTo)
      .then(res => {
        if (res.status === 404) {
          this.setState({reminders: [], otherReminders: []}, () => {
            this.props.setReminders([]);
            this.props.setOtherReminders([]);
          });
          return false;
        } else if (res.status === 200) {
          res.text().then(obj => {
            let reminders = JSON.parse(obj);
            let otherReminders = [];
            reminders.forEach(el => {
              if (el.type === "Other") {
                el.timeOfVisit = el.date;
                el.endOfVisit = moment(el.date).add(30, "minutes").format();
                el.studyTags = {
                  color: "#reminder",
                };
                el.immunity = el.description;
                otherReminders.push(el);
              }
            });
            this.setState({reminders, otherReminders}, () => {
              this.props.setReminders(reminders);
              this.props.setOtherReminders(otherReminders);
            });
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getCalendarData = params => {
    InmunityApi.getVisitsEventsNew(params)
      .then(response => {
        if (response.length === 0) {
          this.setState(
            {
              calendarEvents: [],
              calendarSpinner: false,
            },
            () => {
              let calendarEventsJSON = JSON.stringify(
                this.state.calendarEvents
              );
              localStorage.setItem("calendarEvents", calendarEventsJSON);
            }
          );
        }
        return response;
      })
      .then(calendarEvents => {
        this.setState(
          {
            calendarEvents: {
              results: [...this.state.calendarEvents.results, ...calendarEvents.results],
              next: calendarEvents.next,
              count: calendarEvents.count,
            },
          },
          () => {
            let calendarEventsJSON = JSON.stringify(calendarEvents);
            localStorage.setItem("calendarEvents", calendarEventsJSON);
          }
        );
      })
      .catch(error => {
        if (error.status !== 404) {
          console.log("Error occured while receiving events", error);
        }
      });
  };

  goToInfo = event => {
    let { history } = this.props;
    history.push({
      pathname: `/matches/details/${event.hadmId}`,
      id: event.hadmId,
      detailTitle: "Candidate Details",
    });
  };
  eventColorUp = (event, start, end, isSelected) => {
    let colorClass = event.studyTags.color
      ? `diff-color-${event.studyTags.color.slice(1)}`
      : "";
    return { className: colorClass };
  };

  render() {
    let components = {
      week: {
        header: WeekHeaders,
        event: WeekEvents,
      },
      toolbar: ToolBar,
    };

    return (
      <div className="container-n">
        <div className="calendar-container">
          <div className="calendar-container__header">
            <h1>Dashboard</h1>
            <div className="calendar-container__headerRS">
              <div className="newTrialWrapp wrappForTwoSelects">
                <Select
                  defaultValue={this.state.selectedTrials}
                  closeMenuOnSelect={false}
                  onChange={o => this.handleSelectChange(o, 'selectedTrials', 'selectedTrials')}
                  options={this.props.trialsOptions}
                  className="sort_select withInput"
                  isMulti={true}
                  components={makeAnimated()}
                  placeholder={"Select Trial"}
                />
                <Select
                  defaultValue={this.state.selectedSites}
                  closeMenuOnSelect={false}
                  onChange={s => this.handleSelectChange(s, 'selectedSites', 'selectedSites')}
                  options={this.props.sitesOptions}
                  className="sort_select withInput"
                  isMulti={true}
                  components={makeAnimated()}
                  placeholder={"Select Site"}
                />
                <Select
                  defaultValue={this.state.selectedPhysicians}
                  closeMenuOnSelect={false}
                  onChange={o => this.handleSelectChange(o, 'selectedPhysicians', 'selectedPhysicians')}
                  options={this.props.physiciansOptions}
                  className="sort_select withInput"
                  isMulti={true}
                  components={makeAnimated()}
                  placeholder={"Select Physician"}
                />
              </div>
              <div className="newTrialWrapp wrappForTwoSelects forCheckboxes">
                <div className="wrapForTwoCheckboxes">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="candidates"
                      id="candidates"
                      ref={input => (this.candidates = input)}
                      defaultChecked={
                        (localStorage.getItem("whatWasChecked") &&
                          +localStorage.getItem("whatWasChecked") !== 2) ||
                        !localStorage.getItem("whatWasChecked")
                      }
                    />
                    <label
                      htmlFor="candidates"
                      className="gray-small-text checkbox-label remeber__block"
                    >
                      <span/>
                      <div className="remeber__text">Candidates</div>
                    </label>
                  </div>
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="matches"
                      id="matches"
                      ref={input => (this.matches = input)}
                      defaultChecked={
                        !!(localStorage.getItem("whatWasChecked") &&
                          +localStorage.getItem("whatWasChecked") !== 1)
                      }
                    />
                    <label
                      htmlFor="matches"
                      className="gray-small-text checkbox-label remeber__block"
                    >
                      <span/>
                      <div className="remeber__text">Matches</div>
                    </label>
                  </div>
                </div>
                <div
                  className="mdForHeaderAndArrow__btn"
                  onClick={this.getAllEvents}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>

          {this.state.calendarSpinner && (
            <div className="spinner-container">
              <img
                className="small-spinner calendar-spinner"
                style={{height: "50px"}}
                src={require("../../images/spinner.gif")}
                alt="Spinner"
              />
            </div>
          )}

          <div className="big-calendar">
            <BigCalendar
              components={components}
              events={this.state.allEvents}
              startAccessor={event => {
                return new Date(event.timeOfVisit);
              }}
              endAccessor={event => {
                return new Date(event.endOfVisit);
              }}
              min={new Date(2017, 10, 0, 8, 0, 0)}
              max={new Date(2017, 10, 0, 23, 0, 0)}
              defaultView={"week"}
              views={["week"]}
              defaultDate={
                localStorage.getItem("dashboardWeeks")
                  ? moment(
                  JSON.parse(localStorage.getItem("dashboardWeeks"))
                    .startOfWeek
                  ).startOf("isoWeek")._d
                  : null
              }
              onSelectEvent={event => this.goToInfo(event)}
              eventPropGetter={this.eventColorUp}
              onNavigate={(date, view) => {
                this.setState(
                  {
                    startOfWeek: moment(
                      moment(date).startOf("isoWeek")._d
                    ).format(),
                    endOfWeek: moment(
                      moment(date).endOf("isoWeek")._d
                    ).format(),
                  },
                  () => {
                    let dashboardWeeks = {
                      startOfWeek: this.state.startOfWeek,
                      endOfWeek: this.state.endOfWeek,
                    };
                    localStorage.setItem(
                      "dashboardWeeks",
                      JSON.stringify(dashboardWeeks)
                    );
                    this.getAllEvents();
                    this.getAllReminders();
                  }
                );
              }}
            />
          </div>
        </div>
        <div className="dashboard-tables">
          <PreScreening/>
          {(this.props.permissions.view_data_own || this.props.permissions.view_data) &&
          <CalendarList
            spinner={this.state.calendarSpinner}
            events={this.state.calendarEvents}
            query={this.state.query}
            getAllEvents={this.getCalendarData}
            getAllReminders={this.getAllReminders}
          />
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    trials: state.trials,
    trialsOptions: state.trialsOptions,
    physiciansOptions: state.physiciansOptions,
    sitesOptions: state.sitesOptions,
    trialMatches: state.trialMatches,
    trialCandidates: state.trialCandidates,
    physicians: state.physicians,
    userToken: state.userToken,
    currentUser: state.currentUser,
    reminders: state.reminders,
    otherReminders: state.otherReminders,
    diseases: state.diseases,
    globalSpinner: state.globalSpinner,
    permissions: state.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReminders: reminders => dispatch(setReminders(reminders)),
    setOtherReminders: reminders => dispatch(setOtherReminders(reminders)),
    loadDiseases: () => dispatch(loadDiseases()),
    loadDiseasesSuccess: diseases => dispatch(loadDiseasesSuccess(diseases)),
    loadSitesOptions: sitesOpstions => dispatch(loadSites(sitesOpstions))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
