import React from "react";
import moment from "moment";
import { withRouter } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroller";
const Visits = ({ visits, redirect }) => {
	return (
		<>
			<div className="prescreening-headers">
				<div className="prescreening-head pat-visits">Visits</div>
				<div className="prescreening-head pat-id">Patient ID</div>
				<div className="prescreening-head pat-name">Physician Name</div>
				<div className="prescreening-head pat-overview">Patient Overview</div>
			</div>
			{visits && visits.count > 0 ? (
				visits.results.map((row, i) => (
						<div key={i} onClick={() => redirect(row.hadmId, row.status)}>
							<div className="calendar-list-row" key={i}>
								<div className="cl-cell-event-type">
									{row.timeOfVisit
										? moment(row.timeOfVisit).format("MMM DD YYYY - hh:mm A")
										: "-"}
								</div>
								<div className="cl-cell-event-type row-name">{row.immunity}</div>
								<div className="cl-cell-physician-date">{row.physicianName}</div>
								<div className="cl-cell-notes">{row.summary}</div>
							</div>
						</div>
					))
				) : (
					<div className="spinner-container filters-social__title">
						Please select a trial name or physician name to display upcoming
						appointments
					</div>
				)
			}
		</>
	);
};

const router = withRouter(Visits)
export { router as Visits };
