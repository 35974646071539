import React, { Component, Fragment } from "react";
import Role from "./Role";
import Popup from "reactjs-popup";
import PermissionList from "../header/PermissionList";
import "./index.css";
import crossicon from "../../images/icon-close-w.svg";
import InmunityApi from "../../api/InmunityApi";
import { connect } from "react-redux";
import {getRoles, setPermissions} from "../../actions/trialActions";
import store from "../../store";
import { withRouter } from "react-router";

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      open: false,
      loading: false,
      message: "",
      savePopup: false,
      result: [],
      roleName: "",
      editRole: this.editRole
    };
    this.editRole = this.editRole.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // show spinner
    this.setState({ loading: true });

    this.props
      .dispatch(getRoles())
      .then(() => {
        let result = this.props.roles;
        let roles = [];
        result.forEach(role => {
          roles.push(
            <Role
              id={role.id}
              roleName={role.name}
              crud_trials={role.crud_trials}
              view_trials={role.view_trials}
              add_user={role.add_user}
              analytics_trials={role.analytics_trials}
              view_data_own={role.view_data_own}
              view_data={role.view_data}
              patient_import={role.patient_import}
              editRole={this.editRole}
              usersCount={role.users_count}
            />
          );
        });
        this.setState({ result, roles, loading: false });
      })
      .catch(error => console.log("Error occured while getting roles", error));
  }

  static getDerivedStateFromProps(props, state) {
    let result = props.roles;
    let roles = [];
    if (result.length > state.roles.length) {
      result.forEach(role => {
        roles.push(
          <Role
            id={role.id}
            roleName={role.name}
            crud_trials={role.crud_trials}
            view_trials={role.view_trials}
            add_user={role.add_user}
            give_access_trial={role.give_access_trial}
            import_trial={role.import_trial}
            view_data_own={role.view_data_own}
            view_data={role.view_data}
            patinet_import={role.patient_import}
            editRole={state.editRole}
            usersCount={role.users_count}
          />
        );
      });
      return {
        roles,
        result
      };
    }
  }

  insertRole = (roleName, permissionObject, status, val) => {
    if (status === false) {
      let roles = [...this.state.roles];
      roles.push(
        <Role
          roleName={roleName}
          create_trials={
            permissionObject["crud_trials"]
              ? permissionObject["crud_trials"]
              : false
          }
          view_trials={
            permissionObject["view_trials"]
              ? permissionObject["view_trials"]
              : false
          }
          add_user={
            permissionObject["add_user"] ? permissionObject["add_user"] : false
          }
          analytics_trials={
            permissionObject["analytics_trials"]
              ? permissionObject["analytics_trials"]
              : false
          }
          view_data_own={
            permissionObject["view_data_own"]
              ? permissionObject["view_data_own"]
              : false
          }
          view_data={
            permissionObject["view_data"]
              ? permissionObject["view_data"]
              : false
          }
          editRole={this.editRole}
        />
      );
      this.setState({ roles });
    } else if (status === true) {
      let roles = [...this.state.roles];
      let target = roles.find(el => el.props.roleName === roleName);
      let index = roles.findIndex(el => el.props.roleName === roleName);
      let props = Object.assign({}, target.props, { ...permissionObject });
      let newTarg = Object.assign({}, target);
      newTarg.props = props;
      newTarg.props.roleName = val;
      roles.splice(index, 1, newTarg);
      this.setState({ roles });
    }
  };

  scrollLeft = ({ target }) => {
    console.log("Here")
    let container = document.querySelector(".permission-container__scrollX");
    let scrollAmount = 0;

    let slideTimer = setInterval(function () {
      container.scrollLeft -= 10;
      scrollAmount += 10;
      if (scrollAmount >= 290) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };

  editRole = permissionObj => {
    let open = true;
    this.setState({ open });
    localStorage.setItem("editPerm", JSON.stringify(permissionObj));
  };

  closeModal = () => {
    let open = false;
    this.setState({ open });
  };

  closeSavePopup = () => {
    this.setState({ savePopup: false, message: "" });
  };


  scrollRigth = ({ target }) => {
    let container = document.querySelector(".permission-container__scrollX");
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft += 10;
      scrollAmount += 10;
      if (scrollAmount >= 290) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };

  addRole = () => {
    let val = document.querySelector(".role-popup__input").value.trim();
    let obj = localStorage.getItem("permissionObj")
      ? JSON.parse(localStorage.getItem("permissionObj"))
      : {};

    if (Object.keys(obj).length === 0) {
      let open = false;
      this.setState({ open });
    } else {
      let sendObject = {};
      let roleID = obj.id;
      sendObject = { ...obj };
      delete sendObject.id;
      sendObject.name = val;
      if (val !== "" && val === localStorage.getItem("roleName")) {
        document
          .querySelector(".role-popup__input")
          .classList.remove("redBorder");
        InmunityApi.editRole(sendObject, roleID)
          .then(resolve => {
            if (resolve.status !== 200) {
              this.setState(
                {
                  savePopup: true,
                  message: "An error occurred while saving"
                },
                () => localStorage.removeItem("permissionObj")
              );
            } else {
              this.insertRole(localStorage.getItem("roleName"), obj, true, val);
              let open = false;
              store.dispatch(setPermissions(sendObject));
              this.setState({ open }, () =>
                localStorage.removeItem("permissionObj")
              );
            }
          })
          .catch(error =>
            console.log("Error occured while creating new role", error)
          );
      } else if (val !== "" && val !== localStorage.getItem("roleName")) {
        let res = [];
        res = this.state.result.filter(el => el.name === val);
        if (res.length > 0) {
          this.setState({
            savePopup: true,
            message: `Confirm to overwrite existing role: ${val}`,
            id: res[0].id,
            roleName: res[0].name
          });
        } else {
          this.setState({
            savePopup: true,
            message: `Confirm to create new role: ${val}`
          });
        }
      } else if (val === "") {
        document.querySelector(".role-popup__input").classList.add("redBorder");
      }
    }
  };

  overWriteRole = () => {
    let val = document.querySelector(".role-popup__input").value.trim();
    let obj = localStorage.getItem("permissionObj")
      ? JSON.parse(localStorage.getItem("permissionObj"))
      : {};
    let sendObject = {};
    sendObject = { ...obj };
    delete sendObject.id;
    sendObject.name = val;
    document.querySelector(".role-popup__input").classList.remove("redBorder");
    InmunityApi.editRole(sendObject, this.state.id)
      .then(resolve => {
        if (resolve.status !== 200) {
          this.setState(
            {
              savePopup: true,
              message: "An error occurred while saving"
            },
            () => localStorage.removeItem("permissionObj")
          );
        } else {
          this.insertRole(this.state.roleName, obj, true, val);
          let open = false;
          let savePopup = false;
          let id = "";
          let roleName = "";
          this.setState({ open, id, savePopup, roleName }, () =>
            localStorage.removeItem("permissionObj")
          );
        }
      })
      .catch(error =>
        console.log("Error occured while creating new role", error)
      );
  };

  createNewRole = () => {
    let sendObject = {};
    let val = document.querySelector(".role-popup__input").value.trim();
    let obj = localStorage.getItem("permissionObj")
      ? JSON.parse(localStorage.getItem("permissionObj"))
      : {};
    sendObject = { ...obj };
    sendObject.name = val;
    delete sendObject.id;
    if (val !== "") {
      document
        .querySelector(".role-popup__input")
        .classList.remove("redBorder");
      InmunityApi.createNewRole(sendObject)
        .then(resolve => {
          if (resolve.status !== 201) {
            return resolve.text().then(text => {
              if (text === '{"name":["role with this name already exists."]}') {
                this.setState({
                  savePopup: true,
                  message: `Role with name ${sendObject.name} already exists.
                  Please choose a different name for this role.`
                });
              } else {
                this.setState({
                  message: "An error occurred while saving"
                });
              }
              throw new Error(text);
            });
          } else {
            store.dispatch(getRoles());
            let open = false;
            let savePopup = false;
            this.setState({ open, savePopup }, () =>
              localStorage.removeItem("permissionObj")
            );
          }
        })
        .catch(error =>
          console.log("Error occured while creating new role", error)
        );
    } else {
      document.querySelector(".role-popup__input").classList.add("redBorder");
    }
  };

  goToPending = () => {
    this.props.history.push({ pathname: "/settings/users", showPopup: true });
  };

  render() {
    return (
      <div className="permission">
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          className="popup-newtrial role-popup"
        >
          <div>
            <div className="closePendingPopupCross" onClick={this.closeModal}>
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="ntw__ti-inputs">
              <h1>Edit role</h1>
              <input
                type="text"
                className="TrialInformationWrapper role-popup__input"
                defaultValue={localStorage.getItem("roleName")}
              />
              <PermissionList permissionObj={this.state.permissionObj} />
              <div className="role__container">
                <button
                  className="search-container__plusbtn"
                  onClick={this.addRole}
                >
                  Edit Role
                </button>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          open={this.state.savePopup}
          closeOnDocumentClick
          onClose={this.closeSavePopup}
          className="saveUserPopup"
        >
          <div>
            <div
              className="closePendingPopupCross"
              onClick={this.closeSavePopup}
            >
              <img src={crossicon} alt="Cross" />
            </div>
            {this.state.message.startsWith("Confirm to create") && (
              <Fragment>
                <div className="saveUserPopupText">{this.state.message}</div>
                <div className="newTrialPopupWrapper">
                  <div className="forNTWBtns">
                    <div className="fromScratch" onClick={this.createNewRole}>
                      Confirm
                    </div>
                    <div className="fromTemplate" onClick={this.closeSavePopup}>
                      Cancel
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {this.state.message.startsWith("Confirm to overwrite") && (
              <Fragment>
                <div className="saveUserPopupText">{this.state.message}</div>
                <div className="newTrialPopupWrapper">
                  <div className="forNTWBtns">
                    <div className="fromScratch" onClick={this.overWriteRole}>
                      Confirm
                    </div>
                    <div className="fromTemplate" onClick={this.closeSavePopup}>
                      Cancel
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {!this.state.message.startsWith("Confirm to overwrite") &&
              !this.state.message.startsWith("Confirm to create") && (
                <div className="saveUserPopupText">{this.state.message}</div>
              )}
          </div>
        </Popup>
        {this.props.location.fromPending && (
          <div className="back-to-pending" onClick={this.goToPending}>
            <img
              src={require("./../../images/left-b.png")}
              alt="Left"
              height="12px"
              style={{ paddingRight: "5px", zIndex: 2 }}
            />
            Go back to pending users
          </div>
        )}
        <h1>Roles & Permissions</h1>
        {!this.state.loading ? (
          <div className="permission-container">
            <div className="permission-container__desc">
              <div className="block">
                <div className="status-blocks opacity0" />
                <div className="block-item block-item-text">
                  View/Create/Edit/Delete Trials
                </div>
                <div className="block-item block-item-text">
                  View Trials
                </div>
                <div className="block-item block-item-text">
                  Add/delete users
                </div>
                <div className="block-item block-item-text">
                  Analytics on trials and sites
                </div>
                <div className="block-item block-item-text">
                  View identified data - preconsent
                </div>
                <div className="block-item block-item-text">
                  View identified data - postconsent
                </div>
                <div className="block-item block-item-text">
                  Import patients
                </div>
              </div>
            </div>
            <div className="permission-container__desc permission-container__status">
              <div
                className="permission-container__arrow-left"
                onClick={this.scrollLeft}
              >
                <img src={require("../../images/left.png")} alt="Left" />
              </div>
              <div
                className="permission-container__arrow-right"
                onClick={this.scrollRigth}
              >
                <img src={require("../../images/right.png")} alt="Right" />
              </div>
              <div className="permission-container__scrollX">
                {this.state.roles.map((component, i) => {
                  console.log(component)
                  console.log(i)
                  return <div key={i}>{component}</div>;
                })}
              </div>
            </div>
          </div>
        ) : (
            <div className="spinner-center" style={{ marginTop: "100px" }}>
              <img
                className="big-spinner"
                src={require("../../images/spinner.gif")}
                alt="Big Spinner"
              />
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken,
    roles: state.roles,
    permission: state.permissions
  };
}

export default withRouter(connect(mapStateToProps)(Permission));
