import React from 'react'
import './index.css'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const RecruitmentGoal = ({
  workflow_stages,
  exclusion_reasons,
  criteria_exclusion
}) => {
  let getChartOptions = chart => {
    let data = []
    chart.map(el => {
      data.push({
        name: el.title,
        y: el.value
      })
      return null
    })
    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                'black'
            }
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: 'Patients',
          colorByPoint: true,
          data: data
        }
      ]
    }
    return chartOptions
  }

  return (
    <div className='containerForAnalytics workflowStages'>
      <div className='workflowStages__titles'>
        <p className='forcfa__label'>Workflow Stages</p>
        <p className='forcfa__label'>Reasons for exclusion</p>
        <p className='forcfa__label'>Frequent criteria causing exclusion</p>
      </div>
      <div className='workflowStages__charts' id='workflowStages-id'>
        {workflow_stages && (
          <div
            className='workflow_chart'
            style={{
              width: (window.innerWidth - 180 - 88) * 0.3
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={getChartOptions(workflow_stages)}
            />
          </div>
        )}
        {exclusion_reasons && (
          <div
            className='workflow_chart'
            style={{
              width: (window.innerWidth - 180 - 88) * 0.3
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={getChartOptions(exclusion_reasons)}
            />
          </div>
        )}
        {criteria_exclusion && (
          <div
            className='workflow_chart'
            style={{
              width: (window.innerWidth - 180 - 88) * 0.3
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={getChartOptions(criteria_exclusion)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RecruitmentGoal
