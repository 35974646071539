import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentTrialReducer(state = initialState.currentTrial, action) {
  switch(action.type) {
    case types.CURRENT_TRIAL:
      return action.currentTrial
    default:
      return state;
  }
}
