import React, { useState } from "react";
import { FilterWrapper } from "./filterWrapper";
import FilterResult from "../../Exclusions/Filter/FilterResult";
import "./style.css";
export const SearchFilter = ({ filtersArray, updateFilters, applyFilter }) => {
  const [show, toggle] = useState(false);
  const openFilter = () => toggle(true);
  const cancelFilter = () => toggle(false);
  const [filtersArrayState, changeFiltersArray] = useState([]);
  const applyFilterPrew = filter => {
    if (filter.options.dateFrom) {
      changeFiltersArray(prev => {
        return [
          ...prev,
          {
            filterName: filter.filter,
            dateTo: filter.options.dateTo,
            dateFrom: filter.options.dateFrom
          }
        ];
      });
      updateFilters([
        ...filtersArrayState,
        {
          filterName: filter.filter,
          dateTo: filter.options.dateTo,
          dateFrom: filter.options.dateFrom
        }
      ]);
      applyFilter([
        ...filtersArrayState,
        {
          filterName: filter.filter,
          dateTo: filter.options.dateTo,
          dateFrom: filter.options.dateFrom
        }
      ]);
    } else {
      changeFiltersArray(prev => {
        return [
          ...prev,
          {
            filterName: filter.filter,
            value: filter.options
          }
        ];
      });
      updateFilters([
        ...filtersArrayState,
        {
          filterName: filter.filter,
          value: filter.options
        }
      ]);
      applyFilter([
        ...filtersArrayState,
        {
          filterName: filter.filter,
          value: filter.options
        }
      ]);
    }
    cancelFilter();
  };
  const deleteResult = i => {
    const newState = [...filtersArrayState];
    newState.splice(i, 1);
    changeFiltersArray(newState);
    updateFilters(newState);
    applyFilter(newState);
  };
  return (
    <div className="searchFilter ex_save_search_wrapper ex_wrapper">
      <div className="ex_addWrapp" onClick={openFilter}>
        <div className="ex_save_search_btn">+</div>
        <span className="ex_save_search_text">
          Filter current search results
        </span>
      </div>
      <FilterWrapper
        show={show}
        cancelFilter={cancelFilter}
        applyFilter={applyFilterPrew}
      />
      <div
        className={`wrappForTopSearchItems exclusionsResultsWrapper searchFilterRes`}
      >
        {filtersArray &&
          filtersArray.map((filter, i) => (
            <FilterResult
              name={filter.filterName}
              value={
                filter.dateFrom && filter.dateTo
                  ? `${filter.dateFrom} - ${filter.dateTo}`
                  : filter.value
              }
              removeFilter={deleteResult}
              index={i}
              key={i}
            />
          ))}
      </div>
    </div>
  );
};
