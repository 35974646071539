import { simpleRequest } from "./request";

export const getAuthDataNotification = (url, picker = false, additionalHeaders = null) => {
  let token = localStorage.getItem("token");
  let headers;
  if (additionalHeaders && Array.isArray(additionalHeaders)) {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      [additionalHeaders[0]]: additionalHeaders[1]
    });
  } else {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    });
  }

  const request = new Request(`${process.env.REACT_APP_API_NOTIFICATION + "/" + url}`, {
    method: "GET",
    headers: headers
  });
  return simpleRequest(request);
};


export const putAuthDataNotification = (data="", url, additionalHeaders = null) => {
  let token = localStorage.getItem("token");

  let headers;

  if (additionalHeaders && Array.isArray(additionalHeaders)) {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      [additionalHeaders[0]]: additionalHeaders[1]
    });
  } else {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    });
  }

  const request = new Request(`${process.env.REACT_APP_API_NOTIFICATION + "/" + url}`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(data)
  });
  return simpleRequest(request);
};

export const patchAuthDataNotification = (data="", url, additionalHeaders = null) => {
  let token = localStorage.getItem("token");

  let headers;

  if (additionalHeaders && Array.isArray(additionalHeaders)) {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      [additionalHeaders[0]]: additionalHeaders[1]
    });
  } else {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    });
  }

  const request = new Request(`${process.env.REACT_APP_API_NOTIFICATION + "/" + url}`, {
    method: "PATCH",
    headers: {
      ...headers,
      'Access-Control-Allow-Origin': process.env.REACT_APP_API_NOTIFICATION
    },
    body: JSON.stringify(data),
  });
  return simpleRequest(request);
};

