import React from 'react'
import classes from './lab.module.css';

const RangeValue = ({ value, title = 'Lab Value', type = 'max', onChange, onBlur, small = false }) => {
    return (
        // <div className={classes.selectContainer}>
        <div className={small ? classes.selectContainerSmall : classes.selectContainer}>
            <span className={classes.selectTitle}>{title}</span>
            <input
                type="number"
                value={value}
                placeholder={type}
                className={classes.selectInput}
                onChange={onChange}
                onBlur={onBlur}
            />
        </div>
        // </div>
    )
}

const LabRange = ({ rangeOption, rangeValues, handleLabValueChange, handleSetInfo }) => {
    let content = null;
    const [min, max] = rangeValues;

    if (rangeOption.label === 'Smaller than') {
        content =
            <RangeValue
                value={max}
                type="max"
                onChange={(e) => handleLabValueChange(e, "maxValue")}
                onBlur={() => handleSetInfo("maxValue")}
            />
    }
    else if (rangeOption.label === 'Greater than') {

        content =
            <RangeValue
                value={min}
                type="min"
                onChange={(e) => handleLabValueChange(e, "minValue")}
                onBlur={() => handleSetInfo("minValue")}
            />
    }
    else if (rangeOption.label === 'Between') {
        content =
            <>
                <RangeValue
                    value={min}
                    type="min"
                    title="Min Value"
                    onChange={(e) => handleLabValueChange(e, "minValue")}
                    onBlur={() => handleSetInfo("minValue")}
                    small
                />
                <RangeValue
                    value={max}
                    type="max"
                    title="Max Value"
                    onChange={(e) => handleLabValueChange(e, "maxValue")}
                    onBlur={() => handleSetInfo("maxValue")}
                    small
                />
            </>
    }

    return content
}

export default LabRange