import React from "react";

export default function Spinner({ disList }) {
  return (
    <div className={`spinner-dis-picker ${!disList && "spinner-cnt-dis-pk"}`}>
      <img
        className="medium-spinner"
        src={require("./../../../../images/spinner.gif")}
        alt="Spinner"
      />
    </div>
  );
}
