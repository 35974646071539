import React from "react";
import { Circle } from "rc-progress";
import { analyticsTrialWorkflowStatistic } from "../../../utils/analytics";
import "./index.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const RecruitmentGoal = ({ recruitment_goal }) => {
  let { enroll_goal, enroll_screening, screening_matches } = recruitment_goal;
  let workflowStatistics = analyticsTrialWorkflowStatistic(recruitment_goal);
  const getData = () => {
    let data = [];
    workflowStatistics.forEach((el, index) => {
      if (index !== 0) {
        data.push({
          name: el[0],
          y: el[1]
        });
      }
    });
    return data;
  };
  let options = {
    chart: {
      type: "column",
      showInLegend: true
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      type: "category"
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      bar: {
        showInLegend: true
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}",
          legend: true
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
    },

    series: [
      {
        name: "Patients",
        colorByPoint: true,
        data: getData()
      }
    ]
  };

  return (
    <div className="containerForAnalytics recruitmentGoal">
      <div className="cfa__rg">
        <span className="forcfa__label">Recruitment goal</span>
        <div className="chartBlock rg__chbl">
          <div className="rg__chbl-ls">
            <span className="insideLabelAnalytics">Enrolled Vs Goal</span>
            <Circle
              percent={enroll_goal && enroll_goal.result}
              strokeWidth="2"
              trailWidth="0"
              strokeColor="#0075B3"
              className="lastenCircle"
            />
            <span className="circleNumber">
              {enroll_goal && enroll_goal.result}%
            </span>
            <span className="circleSecNumber">
              {enroll_goal && enroll_goal.total_enrolled}/
              {enroll_goal && enroll_goal.goal}
            </span>
          </div>

          <div className="rg__chbl-ls">
            <span className="insideLabelAnalytics">
              Matches vs Screening Day{" "}
            </span>
            <Circle
              percent={screening_matches && screening_matches.result}
              strokeWidth="2"
              trailWidth="0"
              strokeColor="#5BBEBF"
              className="lastenCircle green"
            />
            <span className="circleNumber">
              {screening_matches && screening_matches.result}%
            </span>
            <span className="circleSecNumber">
              {screening_matches && screening_matches.total_screening}/
              {screening_matches && screening_matches.total_matches}
            </span>
          </div>

          <div className="rg__chbl-ls">
            <span className="insideLabelAnalytics">
              Proceed to screening vs Enrolled
            </span>
            <Circle
              percent={enroll_screening && enroll_screening.result}
              strokeWidth="2"
              trailWidth="0"
              strokeColor="#5BBEBF"
              className="lastenCircle green"
            />
            <span className="circleNumber">
              {enroll_screening && enroll_screening.result}%
            </span>
            <span className="circleSecNumber">
              {enroll_screening && enroll_screening.total_enrolled}/
              {enroll_screening && enroll_screening.total_screening}
            </span>
          </div>

          <div className="rg__chbl-ls">
            <span className="insideLabelAnalytics">Workflow Statistics</span>
            <div className="columnChart">
              {/*<Chart
                options={{
                  showRowNumber: true,
                  bar: { groupWidth: "95%" },
                  legend: { position: "none" }
                }}
                chartType="ColumnChart"
                data={workflowStatistics}
              />
              */}
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentGoal;
