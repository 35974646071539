import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setMedicationsMOAListReducer(
  state = initialState.medicationsMOAList,
  action
) {
  switch (action.type) {
    case types.SET_MEDICATIONS_MOA_LIST:
      return action.medicationsMOAList;
    default:
      return state;
  }
}
