import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import checkicon from "../../../images/icon-check-green.svg";
import crossicon from "../../../images/icon-close-w.svg";
import search from "../../../images/search.svg";

const ImportTrialPopup = ({ status = true, onClose, relocate, trials }) => {
  const [importTrials, changeVisibleTrial] = useState([]);
  const [activeTrial, changeActiveTrial] = useState(null);
  const findImportTrial = ({ target }) => {
    let allTrial = [...trials];
    let result = [];
    if (target.value.length > 0) {
      result = allTrial.filter(el => {
        return (
          el.studyName.toLowerCase().search(target.value.toLowerCase()) !== -1
        );
      });
    } else {
      result = trials;
    }
    changeVisibleTrial(result);
  };

  const checkTrial = id => {
    changeActiveTrial(id);
  };

  useEffect(() => {
    changeVisibleTrial(trials);
  }, [trials]);

  return (
    <Popup
      open={status}
      closeOnDocumentClick
      onClose={onClose}
      className="popup-import"
    >
      <div>
        <div className="closePendingPopupCross" onClick={onClose}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="import-container">
          <div className="import-container__header">
            <h1>Import Trial</h1>
            <p>Please select one of the following options to continue.</p>
          </div>
          <div className="trial-search-container">
            <img className="icon-left-search" src={search} alt="Search" />
            <input
              type="text"
              className="trial-search-field"
              name="trialName"
              id="trialName"
              placeholder="Search for trial"
              onChange={findImportTrial}
            />
          </div>
          <div className="importPopupContainer-trial">
            {importTrials.map(trial => {
              return (
                <div
                  className="importPopup-trial"
                  onClick={() => checkTrial(trial.studyID)}
                  key={trial.studyID}
                >
                  {trial.studyName}
                  <input
                    type="hidden"
                    name={trial.studyName}
                    value={trial.studyID}
                  />
                  <img
                    src={checkicon}
                    className={`importTrialCheck ${activeTrial ===
                      trial.studyID && "importTrialCheck-show"}`}
                    alt="Import Trial"
                  />
                </div>
              );
            })}
          </div>
          <div className="search-container__plusbtn" onClick={relocate}>
            Import Trial
          </div>
        </div>
      </div>
    </Popup>
  );
};

let mapStateToProps = state => ({
  trials: state.trials
});
export default connect(mapStateToProps)(ImportTrialPopup);
