import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function labNamesReducer(state = initialState.labNames, action) {
  switch(action.type) {
    case types.LABNAMES_SUCCESS:
      return action.labNames
    default:
      return state;
  }
}
