export const logout = () => {
  localStorage.removeItem("token");
  window.location.replace("/");
};

var publicURL = ['/', '/patient-details/', '/patient-authorization/', '/account-registration', '/reset-password/']

export const simpleRequest = request => {
  return fetch(request)
    .then(response => {
      if (localStorage.getItem("token")===null && !publicURL.includes(window.location.pathname)) {
        logout();
      } else if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        return response.json();
      }else if (response.status === 401){
        logout()
      } else {
        throw new Error(response);
      }
    })
    .catch(error => {
      return { error };
    });
};

export const NoJSONRequest = request => {
  return fetch(request)
    .then(response => {
      if (localStorage.getItem("token")===null && !publicURL.includes(window.location.pathname)) {
        logout();
      } else {
        return response;
      }
    })
    .catch(error => {
      return { error };
    });
};
