import React, { PureComponent } from 'react'
import checkmark from '../../../images/icon-check-green.svg';
import crossicon from '../../../images/icon-close-grey.svg';

class Note extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      status: this.props.status ? this.props.status : false
    }
  }
  changeNotification = () => {
    this.setState({status: !this.state.status})
    this.props.saveNotification(this.props.notificationName, !this.state.status)
  }
  render() {
    return(
      <div className={this.props.first ? 'first permission-list__item' : 'permission-list__item'}>
        <div className='permission-list__item-title block-item block-item-text popupTextCreateRole'>
          {this.props.notificationName}
        </div>
        <div className='permission-list__item-status' onClick={this.changeNotification}>
          <img src={this.state.status ? checkmark : crossicon  } height='12px'  alt="Permsion Icon"/>
        </div>
      </div>
    )
  }
}

export default Note