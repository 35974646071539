import React, { PureComponent, Fragment } from 'react'
import checkmark from '../../../images/icon-check-green.svg'
import crossicon from '../../../images/icon-close-grey.svg'

class NotificationSample extends PureComponent {
  changeNotification = () => {
    this.setState({ status: !this.state.status })
    this.props.saveNotification(this.props.notificationName, !this.state.status)
  }
  render () {
    return (
      <Fragment>
        <div className='notificationType notificationCol'>
          <span className='title'>{this.props.name}</span>
          <span className='desc'>{this.props.desc}</span>
        </div>
        <div className='SMS smsemailCol'>
          <img src={this.props.sms ? checkmark : crossicon} alt='Cross' />
        </div>
        <div className='E-mail smsemailCol'>
          <img src={this.props.email ? checkmark : crossicon} alt='Cross' />
        </div>
      </Fragment>
    )
  }
}

export default NotificationSample
