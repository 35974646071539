import React, { Component } from "react";
import i18n from "../../i18n";
import crossicon from "../../images/icon-close-w.svg";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import Popup from "reactjs-popup";
import ImmunityApi from "./../../api/InmunityApi";

export default class ContactInfo extends Component {
  constructor() {
    super();
    this.state = {
      edit: false,
      name: "",
      email: [],
      tags: [],
      phone1: "",
      phone2: "",
      note: "",
      other: "",
      contact: {},
      roleValue: "",
      roleOptions: [],
      createSuccess: "",
      immunity: "",
      showCreateContact: false
    };
    this.myRefName = React.createRef();
    this.myRefType = React.createRef();
    this.myRefEmail = React.createRef();
    this.myRefPhone = React.createRef();
    this.myRefOther = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
      ImmunityApi.getContactTypes()
        .then(types => {
          let roleOptions = [];
          let other;
          types.forEach(el => {
            roleOptions.push({ value: el.id, label: el.name });
          });
          other = roleOptions.splice(7, 1);
          roleOptions.push(other[0]);
          if (this.mounted) {
            this.setState({ roleOptions });
          }
        })
        .catch(e => console.log("Error getting types: ", e));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  changeToEdit = () => {
    this.setState({ edit: true });
  };

  handleInputChange = ({ target }) => {
    switch (target.id) {
      case "name":
        this.myRefName.current.classList.remove("redBorder");
        this.setState({ name: target.value });
        break;
      case "email":
        this.myRefEmail.current.classList.remove("redBorder");
        this.setState({ email: target.value });
        break;
      case "phone":
        this.myRefPhone.current.inputElement.classList.remove("redBorder");
        this.setState({ phone1: target.value });
        break;
      case "phone2":
        this.setState({ phone2: target.value });
        break;
      case "contactNotes":
        this.setState({ note: target.value });
        break;
      case "other":
        this.myRefOther.current.classList.remove("redBorder");
        this.setState({ other: target.value });
        break;
      default:
        return null;
    }
  };

  handleSelectChange = roleValue => {
    document.querySelector(".contact-select").classList.remove("redBorder");
    this.setState({ roleValue });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.contact !== state.contact && !props.create) {
      let {
        contact,
        contact: { name, email, phone1, phone2, note, role, immunity }
      } = props;
      let roleValue;
      if (role) {
        roleValue = { value: role.id, label: role.name };
      }
      return {
        name: name || "",
        email: email || "",
        phone1: phone1 || "",
        phone2: phone2 || "",
        note: note || "",
        immunity: immunity || "",
        contact,
        roleValue: roleValue || ""
      };
    } else return null;
  }

  sendForm = () => {
    if (this.myRefName.current.value === "") {
      this.myRefName.current.classList.add("redBorder");
    }
    if (this.myRefType.current.props.value === "") {
      document.querySelector(".contact-select").classList.add("redBorder");
    }
    if (this.state.email.length === 0) {
      this.myRefEmail.current.classList.add("redBorder");
    }
    if (this.myRefPhone.current.props.value === "") {
      this.myRefPhone.current.inputElement.classList.add("redBorder");
    }
    if (
      this.state.roleValue.value === 8 &&
      this.myRefOther.current.value === ""
    ) {
      this.myRefOther.current.classList.add("redBorder");
    }
    let els = document.querySelectorAll(".redBorder");
    els.length === 0 && this.setState({ showCreateContact: true });
  };

  sendData = () => {
    this.setState({ createSuccess: "spinner" });
    const object = {};
    const {
      name,
      email,
      tags,
      phone1,
      phone2,
      note,
      roleValue,
      other,
      edit,
      contact
    } = this.state;
    object.name = name;
    object.email = email;
    object.phone1 = phone1;
    object.phone2 = phone2;
    object.note = note;
    object.role_id = roleValue.value;
    roleValue.value === 8
      ? (object.role = { id: roleValue.value, name: other })
      : (object.role = { id: roleValue.value, name: roleValue.label });

    edit
      ? ImmunityApi.editContact(contact.id, object)
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(text => {
                this.responseCheck(text);
              });
            }
            this.setState(
              { createSuccess: "New contact edited successfully" },
              () => {
                setTimeout(() => {
                  this.props.onClose();
                }, 3000);
              }
            );
          })
          .catch(e => console.log("Error editing contact: ", e))
      : ImmunityApi.createContact(object)
          .then(response => {
            if (response.status !== 201) {
              return response.text().then(text => {
                this.responseCheck(text);
              });
            }
            this.setState(
              { createSuccess: "New contact created successfully" },
              () => {
                setTimeout(() => {
                  this.props.onClose();
                }, 3000);
              }
            );
          })
          .catch(e => console.log("Error creating contact: ", e));
  };

  responseCheck = text => {
    let obj = JSON.parse(text);
    if (obj.email && obj.email[0] === "This field must be unique.") {
      this.setState({
        createSuccess: "Contact email field must be unique!"
      });
      this.myRefEmail.current.classList.add("redBorder");
    } else if (obj.email && obj.email[0] === "Enter a valid email address.") {
      this.setState({
        createSuccess: "Email address is invalid!"
      });
      this.myRefEmail.current.classList.add("redBorder");
    } else if (
      obj.phone1 &&
      obj.phone1[0] === "contact with this phone1 already exists."
    ) {
      this.setState({
        createSuccess: "Sorry, that phone number is in use. The primary phone number of each contact needs to be unique."
      });
      this.myRefPhone.current.inputElement.classList.add("redBorder");
    } else if (
      obj.phone1 &&
      obj.phone1[0] === "The phone number entered is not valid."
    ) {
      this.setState({
        createSuccess: "The phone number entered is not valid!"
      });
      this.myRefPhone.current.inputElement.classList.add("redBorder");
    } else {
      this.setState({
        createSuccess: "Error occured while creating new contact!"
      });
    }
  };

  cancel = () => {
    this.setState({ edit: false });
  };

  handleCloseStatus = () => {
    this.setState({
      showCreateContact: false,
      createSuccess: ""
    });
  };

  removeTag = (i) => {
    const newTags = [ ...this.state.email ];
    newTags.splice(i, 1);
    this.setState({ email: newTags });
  }

  inputKeyDown = (e) => {
    const val = e.target.value;
    var mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.key === 'Enter' && val) {
      if (this.state.email.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      if(val.match(mailformat)) {
        this.setState({ email: [...this.state.email, val]});
        this.tagInput.value = null;
      }
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.email.length - 1);
    }
  }

  validateEmail = (e) => {
    const val = e.target.value;
    let mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(val.match(mailformat)) {
      e.target.classList.remove("redBorder");
      this.myRefEmail.current.classList.remove("redBorder");
    } else {
      e.target.classList.add("redBorder");
    }
  }

  render() {
    let { contact, create } = this.props;
    const {
      edit,
      name,
      email,
      phone1,
      phone2,
      note,
      other,
      roleValue,
      roleOptions,
      showCreateContact,
      immunity,
      tags
    } = this.state;
    return (
      <div style={{ maxHeight: "95vh" }}>
        <div
          className="closePendingPopupCross"
          onClick={() => this.props.onClose()}
        >
          <img src={crossicon} alt="Close" />
        </div>
        {Object.keys(contact).length > 0 || create ? (
          <div className="contact-form">
            {!create ? (
              <h3 className="title-form">{`${
                edit
                  ? i18n.t("general.editContactInfo")
                  : i18n.t("general.contactInfo")
              }`}</h3>
            ) : (
              <h3 className="title-form">{i18n.t("general.newContact")}</h3>
            )}
            <div className="in_filds_wrapp">
              <div className="input-container">
                <label htmlFor="name" className="label-modal">
                  {i18n.t("general.name")}
                </label>
                <input
                  id="name"
                  className="field-modal"
                  disabled={edit || create ? false : true}
                  onChange={this.handleInputChange}
                  value={name}
                  ref={this.myRefName}
                />
              </div>
              {immunity && (
                <div className="input-container">
                  <label htmlFor="immunity-id" className="label-modal">
                    {i18n.t("general.immunityId")}
                  </label>
                  <input
                    id="immunity-id"
                    className="field-modal"
                    disabled={true}
                    onChange={this.handleInputChange}
                    value={immunity}
                  />
                </div>
              )}
              <div className="input-container newTrialWrapp contact-select-wr">
                <label htmlFor="contactType" className="label-modal">
                  {i18n.t("general.contactType")}
                </label>
                <Select
                  value={roleValue}
                  onChange={this.handleSelectChange}
                  isDisabled={
                    edit || create
                      ? !create &&
                        (roleValue.value === 1 ||
                          roleValue.value === 2 ||
                          roleValue.value === 4)
                        ? true
                        : false
                      : true
                  }
                  options={roleOptions}
                  className="sort_select withInput width100 contact-select"
                  ref={this.myRefType}
                />
              </div>
              {roleValue.value === 8 && (
                <div className="input-container">
                  <label htmlFor="other" className="label-modal">
                    {i18n.t("general.other")}
                  </label>
                  <input
                    id="other"
                    value={other}
                    className="field-modal"
                    disabled={edit || create ? false : true}
                    onChange={this.handleInputChange}
                    ref={this.myRefOther}
                  />
                </div>
              )}
              {/* <div className="input-container">
                <label htmlFor="email" className="label-modal">
                  {i18n.t("general.email")}
                </label>
                <input
                  id="email"
                  value={email}
                  className="field-modal"
                  disabled={edit || create ? false : true}
                  onChange={this.handleInputChange}
                  
                />
              </div> */}
              <div className="input-container">
                <label htmlFor="email" className="label-modal">
                  {i18n.t("general.email")}
                </label>
                <ul 
                  className="input-tag__tags field-modal"
                  ref={this.myRefEmail}
                >
                  { email.map((tag, i) => (
                    <li key={tag}>
                      {tag}
                      { (edit || create) && <button type="button" onClick={() => { this.removeTag(i); }}>+</button>}
                    </li>
                  ))}
                  { (edit || create) &&
                    <li className="input-tag__tags__input">
                      <input 
                        type="text" 
                        id="email"
                        placeholder="email@example.com"
                        className="field-modal"
                        onChange={this.validateEmail}
                        onKeyDown={this.inputKeyDown}
                        ref={c => { this.tagInput = c; }} 
                        // 
                        disabled={ (edit || create) ? false : true }
                      />
                    </li>
                  }
                </ul>
              </div>
              <div className="input-container">
                <label htmlFor="phone" className="label-modal">
                  {i18n.t("general.phoneNumber")}
                </label>
                <MaskedInput
                  mask={[
                    "+",
                    "1",
                    " ",
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder={i18n.t("accountRegistration.placeHphone")}
                  id="phone"
                  value={phone1}
                  className="field-modal"
                  disabled={edit || create ? false : true}
                  onChange={this.handleInputChange}
                  ref={this.myRefPhone}
                />
              </div>
              <div className="input-container">
                <label htmlFor="phone2" className="label-modal">
                  {i18n.t("general.phoneNumber2")}
                </label>
                <MaskedInput
                  mask={[
                    "+",
                    "1",
                    " ",
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder={i18n.t("accountRegistration.placeHphone")}
                  id="phone2"
                  value={phone2}
                  className="field-modal"
                  disabled={edit || create ? false : true}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="input-container-larger">
                <label htmlFor="contactNotes" className="label-modal">
                  {i18n.t("general.contactNotes")}
                </label>
                <textarea
                  id="contactNotes"
                  className="TrialInformationWrapper"
                  disabled={edit || create ? false : true}
                  value={note}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="button-container">
              {edit && (
                <div className="cancel-button" onClick={this.cancel}>
                  {i18n.t("general.cancel")}
                </div>
              )}
              {!create ? (
                edit ? (
                  <div className="submit-button" onClick={this.sendForm}>
                    {i18n.t("general.editContact")}
                  </div>
                ) : (
                  <div className="submit-button" onClick={this.changeToEdit}>
                    {i18n.t("general.goToEdit")}
                  </div>
                )
              ) : (
                <div className="submit-button" onClick={this.sendForm}>
                  {i18n.t("general.createContact")}
                </div>
              )}
            </div>
            <Popup
              open={showCreateContact}
              closeOnDocumentClick
              onClose={this.handleCloseStatus}
              className="popup-newtrial wider"
            >
              <div>
                <div
                  className="closePendingPopupCross"
                  onClick={this.handleCloseStatus}
                >
                  <img src={crossicon} alt="Cross" />
                </div>
                <div className="newTrialPopupWrapper">
                  {this.state.createSuccess === "" ? (
                    <div className="import-container__header">
                      <h1>Please confirm to save contact.</h1>
                      <div className="forNTWBtns">
                        <div className="fromScratch" onClick={this.sendData}>
                          Confirm
                        </div>
                        <div
                          className="fromTemplate"
                          onClick={this.handleCloseStatus}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="import-container__header">
                      {this.state.createSuccess !== "spinner" ? (
                        <h1>{this.state.createSuccess}</h1>
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            className="medium-spinner"
                            src={require("./../../images/spinner.gif")}
                            alt="Spinner"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Popup>
          </div>
        ) : (
          <div className="modal-container">
            <div className="contact-form">
              <div className="spinner-container">
                <img
                  className="medium-spinner"
                  src={require("../../images/spinner.gif")}
                  alt="Spinner"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
