import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history, persistor } from "./store";
import { PersistGate } from 'redux-persist/lib/integration/react';
import * as Sentry from '@sentry/browser';

import App from "./components/App";

import {
  // loadDiseases,
  loadLabNames,
  loadLabUoms,
  loadLabWithUom
} from "./actions/trialActions";
import {loadPhysicians} from "./actions/physiciansActions"
import "./i18n";
// store.dispatch(loadDiseases())
if (localStorage.getItem("token")){
  store.dispatch(loadPhysicians());
  store.dispatch(loadLabNames());
  store.dispatch(loadLabUoms());
  store.dispatch(loadLabWithUom());
}

// store.dispatch(setCurrentTrial(Object.keys(va.trials)[0]));

Sentry.init({dsn: "https://43c8c62095184bd986fc2c581835a824@sentry.io/1843012", environment: process.env.REACT_APP_SENTRY});

const target = document.querySelector("#root");
render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);


if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = () =>  (
      <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
    </PersistGate>
  </Provider>
    )
    render(
      <NextApp />,
      target
    )
  })
}