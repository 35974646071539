import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";

import "react-table/react-table.css";

const LabTable = ({ labValues }) => {
	const [expanded, setExpanded] = useState({});
	useEffect(() => {
		// document.addEventListener("waiting", () => console.log("hi"))
	}, []);
	return (
		<div className="ntw__ti ntw__tablePlaceboDrugs labPopup medication">
			<ReactTable
				resizable={false}
				defaultFilterMethod={(filter, row) => {
					const id = filter.pivotId || filter.id;
					return row[id] !== undefined
						? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
						: true;
				}}
				expanded={expanded}
				onExpandedChange={newExpanded => {
					setExpanded(newExpanded);
				}}
				pivotBy={["name"]}
				data={labValues}
				columns={[
					{
						Header: "Condition Name",
						accessor: "name",
					},
					{
						Header: "Dosage",
						accessor: "doseValRx",
					},
					{
						Header: "UoM",
						accessor: "doseUnitRx",
					},
					{
						Header: "ProdStrength",
						accessor: "prodStrength",
					},
					{
						Header: "Route",
						accessor: "route",
					},
					{
						id: "chartTime",
						Header: "Date",
						accessor: d => {
							return moment(d.startDate).format("MMM DD YYYY");
						},
					},
				]}
				showPagination={false}
			/>
		</div>
	);
};

export default LabTable;
