import * as types from "../actions/actionTypes";

const initialState = ""

export default function farLabel(state = initialState, action) {
  switch (action.type) {
    case types.SET_FAR_LABEL:
      return action.label;
    default:
      return state;
  }
}
