export const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 4) {
    errors.password = "4 characters minimum";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const validateRegistration = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length < 2) {
    errors.firstName = "2 characters minimum";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length < 2) {
    errors.lastName = "2 characters minimum";
  }
  if (values.firstPhone) {
    let phone = values.firstPhone
      .split(" ")
      .map(el => {
        if (el[0] === "(") {
          return el.slice(1, -1);
        }
        return el;
      })
      .join("");
    if (!phone) {
      errors.firstPhone = "Required";
    }
    if (!/\+1[0-9]{10}/.test(phone)) {
      errors.firstPhone = "Invalid first phone";
    }
  }
  if (values.secondPhone) {
    let phone = values.secondPhone
      .split(" ")
      .map(el => {
        if (el[0] === "(") {
          return el.slice(1, -1);
        }
        return el;
      })
      .join("");
    if (!phone) {
      errors.secondPhone = "Required";
    }
    if (!/\+1[0-9]{10}/.test(phone)) {
      errors.secondPhone = "Invalid first phone";
    }
  }
  errors.FirstPassword = {};
  errors.SecondPassword = {};
  if (!values.FirstPassword) {
    errors.FirstPassword["error"] = "Required";
    errors.FirstPassword["characters"] = true;
    errors.FirstPassword["uppercase"] = true;
    errors.FirstPassword["lowercase"] = true;
    errors.FirstPassword["number"] = true;
  } else {
    if (!/^([A-Za-z0-9]){8,15}/.test(values.FirstPassword)) {
      errors.FirstPassword["characters"] = true;
    }
    if (!/^(?=.*[a-z])/.test(values.FirstPassword)) {
      errors.FirstPassword["lowercase"] = true;
    }
    if (!/^(?=.*[A-Z])/.test(values.FirstPassword)) {
      errors.FirstPassword["uppercase"] = true;
    }
    if (!/^(?=.*[0-9])/.test(values.FirstPassword)) {
      errors.FirstPassword["number"] = true;
    }
  }
  if (!values.SecondPassword) {
    errors.SecondPassword["error"] = "Required";
    errors.SecondPassword["characters"] = true;
    errors.SecondPassword["uppercase"] = true;
    errors.SecondPassword["lowercase"] = true;
    errors.SecondPassword["number"] = true;
  } else {
    if (!/^([A-Za-z0-9]){8,15}/.test(values.SecondPassword)) {
      errors.SecondPassword["characters"] = true;
    }
    if (!/^(?=.*[a-z])/.test(values.SecondPassword)) {
      errors.SecondPassword["lowercase"] = true;
    }
    if (!/^(?=.*[A-Z])/.test(values.SecondPassword)) {
      errors.SecondPassword["uppercase"] = true;
    }
    if (!/^(?=.*[0-9])/.test(values.SecondPassword)) {
      errors.SecondPassword["number"] = true;
    }
  }
  if (Object.keys(errors.FirstPassword).length === 0) {
    delete errors.FirstPassword;
  }
  if (Object.keys(errors.SecondPassword).length === 0) {
    delete errors.SecondPassword;
  }
  return errors;
};
