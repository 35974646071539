import React, { useCallback } from 'react'
import handleNullDate from '../../../../../utils/handleNullDate';
import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";

const LabCalendar = React.forwardRef((props, ref) => {
    const { handleFromChange, handleToChange, from, to } = props.data;

    const focusToPicker = useCallback(
        () => { ref.current.getInput().focus() },
        [ref],
    )

    return (
        <div className="ntw__ti-input ntw__ti-iTrialName nextLine dateRange dateRange_fix forGettingValueFields">
            <span className="InputFromTo-from">
                <span className="ntw__ti-inputlabel">
                    Start date
                    </span>
                <DayPickerInput
                    value={handleNullDate(from)}
                    placeholder="From"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        numberOfMonths: 2,
                        onDayClick: focusToPicker
                    }}
                    onDayChange={handleFromChange}
                />
            </span>
            <span>_</span>
            <span className="InputFromTo-to">
                <span className="ntw__ti-inputlabel">End date</span>
                <DayPickerInput
                    ref={ref}
                    value={handleNullDate(to)}
                    placeholder="To"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        month: from,
                        fromMonth: from,
                        numberOfMonths: 2
                    }}
                    onDayChange={handleToChange}
                />
            </span>
        </div>
    )
})

export default LabCalendar