import React, { useState, useEffect } from "react";
import FilterItem from "./item";

export default function Filters(props) {
  let { filters, type, deleteFilter } = props;
  const [, setFilter] = useState([]);
  const [filterInclude, setFilterInclude] = useState([]);
  const [filterExclude, setFilterExclude] = useState([]);

  const renderIncludeExclude = data => {
    let filterInclude = [],
      filterExclude = [];
    data.include && data.include.map(item => filterInclude.push(item));
    data.exclude && data.exclude.map(item => filterExclude.push(item));
    setFilterInclude(filterInclude);
    setFilterExclude(filterExclude);
  };
  useEffect(() => {
    switch (type) {
      case "medication":
      case "disease":
        renderIncludeExclude(filters);
        break;
      case "procedure":
      case "allergy":
      case "lab":
      case "substance":
      case "smoking":
        renderIncludeExclude(filters);
        break;
      case "searchDate":
        break;
      case "immunity":
      case "search_id":
        break;
      default:
        setFilter(filters);
        break;
    }
  }, [filters]);
  return (
    <>
      {Array.isArray(filters) &&
        filters.map((item, key) => {
          return (
            <FilterItem
              deleteFilter={deleteFilter}
              elName={item.name}
              required={item.required}
              type={type}
              key={key}
            />
          );
        })}
      {filterExclude.map((item, key) => {
        return (
          <FilterItem
            deleteFilter={deleteFilter}
            elName={item.name}
            type={type}
            required={item.required}
            key={key}
            exclude
          />
        );
      })}
      {filterInclude.map((item, key) => {
        return (
          <FilterItem
            deleteFilter={deleteFilter}
            elName={item.name}
            required={item.required}
            type={type}
            key={key}
            include
          />
        );
      })}
    </>
  );
}
